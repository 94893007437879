<template>
  <div class="mb-5 flex bg-grey-1 p-3 rounded border-grey-light border border-solid">
    <div class="flex-auto">
      <h5 class="mb-2 text-grey-darker font-normal text-xs">
         {{ $t('mcs.messages.sms.select[0]') }}
      </h5>
      <h5 class="text-xs">
        <span class="font-normal">
          {{ selected.template.templateName }}
        </span>
        <span class="ml-1 font-medium">
          {{ selected.template.languageName }}
        </span>
      </h5>
    </div>
    <div class="flex-auto text-right">
      <el-button
        @click="discard"
        size="small"
      >
        {{ $t('actions.discard') }}
      </el-button>
      <el-button
        @click="$emit('select-template')"
        type="primary"
        size="small"
      >
        {{ $t('mcs.messages.sms.select[1]') }}
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'message_ca_selected_template',

  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    discard() {
      this.$confirm(this.$t('mcs.messages.sms_engage.selected[2]'), this.$t('actions.discard'), {
        confirmButtonText: this.$t('actions.ok'),
        cancelButtonText: this.$t('actions.cancel'),
        type: 'warning',
      }).then(() => {
        this.$emit('reset-template');
      }).catch(() => { });
    },
  },
};
</script>
