<template>
  <div :class="`ca-preview ${channel.toLowerCase()}`">
    <h1 class="text-base text-black mb-5">
      Message Preview
    </h1>
    <div>
      <div class="preview-phone-header">
        <ChannelsFallback :channels="channels" :with-label="false" />
      </div>
      <div v-if="channel.match(/WA/i)" :class="[
          'p-5 prev-content max-h-full overflow-y-auto',
        ]">
        <div v-if="Object.keys(header).length" class="text-grey-dark bg-grey-lightest rounded-t shadow">
          <div class="text-center" v-if="header.type !== 'text'">
            <div v-if="header.type === 'image'" class="w-full">
              <ImageDisplay :file-url="getFileUrl(customFields)" :is-preview="Boolean(true)" />
            </div>
            <div v-else class="py-6 px-2">
              <img :src="urlResolver(
                    bgUrl,
                    require(`@/assets/media/${getMediaIcons(header.type, customFields)}.svg`)
                  )" :style="{width: '40px', height: '40px'}" class="align-middle" :alt="header.type" />
            </div>
            <div v-if="header.type === 'location'" class="bg-grey-shade-custom text-left py-2">
              <span class="p-1 ml-2 text-xs block">{{ getLocationFields(customFields).name }}</span>
              <span class="text-grey-dark p-1 ml-2 text-xs block">{{ getLocationFields(customFields).address }}</span>
            </div>
          </div>
        </div>
        <div v-else class="text-sm font-semibold text-black"
          v-html="replaceHeaderField(header.value, customFields, variables)" />
        <div v-if="getMessage() || footer" :class="[
              'min-w-full bg-white p-2 bg-green-lighter',
              'text-xs',
              { 'rounded-bl-lg rounded-r-lg': !Object.keys(header).length },
            ]">
          <div class="prev-wrapper whitespace-pre-wrap break-words text-black leading-tight"
            v-html="getWAPreviewContent(getMessage(), customFields, variables)" />
          <div class="mt-3 text-grey-dark" :style="{fontSize: '10px'}" v-if="footer">
            {{ footer }}
          </div>
          <div class="mt-3 border-t solid border-grey-light text-grey-dark p-2 text-base text-center"
            v-if="hasCTAButtons()">
            <div v-for="(btn,i) in waButtons" :key="i" class="py-2">
              <div v-if="btn.type.toLowerCase() === 'phone_number'">
                <a :href="`tel:${btn.phoneNumber}`" target="_blank" class="text-blue hover:text-blue-light no-underline"
                  rel="nofollow noopener">
                  <img :src="getTemplateIcon(phoneIcon)" class="align-middle" :style="{height: '15px'}" />
                  <span class="align-middle"> {{ btn.text }} </span>
                </a>
              </div>

              <div v-if="btn.type.toLowerCase() === 'url'">
                <a :href="`${replaceURLSuffix(btn.url, customFields, variables)}`" target="_blank"
                  class="text-blue hover:text-blue-light no-underline" rel="nofollow noopener">
                  <img :src="getTemplateIcon(externalLinkIcon)" class="align-middle" :style="{height: '15px'}" />
                  <span class="align-middle"> {{ btn.text }} </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasQRButtons()" class="mt-3">
          <div class="flex flex-wrap">
            <div v-for="(btn,i) in waButtons" :key="i" class="quick-btn px-1">
              <el-button size="small"
                class="w-full block bg-white shadow rounded mb-3 text-blue border border-transparent">
                {{ btn.text }}
              </el-button>
            </div>
          </div>
        </div>
      </div>

      <div v-else :class="[
            'bg-white p-3 prev-content max-h-full overflow-y-auto rounded-b-lg',
            { 'bg-purple-lightest-1' : channel.match(/VB/i)},
            { 'bg-green-lightest-1' : channel.match(/LN/i)}
          ]">
        <div v-if="body || imageInputs || Object.keys(buttonInputs).length > 0 && buttonInputs.title"
          class="rounded-lg bg-white shadow">
          <div v-if="getMessage()" :class="[
                'body rounded-t-lg',
                { 'bg-grey-lighter' : channel.match(/VB|LN/i)},
              ]">
            <div class="leading-normal min-w-full p-2
                    text-xs prev-wrapper whitespace-pre-wrap break-words max-h-full"
              v-html="getNonWAPreviewContent(getMessage(), customFields, variables)" />
          </div>
          <div v-if="imageInputs" class="image text-center">
            <div v-if="imageInputs.previewUrl.match(/\/s3\/uploads/)" ref="imageInput" />
            <img v-else :src="imageInputs.previewUrl" :alt="imageInputs.previewUrl" />
          </div>
          <div v-if="Object.keys(buttonInputs).length > 0 && buttonInputs.title" :class="[
                'button text-center px-4 py-4 rounded-b-lg',
                { 'bg-grey-lighter' : channel.match(/VB/i)},
              ]">
            <a :href="buttonInputs.url" rel="noopener noreferrer" target="_blank" class="'bg-white rounded-full py-2 px-4 text-sm font-medium no-underline
                shadow border solid border-grey-lighter block'" :class="[{
                  'bg-purple border-transparent text-white hover:bg-purple-light' :
                  channel.match(/VB/i)
                }]">
              {{buttonInputs.title}}
            </a>
          </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
import axios from 'axios';
import { urlResolver } from '@/utils/common';

import previewContentMixin from '@/mixins/preview-content';
import ChannelsFallback from '@/components/ChannelsFallback.vue';
import ImageDisplay from '@/components/ImageDisplay.vue';

import phoneIcon from '@/assets/misc/phone.svg';
import externalLinkIcon from '@/assets/misc/external-link.svg';

export default {
  name: 'message_ca_preview',

  mixins: [previewContentMixin],

  components: {
    ChannelsFallback,
    ImageDisplay,
  },

  props: {
    header: {
      type: Object,
      default: () => ({}),
    },

    body: {
      type: String,
      default: '',
    },

    imageInputs: {
      type: String,
      default: '',
    },

    buttonInputs: {
      type: Object,
      default: () => ({}),
    },

    footer: {
      type: String,
      default: '',
    },

    waButtons: {
      type: Array,
      default: () => [],
    },

    channel: {
      type: String,
      default: '',
    },

    customFields: {
      type: Array,
      default: () => [],
    },

    variables: {
      type: Array,
      default: () => [],
    },

    channels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
      phoneIcon,
      externalLinkIcon,
    };
  },

  watch: {
    imageInputs(image) {
      if (Object.keys(image).length) {
        this.$nextTick(() => {
          this.downloadImage();
        });
      }
    },
  },

  methods: {
    getTemplateIcon(icon) {
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${icon}`;
    },

    getMessage() {
      if (this.body.trim() && this.body !== '<p></p>') {
        return this.body;
      }

      return '';
    },

    urlResolver(bgurl, path) {
      return urlResolver(bgurl, path);
    },

    hasCTAButtons() {
      const { waButtons } = this;
      return waButtons.length
        && waButtons.some(v => ['url', 'phone_number'].includes(v.type.toLowerCase()));
    },

    hasQRButtons() {
      const { waButtons } = this;
      return waButtons.length
        && waButtons.some(v => v.type.toLowerCase() === 'quick_reply');
    },

    getMediaIcons(type = 'image', customFields) {
      let iconColor = 'dark';

      const hasValue = customFields.some(v => v.headerType === type && v.value);

      if (hasValue) iconColor = 'green';

      return `${type}-${iconColor}`;
    },

    getFileUrl(customFields = []) {
      let fileUrl = '';
      const obj = customFields.find(v => v && v.headerType === 'image');
      if (obj && obj.value) {
        fileUrl = obj.value;
      }

      return fileUrl;
    },

    downloadImage() {
      if (this.imageInputs && this.$refs.imageInput && this.imageInputs.previewUrl.match(/\/s3\/uploads/)) {
        const imgBox = this.$refs.imageInput;
        const fileUrl = this.imageInputs.previewUrl;
        imgBox.innerHTML = '';

        // const img = new Image();
        // img.src = 'https://picsum.photos/200/300';
        // img.alt = 'loading...';
        // img.style.verticalAlign = 'middle';
        // img.style.width = '100%';
        // img.style.height = 'auto';

        // img.onload = () => {
        //   imgBox.appendChild(img);
        // };

        // fetching images with jwt
        const jwt = localStorage.getItem('WWW-Authenticate');

        axios.get(fileUrl, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }).then((response) => {
          const img = new Image();

          img.src = response.data.url;
          img.alt = 'loading...';
          img.style.verticalAlign = 'middle';
          img.style.width = '100%';
          img.style.height = 'auto';

          img.onload = () => {
            imgBox.appendChild(img);
          };
        }).catch((err) => {
          console.log(err);
        });
      }
    },
  },
};
</script>
<style lang="scss">
  .ca-preview {
    width: 450px;
    padding: 20px;
    border-bottom: 1px solid #A3A3A3;
    padding-bottom: 0;

    .prev-content {
      min-height: 330px;
      max-height: 315px;
      padding-bottom: 2rem;
      border: 8px solid #A3A3A3;
      border-top: 0;
      border-bottom: 0;

      p {
        max-width: 15rem;
        word-break: normal;
      }

      .button {
        a {
          border: 1px solid #ccc;
        }
      }
    }

    .___arw {
      font-weight: 600 !important;
    }
  }

  .bg-purple-lightest-1 {
    background-color: #F8F8FF;
  }

  .bg-green-lightest-1 {
    background-color: #F6FEF6;
  }

  .bg-grey-shade-custom {
    background-color: #f5f5f5;
  }
</style>
