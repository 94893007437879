<template>
  <div>
    <div class="flex justify-between">
      <h1 class="mb-5 text-xl text-color font-semibold page-title">Create a new campaign</h1>
      <div class="flex flex-col justify-center -mt-3 mr-3">
        <el-breadcrumb separator="_________">
          <el-breadcrumb-item
            v-for="(item, i) in processList"
            :key="i"
            class="br-item"
          >
            <i
              v-if="getStepIndexByName(step) > i || campaignSent"
              class="mr-1 align-middle icon-novo-wrap-up text-blue-dark font-semibold"
            />
            <i v-else
              class="mr-1 align-middle icon-novo-radio font-semibold"
              :class="{ 'text-blue-dark font-black': getStepIndexByName(step) >= i }"
            />
            {{item.label}}
          </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
  </div>
    <div class="bg-white rounded">
      <div v-if="campaignSent" class="container mb-10">
        <div class="m-20 mt-0 p-5">
          <div class="text-center mb-5">
            <img :src="getIcon('checkIcon')" alt="icon" class="align-middle" :style="{width: '30px', height: '30px'}" />
            <i18n v-if="campaignSent && campaignSent.smsCampaignApproval" path="mcs.main[0]" tag="span">
              <template #campaignName>
                <span class="font-semibold">{{ form.CampaignDetails.campaignName }}</span>
              </template>
            </i18n>
            <i18n v-else path="mcs.main[1]" tag="span" class="ml-5">
              <template #campaignName>
                <span class="font-semibold">{{ form.CampaignDetails.campaignName }}</span>
              </template>
            </i18n>
          </div>
          <div class="text-center mb-10">
            <el-button class="ml-3" id="mcs-go-to-campaign" @click="goToCampaigns()">
              {{ $t('mcs.main[3]') }}
            </el-button>
            <el-button type="primary" id="mcs-create-another-campaign" @click="sendAgain()">
              {{ $t('mcs.main[2]') }}
            </el-button>
          </div>
          <el-row :gutter="20" class="send-details">
            <el-col :span="8">
              <Details :messaging-type="form.Type" :sub-account="form.SubAccountId" :channels="channels" />
              <ValidRecipients :total="getTotals().totalValidRecipients"
                :valid-percentage="getTotals().validPercentage" />
            </el-col>
            <el-col :span="8">
              <MessagePreview :has-whats-app="hasWhatsApp()" :message-type="form.Type" :message="form.Message.message"
                :custom-fields="form.Message.customFields" :variables="variables"
                :sms-parts="form.Message.smsParts ? form.Message.smsParts : 0" :total-sms="getTotals().totalSmsMsgs"
                :header="form.Message.header" :footer="form.Message.footer" :wa-buttons="form.Message.waButtons"
                :image-inputs="form.Message.imageInputs" :button-inputs="form.Message.buttonInputs"
                :channel="getMainChannel()" :total-ca-msgs="getTotals().totalCaMsgs"
                :total-voice-msgs="getTotals().totalVoiceMsgs" />
            </el-col>
            <el-col :span="8">
              <EstimatedCost v-if="showCost" :estimated-cost="getEstimatedCostDisplay()" />
              <ProcessWhen :send-now="form.CampaignDetails.sendNow" :schedule-date="form.CampaignDetails.scheduleDate"
                :estimated-process-time="getEstimatedTimeDisplay()"
                :schedule-timezone="form.CampaignDetails.scheduleTimezone" />
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-else>
        <div>
          <component :is="formComponent" :loading="loading" :form="form" :breadcrumbs="processList"
            @reset-all="resetAll" @discard="discardForm" @submit="submitForm"
            @change-process-index="changeProcessIndex" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapGetters,
  mapActions,
} from 'vuex';

import moment from 'moment';
import momentz from 'moment-timezone';
import _ from 'lodash';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import CampaignDetailsMixin from '@/mixins/campaign-details';
import countriesJson from '@/json/countries.json';
import channelsJson from '@/json/channels.json';
import { removeBrackets, transformToWAValidTemplate } from '@/utils/common';
import checkIcon from '@/assets/misc/recepients.svg';
import ChannelsForm from './partials/Channels.vue';
import MessagesForm from './partials/Messages/index.vue';
import RecipientsContacts from './partials/RecipientsContacts/index.vue';
import SendForm from './partials/Send/index.vue';

import Details from './partials/Send/partials/Details.vue';
import ValidRecipients from './partials/Send/partials/Recipients.vue';
import MessagePreview from './partials/Send/partials/MessagePreview.vue';
import EstimatedCost from './partials/Send/partials/EstimatedCost.vue';
import ProcessWhen from './partials/Send/partials/ProcessWhen.vue';


export default {
  name: 'sender',

  components: {
    Breadcrumbs,
    Details,
    ValidRecipients,
    MessagePreview,
    EstimatedCost,
    ProcessWhen,
  },

  mixins: [CampaignDetailsMixin],

  computed: {
    ...mapGetters({
      form: 'getForm',
      step: 'getStep',
      timezones: 'common/getTimeZones',
      campaignSent: 'websender/getCampaignSent',
      socketId: 'socket/getSocketId',
      queueUrl: 'socket/getQueueUrl',
      recipientsFormStepNew: 'recipientsContacts/getFormStep',
      summaryNew: 'recipientsContacts/getSummary',
      user: 'user/getUser',
      hasMcsAccess: 'user/getMcsAccess',
      hasPricingEntitlement: 'user/getPricingEntitlementAccess',
      shouldHideCost: 'user/getHideCostFlag',
      messageSamples: 'websender/getMessageSamples',
    }),

    showCost() {
      return this.hasPricingEntitlement && !this.shouldHideCost;
    },

    summary() {
      return this.summaryNew;
    },

    recipientsFormStep() {
      return this.recipientsFormStepNew;
    },

    formComponent() {
      let form = null;
      switch (this.step) {
        case 'CHANNEL':
          form = ChannelsForm;
          break;
        case 'RECIPIENTS':
          form = RecipientsContacts;
          break;
        case 'MESSAGE':
          form = MessagesForm;
          break;
        case 'SEND':
          form = SendForm;
          break;
        default:
          form = ChannelsForm;
          break;
      }
      return form;
    },
  },

  watch: {
    step(val, oldVal) {
      const formKeys = Object.keys(this.form);
      const currBreadcrumb = this.processList[this.getStepIndexByName(oldVal)];

      if (!formKeys.length) {
        this.processList = JSON.parse(JSON.stringify(this.defaultProcessList));
      } else {
        this.processList[this.getStepIndexByName(val)].disable = false;
      }

      // Update breadcrumbs channel title
      if (formKeys.length && oldVal.match(/CHANNEL/g)
          && ['Type', 'SubAccountId'].every(k => formKeys.indexOf(k) > -1)) {
        const channelName = this.form.Channels.length ? channelsJson.find(c => c.code
          === this.form.Channels[0]).name : this.$t('mcs.chat_app_msg');

        let newTitle = '';

        if (this.form.Type === 'SMS') {
          newTitle = this.$t('mcs.main[6]');
        } else if (this.form.Type === 'SMS_ENGAGE') {
          newTitle = this.$t('mcs.main[7]');
        } else if (this.form.Type === 'VOICE') {
          newTitle = this.$t('mcs.main[8]');
        } else {
          newTitle = `${this.$t('actions.send')} ${channelName}`;
        }

        currBreadcrumb.label = newTitle;

        // Step rules
        currBreadcrumb.error = !this.form.SubAccountUid || !this.form.SubAccountId
          || !this.form.Type;
        if (currBreadcrumb.error) {
          this.setStep(oldVal);
          this.processList[this.getStepIndexByName(val)].disable = false;
        }
      } else if (val.match(/CHANNEL/g) || !formKeys.length) {
        this.processList[this.getStepIndexByName('CHANNEL')].label = this.$t('mcs.main[9]');
      }

      // Update breadcrumbs recipients title
      if (formKeys.length && oldVal.match(/RECIPIENTS/g) && Object.keys(this.summary).length) {
        currBreadcrumb.label = this.$tc('column_labels.recipients', 1);
      } else if (val.match(/RECIPIENTS/g) || !formKeys.length) {
        this.processList[this.getStepIndexByName('RECIPIENTS')].label = this.$tc('column_labels.recipients', 2);
      }
    },
  },

  data() {
    const defaultProcessList = [
      {
        title: 'Choose a channel',
        label: this.$t('mcs.main[9]'),
        disable: false,
        name: 'CHANNEL',
        error: false,
      },
      {
        title: 'Add recipients',
        label: this.$t('mcs.main[11]'),
        disable: true,
        name: 'RECIPIENTS',
        error: false,
      },
      {
        title: 'Compose a message',
        label: this.$t('mcs.main[12]'),
        disable: true,
        name: 'MESSAGE',
        error: false,
      },
      {
        title: 'Send',
        label: this.$t('actions.send'),
        disable: true,
        name: 'SEND',
        error: false,
      },
    ];

    return {
      loading: false,
      defaultProcessList,
      processList: JSON.parse(JSON.stringify(defaultProcessList)),
      defaultForm: {
        appType: 'CHAT_APPS',
        subaccountId: '',
      },
    };
  },

  beforeRouteLeave(to, from, next) {
    this.setPartialData(undefined);
    this.setCampaignSent(undefined);
    this.setStep('CHANNEL');
    this.resetForm();
    this.setRecipientStep('ADD_RECIPIENT');
    this.setRecipientStepNew('ADD_RECIPIENT');

    next();
  },

  created() {
    // eslint-disable-next-line no-console
    this.getUser();
    this.getTimeZones();
    this.setCampaignSent(undefined);
    this.resetForm();
    this.setStep('CHANNEL');
  },

  methods: {
    ...mapMutations({
      resetForm: 'RESET_FORM',
      updateForm: 'UPDATE_FORM',
      setStep: 'SET_STEP',
      setCampaignSent: 'websender/SET_CAMPAIGN_SENT_DATA',
      setRecipientStep: 'recipients/SET_FORM_STEP',
      setRecipientStepNew: 'recipientsContacts/SET_FORM_STEP',
    }),

    ...mapActions({
      sendSmsMessage: 'websender/sendSmsMessage',
      sendSmsCampaignApproval: 'websender/sendSmsCampaignApproval',
      sendSmsMessageCampaignApi: 'sender/sendSmsMessageCampaignApi',
      sendChatAppsMessage: 'websender/sendChatAppsMessage',
      sendVoiceMessage: 'voice/sendVoiceMessage',
      getTimeZones: 'common/getTimeZones',
      getUser: 'user/getUser',
      setPartialData: 'websender/setPartialData',
    }),

    isError(item) {
      return item.error && !item.disable;
    },

    getIcon(type) {
      let icon = '';

      switch (type) {
        case 'checkIcon': {
          icon = checkIcon;
          break;
        }
        default:
      }

      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${icon}`;
    },

    formatNumber(n) {
      return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },

    getStepIndexByName(name) {
      return this.processList.findIndex(p => p.name === name);
    },

    getHeaderByIndex(index) {
      const processItem = this.processList[index] || null;
      return processItem ? processItem.label : '';
    },

    discardForm() {
      if (this.form.SubAccountId) {
        this.$confirm(this.$t('mcs.main[13]'), this.$t('mcs.main[14]'), {
          confirmButtonText: this.$t('mcs.main[15]'),
          cancelButtonText: this.$t('mcs.main[16]'),
          type: 'warning',
        }).then(() => {
          this.$router.push('/');
        }).catch(() => {});
      } else {
        this.$router.push('/');
      }
      // } else {
      //   this.resetAll();
      // }
    },

    resetAll() {
      this.processList = JSON.parse(JSON.stringify(this.defaultProcessList));
      this.setStep('CHANNEL');
      this.resetForm();
      this.setRecipientStep('ADD_RECIPIENT');
      this.setRecipientStepNew('ADD_RECIPIENT');
    },

    getTimeZone(tzId) {
      const { TimeZoneId } = this.user;
      const tzn = this.timezones.find(v => v.TimeZoneName === 'Asia/Singapore');

      const tznById = this.timezones.find(v => v.TimeZoneId === Number(tzId));
      const tznByUser = this.timezones.find(v => v.TimeZoneId === Number(TimeZoneId));

      // if timezone is not selected ,use user set timezone
      // if both is empty, just use SG as default
      return tznById || tznByUser || tzn;
    },

    getCustomFields(customFields = []) {
      let cfs = {};

      // should only get the body custom fields
      let dynamicFields = customFields.filter(v => !v.headerType);

      dynamicFields = customFields.filter(v => !v.buttonType);

      if (customFields.length) {
        cfs = dynamicFields.reduce((a, b) => {
          const f = removeBrackets(b.field);
          const { type, value } = b;

          // eslint-disable-next-line no-param-reassign
          a[f] = {
            type,
            value,
          };

          return a;
        }, { });
      }

      return cfs;
    },

    getHeaderValues(customFields = [], headerType) {
      if (headerType === 'location') {
        const headerFields = customFields.filter(v => v.headerType === 'location');

        if (headerFields.length) {
          // it means it has only lat long
          if (headerFields.length === 1) {
            const { type, value } = headerFields[0];
            return {
              type,
              value,
            };
          }

          // it means it has only lat long, name and address
          if (headerFields.length > 1) {
            const values = customFields.reduce((a, b) => {
              a.push(b.value);
              return a;
            }, []);

            // join the values as expected in the backend
            return {
              type: 'location',
              value: values.join(),
            };
          }
        }
      } else {
        const headerFields = customFields.find(v => v.headerType);

        if (headerFields) {
          const { type, value, headerType } = headerFields;

          return {
            type: headerType || type,
            value,
          };
        }
      }

      return { type: '', value: '' };
    },

    getWAButton(customFields = []) {
      const waButtonField = customFields.find(v => v.buttonType);

      if (waButtonField) {
        const { type, value, buttonType } = waButtonField;

        return {
          type,
          value,
          buttonType,
        };
      }

      return { type: '', value: '', buttonType: '' };
    },

    getWaButtonValues(wabuttons, buttonfield) {
      const buttons = wabuttons.slice(0).map((v) => {
        const data = v;

        data.type = data.type.toLowerCase();

        const { buttonType } = buttonfield;

        if (data.type.toLowerCase() === 'phone_number') {
          data.value = data.phoneNumber;
        }

        if (data.type.toLowerCase() === buttonType.toLowerCase()) {
          data.value = buttonfield.value || '';
          data.dynamic = true;
        } else {
          data.dynamic = false;
        }

        return data;
      });


      return buttons;
    },

    getColumnLabels(columLabels = {}) {
      const labels = {};

      Object.keys(columLabels).forEach((v) => {
        let l = v;

        if (l.toLowerCase().match(/msisdn/i)) {
          l = 'Mobile';
        }

        labels[l] = columLabels[v];
      });

      return labels;
    },

    getCAText(msg) {
      let text = msg;

      if (this.hasWhatsApp()) {
        const waMsg = transformToWAValidTemplate(msg);
        text = this.$sanitize(waMsg, { allowedTags: [] });
        text = _.unescape(text);
      }

      return text;
    },

    getSmsPayload() {
      const {
        Recipients,
        Message,
        CampaignDetails,
        SubAccountId,
        Type,
      } = this.form;

      const {
        contactsFileId,
        source,
        groups,
        contacts,
        AllowDuplicates: allowDuplicates,
        AllNumbersLocal: allNumbersLocal,
        Summary,
      } = Recipients;

      const {
        scheduleDate,
        scheduleTimezone: tzId,
        sendNow,
        campaignName,
        clientMsgId,
      } = CampaignDetails;

      const {
        senderId,
        message: text,
        smsParts,
        unicodeType,
      } = Message;

      let payload = {
        meta: {},
        message: {},
      };

      const tz = this.getTimeZone(tzId).TimeZoneName;

      let message = {
        from: senderId,
        // to be discussed
        // values here should be `Contacts`, `Groups` or `Upload`
        to: source === 'INPUT' ? 'Groups' : 'Upload',
        allowDuplicates,
        text: text.replace(/=HYPERLINK\(/ig, ''),
        parts: smsParts,
        clientMessageId: clientMsgId,
        encoding: 'AUTO', // unicodeType,
        // not implemented in multisender, just make it false
        saveTemplate: false,
        schedule: sendNow ? 'Send message now' : 'Schedule this message',
        scheduleDate: !sendNow ? scheduleDate : moment().format(),
        scheduleTimezone: this.getTimeZone(tzId),
        campaignName,
        subAccountId: SubAccountId,
        country: '',
        allNumbersLocal,
        templateId: Message.template ? Message.template.TemplateId : '',
        templateName: Message.template ? Message.template.TemplateName : '',
      };

      const metaCommon = {
        Countries: this.getCountriesWithContactTotals(),
        source,
        groups: groups || [],
        contacts: contacts || [],
        productType: Type,
      };

      // Create payload for sending SMS
      if (this.hasMcsAccess) {
        // Check is this message is scheduled or not
        if (!sendNow) {
          // This message is scheduled
          // Format date with timezone to ISO 8601
          message = {
            ...message,
            scheduledAt: momentz(scheduleDate).clone().tz(tz).format(),
          };
        }

        if (source === 'UPLOAD') {
          const { Location } = Recipients;
          message.country = Location;
        }

        payload = {
          meta: {
            source: metaCommon.source,
            productType: metaCommon.productType,
            contactsFileId,
          },
          message,
        };
      } else {
        message = {
          ...message,
          scheduledAt: !sendNow ? momentz(scheduleDate).clone().tz(tz).format() : momentz().format(),
        };

        if (source === 'INPUT') {
          const { result, groups: gp } = Summary;
          const { result: res } = gp;

          payload = {
            meta: {
              // ContactsTotal: metaCommon.contacts.length,
              // GroupsTotal: result.groupsTotal,

              // hotfix: groups total for sms sender
              Total: res.ContactsUniqueCount,
              UniqueEntries: result.ContactsUniqueCount,
              DuplicateEntries: result.ContactsDuplicatesCount,
              // from mixin
              ...metaCommon,
            },
            message,
          };
        }

        if (source === 'UPLOAD') {
          const { Location } = Recipients;
          const summary = Summary;

          message.country = Location;

          payload = {
            meta: {
              ...metaCommon,
              ...summary,
            },
            message,
          };
        }

        payload.meta.SocketID = this.socketId;
        payload.meta.QueueURL = this.queueUrl;
      }

      payload.meta.messageSamples = this.messageSamples.map(message => JSON.stringify(message));

      return payload;
    },

    getNonSMSPayload() {
      const {
        Recipients,
        SubAccountUid,
        SubAccountId,
        Message,
        CampaignDetails,
        PriceRange,
        FallbackConfig,
        Channels,
        Type,
      } = this.form;

      const {
        source,
        groups,
        contacts,
        Summary,
        AllowDuplicates: allowDuplicates,
        AllNumbersLocal: enforceLocalFormat,
      } = Recipients;

      const { customFields, message, header } = Message;
      const {
        campaignName, clientMsgId, clientBatchId, scheduleDate, scheduleTimezone: tzId, sendNow,
      } = CampaignDetails;
      const { currency, total } = PriceRange;
      const { type } = header || {};
      const { type: headerType, value: headerValue } = this.getHeaderValues(customFields, type);
      const { type: waButtonType } = this.getWAButton(customFields);
      const tz = this.getTimeZone(tzId).TimeZoneName;

      let payload = {
        meta: {
          productType: Type,
          channels: Channels,
          allowDuplicates,
          enforceLocalFormat,
          campaignName,
          clientMessageId: clientMsgId,
          countryCode: '',
          contacts: contacts || [],
          groups: groups || [],
          customFields: this.getCustomFields(customFields),
          subAccountUid: SubAccountUid,
          subAccountId: SubAccountId,
          template: Message.template ? Message.template : undefined,
          price: total,
          currency,
          channel: FallbackConfig && FallbackConfig.length ? FallbackConfig[0].ChannelType : '',
          header: headerType ? { type: headerType } : undefined,
          buttons: waButtonType ? { type: waButtonType } : undefined,
        },
      };

      if (Type === 'CHAT_APPS') {
        payload.message = {
          header: headerValue,
          text: this.getCAText(message),
          fallbackText: Message.fallbackOptions ? this.getCAText(Message.fallbackOptions.message) : '',
          fallbackSenderId: Message.fallbackOptions ? Message.fallbackOptions.senderId : '',
          imageURL: Message.imageInputs ? Message.imageInputs.url : '',
          buttonFields: Message.buttonInputs ? Message.buttonInputs : undefined,
          // eslint-disable-next-line max-len
          buttons: Message.waButtons ? this.getWaButtonValues(Message.waButtons, this.getWAButton(customFields)) : [],
        };

        if (this.hasMcsAccess) {
          // Check is this message is scheduled or not
          if (!sendNow) {
            // This message is scheduled
            // Format date with timezone to ISO 8601
            payload.message = {
              ...payload.message,
              schedule: sendNow ? 'Send message now' : 'Schedule this message',
              scheduledAt: momentz(scheduleDate).clone().tz(tz).format(),
            };
          }
        }
      }

      // Add contacts file id in the payload
      if (this.hasMcsAccess) {
        const { contactsFileId } = Recipients;

        payload.meta.contactsFileId = contactsFileId;
      }

      if (source === 'UPLOAD') {
        const { Location: countryCode, columnLabels, FileUrl } = Recipients;

        if (countryCode) {
          payload.meta.countryCode = countriesJson.find(v => v.value === countryCode).callingCode;
        }

        payload.meta.columnLabels = this.getColumnLabels(columnLabels);

        if (!this.hasMcsAccess) {
          payload.meta.file = {
            key: Summary.Key,
            fileName: Summary.FileName,
            fileType: Summary.FileType,
            hash: Summary.Hash,
            location: FileUrl,
          };
        }
      }

      if (Type === 'VOICE') {
        const {
          source: vsource,
          speed,
          language,
          repetition,
          profile: vProfile,
          uploads,
        } = Message;

        const voicePayload = { meta: {}, message: {} };

        if (!uploads) {
          voicePayload.message = {
            source: vsource.replace('+', ''),
            type: 'TextToSpeech',
            voiceProfile: vProfile.profile,
            speed,
            content: this.$sanitize(message, { allowedTags: [] }),
            repetition,
          };
        } else {
          const { audioUrl } = uploads;

          voicePayload.message = {
            source: vsource.replace('+', ''),
            type: 'PlayFile',
            playFileUrl: audioUrl,
            repetition,
          };
        }

        if (!sendNow) {
          // This message is scheduled
          // Format date with timezone to ISO 8601
          voicePayload.message = {
            ...voicePayload.message,
            scheduledAt: momentz(scheduleDate).clone().tz(tz).format(),
          };
        }

        const { contactsFileId } = Recipients;

        voicePayload.meta = {
          contactsFileId,
          subAccountUid: SubAccountUid,
          campaignName,
        };

        if (clientBatchId) {
          voicePayload.meta.clientBatchId = clientBatchId;
        }

        payload = { ...voicePayload };
      }

      return payload;
    },

    async submitForm() {
      const { Type, CampaignDetails: campaign } = this.form;
      const { Roles, AccessLevel } = this.user;

      let type = Type;

      if (type === 'SMS_ENGAGE') {
        type = 'SMS';
      }

      const { track } = this.$telemetry;

      const {
        sendNow,
        campaignName,
      } = campaign;

      switch (type) {
        case 'SMS': {
          const payload = this.getSmsPayload();

          try {
            this.loading = true;
            if (Roles && Roles.includes('SMS.NeedsApproval_v2') && AccessLevel === 'U') {
              if (!this.hasMcsAccess) {
                const SMSTotal = this.getTotals().totalSmsMsgs;
                payload.SMSCount = SMSTotal;
                payload.socketId = this.socketId;
                payload.queueUrl = this.queueUrl;

                payload.message.text = this.replaceUrl(payload.message.text);
              }
              await this.sendSmsCampaignApproval(payload);
            } else {
              await this.sendSmsMessage(payload);
            }

            const { user } = this;

            track('message_sent', {
              uiArea: 'multichannel_sender_campaign_page',
              // eslint-disable-next-line no-nested-ternary
              campaignName,
              isScheduled: sendNow ? 'NO' : 'YES',
              isSubmitted: 'YES',
            });

            // call global method to reflect the balance automatically in header
            if (this.$getUserBalance) {
              this.$getUserBalance(user);
            }
          } catch (err) {
            track('message_sent', {
              uiArea: 'multichannel_sender_campaign_page',
              // eslint-disable-next-line no-nested-ternary
              campaignName,
              isScheduled: sendNow ? 'NO' : 'YES',
              isSubmitted: 'NO',
            });
            this.$showError(this, err);
            // this.$message.error(err.message);
          } finally {
            this.loading = false;
          }
          break;
        }

        case 'CHAT_APPS': {
          const payload = this.getNonSMSPayload();

          try {
            this.loading = true;
            await this.sendChatAppsMessage(payload);

            track('message_sent', {
              uiArea: 'multichannel_sender_campaign_page',
              // eslint-disable-next-line no-nested-ternary
              campaignName,
              isScheduled: sendNow ? 'NO' : 'YES',
              isSubmitted: 'YES',
            });
          } catch (err) {
            track('message_sent', {
              uiArea: 'multichannel_sender_campaign_page',
              // eslint-disable-next-line no-nested-ternary
              campaignName,
              isScheduled: sendNow ? 'NO' : 'YES',
              isSubmitted: 'NO',
            });
            this.$showError(this, err);
            // this.$message.error(err.message);
          } finally {
            this.loading = false;
          }
          break;
        }

        case 'VOICE': {
          // todo: use voice api to send message
          const payload = this.getNonSMSPayload();

          try {
            this.loading = true;
            await this.sendVoiceMessage(payload);

            track('message_sent', {
              uiArea: 'multichannel_sender_campaign_page',
              // eslint-disable-next-line no-nested-ternary
              campaignName,
              isScheduled: sendNow ? 'NO' : 'YES',
              isSubmitted: 'YES',
            });

            this.$notify.success({ title: `Alert message successfully sent to ${this.getTotals().totalValidRecipients.toLocaleString()} recepients` });
            this.goToCampaigns();
          } catch (err) {
            track('message_sent', {
              uiArea: 'multichannel_sender_campaign_page',
              // eslint-disable-next-line no-nested-ternary
              campaignName,
              isScheduled: sendNow ? 'NO' : 'YES',
              isSubmitted: 'NO',
            });
            this.$showError(this, err);
            // this.$message.error(err.message);
          } finally {
            this.loading = false;
          }
          break;
        }


        default:
      }
    },

    changeProcessIndex(index) {
      this.processList[index].disable = false;
      this.setStep(this.processList[index].name);
    },

    sendAgain() {
      this.setCampaignSent(undefined);
      this.resetForm();
      this.setStep('CHANNEL');
      this.setRecipientStep('ADD_RECIPIENT');
      this.setRecipientStepNew('ADD_RECIPIENT');
    },

    goToCampaigns() {
      this.setCampaignSent(undefined);
      this.resetForm();
      this.setStep('CHANNEL');
      this.setRecipientStep('ADD_RECIPIENT');
      this.setRecipientStepNew('ADD_RECIPIENT');
      this.$router.push('/');
    },

    replaceUrl(t) {
      const expURL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      const regex = new RegExp(expURL);
      const urlMatches = t.match(regex);

      if (!urlMatches) { return t; }

      return urlMatches.reduce((n, c) => {
        let nt = t;
        if (n.length > 0) {
          nt = n;
        }
        let nc = c.replace('.', '&#x2024;');
        nc = nc.replace(':', '&#x205A;');
        n = nt.replace(c, nc);

        return n;
      }, '');
    },
  },
};
</script>
