<template>
  <div>
   <svg width="168" height="33" viewBox="0 0 168 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_28_16785)">
    <rect y="7.5" width="2" height="18" rx="1" fill="#0056E0"/>
    <rect x="4" y="4.5" width="2" height="24" rx="1" fill="#0056E0"/>
    <rect x="8" y="7.5" width="2" height="18" rx="1" fill="#0056E0"/>
    <rect x="12" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="16" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="20" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="24" y="10.5" width="2" height="12" rx="1" fill="#0056E0"/>
    <rect x="28" y="5.5" width="2" height="22" rx="1" fill="#0056E0"/>
    <rect x="32" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="36" y="5.5" width="2" height="22" rx="1" fill="#0056E0"/>
    <rect x="40" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="44" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="48" y="10.5" width="2" height="12" rx="1" fill="#0056E0"/>
    <rect x="52" y="5.5" width="2" height="22" rx="1" fill="#0056E0"/>
    <rect x="56" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="60" y="2.5" width="2" height="28" rx="1" fill="#0056E0"/>
    <rect x="64" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="68" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="72" y="2.5" width="2" height="28" rx="1" fill="#0056E0"/>
    <rect x="76" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="80" y="11.5" width="2" height="10" rx="1" fill="#0056E0"/>
    <rect x="84" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="88" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="92" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="96" y="10.5" width="2" height="12" rx="1" fill="#0056E0"/>
    <rect x="100" y="5.5" width="2" height="22" rx="1" fill="#0056E0"/>
    <rect x="104" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="108" y="5.5" width="2" height="22" rx="1" fill="#0056E0"/>
    <rect x="112" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="116" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="120" y="10.5" width="2" height="12" rx="1" fill="#0056E0"/>
    <rect x="124" y="5.5" width="2" height="22" rx="1" fill="#0056E0"/>
    <rect x="128" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="132" y="2.5" width="2" height="28" rx="1" fill="#0056E0"/>
    <rect x="136" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="140" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="144" y="2.5" width="2" height="28" rx="1" fill="#0056E0"/>
    <rect x="148" y="0.5" width="2" height="32" rx="1" fill="#0056E0"/>
    <rect x="152" y="11.5" width="2" height="10" rx="1" fill="#0056E0"/>
    <rect x="156" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="160" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    <rect x="164" y="14.5" width="2" height="4" rx="1" fill="#0056E0"/>
    </g>
    <defs>
    <clipPath id="clip0_28_16785">
    <rect width="168" height="32" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
  </svg>
</div>
</template>
<script>
export default {
  name: 'voice_visualiser',
};
</script>
