<template>
  <div class="mb-5 ca-msg-box">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.prevent.native
    >
      <div class="w-2/3">
        <div class="flex">
          <h5 class="text-black font-medium mb-2 flex-auto text-sm label__asterisk">
            {{ $t('column_labels.message') }}
          </h5>
          <div
            v-if="textLimit !== 0"
            class="flex-shrink text-xs text-grey"
          >
            {{ form.message ? form.message.trim().length : 0 }} /  {{ textLimit }}
          </div>
        </div>
        <el-form-item prop="message">
          <el-input
            ref="msgBox"
            v-model="form.message"
            :placeholder="$t('mcs.messages.sms.msg_box[3]')"
            type="textarea"
            class="text-xs"
            :rows="4" />
        </el-form-item>
      </div>
    </el-form>
    <div v-if="variables && variables.length">
      <p class="mb-2 text-xs"> {{ $t('mcs.messages.sms.msg_box[4]') }} </p>
      <div class="mb-2">
        <span
          v-for="i in variables"
          :key="i"
          class="mr-3 item-tag"
        >
          {{ i }}
           <i
            v-if="hasAdded(i) && isRemovable"
            class="el-icon-close el-icon--right"
            @click="removeVariable(i)"
          />
          <i
            v-else
            class="el-icon-plus el-icon--right"
              @click="addVariable(i)"
          />
        </span>
      </div>
      <p
        v-if="source === 'UPLOAD'"
        class="text-xs"
      >
        <span>
          <a
            href="#"
            class="text-blue hover:text-blue-darker no-underline"
            @click.prevent="$emit('modify-custom-fields')">
            {{ $t('actions.modify') }}
          </a> {{ $t('mcs.messages.sms.msg_box[5]') }}.
        </span>
        <a
          href="https://support.wavecell.com/hc/en-us/articles/115005656203-How-can-I-add-custom-fields-on-my-messages-"
          class="text-blue hover:text-blue-darker no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >{{ $t('mcs.messages.sms.msg_box[6]') }}</a>
      </p>
    </div>
  </div>
</template>
<script>
import insertAtCursor from '@/utils/insertAtCursor';
import { hasBrackets } from '@/utils/common';

export default {
  name: 'message_ca_box',

  props: {
    variables: {
      type: Array,
      default: () => [],
    },

    message: {
      type: String,
      default: '',
    },

    source: {
      type: String,
      default: '',
    },

    isRemovable: {
      type: Boolean,
      default: true,
    },

    textLimit: {
      type: Number,
      default: 0,
    },
  },

  data() {
    const self = this;

    return {
      form: {
        message: '',
      },

      rules: {
        message: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.message') }),
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  created() {
    this.form.message = this.message;

    if (this.textLimit) {
      this.rules.message.push({
        max: this.textLimit,
        message: this.$t('mcs.messages.chat_apps.msg_box[0]', { limit: this.textLimit }),
        trigger: 'blur',
        transform(value) {
          return value.trim();
        },
      });
    }
  },

  watch: {
    'form.message': {
      handler(n) {
        if (this.$refs.form) {
          const self = this;
          this.$refs.form.validateField('message', (err) => {
            if (!err) {
              self.$emit('update-message', n);
            } else {
              self.$emit('update-message', '');
            }
          });
        }
      },
    },
  },

  methods: {
    addVariable(field) {
      if (this.$refs.msgBox) {
        const cfText = `{{${field}}}`;
        const textArea = this.$refs.msgBox.$refs.textarea;

        const textfield = insertAtCursor(textArea, cfText);

        this.form.message = textfield.value;
      }
    },

    removeVariable(v) {
      const html = this.form.message;

      const content = html.replace(`{{${v}}}`, '');

      this.form.message = content;
    },

    hasAdded(v) {
      const html = this.form.message;

      const b = hasBrackets(html);

      if (b && b.length) {
        return b.includes(`{{${v}}}`);
      }

      return false;
    },
  },
};
</script>
