<template>
  <div
    id="multichannel-sender"
    class="h-full">
    <RouterView />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      hasMcsAccess: 'user/getMcsAccess',
    }),
  },

  created() {
    this.getUser();
  },

  beforeDestroy() {
    this.$el.className = '';
  },

  mounted() {
    // window.addEventListener('localeChanged', () => {
    //   this.$i18n.locale = this.$getSelectedLocale();
    // });

    if (!this.hasMcsAccess) {
      try {
        const { UserId, Token } = this.user;
        const payload = { UserId, Token };

        this.socketConnect(payload);
      } catch {
        this.$message.error('Oops, Unable to connect to web socket.');
      }
    }
  },

  methods: {
    ...mapActions({
      socketConnect: 'socket/connect',
      getUser: 'user/getUser',
      getTimeZones: 'common/getTimeZones',
    }),
  },
};
</script>

<style lang="scss">
  .el-table th {
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
  }

  .min-width-1\/2 {
    min-width: 50%;
  }

  .el-radio__input.is-checked+.el-radio__label {
    color: #333333;
  }

  html, body {
    min-width: 998px;
    height: 100%;
  }

  mark {
    color: #3490DC;
    background: none;
  }

  .l-container {
    margin: 0 auto;
    width: 100%;
  }

  .icon-ca-logs:before {
    content: "\c900";
  }
  .icon-ca-reports:before {
    content: "\c901";
  }
  .icon-ca-pricing:before {
    content: "\c902";
  }
  .icon-ca-overview:before {
    content: "\c903";
  }
  .icon-ca-dashboard:before {
    content: "\c904";
  }
  .icon-ca-templates:before {
    content: "\c905";
  }
  .icon-ca-channels:before {
    content: "\c906";
  }

  .fade-left-enter-active {
    transition: all 0.4s ease;
  }

  .fade-left-leave-active {
    transition: all 0.4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .fade-left-enter,
  .fade-left-leave-to {
    transform: translateX(-10px);
    opacity: 0;
  }

  .fade-up-enter-active {
    transition: all 0.4s ease;
  }

  .fade-up-leave-active {
    transition: all 0.4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .fade-up-enter,
  .fade-up-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }

  .slide-left-enter-active {
    transition: all 0.2s ease;
  }

  .slide-left-leave-active {
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-left-enter {
    transform: translateX(10px);
    opacity: 0;
  }

  .slide-left-leave-to {
    transform: translateX(-10px);
    opacity: 0;
  }

  .slide-up-enter-active {
    transition: all 0.2s ease;
  }

  .slide-up-leave-active {
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-up-enter {
    transform: translateY(10px);
    opacity: 0;
  }

  .slide-up-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }

  .item-tag {
    color: #429468;
    font-size: 12px;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    outline: 0;
    display: inline-block;
    border: 1px solid #429468;
    background-color: #D2F3E1;
    border-radius: 9999px;
    line-height: 1;

    .el-icon--right {
      font-weight: 900;
      color: #429468;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .label__asterisk {
    &:before {
      content: '*';
      color: #F56C6C;
      margin-right: 4px;
    }
  }

  .send-details .haslogo {
    i[class*=" el-icon-"], i[class^=el-icon-] {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .send-details .haschannels {
    i[class*=" el-icon-"], i[class^=el-icon-] {
      font-size: 8px;
      font-weight: 600;
    }

    ul li img {
      opacity: 0.5;
    }

    ul li:first-child img {
      opacity: 1;
    }
  }

  .send-details {
    .bg-sky-blue-lightest {
      background-color: #DEF1F5;
    }

    .text-sky-blue {
      color: #3EC3D5;
    }
  }

  .quick-btn {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 50%;
  }

  .cp-box {
    border-radius: 48px 48px 0px 0px;
    border-left: 8px solid #a3a3a3;
    border-right: 8px solid #a3a3a3;
    border-top: 8px solid #a3a3a3;
    min-height: 315px;
    overflow-y: auto;
  }


  .button-radio .el-radio-button__inner {
    background-color: white;
    color: #2a2a2a;
    border: 1px solid #e7e7e7;
  }

  .button-radio .el-radio-button .el-radio-button--medium .is-active,
  .button-radio .el-radio-button .el-radio-button--medium .is-active span {
    pointer-events: none;
    background-color:  #2a2a2a;
    cursor: not-allowed;
  }

  .el-upload-dragger {
    width: 100%;
    border: 2px dashed #0056e0 !important;
  }

</style>
