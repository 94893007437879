var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"text-grey-dark text-xs mb-3"},[_vm._v("\n    "+_vm._s(_vm.$t('mcs.messages.chat_apps.msg_types[0]'))+"  "),_c('b',[_vm._v(_vm._s(_vm.getChannelName(_vm.channel))+" ")])]),_c('div',{staticClass:"w-2/3 flex"},_vm._l((_vm.getTypes),function(i){return _c('div',{key:i,class:[
        'rounded p-2 text-xs flex-1 mr-3 border',
        { 'bg-grey-lighter': i.type !== _vm.active },
        { 'bg-white border-green': i.type === _vm.active },
      ]},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-auto"},[_c('el-radio',{staticClass:"w-full",attrs:{"label":i.type},on:{"change":_vm.changeMediaType},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('span',{class:['text-xs mr-3', { 'text-grey-dark' : i.type !== _vm.active}]},[_vm._v("\n              "+_vm._s(i.name)+"\n            ")])])],1),_c('div',{staticClass:"flex-shrink"},[_c('i',{class:[
            `align-middle text-lg ${i.icon}`,
            { 'font-bold': i.type === _vm.active },
            { 'text-grey': i.type !== _vm.active }
            ]})])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }