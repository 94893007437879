<template>
  <div class="voice-send-test-msg mt-6">
    <h5 class="text-black font-medium mb-1 text-sm">
      {{ $t('mcs.messages.voice.send_test[0]') }}
    </h5>
    <p class="mb-2 text-sm text-grey">Send a sample voice message to your number to hear how it sounds.</p>
    <div class="mb-2">
      <Destination
        :destination="form.dest"
        :loading="loading"
        @update-destination="(v) => {
          updateDestination(v)
        }"
        @reset-destination="form.dest = ''"
      />
    </div>
     <div class="mb-4 py-2 rounded flex border-grey-lightest border w-full">
      <div class="px-4 py-2 border-l-4 border-yellow flex w-full">
        <div class="flex-shrink align-top">
          <i class="icon-novo-exclamation text-yellow text-2xl mr-4" />
        </div>
        <div class="leading-normal flex-auto text-sm" :style="{wordBreak: 'break-word'}">
          Sending a test voice message will incur standard charges
        </div>
      </div>
    </div>
    <el-button
      type="primary"
      id="send-test-voice-message"
      @click="handleSubmit"
      :disabled="(selected.action === 'message' && !selected.message) || (selected.action === 'uploads' && !selected.uploads)  || !form.dest || !selected.source"
      :loading="loading"
    >
      Send test voice message
    </el-button>

</div>
</template>
<script>
import PhoneNumberMixin from '@/mixins/phone-number';
import { hasBrackets, removeBrackets, factorPascalCasedWords } from '@/utils/common';

import Destination from './Destination.vue';

export default {
  name: 'message_voice_send_test_msg',

  mixins: [PhoneNumberMixin],

  components: {
    Destination,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    selected: {
      type: Object,
      default: () => ({}),
    },

    mcsForm: {
      type: Object,
      default: () => ({}),
    },

    partialData: {
      type: Object,
      default: () => ({ List: [] }),
    },

    variables: {
      type: Object,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        dest: '',
      },

      dynamicFields: {},
    };
  },

  computed: {
  },

  created() {
    this.reset();
  },

  methods: {
    handleSubmit() {
      const {
        dest,
      } = this.form;

      const {
        source,
        profile: voiceProfile,
        repetition,
        speed,
        message,
        uploads,
      } = this.selected;

      let payload = {};

      if (this.selected.action === 'message') {
        payload = {
          meta: {
            destination: this.getPhoneNumberInfo(dest).number.e164.replace('+', ''),
            country: this.getPhoneNumberInfo(dest).regionCode,
          },
          message: {
            source: source.replace('+', ''),
            type: 'TextToSpeech',
            voiceProfile: voiceProfile.profile,
            speed,
            content: this.replaceDynamicFields(message),
            repetition,
          },
        };
      } else {
        const { audioUrl } = uploads;

        payload = {
          meta: {
            destination: this.getPhoneNumberInfo(dest).number.e164.replace('+', ''),
            country: this.getPhoneNumberInfo(dest).regionCode,
          },
          message: {
            source: source.replace('+', ''),
            type: 'PlayFile',
            playFileUrl: audioUrl,
            repetition,
          },
        };
      }

      this.$emit('send-test-message', payload);
    },

    replaceDynamicFields(selectedMessage) {
      let message = selectedMessage;
      const b = hasBrackets(message);

      if (b && b.length && this.partialData && this.partialData.List.length) {
        const validRows = this.partialData.List.slice(0, 10);

        if (validRows.length) {
          const arrangedColumsByIndex = validRows.map((row) => {
            const { columnLabels } = this.mcsForm.Recipients;
            return Object.keys(columnLabels).map(key => row[columnLabels[key]]);
          });

          if (arrangedColumsByIndex.length) {
            const sampleMessage = arrangedColumsByIndex.map(row => message.replace(/{{\s*([a-zA-Z0-9_]+)\s*}}/g, (match, p1) => {
              const variable = row[this.variables.indexOf(p1)];
              return variable;
            }));

            // eslint-disable-next-line prefer-destructuring
            message = sampleMessage[0];
          }
        } else {
          b.forEach((val) => {
            const v = removeBrackets(val);
            if (this.variables.includes(v)) {
              message = message.replace(`{{${v}}}`, factorPascalCasedWords(v));
            }
          });
        }
      }

      return message;
    },

    updateDestination(v) {
      this.form.dest = v;
    },

    reset() {
      this.form.dest = '';
      this.dynamicFields = {};
    },
  },

};
</script>
<style lang="scss">
  .test-voice-form {
    .w-2\/3 {
      width: 100%;
    }

    .slider {
      width: 75%;
    }

    .voice-speed {
      .w-1\/2 {
        width: 70%;
      }
    }

    .voice-repetition {
      .el-select {
        width: 50%;
      }
    }
  }
</style>
