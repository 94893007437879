<template>
  <div class="flex p-2 py-1 border border-grey-light rounded-lg haslogo z-10 relative bg-white">
    <div class="flex-shrink py-3">
      <img :src="getIcon()" alt="icon"/>
    </div>
    <div class="flex-auto ml-5 mt-2 py-2">
      <p class="text-grey-dark text-sm mb-1">{{ $t('column_labels.total_msgs') }}</p>
      <p class="text-black text-lg font-medium">{{ total.toString().toLocaleString() }}</p>
    </div>
  </div>
</template>

<script>
import totalMessagesIcon from '@/assets/misc/total-messages.svg';

export default {
  name: 'send_estimated_cost',

  props: {
    total: {
      type: Number,
      required: true,
    },
  },

  methods: {
    getIcon() {
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${totalMessagesIcon}`;
    },
  },
};
</script>
