<template>
<el-dialog
  :title="$t('mcs.messages.sms_engage.forms[0]')"
  :visible="modalVisible"
  width="65%"
  top="60px"
  destroy-on-close
  @close="closeModal"
>
  <div class="flex flex-col sms-engage-forms h-full"
    v-loading="loading">
    <div class="flex-auto mb-5">
      <el-input
        size="small"
        style="width: 300px;"
        :placeholder="$t('mcs.messages.sms_engage.forms[1]')"
        suffix-icon="el-icon-search"
        v-model="search"
      />
    </div>

    <div class="flex-auto min-h-custom">
      <el-table
        :data="tableData.paginated"
        class="w-full">
        <el-table-column
          :label="$t('column_labels.sms_engage_id')">
          <template slot-scope="scope">
            <span class="text-xs truncate">
              <text-highlight
                :queries="search"
                :highlightStyle="{ background: 'none'}"
              >
                {{ scope.row.SurveyId }}
              </text-highlight>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('column_labels.sms_engage_name')">
          <template slot-scope="scope">
            <span class="text-xs truncate">
              <text-highlight
                :queries="search"
                :highlightStyle="{ background: 'none'}"
              >
                {{ scope.row.SurveyTitle }}
              </text-highlight>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="selectSurvey(scope.row)">
            {{ $t('actions.select') }}
          </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="flex-shrink mt-10">
      <Pagination
        v-if="total > 0"
        @next="nextPage"
        @prev="prevPage"
        @change-page-no="changePageNo"
        @change-page-size="changePageSize"
        :page-count="pageCount"
        :page-no="pageNo"
        :page-size="pageSize"
        :current-page-size="currentPageSize"
        :page-sizes="[5, 10, 15, 20, 50]"
        :total="total"
      />
    </div>
  </div>
</el-dialog>
</template>
<script>
import TextHighlight from 'vue-text-highlight';
import Pagination from '@/components/Pagination.vue';


export default {
  name: 'messages_sms_engage_forms',

  components: {
    Pagination,
    TextHighlight,
  },

  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },

    forms: {
      type: Array,
      default: () => [],
    },

    modalVisible: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      senderId: 'all',

      pageSize: 5,
      pageNo: 0,
    };
  },

  computed: {
    pageCount() {
      if (this.tableData
        && this.tableData.original
        && Array.isArray(this.tableData.original)) {
        return Math.ceil(this.tableData.original.length / this.pageSize);
      }

      return 0;
    },

    currentPageSize() {
      if (this.tableData
        && this.tableData.paginated
        && Array.isArray(this.tableData.paginated)) {
        return this.tableData.paginated.length;
      }

      return 0;
    },

    total() {
      if (this.tableData
        && this.tableData.original
        && Array.isArray(this.tableData.original)) {
        return this.tableData.original.length;
      }

      return 0;
    },

    tableData() {
      const forms = this.forms.slice(0);
      const start = this.pageNo * this.pageSize;
      const end = start + this.pageSize;

      if (this.search.trim().length) {
        const searchString = this.$sanitize(this.search);
        const filters = ['SurveyId', 'SurveyTitle'];
        const result = forms
          .filter(row => filters.some((k) => {
            if (row[k]) {
              return row[k]
                .replace(/\s+(?=\d)|[+]/g, '')
                .toLowerCase()
                .includes(searchString.replace(/\s+(?=\d)|[+]/g, '').toLowerCase());
            }
            return 0;
          }));

        return {
          paginated: result.slice(start, end) || [],
          original: result || [],
        };
      }

      return {
        paginated: forms.slice(start, end) || [],
        original: forms || [],
      };
    },
  },

  watch: {
    'tableData.original': {
      handler(n, o) {
        if (n && Array.isArray(n)
          && o && Array.isArray(o)
          && n.length !== o.length) {
          this.changePageNo(1);
        }
      },
    },
  },

  methods: {
    nextPage() {
      this.pageNo = this.pageNo + 1;
    },

    prevPage() {
      this.pageNo = this.pageNo - 1;
    },

    changePageNo(pageNo) {
      this.pageNo = pageNo - 1;
    },

    changePageSize(size) {
      this.pageSize = size;
      this.pageNo = 0;
    },

    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },

    reset() {
      this.search = '';
      this.pageSize = 5;
      this.pageNo = 0;
    },

    selectSurvey(row) {
      this.reset();
      this.$emit('select-se-form', row);
    },
  },
};
</script>
<style lang="scss">
  .sms-engage-forms {
    .min-h-custom {
      min-height: calc(100vh - 400px);
    }
  }
</style>
