import axios from 'axios';
import qs from 'query-string';
import Vue from 'vue';

// eslint-disable-next-line max-len
const responseErrorConfig = err => Promise.reject(err && err.response && err.response.data ? err.response.data : err);
const responseSuccessConfig = (response) => {
  return Vue.prototype.http.responseSuccessConfig(response);
};
const requestErrorConfig = err => Promise.reject(err);
const requestSuccessConfig = (config, api) => { // eslint-disable-line
  return Vue.prototype.http.requestSuccessConfig(config, api);
};

// Create instances for Connect v1, v2, campaign, contacts, and voice APIs
const httpv1 = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
});
const httpv2 = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/v2`,
});
const campaignApi = axios.create({
  baseURL: `${process.env.VUE_APP_CAMPAIGN_API_URL}/api/v1`,
});
const contactsApi = axios.create({
  baseURL: `${process.env.VUE_APP_CONTACTS_API_URL}/api/v1`,
});
const voiceApi = axios.create({
  baseURL: `${process.env.VUE_APP_VOICE_API_URL}/api/v1`,
});

httpv1.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpv1.interceptors.request.use(config => requestSuccessConfig(config, 'v1'), requestErrorConfig);
httpv2.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpv2.interceptors.request.use(config => requestSuccessConfig(config, 'v2'), requestErrorConfig);
campaignApi.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
campaignApi.interceptors.request.use(config => requestSuccessConfig(config, 'campaign'), requestErrorConfig);
contactsApi.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
contactsApi.interceptors.request.use(config => requestSuccessConfig(config, 'contacts'), requestErrorConfig);
voiceApi.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
voiceApi.interceptors.request.use(config => requestSuccessConfig(config, 'voice'), requestErrorConfig);


/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

const generateMethods = httpversion => ({
  request: options => httpversion(options),
  get: (url, params = {}) => httpversion.get(url, {
    params: {
      ...params,
    },
    paramsSerializer(p) {
      return qs.stringify(p, { indices: false });
    },
  }),
  post: (url, data = {}, config = {}) => httpversion.post(url, data, config),
  put: (url, data = {}) => httpversion({ method: 'put', url, data }),
  delete: (url, data = {}) => httpversion({ method: 'delete', url, data }),
  patch: (url, data = {}) => httpversion({ method: 'patch', url, data }),
  baseURL: httpversion.defaults.baseURL,
});


export default {
  v1: generateMethods(httpv1),
  v2: generateMethods(httpv2),
  campaignApi: generateMethods(campaignApi),
  contactsApi: generateMethods(contactsApi),
  voiceApi: generateMethods(voiceApi),
};
