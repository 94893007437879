<template>
  <div v-loading="downloading">
    <div v-if="sourceUrl" class="mt-4">
      <audio :src="sourceUrl" controls class="voice-audio-player w-full"></audio>
      <div class="mt-2 text-center text-xs">
        <span class="truncate">{{ fileData ?  fileData.fileName: '-' }}</span>
        <span class="text-grey ml-1">({{ fileData ? fileData.fileSizeInMB.toFixed(2) : 0 }} MB)</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'voice_audio_player',

  props: {
    fileData: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      sourceUrl: '',
      downloading: false,
    };
  },

  watch: {
    fileData(fileData) {
      if (fileData) {
        const { audioUrl } = fileData;
        this.sourceUrl = audioUrl;
      }
    },

    deep: true,
  },

  created() {
    const { audioUrl } = this.fileData;
    this.sourceUrl = audioUrl || '';
  },

  methods: {

  },
};
</script>
