/* eslint-disable vue/no-parsing-error */
<template>
  <div>
    <div class="flex justify-between items-center border-b border-gray-dark mb-5">
      <div class="flex items-center pb-5 w-full">
        <!-- <a @click.prevent="$emit('deselect-campaign')" id="mcs-back-btn" class="mr-5 hover:text-blue cursor-pointer">
          <i class="el-icon-arrow-left"></i>
          {{ $t('actions.back') }}
        </a> -->
        <div class="inline-flex items-center">
          <img :src="getChannelIcon(isSms() ? 'SMS' : campaign.ChannelType)" class="block mr-3" />
          <div>
            <h2 class="text-base font-bold mb-2">
              {{ campaign.CampaignName || $t('mcs.campaigns.unknow_campaign') }}
            </h2>
            <div>
              <transition name="slide-up" mode="out-in">
                <div v-if="campaignStatus.has(campaign.CampaignStatusId)" class="status-tag text-xs mr-2"
                  :key="campaign.CampaignStatusId" :class="[getTagClassName(
                  campaign.CampaignStatusId,
                  $wcsender.dateIsInTheFuture(campaign.ScheduledAt))]">
                  {{ getStatusName(campaign.CampaignStatusId, campaign.ScheduledAt) }}
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div v-loading="loading" class="pb-5">
        <!-- Show Approve/Reject button if CampaignStatus is WaitingApproval -->
        <div
          v-if="campaign.CampaignStatusId === 6 && (userData.AccessLevel === 'A' || (userData.AccessLevel === 'U' && userData.Roles.includes('SMS.CampaignApprover_v2') && campaign.CreatedBy !== userData.UserId))"
          class="flex items-center">
          <a class="py-2 px-4 text-xs text-white inline-block border border-red-light rounded
             bg-red-lightest cursor-pointer hover:bg-red-lighter hover:text-white flex
            items-center leading-none ml-2" @click.prevent="$emit('reject-campaign', campaign)">
            <i class="el-icon-error mr-2 text-base"></i>
            {{ $t('actions.reject') }}
          </a>
          <a class="py-2 px-4 text-xs text-green-darkest inline-block border border-green-light rounded
            bg-green-lightest cursor-pointer hover:bg-green-lighter hover:text-green-darkest flex
            items-center leading-none ml-2" @click.prevent="$emit('approve-campaign', campaign)">
            <i class="el-icon-success mr-2 text-base"></i>
            {{ $t('actions.approve') }}
          </a>
        </div>
        <div v-else class="flex items-center">
          <a v-if="(userData.AccessLevel === 'A' || campaign.CreatedBy === userData.UserId) && isScheduled() && !campaign.CampaignStatusName.match(/Cancelled/i) && Number(campaign.CampaignStatusId) !== 7"
            class="py-2 px-4 text-xs text-white inline-block border border-red
                    rounded cursor-pointer bg-red-lighter border-red-dark
                    text-white flex items-center mr-5 flex items-center leading-none ml-2"
            @click.prevent="$emit('cancel-campaign', campaign)">
            <i class="el-icon-close mr-2 text-base"></i>
            {{ $t('actions.cancel') }}
          </a>
          <el-button v-if="(userData.AccessLevel === 'A' || campaign.CreatedBy === userData.UserId) && isSms() && ![7, 5, 6, 3].includes(campaign.CampaignStatusId) && !isScheduled()"
            type="button" size="small" icon="el-icon-download -mt-1" @click.prevent="downloadCampaign(campaign)">
            {{ $t('actions.download') }}
          </el-button>

        </div>
      </div>
    </div>
    <div>
      <transition name="slide-up" mode="out-in">
        <div v-if="campaign.ReviewedByLogin" :key="campaign.CampaignStatusName">
          <div v-if="![5,7].includes(campaign.CampaignStatusId)" class="border border-green-light bg-green-lightest text-xs rounded p-5
            text-green-darkest mb-5 flex items-center">
            <i class="el-icon-success text-xl mr-3"></i>
            {{ $t('column_labels.approved_by') }}&nbsp;
            <strong v-if="campaign.ReviewedByLogin" class="font-semibold">
              {{ campaign.ReviewedByLogin }}
            </strong>
            &nbsp;
            <span v-if="campaign.ReviewedAt">
              {{ getLocalTime(campaign.ReviewedAt, currentTzOffset, currentTz) }}
            </span>
          </div>
          <div v-else-if="[5,7].includes(campaign.CampaignStatusId)" class="border border-red-light bg-red-lightest text-xs rounded p-5
            text-white mb-5 flex items-center">
            <i class="el-icon-error text-xl mr-3"></i>
            <div>
              <div>
                {{ campaign.CampaignStatusName }} by&nbsp;
                <strong v-if="campaign.ReviewedByLogin" class="font-semibold">
                  {{ campaign.ReviewedByLogin }}
                </strong>
                &nbsp;
                <span v-if="campaign.ReviewedAt">
                  {{ getLocalTime(campaign.ReviewedAt, currentTzOffset, currentTz) }}
                </span>
              </div>
              <div v-if="campaign.CampaignStatusName === 'Rejected'" class="flex mt-2">
                {{ $t('column_labels.reason') }}:
                <p v-if="campaign.RejectionMsg" class="ml-2">{{ campaign.RejectionMsg }}</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="flex flex-nowrap">
        <div class="flex flex-col flex-grow">
          <div class="relative z-10">
            <h3 class="text-base font-bold mb-2">{{ $t('column_labels.information') }}</h3>
            <div class="mt-5 mb-10 flex">
              <div class="text-xs text-grey-dark mr-10">
                {{ $t('column_labels.product_type') }}
                <span class="text-sm text-black block mt-2 font-bold flex">
                  {{ isSms() ? 'SMS' : 'Chat Apps' }}
                </span>
              </div>
              <div class="text-xs text-grey-dark mr-10">
                {{ $t('column_labels.created_on') }} {{ currentTz }}
                <span class="text-sm text-black block mt-2 font-bold">
                  {{ campaign.CreatedAt && getLocalTime(campaign.CreatedAt, currentTzOffset, currentTz) }}
                </span>
              </div>
              <div class="text-xs text-grey-dark mr-10">
                {{ $t('column_labels.created_by') }}
                <span class="text-sm text-black block mt-2 font-bold">
                  {{ campaign.CreatedBy_Username && campaign.CreatedBy_Username }}
                </span>
              </div>
            </div>
            <div v-if="isSms()" class="mt-5 mb-10 flex">
              <div class="text-xs text-grey-dark mr-10">
                {{ $t('column_labels.sms_parts') }}
                <span class="text-sm text-black block mt-2 font-bold">
                  {{ getTextAttrs(campaign.TemplateBody).smsParts }}
                </span>
              </div>
              <div class="text-xs text-grey-dark mr-10">
                {{ $t('column_labels.encoding_type') }}
                <span class="text-sm text-black block mt-2 font-bold">
                  {{ getTextAttrs(campaign.TemplateBody).unicodeType }}
                </span>
              </div>
            </div>
            <div class="mt-5 mb-10 flex">
              <div v-if="showCost" class="text-xs text-grey-dark mr-10">
                {{ $t('column_labels.total_cost') }}
                <span class="text-xl text-blue block mt-2 font-bold">
                  {{ getCost(campaign) }}
                </span>
              </div>
              <div v-if="isSms()" class="text-xs text-grey-dark mr-10">
                {{ $t('column_labels.total_sms') }}
                <span class="text-xl text-blue block mt-2 font-bold">
                  {{ $wcsender.formatNumber(campaign.SmsTotal) }}
                </span>
              </div>
            </div>
            <div v-if="showMessage">
              <h3 class="text-base font-bold mt-10 mb-2">{{ $t('column_labels.message') }}</h3>
              <div class="preview-container">
                <div class="text-right bubble flex justify-between items-center">
                  <p class="text-xs text-grey-dark py-2">
                    {{ campaign.ClientMessageId ? `${campaign.ClientMessageId} • ` : '' }}
                    {{ campaign.TemplateBody.length ? `${campaign.TemplateBody.length} characters` : '' }}
                  </p>
                  <a 
                    v-if="hasMessageSamples"
                    @click="previewMessageSample" 
                    class="text-blue flex items-center no-underline mb-3 inline-block text-sm mt-5 cursor-pointer"
                  >
                    <i class="icon-novo-eye mr-1" />
                    Preview with campaign data
                  </a>
                </div>
                <div class="bubble text-sm text-blue-dark p-3 bg-blue-lightest border
                  border-blue-lighter rounded inline-block bg-white leading-loose whitespace-pre-wrap"
                >{{ messageSamplePreview.text ? messageSamplePreview.text : campaign.TemplateBody  }}
                </div>
                <p class="ml-4 text-sm text-grey-darker py-2" v-if="messageSamplePreview.destination">Destination: {{ messageSamplePreview.destination}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-initial pl-16">
          <DonutChart :data="chartData" />
          <ul class="list-reset">
            <li v-for="(d, i) in chartData" :key="i" class="mb-5">
              <div class="flex justify-between items-center">
                <div>
                  <svg height="10" width="10" class="mr-2">
                    <circle cx="5" cy="5" r="4" :stroke="d.color" stroke-width="2" fill="none" />
                  </svg>
                  <span class="text-sm text-grey-dark">{{ d.label }}</span>
                </div>
                <span class="text-xs text-blue">
                  {{ toPercentString(convertValueToPercent(chartData)[i].value || 0) }}
                </span>
              </div>
              <span class="text-lg text-black mt-2 block ml-4 font-bold">
                {{ $wcsender.formatNumber(d.value) }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- <code class="text&#45;xs whitespace&#45;pre"> -->
      <!--   {{ campaign }} -->
      <!-- </code> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Moment from 'moment';


import statusJson from '@/json/campaignStatus.json';

import campaignListMixin from '@/mixins/campaign-list';
import msgMixin from '@/mixins/message';

import DonutChart from '@/components/DonutChart.vue';

export default {
  name: 'sms-campaign-detail',

  mixins: [msgMixin, campaignListMixin],

  components: {
    DonutChart,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    campaign: {
      type: Object,
      required: true,
    },

    showMessage: {
      type: Boolean,
      default: false,
    },

    currentTz: {
      type: String,
      default: '(UTC-00)',
    },

    currentTzOffset: {
      type: Number,
      default: 0,
    },

    getLocalTime: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const statusMap = new Map();
    statusJson.forEach((s) => {
      statusMap.set(s.value, s.name);
    });

    const self = this;

    return {
      messageSamplePreview: {},
      campaignStatus: statusMap,
      chartData: [
        {
          label: self.$t('column_labels.delivered'),
          value: this.isSms() ? this.campaign.SmsDelivered : this.campaign.MsgDelivered,
          color: '#3490dc',
        },
        {
          label: self.$t('column_labels.undelivered'),
          value: this.isSms() ? this.campaign.SmsUndelivered : this.campaign.MsgUndelivered,
          color: '#1c3d5a',
        },
        {
          label: self.$t('column_labels.rejected'),
          value: this.isSms() ? this.campaign.SmsError : this.campaign.MsgError,
          color: '#b8c2cc',
        },
      ],
    };
  },

  created() {
    this.getUser();
  },

  computed: {
    ...mapGetters({
      socketId: 'socket/getSocketId',
      queueUrl: 'socket/getQueueUrl',
      userData: 'user/getUser',
      hasPricingEntitlement: 'user/getPricingEntitlementAccess',
      shouldHideCost: 'user/getHideCostFlag',
    }),

    showCost() {
      return this.hasPricingEntitlement && !this.shouldHideCost;
    },

    hasMessageSamples() {
      try {
        const campaignMeta = JSON.parse(this.campaign.CampaignMeta || '{}');
        
        return campaignMeta && campaignMeta.messageSamples && campaignMeta.messageSamples.length > 0;
      } catch (err) {
        this.$showError(this, err);
        return false;
      } 
    },
  },

  methods: {
    ...mapActions({
      sendCampaignToEmail: 'campaigns/downloadSMSCampaign',
      getUser: 'user/getUser',
    }),

    previewMessageSample() {
      const campaignMeta = JSON.parse(this.campaign.CampaignMeta) || {};
      const randomRow = campaignMeta.messageSamples[Math.floor(Math.random() * campaignMeta.messageSamples.length)];

      try {
        const message = JSON.parse(randomRow);
        if (message && typeof message  === "object") {
          this.messageSamplePreview = message;
        } 
      }
      catch (e) { 
        this.messageSamplePreview = { text: randomRow };
      }
    },

    getStatusName(statusId, schedule) {
      if (
        ([1, 2, 3, 4].includes(statusId)) && this.$wcsender.dateIsInTheFuture(schedule)) {
        return this.campaignStatus.get(99);
      }
      return this.campaignStatus.get(statusId);
    },

    getCost(campaignDetail) {
      const d = this.$vars.PRICE_DECIMAL;
      const l = this.$vars.LOCALE;
      const { Price, PriceCurrency } = campaignDetail;
      // Numbers above 0 will only show 2 decimal places
      const decimal = Price < 1 ? d : 2;
      return `${PriceCurrency || ''} ${this.$wcsender.formatPrice(Price, decimal, l)}`;
    },

    toPercentString(n) {
      return `${n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}%`;
    },

    isScheduled() {
      const current = Moment();
      const timeLimit = this.$vars && this.$vars.SCHEDULED_TIME_LIMIT_IN_MINS ? this.$vars.SCHEDULED_TIME_LIMIT_IN_MINS : 3;
      const st = Moment.utc(this.campaign.ScheduledAt).subtract(timeLimit, 'minutes');
      return !current.isSameOrAfter(st);
    },

    convertValueToPercent(data) {
      const total = data.reduce((acc, curr) => acc + curr.value, 0);
      return data.map(d => ({
        ...d,
        value: total === 0 ? 0 : (d.value / total) * 100,
      }));
    },

    async downloadCampaign(campaign) {
      const {
        // eslint-disable-next-line camelcase
        CampaignId: campaign_id,
        // eslint-disable-next-line camelcase
        CampaignName: campaign_name,
        MsgTotal: total,
      } = campaign;

      const payload = {
        campaign_id,
        campaign_name,
        socket_id: this.socketId,
        queue_url: this.queueUrl,
        total,
      };

      try {
        this.$notify({
          title: this.$t('mcs.campaigns.sms_details[0]'),
          message: this.$t('mcs.campaigns.sms_details[1]'),
          type: 'success',
        });
        await this.sendCampaignToEmail(payload);
      } catch (err) {
        // this.$message.error(err.message || 'Failed to send campaign via email');
        // this.$notify.error({
        //   title: 'Error',
        //   message: 'Failed to download campaign',
        // });
        this.$showError(this, err);
      }
    },


    isSms() {
      return this.campaign.Product === 'SMS';
    },

    // Get status class name for tags
    getTagClassName(statusVal, isFuture) {
      let className = 'status-tag';
      if (statusVal === 0) { // Loaded
        className += '--blue';
      } else if (statusVal === 2 && !isFuture) { // Processing
        className += '--yellow';
      } else if (statusVal === 6) { // Pending
        className += '--yellow';
      } else if (statusVal === 3 && !isFuture) { // Failed
        className += '--red';
      } else if (statusVal === 4 && !isFuture) { // Sent
        className += '--green';
      } else if (statusVal === 4 && isFuture) {
        className += '--orange';
      } else if (statusVal === 3 && isFuture) {
        // tbh, the status is already failed even though it's still scheduled, idkw.
        className += '--orange';
      } else if (statusVal === 2 && isFuture) {
        className += '--orange';
      }
      return className;
    },
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  min-width: 500px;
  margin-left: 15px;
  position: relative;
}

.status-tag {
  border-radius: 5px;
  padding: 4px 10px;
  line-height: 1;
  display: inline-block;
  color: tomato;
  border: solid 1px currentColor;

  &--gray {
    color: black;
    background-color: #d3d4d5;
    border: solid 1px #d3d4d5;
  }


  &--green {
    color: #46D381;
    background-color: transparentize(#46D381, 0.9);
    border-color: currentColor;
  }

  &--yellow {
    color: black;
    background-color: #f2ce5c;
    border: solid 1px #f2ce5c;
  }

  &--red {
    color: #f13a62;
    background-color: transparentize(#f13a62, 0.9);
    border-color: currentColor;
  }

  &--blue {
    color: #80bfea;
    background-color: transparentize(#80bfea, 0.9);
    border-color: currentColor;
  }

  &--orange {
    color: #e6a23c;
    background-color: transparentize(#e6a23c, 0.9);
    border-color: currentColor;
  }
}

.preview-container {
  width: 500px;
}
</style>
