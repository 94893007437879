import actions from './actions';

export default {
  namespaced: true,

  state: {
    templates: [],
    enums: {},
  },

  getters: {
    getTemplates: state => state.templates,
    getEnums: state => state.enums,
  },

  mutations: {
    SET_TEMPLATES(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.templates = payload.templates;
    },

    SET_ENUMS(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.enums = payload.enums;
    },
  },

  actions,
};
