<template>
  <div :class="{'selected': Object.keys(selected).length && selected.templateId === template.templateId && selected.type === type}"
    class="border border-grey-lighter template-card relative leading-normal flex flex-col p-3"
    @click="() => {
      $emit('select-template', template, type);
    }"
  >
    <div class="flex-auto">
      <h4 class="pb-4">{{ template.name }}</h4>
      <p class="text-sm break-words truncated">{{ template.text }}</p>
    </div>
    <div class="border-t border-grey-lighter flex-shrink flex flex-row justify-between pt-3 text-sm">
      <p class="text-grey-dark">Characters:
        <span class="text-black font-semibold"> {{getSMSDetails(template.text).charsUsed }}</span>
      </p>
      <p class="text-grey-dark">SMS Parts:
        <span class="text-black font-semibold"> {{ getSMSDetails(template.text).smsParts }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import MessageMixin from '@/mixins/message';

export default {
  name: 'Template',

  mixins: [MessageMixin],

  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },

  methods: {
    getSMSDetails(message) {
      // from mixins
      return this.getTextAttrs(message);
    },
  },
};
</script>
