// import http from '@/utils/http';
import templatesJson from './templates-sample.json';

const delay = t => new Promise(resolve => setTimeout(resolve, t));

export default {
  // Fetch logs data
  getAlertTemplates: async ({ commit }) => {
    try {
      const data = await delay(3000).then(() => templatesJson);

      const { messageTemplates } = data;

      const { templates, total } = messageTemplates;

      commit('SET_ALERT_TEMPLATES', { templates, total });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

};
