<template>
  <div>
    <h3>Confirmation and send</h3>
    <div class="mt-2 flex">
      <div class="w-3/5">
        <RecipientsInfo
          :summaryInfo="summaryInfo"
          :downloading="downloading"
          @download-invalid-contacts="downloadInvalidContacts"
          @allow-all-duplicates="handleAllowAllDuplicates"
        />
        <DestinationsChart :countries-chart-data="countriesChartData"/>
      </div>
      <div class="w-2/5">
        <MessagePreview
          :template="form.template"
          :senderId="form.senderId"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
} from 'vuex';

import countriesJson from '@/json/countries.json';
import RecipientsInfo from './RecipientsInfo.vue';
import MessagePreview from './MessagePreview.vue';
import DestinationsChart from './DestinationsChart.vue';

export default {
  name: 'Summary',

  components: {
    RecipientsInfo,
    MessagePreview,
    DestinationsChart,
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },

    step: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      downloading: false,
    };
  },

  computed: {
    countriesChartData() {
      const { countries } = this.form.summary;
      const countryMatching = Object.keys(countries).reduce((a, b) => {
        const country = countriesJson.find(c => c.value.toUpperCase() === b.toUpperCase());

        if (country) {
          const { name } = country;
          a.push({ country: name, total: countries[b] });
        } else {
          a.push({ country: 'unknown', total: countries[b] });
        }

        return a;
      }, []);

      const countriesWithTotals = countryMatching.reduce((a, b) => {
        if (b.country !== 'unknown') {
          a.labels.push(`${b.country} (${b.total.toLocaleString()})`);
          a.data.push(b.total);
        }
        return a;
      }, { labels: [], data: [] });


      const unknownTotal = countryMatching.reduce((a, b) => {
        if (b.country === 'unknown') {
          // eslint-disable-next-line no-param-reassign
          a += b.total;
        }

        return a;
      }, 0);


      const chartLabels = unknownTotal < 1 ? [...countriesWithTotals.labels]
        : [...countriesWithTotals.labels].concat([`Unknown (${unknownTotal.toLocaleString()})`]);

      const chartData = unknownTotal < 1 ? [...countriesWithTotals.data]
        : [...countriesWithTotals.data].concat([unknownTotal]);

      return {
        chartData,
        chartLabels,
      };
    },

    summaryInfo() {
      const { summary } = this.form;
      const { recipients } = this.form;
      const { meta } = recipients;
      const { allowDuplicates } = meta;

      const {
        total,
        valid,
        invalid,
        duplicates,
      } = summary;

      return {
        total: {
          title: 'Total contacts',
          label: 'recipients in the list',
          value: total,
          iconClass: 'icon-novo-user-groups-line text-black',
        },
        valid: {
          title: 'Valid',
          label: 'recipients who will receive the message',
          value: valid,
          iconClass: 'icon-novo-check text-green-darkest',
        },

        invalid: {
          title: 'Unable to import',
          label: 'numbers are malformed or have errors',
          value: invalid,
          iconClass: 'icon-novo-close-circle text-red',
        },

        duplicate: {
          title: 'Duplicates',
          label: 'duplicate contacts will not be included in your message',
          value: duplicates,
          allowDuplicates,
          iconClass: 'icon-novo-error text-yellow-darker',
        },
      };
    },
  },

  created() {

  },

  methods: {
    ...mapActions({
      downloadFile: 'common/downloadFile',
    }),

    async downloadInvalidContacts() {
      const { invalidFileLocation } = this.form.summary;
      if (invalidFileLocation) {
        try {
          this.downloading = true;
          await this.downloadFile(invalidFileLocation);
        } catch (err) {
          this.$showError(this, err);
        } finally {
          this.downloading = false;
        }
      }
    },

    handleAllowAllDuplicates(isAllowDuplicates) {
      const { recipients } = this.form;
      const { meta } = recipients;

      const newMeta = { ...meta, allowDuplicates: isAllowDuplicates };
      const newRecipients = { ...recipients, meta: newMeta };
      this.$emit('update-form', { recipients: newRecipients });
    },
  },
};
</script>
