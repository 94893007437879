<template>
  <el-dialog
    title="Changing voice message type?"
    :visible.sync="visible"
    width="700px"
    :before-close="handleClose"
  >
    <div>
      <p class="text-base leading-normal" :style="{wordBreak: 'break-word'}">
        Changing to a different voice message type will discard the current message content.
        Select 'Continue' to proceed or 'Cancel' to keep the existing message.
      </p>
    </div>
    <span
      slot="footer"
      class="dialog-footer text-right"
    >
      <el-button
        type="text" @click="$emit('cancel', type)"
        class="mr-6"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        @click="$emit('proceed', type === 'message' ? 'uploads' : 'message')"
      >
        Continue
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'RecipientConfirmation',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleClose() {
      this.$emit('cancel', this.type);
    },
  },
};
</script>
