import http from '@/utils/http';

export default {
  async uploadContacts({ commit }, payload) {
    try {
      const formData = new FormData();

      if (payload.Source === 'UPLOAD') {
        formData.append('File', payload.File);
        formData.append('AllowDuplicates', payload.AllowDuplicates);
        formData.append('AllNumbersLocal', payload.AllNumbersLocal);
        formData.append('FileHasHeader', false);

        if (payload.Country) {
          formData.append('Country', payload.Country);
        }

        const columns = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const key in payload.Column) {
          if (payload.Column[key] !== -1) {
            const columnLabel = key === 'MSISDN' ? 'Mobile' : key;
            const columnValue = payload.Column[key];
            columns[columnLabel] = columnValue;
          }
        }

        formData.append('FileColumns', JSON.stringify(columns));
      } else {
        const { Contacts, Groups } = payload;

        Contacts.forEach(contact => formData.append('Msisdns', contact));
        Groups.forEach(group => formData.append('ContactGroupIds', group.id));
      }

      const response = await http.campaignApi.post(
        '/contacts/',
        formData,
      );

      const { data } = response;

      commit('SET_UPLOAD_DETAILS', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getContactsSummaryStatus({ commit }, payload) {
    try {
      const { id } = payload;

      const response = await http.campaignApi.get(
        `/contacts/${id}`,
      );

      const { data } = response;

      commit('SET_SUMMARY_DETAILS', data);

      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },


  async getContacts({ commit }, payload) {
    try {
      const {
        accountId, limit, offset, query,
      } = payload;

      const queryParams = `limit=${limit}&offset=${offset}&query=${query}`;

      const response = await http.contactsApi.get(
        `/accounts/${accountId}/contacts?${queryParams}`,
      );

      const { data } = response;

      commit('SET_CONTACTS', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getContactGroups({ commit }, payload) {
    try {
      const {
        accountId, limit, offset, name,
      } = payload;

      const queryParams = `limit=${limit}&offset=${offset}&name=${name || ''}`;

      const response = await http.contactsApi.get(
        `/accounts/${accountId}/groups?${queryParams}`,
      );

      const { data } = response;

      commit('SET_CONTACT_GROUPS', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getContactGroupsById({ commit }, payload) {
    try {
      const {
        accountId, groupId,
      } = payload;

      const response = await http.contactsApi.get(
        `/accounts/${accountId}/groups/${groupId}`,
      );

      const { data } = response;

      commit('SET_CONTACT_GROUPS_BY_ID', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getContactsFromDirectories({ commit, state }, payload) {
    const {
      limit,
      filters,
      currentPage,
      // eslint-disable-next-line no-unused-vars
      accountId,
    } = payload;

    const { departments = '', locations = '' } = filters;

    let data = [];


    try {
      if (!state.contactDirectories) {
        const { data: response } = await http.v2.get('/contacts?type=cc');
        data = response;
      } else {
        data = JSON.parse(JSON.stringify(state.contactDirectories));
      }

      data.items = data.contacts.all || [];

      let loc = [];
      let dept = [];

      if (data.items.length) {
        if (departments && departments.toLowerCase() !== 'all') {
          dept = data.contacts.byDepartment[departments] || [];
        }
        if (locations && locations.toLowerCase() !== 'all') {
          loc = data.contacts.byLocation[locations] || [];
        }

        if (dept.length) {
          data.items = [...dept];
        }

        if (loc.length) {
          if (dept.length) {
            const merged = dept.filter(v => v.location === locations);

            data.items = [...new Set(merged)];
          } else {
            data.items = [...loc];
          }
        }

        // remove plus
        data.items = data.items.map((v) => {
        // eslint-disable-next-line no-param-reassign
          v.phoneNumber = v.phoneNumber.replace('+', '');

          return v;
        });
      }

      data.filters.locations = data.filters.locations.sort();
      data.filters.departments = data.filters.departments.sort();

      const totalFiltered = data.items.length;

      const totalPages = Math.ceil(totalFiltered / limit);

      const hasPreviousPage = currentPage !== 1 && totalPages > 1;
      const hasNextPage = currentPage < totalPages && totalPages > 1;

      const paginationDef = {
        totalFiltered, totalPages, page: currentPage, hasPreviousPage, hasNextPage,
      };

      commit('SET_CONTACT_DIRECTORIES', { ...data, ...paginationDef });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
