import { mkConfig, generateCsv, download } from 'export-to-csv/output';
import * as XLSX from 'xlsx';
import { mapActions } from 'vuex';
import { decodeJwt } from '@/utils/common';

export default {
  methods: {
    ...mapActions({
      getUploadUrl: 'common/getUploadUrl',
      request: 'common/request',
    }),

    downloadSampleFile(id) {
      const options = {
        fieldSeparator: ',',
        decimalSeparator: '.',
        title: 'sample_file',
        filename: 'sample_file',
        showColumnHeaders: false,
        useKeysAsHeaders: true,
      };

      const csvConfig = mkConfig({ ...options });
      const data = [
        { Mobile: 6596793616, 'First name': 'Chris', 'Last name': 'Shiflet' },
        { Mobile: 6596790613, 'First name': 'Don Don', 'Last name': 'Ampalayo' },
        { Mobile: 6596790641, 'First name': 'John', 'Last name': 'Boonham' },
        { Mobile: 6596793611, 'First name': 'Chris', 'Last name': 'Shiflet' },
        { Mobile: 6596780611, 'First name': 'Sean', 'Last name': 'Yseult' },
        { Mobile: 6596790612, 'First name': 'Mike', 'Last name': 'Pinisi' },
        { Mobile: 6596790614, 'First name': 'Don Don', 'Last name': 'Ampalayo' },
        { Mobile: 6596790642, 'First name': 'John', 'Last name': 'Boonham' },
        { Mobile: 6596793616, 'First name': 'Chris', 'Last name': 'Shiflet' },
        { Mobile: 6596780617, 'First name': 'Sean', 'Last name': 'Yseult' },
        { Mobile: 6596790612, 'First name': 'Mike', 'Last name': 'Pinisi' },
        { Mobile: 6596790614, 'First name': 'Don Don', 'Last name': 'Ampalayo' },
        { Mobile: 6596790642, 'First name': 'John', 'Last name': 'Boonham' },
        { Mobile: 6596793616, 'First name': 'Chris', 'Last name': 'Shiflet' },
        { Mobile: 96780617, 'First name': 'Sean', 'Last name': 'Yseult' },
      ];


      const csv = generateCsv(csvConfig)(data);

      // Get the button in your HTML
      const csvBtn = document.querySelector(`#${id}`);

      // Add a click handler that will run the `download` function.
      // `download` takes `csvConfig` and the generated `CsvOutput`
      // from `generateCsv`.
      csvBtn.addEventListener('click', () => download(csvConfig)(csv));
    },

    handleUploadedContactsFile(file = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          const { raw: fileDetails } = file;

          const supportedFileTypes = [
            'text/csv',
            'text/plain',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ];

          const isAccepted = supportedFileTypes.includes(fileDetails.type.toLowerCase());

          const is30MBLimit = fileDetails.size / 1024 / 1024 <= 30;

          //  size in bytes
          const sizeb = fileDetails.size;

          const isEmpty = sizeb < 1;
          const isCorrupt = sizeb < 2;

          if (!isAccepted) {
            throw new Error('File not accepted.');
          }
          if (!is30MBLimit) {
            throw new Error('File exceeds file size limit.');
          }

          if (isCorrupt) {
            throw new Error('File is corrupted.');
          }

          if (isEmpty) {
            throw new Error('File is empty.');
          }

          let data;
          if (fileDetails.type === supportedFileTypes[2]) {
            data = await fileDetails.arrayBuffer();
          } else {
            // Set BOM in the file
            const blob = new Blob(['\ufeff', fileDetails], { type: `${fileDetails.type};charset=utf-8` });
            data = await blob.arrayBuffer();
          }

          // Set raw to true to read each column as a string
          const workbook = XLSX.read(data, { sheetRows: 20, raw: true, sheetStubs: true });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' }); // defval - to include all the empty cells

          // Get the length of the row with the biggest length
          const columnCount = rows.reduce((max, currentArray) => {
            if (Array.isArray(currentArray)) {
              const { length } = currentArray;
              return length > max ? length : max;
            }
            return max;
          }, 0);

          // Adjust the items in the first row so headers can be matched accordingly
          const firstRow = rows[0];
          if (firstRow.length < columnCount || firstRow.includes(undefined)) {
            const newFirstRow = [];
            const columns = [...Array(columnCount).keys()];
            // eslint-disable-next-line no-restricted-syntax
            for (const i in columns) {
              if (i > firstRow.length - 1) {
                newFirstRow[i] = '';
              } else {
                newFirstRow[i] = !firstRow[i] ? '' : firstRow[i];
              }
            }
            rows[0] = newFirstRow;
          }
          // Get only first 20
          const n = 20;
          const partialData = {
            List: rows.slice(0, n),
          };

          resolve({
            file: fileDetails,
            fileName: fileDetails.name,
            fileType: fileDetails.type,
            partialData,
          });
        } catch (e) {
          reject(e);
        }
      });
    },

    handleUploadRequest(fileData = {}) {
      return new Promise(async (resolve, reject) => {
        try {
          const { file } = fileData;
          const uploadData = await this.getUploadUrl({ file });
          const { uploadUrl, dbUrl, mimeType } = uploadData;

          const storageOrigin = uploadUrl ? uploadUrl.split('/')[2] : ''; // If from S3 or Google Cloud Storage

          const headers = storageOrigin === 'storage.googleapis.com' ? {
            'Content-Type': mimeType,
          } : {
            'Content-Type': mimeType,
            'Content-Disposition': `attachment;filename="${encodeURI(file.name)}"`,
          };

          const uploadReq = {
            method: 'PUT',
            url: uploadUrl,
            data: file,
            headers,
          };

          await this.request({ ...uploadReq });

          // Decode jwt and determine region based on RegionId
          const token = localStorage.getItem('WWW-Authenticate');
          const decodedData = decodeJwt(token);
          const { apiConfig } = this.http;
          let regionId = null;
          let rootUrl = '';

          if (decodedData && Object.keys(decodedData).includes('RegionId')) {
            const { RegionId: region } = decodedData;
            regionId = region;

            rootUrl = regionId !== null ? apiConfig.v2[regionId] : window.location.origin;
          }

          resolve({
            fileUrl: `${rootUrl}${dbUrl}?regionId=${regionId}`,
            file,
            mimeType,
          });
        } catch (err) {
          reject(err);
        }
      });
    },
  },
};
