<template>
  <div class="bg-white flex justify-between items-center text-xs leading-none">
    <div>
      <span class="pt-3 pr-3 inline-block leading-none cursor-pointer text-sm capitalize">Rows</span>
      <el-select
        v-model="pageLimit"
        :style="{ width: '70px' }"
        size="small"
        @change="handlePageLimit"
        :disabled="loading"
      >
        <el-option
          v-for="(v, i) in  paginationDef.pageLimits"
          :key="i"
          :value="v"
        >
          {{ v }}
        </el-option>
      </el-select>
    </div>
    <div class="flex">
      <a class="p-3 inline-block leading-none cursor-pointer text-sm"
        :class="{ 'cursor-not-allowed text-grey disabled': loading || !paginationDef.hasPreviousPage }"
        @click.prevent="handlePrevPage"
      >
        <i class="icon-novo-arrow-left-long mr-2 text-l align-middle" /> <span class="capitalize">Prev</span>
      </a>
      <!-- <el-input :style="{ width: '42px' }" v-model="page" readonly class="inline-block text-xl mx-3" /> -->
      <el-select
        class="mt-1"
        v-model="page"
        :style="{ width: '80px' }"
        size="small"
        @change="handlePageChange"
        :value="paginationDef.currentPage"
        :disabled="loading"
      >
        <el-option
          v-for="(v, i) in  paginationDef.pages"
          :key="i"
          :value="v"
        >
          {{ v }}
        </el-option>
      </el-select>
      <a class="p-3 inline-block leading-none cursor-pointer text-sm capitalize"
        :class="{ 'cursor-not-allowed text-grey disabled': loading || !paginationDef.hasNextPage }"
        @click.prevent="handleNextPage">
        <span class="capitalize">Next</span>
        <i class="ml-2 icon-novo-arrow-right-long text-l align-middle" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    filters: {
      default: () => ({}),
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    paginationDef: {
      type: Object,
      default: () => ({
        currentPage: 1,
        pages: 0,
        total: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        limit: 20,
        pageLimits: [10, 20, 30, 40, 50],
      }),
    },

    data: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      page: 1,
      pageLimit: 20,
    };
  },

  watch: {
    'paginationDef.currentPage': {
      handler(v) {
        this.page = v;
      },
    },

    'paginationDef.limit': {
      handler(v) {
        this.pageLimit = v;
      },
    },

    deep: true,
  },


  methods: {
    handleNextPage() {
      if (!this.paginationDef.hasNextPage || this.loading) {
        return;
      }
      this.$emit('handle-page-change', { currentPage: this.paginationDef.currentPage + 1 });
    },

    handlePrevPage() {
      if (!this.paginationDef.hasPreviousPage || this.loading) {
        return;
      }

      this.$emit('handle-page-change', { currentPage: this.paginationDef.currentPage - 1 });
    },

    handlePageChange(p) {
      const currentPage = Number(p);
      this.$emit('handle-page-change', { currentPage });
    },

    handlePageLimit(p) {
      this.$emit('handle-page-change', { currentPage: 1, limit: Number(p) });
    },
  },
};
</script>
