/* eslint-disable no-param-reassign */

import actions from './actions';

export default {
  namespaced: true,

  state: {
    voiceProfiles: [],
    virtualNumbers: [],
  },

  getters: {
    getVoiceProfiles: state => state.voiceProfiles,
    getVirtualNumbers: state => state.virtualNumbers,
  },

  mutations: {
    SET_VOICE_PROFILES(state, payload) {
      state.voiceProfiles = payload;
    },

    SET_VIRTUAL_NUMBERS(state, payload) {
      state.virtualNumbers = payload;
    },
  },

  actions,
};
