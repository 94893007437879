import whatsAppIcon from '@/assets/channels/whatsapp.svg';
import weChatIcon from '@/assets/channels/wechat.svg';
import facebookIcon from '@/assets/channels/facebook.svg';
import googleRcsIcon from '@/assets/channels/googlercs.svg';
import messengerIcon from '@/assets/channels/applebusinesschat.svg';
import lineIcon from '@/assets/channels/line.svg';
import viberIcon from '@/assets/channels/viber.svg';
import zaloIcon from '@/assets/channels/zalo.svg';
import smsIcon from '@/assets/channels/sms.svg';
import kakaoIcon from '@/assets/channels/kakao.svg';
import instagramIcon from '@/assets/channels/instagram.svg';
import voiceIcon from '@/assets/apps/voice.svg';

import channelJson from '@/json/channels.json';
import appTypeJson from '@/json/appType.json';
import statusJson from '@/json/campaignStatus.json';

export default {
  methods: {
    campaignStatus(code) {
      return statusJson.find(e => e.value === code);
    },

    productTypeByCode(code) {
      return appTypeJson.find(e => e.code === code);
    },

    channelByCode(code) {
      // If code is empty
      if (!(code && code.trim().length)) {
        return {
          name: 'SMS', code: 'SMS', value: 'SMS', campaignCode: 'SM',
        };
      }

      return channelJson.find(e => e && (e.code === code || e.campaignCode === code));
    },

    // returns an object which contains name, code, value, and campaignCode
    getProductDetail(campaign) {
      if (!(campaign && Object.keys(campaign).length)) return;

      const { ChannelType, Product } = campaign;

      // If Product is voice
      if (Product === 'VO') {
        return { // eslint-disable-line
          name: 'Voice',
          code: 'VO',
          value: 'VOICE',
          campaignCode: 'VO',
        };
      }
      // else Product is SMS or CA
      return this.channelByCode(ChannelType); // eslint-disable-line
    },

    // Get Icons
    getChannelIcon(code) {
      let icon = '';
      switch (code) {
        case 'WA':
          icon = whatsAppIcon;
          break;
        case 'WC':
          icon = weChatIcon;
          break;
        case 'FB':
          icon = facebookIcon;
          break;
        case 'GG':
          icon = googleRcsIcon;
          break;
        case 'IM':
          icon = messengerIcon;
          break;
        case 'LN':
          icon = lineIcon;
          break;
        case 'VB':
          icon = viberIcon;
          break;
        case 'ZN':
          icon = zaloIcon;
          break;
        case 'ZL':
          icon = zaloIcon;
          break;
        case 'SM':
          icon = smsIcon;
          break;
        case 'VO':
          icon = voiceIcon;
          break;
        case 'IG':
          icon = instagramIcon;
          break;
        case 'KK':
          icon = kakaoIcon;
          break;
        default:
          icon = smsIcon;
          break;
      }
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${icon}`;
    },

    getChannelName(code) {
      const channel = this.channelsJson.find(c => c && (c.code === code || c.campaignCode === code));
      return channel ? channel.name : code;
    },
  },
};
