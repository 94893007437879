<template>
  <el-dialog
    class="ma-recipient-dialog"
    title="Type a phone number"
    :visible.sync="modalVisible"
    :footer="null"
    width="540px"
    :before-close="onModalBeforeClose"
    @open="onModalOpen"
  >
    <div class="flex flex-col">
      <el-form
        ref="mobileFormRef"
        :model="mobileForm"
        :rules="rules"
        @submit.prevent.native
        class="mb-form"
      >
        <el-form-item>
          <div class="relative cctel">
            <p class="text-sm">Phone number</p>
            <div class="flex justify-between">
              <VueTelInput
                v-model="mobileForm.mobileNo"
                mode="international"
                :default-country="form.country || countryDetails.country"
                :auto-default-country="false"
                :input-options="{ showDialCode: false, placeholder: 'Enter a valid phone number'}"
                :dropdownOptions="{
                  showDialCodeInList: true,
                  showDialCodeInSelection: true,
                  showSearchBox: true,
                  showFlags: true
                }"
                :disabled="loading"
                required
                class="cctel-input"
                @input="handlePhoneValidate"
              >
                <template v-slot:arrow-icon>
                  <i class="icon-novo-arrow-down ml-1" />
                </template>
              </VueTelInput>
              <el-button
                type="primary"
                :disabled="!isValid"
                @click="add()"
                class="ml-1 block"
                :style="{width: '150px'}"
              >
                Add
              </el-button>
            </div>
          </div>
          <p
            v-if="!isValid && mobileForm.mobileNo !==''"
            class="text-xs text-red"
          >
            {{ errorMessage }}
          </p>
          <p class="text-xs">Use international format, including country code prefix (e.g {{ countryDetails.placeholder }})</p>
        </el-form-item>
      </el-form>
      <div
        class="mt-3 mb-6 w-3/5 text-xs oveflow-y-auto"
        :style="{maxHeight: '300px'}"
      >
        <div
          v-for="(v,i) in selectedContacts"
          :key="i"
          class="px-3 py-3 border-b border-grey-lighter flex justify-between"
        >
          <div>
            <span :class="`mr-2 flag-icon flag-icon-squared flag-icon-${getPhoneNumberInfo(v).regionCode.toLowerCase()}`" />
            {{ getPhoneNumberInfo(v).number.international }}
          </div>
          <div>
            <i
              class="icon-novo-close-circle cursor-pointer text-xl font-medium align-middle"
              @click="remove(v)"
            />
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer flex justify-end"
      >
        <el-button
          type="text"
          class="mr-2"
           @click="handleClose()"
        >
          Close
        </el-button>
        <el-button
          type="primary"
          @click="handleDone()"
          :disabled="!selectedContacts.length"
        >
          Done
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';

import _ from 'lodash';

import PhoneNumberMixin from '@/mixins/phone-number';

export default {
  name: 'PhoneNumber',

  components: {
    VueTelInput,
  },

  mixins: [PhoneNumberMixin],

  props: {
    addedContacts: {
      type: Array,
      default: () => [],
    },
    modalVisible: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: '',
    },

    countryDetails: {
      type: Object,
      default: () => ({}),
    },

    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      mobileForm: {
        mobileNo: '',
      },

      isValid: false,
      errorMessage: 'Phone number must be valid.',

      selectedContacts: [],
    };
  },

  mounted() {

  },

  methods: {
    onModalOpen() {

    },
    onModalBeforeClose(done) {
      this.reset();
      this.$emit('close-modal');
      done();
    },
    handlePhoneValidate(n, validator) {
      this.isValid = validator && validator.valid;
      if (!this.isValid) {
        this.errorMessage = 'Phone number must be valid.';
      }
    },

    add() {
      const isDuplicate = [...this.addedContacts, ...this.selectedContacts]
        .some(v => v && this.getPhoneNumberInfo(v).number.e164 === this.getPhoneNumberInfo(this.mobileForm.mobileNo).number.e164);

      if (isDuplicate) {
        this.isValid = false;
        this.errorMessage = 'Phone number has been already added in the list.';
        return;
      }

      this.selectedContacts = _.union([...this.selectedContacts], [this.mobileForm.mobileNo]);
      this.mobileForm.mobileNo = '';
    },

    remove(d) {
      this.selectedContacts = this.selectedContacts.filter(v => v !== d);
    },

    handleDone() {
      const formattedContacts = this.selectedContacts.map(v => this.getPhoneNumberInfo(v).number.e164);

      this.$emit('add-to-main-list', formattedContacts, [], this.type);
      this.reset();
      this.$emit('close-modal');
    },

    handleClose() {
      this.reset();
      this.$emit('close-modal');
    },

    reset() {
      this.mobileForm.mobileNo = '';
      this.isValid = false;
      this.selectedContacts = [];
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
.cctel {
  .vue-tel-input {
    border: none;
    border-radius: .5rem;
    height: 40px;
    width: 400px;
    background-color: #f1f1f1;

    .vti__input {
      width: 350px;
      border: none;
      border-radius: .5rem;
      background-color: #f1f1f1;
    }

    .vti__dropdown {
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 7px;
      padding-right: 7px;
      outline: 0;
      width: 90px;

      input {
        font-size: 0.75rem;
      }

      .vti__dropdown-list {
        border: 1px solid #DCDFE6;
      }
    }
  }

  .vue-tel-input:focus-within {
    box-shadow: none;
    border: none;
  }
}

.mb-form .el-form-item__content  {
  line-height: 30px  !important;;
}

.mb-form .el-form-item {
  margin-bottom: 0px !important;
}
</style>
