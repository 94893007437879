import http from '@/utils/http';

export default {
  async getVirtualNumberBySa({ commit }, payload) {
    const { subAccountUid } = payload;
    try {
      const url = `/virtual-numbers?subAccountUid=${subAccountUid}`;
      const { data: { virtualNumbers = [] } } = await http.v1.get(url);
      commit('SET_VIRTUAL_NUMBERS', virtualNumbers);
      return virtualNumbers;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async validateSenderId({ commit }, payload) {
    try {
      const { data } = await http.v2.post('/accounts/sender-ids/validate', payload);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return {};
    }
  },

  async getSenderIds({ commit }) {
    try {
      const { data } = await http.v2.get('/accounts/sender-ids');
      commit('SET_SENDER_IDS', { senderIds: data });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async removeSenderId({ commit }, payload) {
    const { senderId } = payload;
    try {
      await http.v1.put(`/websender/sender-ids/${senderId}/delete`);
      commit('REMOVE_SENDER_ID', payload);

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async processUpload({ commit }, payload) {
    const pl = payload;
    const { SocketId: id, QueueURL: url } = pl;
    delete pl.SocketId;

    try {
      await http.v1.post(
        `/websender/uploads/${payload.Hash}/process?socketId=${id || ''}&queueUrl=${url || ''}`,
        payload,
      );
      commit('SET_ACTIVE_STEP', 1);
      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async sendSmsMessage({ commit, rootState }, payload) {
    try {
      let campaignData = {};

      if (rootState.user.hasMcsAccess || payload.type === 'PROACTIVE-ALERT') {
        if (payload.type) {
          // eslint-disable-next-line no-param-reassign
          delete payload.type;
        }
        const { data } = await http.campaignApi.post(
          'campaigns/sms',
          payload,
        );

        campaignData = data;
      } else {
        let query = '';
        // if (payload.meta.source === 'UPLOAD') {
        const { SocketID: id, QueueURL: url } = payload.meta;
        query = `?socketId=${id || ''}&queueUrl=${url || ''}`;
        // }

        const slug = `/websender/sms/send-mcs${query}`;

        const { data } = await http.v1.post(
          `${slug}`,
          payload,
        );

        campaignData = data;
      }

      commit('SET_CAMPAIGN_SENT_DATA', campaignData);
      return campaignData;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async sendSmsCampaignApproval({ commit, rootState }, payload) {
    let campaignData = {};

    try {
      if (rootState.user.hasMcsAccess) {
        const { data } = await http.campaignApi.post(
          'campaigns/sms',
          payload,
        );

        campaignData = data;
      } else {
        const body = payload;

        if (body.meta.source === 'UPLOAD') {
          const { SocketID: id, QueueURL: url } = body.meta;

          body.socketId = id;
          body.queueUrl = url;
        }

        const slug = '/websender/sms/send-for-approval';

        await http.v1.post(
          `${slug}`,
          body,
        );

        campaignData = {
          smsCampaignApproval: true,
          scheduled: body.message.schedule !== 'Send message now',
        };
      }

      commit('SET_CAMPAIGN_SENT_DATA', campaignData);
      return campaignData;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async sendChatAppsMessage({ commit, rootState }, payload) {
    try {
      let campaignData = {};

      if (rootState.user.hasMcsAccess) {
        const { data } = await http.campaignApi.post(
          'campaigns/chatapps',
          payload,
        );

        campaignData = data;
      } else {
        const slug = '/mcs/chatapps/send';
        const { data } = await http.v2.post(
          `${slug}`,
          payload,
        );

        campaignData = data;
      }

      commit('SET_CAMPAIGN_SENT_DATA', campaignData);

      return campaignData;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async sendVoiceMessage({ commit }, payload) {
    const slug = '/mcs/voice/send';

    try {
      const { data } = await http.v2.post(
        `${slug}`,
        payload,
      );
      commit('SET_CAMPAIGN_SENT_DATA', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async sendVoiceTestMessage({ commit }, payload) {
    const slug = '/mcs/voice/send-test';

    try {
      await http.v2.post(
        `${slug}`,
        payload,
      );

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  setPartialData({ commit }, payload) {
    commit('SET_PARTIAL_DATA', payload);
  },

  setUploadData({ commit }, payload) {
    commit('SET_UPLOAD_DATA', payload);
  },

  setUploadDataCc({ commit }, payload) {
    commit('SET_UPLOAD_DATA_CC', payload);
  },

  setProcessing({ commit }, payload) {
    commit('SET_PROCESSING', payload);
  },

  setActiveStep({ commit }, payload) {
    commit('SET_ACTIVE_STEP', payload);
  },

  // validateDestination({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     Vue.http({
  //       url: `${process.env.VUE_APP_CP_API_URL}/websender/msisdn/validate`,
  //       body: payload,
  //       method: 'post',
  //     })
  //       .then((response) => {
  //         resolve(response.body);
  //       })
  //       .catch((err) => {
  //         commit('API_ERROR', err, { root: true });
  //         reject(err);
  //       });
  //   });
  // },

  async validateDestination({ commit }, payload) {
    const slug = '/websender/msisdn/validate';

    try {
      const { data } = await http.v1.post(
        `${slug}`,
        payload,
      );

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async getSmsEngageFormQRCode({ commit }, payload) {
    const { sid, subAccountUid } = payload;
    const slug = `/mcs/sms-engage/qrcode?sid=${sid}&subaccountUid=${subAccountUid}`;

    try {
      const { data } = await http.v2.get(`${slug}`);

      commit('SET_SE_QR_CODE', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async getSmsEngageForms({ commit }, payload) {
    const { subAccountUid } = payload;
    const slug = `/mcs/sms-engage/forms?subaccountUid=${subAccountUid}`;

    try {
      const { data } = await http.v2.get(`${slug}`);

      commit('SET_SE_FORMS', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  setMessageSamples({ commit }, payload) {
    commit('SET_MESSAGE_SAMPLES', payload);
  },
};
