import {
  transformToHTML, removeBrackets, replaceLink, hasBrackets,
} from '@/utils/common';

export default {
  methods: {
    getWAPreviewContent(content, customFields, variables) {
      let transformed = transformToHTML(this.$sanitize(content.trim()))
        .replace(/\n/g, '<p></p>')
        .replace(/<p><\/p>/g, '\n').replace(/<p>/g, '');

      transformed = this.matchWACustomFields(transformed, customFields, variables);

      transformed = replaceLink(transformed);

      return transformed;
    },

    getNonWAPreviewContent(content, customFields, variables) {
      let transformed = this.$sanitize(content.trim());

      transformed = this.matchWACustomFields(transformed, customFields, variables);

      transformed = replaceLink(transformed);

      return transformed;
    },

    getFieldsMatched(text) {
      return `<span class="item-tag p-1">${text}</span>`;
    },

    matchWACustomFields(content, customFields, variables) {
      let c = content;

      if (customFields && customFields.length) {
        const assigned = customFields;

        if (assigned.length) {
          assigned.forEach((v) => {
            // to avoid regex escape issue
            if (v.field) {
              const pos = removeBrackets(v.field);

              if (variables.includes(v.value)) {
                c = c.replace(`{{${pos}}}`, this.getFieldsMatched(v.value));
              } else {
                c = c.replace(`{{${pos}}}`, v.value);
              }
            }
          });

          return this.$sanitize(c);
        }

        return this.$sanitize(c);
      }

      return this.matchCustomFields(c, variables);
    },

    getPreviewContent(content, variables) {
      let transformed = this.$sanitize(content.trim());

      transformed = this.matchCustomFields(transformed, variables);

      return transformed;
    },

    matchCustomFields(content, variables) {
      let c = content;
      const cf = hasBrackets(content);

      if (cf && cf.length) {
        cf.forEach((v) => {
          const field = removeBrackets(v);
          if (variables.includes(field)) {
            c = c.replace(`{{${field}}}`, this.getFieldsMatched(field));
          }
        });

        return this.$sanitize(c);
      }

      return this.$sanitize(c);
    },

    replaceHeaderField(content = '', customFields, variables) {
      let c = this.$sanitize(content);
      const cf = hasBrackets(c);

      if (customFields && customFields.length) {
        const headerField = customFields.find(v => v.headerType === 'text');

        if (cf && cf.length && headerField) {
          const field = headerField.field.replace(/h_/i, '').trim();

          const pos = removeBrackets(field);

          if (variables.includes(headerField.value)) {
            c = c.replace(`{{${pos}}}`, this.getFieldsMatched(headerField.value));
          } else {
            c = c.replace(`{{${pos}}}`, headerField.value);
          }

          return this.$sanitize(c);
        }

        return this.$sanitize(c);
      }

      return this.$sanitize(c);
    },

    replaceURLSuffix(content, customFields, variables) {
      let c = content;
      const cf = hasBrackets(c);


      if (customFields && customFields.length) {
        const suffixField = customFields.find(v => (v.buttonType || '').toLowerCase() === 'url');

        if (cf && cf.length && suffixField) {
          const field = suffixField.field.replace(/s_/i, '').trim();

          const pos = removeBrackets(field);

          if (variables.includes(suffixField.value)) {
            c = c.replace(`{{${pos}}}`, `your-url-suffix-${suffixField.value}`);
          } else {
            c = c.replace(`{{${pos}}}`, suffixField.value);
          }

          return c;
        }

        return c;
      }

      return c;
    },

    getLocationFields(customFields = []) {
      // get the location fields... latlong, name, address
      if (customFields.length) {
        return customFields.reduce((a, b) => {
          // eslint-disable-next-line no-param-reassign
          a[removeBrackets(b.field)] = this.$sanitize(b.value);
          return a;
        }, {});
      }

      return {
        address: '',
        name: '',
        latlong: '',
      };
    },
  },
};
