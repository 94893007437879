<template>
  <div class="bg-white flex justify-end items-center text-xs leading-none">
    <div class="flex">
      <a class="p-3 inline-block leading-none cursor-pointer text-base"
        :class="{ 'cursor-not-allowed text-grey disabled': loading || filters.page === 1}" @click.prevent="handlePrevPage">
        <i class="icon-novo-arrow-left-long mr-2 text-xl align-middle" /> <span class="capitalize">{{ $t('actions.prev') }}</span>
      </a>
      <el-input :style="{width: '42px'}" v-model="page" readonly  class="inline-block text-xl mx-3"/>
      <a class="p-3 inline-block leading-none cursor-pointer text-base capitalize"
        :class="{ 'cursor-not-allowed text-grey disabled': loading || campaigns.length < paginationLimit }" @click.prevent="handleNextPage">
        <span class="capitalize">{{ $t('actions.next') }}</span> <i class="ml-2 icon-novo-arrow-right-long text-xl align-middle"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationNew',

  props: {
    filters: {
      default: () => ({}),
      type: Object,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    paginationLimit: {
      type: Number,
      default: 10,
    },

    campaigns: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      page: 1,
    };
  },

  watch: {
    'filters.page': {
      handler(v) {
        this.page = v;
      },
    },
  },


  methods: {
    handleNextPage() {
      if (this.loading || this.campaigns.length < this.paginationLimit) {
        return;
      }
      this.$emit('handle-page-change', this.filters.page + 1);
    },

    handlePrevPage() {
      if (this.loading || this.filters.page === 1) {
        return;
      }
      this.$emit('handle-page-change', this.filters.page - 1);
    },
  },
};
</script>
