<template>
  <div class="donut-chart">
    <svg
      class="donut-chart__chart"
      :width="defaultOptions.width"
      :height="defaultOptions.width"
      viewBox="0 0 90 90">
      <circle
        class="donut-chart__circle"
        :stroke-width="defaultOptions.strokeWidth"
        :stroke-linecap="defaultOptions.strokeLineCap"
        r="35"
        cx="45"
        cy="45"
        fill="transparent"
        stroke="whitesmoke"></circle>
      <circle
        v-for="i in data.length"
        :key="i"
        :ref="`bar${data.length - i}`"
        class="donut-chart__circle donut-chart__circle--bar"
        :stroke-width="defaultOptions.strokeWidth"
        :stroke-linecap="defaultOptions.strokeLineCap"
        :visibility="data[data.length - i].value <= 0 ? 'hidden' : 'visible'"
        r="35"
        cx="45"
        cy="45"
        fill="transparent"
        stroke="gray"
        @mouseover="showPopup(data.length - i)"
        @mouseleave="showPopup()">{{data.length - 1}}{{data[data.length - i].label}}</circle>
      <circle
        class="donut-chart__mask"
        :r="Math.abs(35 - (defaultOptions.strokeWidth / 2))"
        cx="45"
        cy="45"
        fill="transparent"></circle>
    </svg>
    <transition name="fade-up" mode="out-in">
      <div
        :key="label"
        :class="[!!defaultOptions.labelTop ? 'flex-col' : 'flex-col-reverse']"
        class="donut-chart__label flex text-center absolute"
      >
        <slot v-if="label.hover" name="hover-icon"></slot>
        <p v-if="label.name" class="my-1 text-xs text-grey-dark">
          {{ label.name }}
        </p>
        <span
          class="text-xl my-1"
          :style="{ color: label.color }"
        >{{ label.value }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'donut-chart',

  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      label: {
        name: '',
        value: '',
        color: '#000',
        hover: false,
      },

      defaultOptions: {
        width: 180,
        strokeWidth: 4,
        strokeLineCap: 'round',
        labelColor: '',
        labelTop: true,
      },
    };
  },

  created() {
    // Overrite defaultOptions with options
    this.defaultOptions = { ...this.defaultOptions, ...this.options };
  },

  computed: {
    totalSum() {
      return this.data.reduce((acc, curr) => acc + curr.value, 0);
    },
  },

  mounted() {
    this.label = {
      ...this.label,
      name: this.title,
      value: this.data.length,
    };

    this.showPopup();
    this.populateGraph();
  },

  methods: {
    showPopup(index = -1) {
      const data = this.data[index] || {};
      const d = this.convertValueToPercent(this.data)[index] || {};
      let v = '';

      if (d.value) {
        v = this.toPercentString(d.value || 0);
      } else {
        v = this.title ? this.formatNumber(this.totalSum) : '';
      }

      this.label = {
        name: data.label || this.title,
        value: v,
        color: this.defaultOptions.labelColor || (data.color || '#000'),
        hover: d.value,
      };
    },

    formatNumber(n) {
      return n.toLocaleString();
    },

    populateGraph() {
      const percentData = this.convertValueToPercent(this.data);
      let offsetDraw = 0; // Used for offsetting
      percentData
        .forEach((d, i) => {
          offsetDraw = this.setBarValues({ ...d, index: i }, offsetDraw);
        });
    },

    convertValueToPercent(data) {
      const total = data.reduce((acc, curr) => acc + curr.value, 0);
      return data.map(d => ({
        ...d,
        value: (d.value / (total || 1)) * 100,
      }));
    },

    toPercentString(n) {
      return `${n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}%`;
    },

    setBarValues(data = {}, prevOffset = 0) {
      const { value, color, index } = data;
      const circle = this.$refs[`bar${index}`][0];
      const rad = circle.getAttribute('r');
      const cir = 2 * rad * Math.PI;
      const draw = (value * cir / 100) + prevOffset;

      circle.style.stroke = color;
      circle.style.strokeDashoffset = 0;
      circle.style.strokeDasharray = `${draw} 999`;

      return draw;
    },
  },
};
</script>

<style lang="scss">
.donut-chart {
  position: relative;
  display: inline-block;

  &__mask {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: traslate(-50%, -50%);
  }

  &__circle {
    stroke-dasharray: 999 999;
    position: relative;

    &--bar {
      stroke-dasharray: 0 999;
      transform-origin: center;
      transition: stroke-dasharray 1s cubic-bezier(0.19, 1, 0.22, 1);
      transform: rotate(-90deg);
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__label {
    padding: 0 40px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .fade-up-enter-active {
    transition: all 0.4s ease;
  }

  .fade-up-leave-active {
    transition: all 0.4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .fade-up-enter,
  .fade-up-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
}
</style>
