<template>
  <div class="flex flex-wrap">
    <div
      v-for="(k, i) in Object.keys(summaryInfo)"
      :key="i"
      :span="10"
      class="border border-grey-lightest rounded mt-4 p-3 mr-4"
      :style="{ 'height': k === 'duplicate' || k === 'invalid' ? '190px' : '130px', width: '47%'}"
    >
      <div class="flex justify-between">
        <span class="block font-semibold text-xl">{{ summaryInfo[k].title }}</span>
        <span class="block font-medium text-2xl">
          <i :class="summaryInfo[k].iconClass" class="font-medium"/>
        </span>
      </div>
      <div class="mt-4">
        <span class="text-2xl font-semibold">
          {{ Number(summaryInfo[k].value).toLocaleString() }}
        </span>
      </div>
      <div class="mt-2">
        <span class="text-grey-dark text-sm">{{ summaryInfo[k].label }}</span>
        <div
          v-if="k === 'invalid'"
          class="mt-4"
        >
          <el-button
            @click="$emit('download-invalid-contacts')"
            :loading="downloading"
            :disabled="Number(summaryInfo[k].value) < 1"
          >
            View list
          </el-button>
        </div>
        <div
          v-if="k === 'duplicate'"
          class="mt-6"
        >
          <el-switch v-model="allowDups" @change="handleChange"/>
          <span class="text-sm ml-2">Include duplicates</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'RecipientsInfo',

  props: {
    summaryInfo: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      allowDups: false,
    };
  },

  created() {
    const { allowDuplicates } = this.summaryInfo.duplicate;
    this.allowDups = allowDuplicates;
  },

  computed: {
  },

  methods: {
    handleChange() {
      this.$emit('allow-all-duplicates', this.allowDups);
    },
  },
};
</script>

<style lang="scss">

</style>
