import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment-timezone';

export default {
  data() {
    const self = this;

    return {
      timeZone: null,
      timeZones: [],
      dateHeader: self.$t('column_labels.date_sent_received'),
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),

    ...mapState('common', [
      'timezones',
    ]),

    currentTzOffset() {
      let gmtOffset = 0;

      if (this.timeZones
        && Array.isArray(this.timeZones)
        && this.timeZones.length) {
        const selectedTz = this.timeZones.find(t => t.TimeZoneName === this.timeZone);
        if (selectedTz) gmtOffset = (selectedTz.GMTOffset / 60); // utcOffset should be in minutes
      }

      return gmtOffset;
    },

    currentTz() {
      let tz = '(UTC-00:00):';

      if (this.timeZones
        && Array.isArray(this.timeZones)
        && this.timeZones.length) {
        const selectedTz = this.timeZones.find(t => t.TimeZoneName === this.timeZone);
        if (selectedTz) {
          [tz] = selectedTz.Combined.split(' ');
        }
      }

      return `${tz.slice(0, -1)}`; // remove end :
    },
  },

  created() {
    this.setTz();
    this.getUser();
  },

  watch: {
    timeZone() {
      this.dateHeader = `${this.$t('column_labels.date_sent_received')} ${this.currentTz || '(GMT)'}`;
    },
  },

  methods: {
    ...mapActions({
      getTimezones: 'common/getTimeZones',
      setTimezones: 'common/setTimeZones',
      getUser: 'user/getUser',
    }),

    async getTimezoneLists() {
      try {
        await this.getTimezones();
        localStorage.setItem('timezones', JSON.stringify(this.timezones));
        this.timeZones = this.timezones;
        this.timeZone = this.user.TimeZoneName || this.$jstz_mc.determine().name();
      } catch (e) {
        this.$showError(this, e);
      }
    },

    setTz() {
      try {
        const timezones = JSON.parse(localStorage.getItem('timezones'));

        if (Array.isArray(timezones) && timezones.length > 0) {
          this.setTimezones(timezones);
          this.timeZones = timezones;
        } else {
          this.getTimezoneLists();
        }

        this.timeZone = this.user.TimeZoneName || this.$jstz_mc.determine().name(); // eslint-disable-line
      } catch (e) {
        this.getTimezoneLists();
        this.timeZone = this.user.TimeZoneName || this.$jstz_mc.determine().name();
      }
    },

    getLocalDateTime(time) {
      return moment(time).utcOffset(this.currentTzOffset, false).format('MMM DD YYYY hh:mm A');
    },

    getLocalTime(date, offset, tz) {
      const d = moment(date).utcOffset(offset, false).format('MMM DD, YYYY hh:mm A');

      return `${d} ${tz}`;
    },
  },
};
