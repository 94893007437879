import http from '@/utils/http';

export default {
  async sendSmsMessageCampaignApi({ commit }, payload) {
    try {
      const { data } = await http.campaignApi.post(
        'campaigns/sms',
        payload,
      );
      commit('SET_CAMPAIGN_SENT_DATA', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async sendSmsCampaignApproval({ commit }, payload) {
    const body = payload;

    if (body.meta.source === 'UPLOAD') {
      const { SocketID: id, QueueURL: url } = body.meta;

      body.socketId = id;
      body.queueUrl = url;
    }

    const slug = '/websender/sms/send-for-approval';

    try {
      await http.v1.post(
        `${slug}`,
        body,
      );

      const reply = {
        smsCampaignApproval: true,
        scheduled: body.message.schedule !== 'Send message now',
      };

      commit('SET_CAMPAIGN_SENT_DATA', reply);

      return reply;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async sendChatAppsMessage({ commit }, payload) {
    const slug = '/mcs/chatapps/send';

    try {
      const { data } = await http.v2.post(
        `${slug}`,
        payload,
      );
      commit('SET_CAMPAIGN_SENT_DATA', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async sendVoiceMessage({ commit }, payload) {
    const slug = '/mcs/voice/send';

    try {
      const { data } = await http.v2.post(
        `${slug}`,
        payload,
      );
      commit('SET_CAMPAIGN_SENT_DATA', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async sendVoiceTestMessage({ commit }, payload) {
    const slug = '/mcs/voice/send-test';

    try {
      await http.v2.post(
        `${slug}`,
        payload,
      );

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  setPartialData({ commit }, payload) {
    commit('SET_PARTIAL_DATA', payload);
  },

  setUploadData({ commit }, payload) {
    commit('SET_UPLOAD_DATA', payload);
  },

  setUploadDataCc({ commit }, payload) {
    commit('SET_UPLOAD_DATA_CC', payload);
  },

  setProcessing({ commit }, payload) {
    commit('SET_PROCESSING', payload);
  },

  setActiveStep({ commit }, payload) {
    commit('SET_ACTIVE_STEP', payload);
  },

  async validateDestination({ commit }, payload) {
    const slug = '/websender/msisdn/validate';

    try {
      const { data } = await http.v1.post(
        `${slug}`,
        payload,
      );

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async getSmsEngageFormQRCode({ commit }, payload) {
    const { sid, subAccountUid } = payload;
    const slug = `/mcs/sms-engage/qrcode?sid=${sid}&subaccountUid=${subAccountUid}`;

    try {
      const { data } = await http.v2.get(`${slug}`);

      commit('SET_SE_QR_CODE', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async getSmsEngageForms({ commit }, payload) {
    const { subAccountUid } = payload;
    const slug = `/mcs/sms-engage/forms?subaccountUid=${subAccountUid}`;

    try {
      const { data } = await http.v2.get(`${slug}`);

      commit('SET_SE_FORMS', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

};
