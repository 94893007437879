import { transformObjectKeys, isJSON } from '@/utils/common';
import http from '@/utils/http';
import Moment from 'moment';

export default {
  // Fetch logs data
  getCampaigns: async ({ commit, state, rootState }) => {
    commit({ type: 'SET_LOADING', bool: true });
    const { daterange, ...f } = state.filters;
    const dates = daterange.map(dt => Moment(dt).format('YYYY-MM-DD'));

    try {
      let campaigns = [];

      if (rootState.user.hasMcsAccess) {
        // Use campaign api
        const params = {
          dateFrom: Moment(dates[0]).startOf('day').utcOffset(+0, true).utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          dateTo: Moment(dates[1]).endOf('day').utcOffset(+0, true).utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          campaignStatus: f.status,
          channelType: f.channel,
          campaignName: f.campaignName || '',
          limit: 10,
          offset: f.page - 1,
        };

        const query = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
        const { data } = await http.campaignApi.get(`/campaigns?${query}`);

        campaigns = data.items || [];

        if (campaigns.length) {
          campaigns.forEach((c) => {
            let obj = {};
            if (c.campaignMeta && isJSON(c.campaignMeta)) {
              // eslint-disable-next-line no-param-reassign
              obj = JSON.parse(c.campaignMeta) || {};
              transformObjectKeys(obj, 'camelcase');
              // eslint-disable-next-line no-param-reassign
              c.campaignMeta = JSON.stringify(obj);
            }
          });
        }

        transformObjectKeys(campaigns);
      } else {
        const p = { daterange: dates, ...f };
        const query = Object.keys(p).map(key => `${key}=${p[key]}`).join('&');
        const { data } = await http.v2.get(`campaigns?${query}`);
        campaigns = data;
      }
      commit({ type: 'SET_CAMPAIGNS', campaigns });
      commit({ type: 'SET_LOADING', bool: false });
      return campaigns;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      commit({ type: 'SET_LOADING', bool: false });
      return Promise.reject(err);
    } finally {
      // commit({ type: 'SET_LOADING', bool: false });
    }
  },

  getStatuses: async ({ commit }) => {
    commit({ type: 'SET_LOADING', bool: true });
    try {
      const { data } = await http.v2.get('enums/campaign/statuses');
      commit({ type: 'SET_STATUSES', statuses: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', bool: false });
    }
  },

  // Download campaign
  downloadSMSCampaign: async ({ commit }, payload) => {
    try {
      await http.v1.post('/campaigns/action/download', payload);
      return true;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  downloadChatappsCampaign: async ({ commit }, payload) => {
    try {
      const searchParams = new URLSearchParams();
      const { additionalEmail } = payload;
      if (additionalEmail) { searchParams.set('additionalEmail', additionalEmail); }
      await http.v2.get(`campaigns/${payload.campaign_id}/download?${searchParams.toString()}`);
      return true;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Cancel campaign
  cancelCampaign: async ({ commit, rootState, dispatch }, payload) => {
    commit({ type: 'SET_LOADING', bool: true });
    const { CampaignId } = payload;
    try {
      if (rootState.user.hasMcsAccess) {
        await http.campaignApi.post(`/campaigns/${CampaignId}/cancel`);
      } else {
        await http.v1.put(`/campaigns/action/cancel?campaign_id=${CampaignId}`);
      }

      dispatch('getCampaigns');
      return true;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', bool: false });
    }
  },

  // Reject campaign
  rejectCampaign: async ({ commit, rootState, dispatch }, payload) => {
    commit({ type: 'SET_LOADING', bool: true });
    try {
      if (rootState.user.hasMcsAccess) {
        await
        http.campaignApi.put(
          `/campaigns/${payload.CampaignId}/reject`,
          {
            campaignId: payload.CampaignId,
            campaignName: payload.CampaignName,
            reason: payload.Reason,
            createdByUserName: payload.CreatedBy_Username,
            createdByUserId: payload.CreatedBy,
          },
        );
      } else {
        await
        http.v1.put(
          '/campaigns/action/reject',
          {
            campaignId: payload.CampaignId,
            campaignName: payload.CampaignName,
            reason: payload.Reason,
            createdByUserName: payload.CreatedBy_Username,
            createdByUserId: payload.CreatedBy,
          },
        );
      }

      dispatch('getCampaigns');
      return true;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', bool: false });
    }
  },

  // Approve campaign
  approveCampaign: async ({ commit, rootState, dispatch }, payload) => {
    commit({ type: 'SET_LOADING', bool: true });
    try {
      if (rootState.user.hasMcsAccess) {
        await
        http.campaignApi.put(
          `/campaigns/${payload.CampaignId}/approve`,
          {
            campaignId: payload.CampaignId,
            createdBy: payload.CreatedBy,
            queueUrl: payload.QueueUrl,
            socketId: payload.SocketId,
          },
        );
      } else {
        await
        http.v1.put(
          '/campaigns/action/approve',
          {
            campaignId: payload.CampaignId,
            createdBy: payload.CreatedBy,
            queueUrl: payload.QueueUrl,
            socketId: payload.SocketId,
          },
        );
      }

      dispatch('getCampaigns');

      return true;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', bool: false });
    }
  },

  // Fetch campaign fallback detail
  getFallbackDetail: async ({ commit, rootState }, p) => {
    const { campaignId, ...payload } = p;
    const query = Object.keys(payload).map(key => `${key}=${payload[key]}`).join('&');
    try {
      if (rootState.user.hasMcsAccess) {
        const { data } = await http.campaignApi.get(`campaigns/${campaignId}`);
        if (data.channels) {
          transformObjectKeys(data.channels);
        }

        commit({ type: 'SET_FALLBACK_DETAIL', data });
        return data;
      }
      const { data } = await http.v2.get(`campaigns/${campaignId}?${query}`);
      commit({ type: 'SET_FALLBACK_DETAIL', data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
