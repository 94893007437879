<template>
  <div>
    <h3 class="text-black font-medium mb-3">
      Confirm and send
    </h3>
    <!-- <p class="leading-tight text-grey-darker mb-3 text-sm">
      Name your campaign, select when you do want to process the campaign and you're done.
    </p> -->
    <el-form ref="form" :model="form" :rules="rules" @submit.prevent.native>
      <p class="text-sm mb-2">
        {{ $t('mcs.send.form[1]') }} <span class="required" />
      </p>
      <el-form-item prop="campaignName">
        <el-input v-model="form.campaignName" class="text-xs w-3/5"
          :placeholder="$t('mcs.send.form[5]')" />
      </el-form-item>
      <div class="flex mb-2 w-3/5">
        <p class="flex-1 text-sm">
          {{formType === 'VOICE' ? 'Custom Campaign ID' : $t('fields.client_msg_id') }}
        </p>
        <p class="flex-1 text-right text-sm text-grey">
          {{ $t('validations.optional') }}
        </p>
      </div>
      <el-form-item v-if="formType === 'VOICE'" prop="clientBatchId">
        <el-input v-model="form.clientBatchId" class="text-xs w-3/5"
          placeholder="Assign a custom campaign ID (e.g Summer2024Promo)" :disabled="!form.campaignName" />
      </el-form-item>
      <el-form-item v-else prop="clientMsgId">
        <el-input v-model="form.clientMsgId" class="text-xs w-3/5"
          :placeholder="$t('mcs.send.form[6]')" :disabled="!form.campaignName" />
      </el-form-item>
      <div v-if="hasSchedule">
        <hr class="mx-0 mt-5 mb-10 h-px bg-grey w-48">
        <p class="leading-tight text-sm">
          <!-- {{ $t('mcs.send.form[2]') }} -->
            Message delivery schedule
        </p>
        <el-form-item>
          <el-radio v-model="form.sendNow" :label="Boolean(true)" size="small">
            {{ $t('mcs.send_now') }}
          </el-radio>
          <el-radio v-model="form.sendNow" size="small" :label="Boolean(false)">
            {{ $t('mcs.sched_later') }}
          </el-radio>
        </el-form-item>
      </div>
      <div v-if="!form.sendNow && hasSchedule">
        <div class="flex">
          <div class="flex-shrink mr-10">
            <p class="text-sm mb-2 label__asterisk">
              {{ $t('mcs.send.form[3]') }}
            </p>
            <el-form-item class="sched-timer">
              <el-date-picker v-model="form.scheduleDate" :disabled="disableField" type="datetime" clearable
                size="small" popper-class="sched-mcs-scheduler" :placeholder="$t('mcs.send.form[3]')"
                format="dd MMM yyyy HH:mm" :picker-options="pickerOptions" />
            </el-form-item>
          </div>
          <div class="flex-auto">
            <p class="text-sm mb-2 label__asterisk">
              {{ $t('fields.timezone') }}
            </p>
            <el-form-item>
              <el-select v-model="form.scheduleTimezone" :disabled="disableField" filterable style="width: 60%"
                size="small" :placeholder="$t('fields.timezone')">
                <el-option v-for="tz in timezones" :key="tz.TimeZoneId" :label="tz.Combined" :value="tz.TimeZoneId" />
              </el-select>
            </el-form-item>
          </div>
        </div>
        <p v-if="!isValidSched" class="text-red text-xs">{{ $t('apiErrors.minimal_10_mins', { value: '10 mins'} ) }}.</p>
        <p class="text-grey-dark text-xs mt-3">
          {{ $t('mcs.send.form[4]') }}
        </p>
      </div>
    </el-form>
  </div>
</template>
<script>
import moment from 'moment';


export default {
  name: 'send_campaign_form',

  props: {
    user: {
      type: Object,
      default: () => ({}),
    },

    data: {
      type: Object,
      default: () => ({}),
    },

    mcsForm: {
      type: Object,
      default: () => ({}),
    },

    timezones: {
      type: Array,
      default: () => [],
    },

    hasSchedule: {
      type: Boolean,
      default: false,
    },
    isValidSched: {
      type: Boolean,
      default: false,
    },

    formType: {
      type: String,
      default: '',
    },
  },

  watch: {
    form: {
      deep: true,
      handler(n) {
        if (n.sendNow) {
          this.form.scheduleDate = '';
          this.scheduleTimezone = '';
        } else {
          if (!n.scheduleDate) {
            this.form.scheduleDate = moment().format('llll');
          }

          if (!n.scheduleTimezone) {
            this.setTimeZone();
          }
        }

        const obj = n;

        if (this.$refs.form) {
          const self = this;

          this.$refs.form.validateField('campaignName', (err) => {
            if (!err) {
              this.$emit('update-campaign-details', obj);
            } else {
              obj.campaignName = '';
              self.$emit('update-campaign-details', obj);
            }
          });

          if (n.clientMsgId) {
            this.$refs.form.validateField('clientMsgId', (err) => {
              if (!err) {
                this.$emit('update-campaign-details', obj);
              } else {
                obj.clientMsgId = '';
                self.$emit('update-campaign-details', obj);
              }
            });
          }

          if (n.clientBatchId) {
            this.$refs.form.validateField('clientBatchId', (err) => {
              if (!err) {
                this.$emit('update-campaign-details', obj);
              } else {
                obj.clientBatchId = '';
                self.$emit('update-campaign-details', obj);
              }
            });
          }

          if (!n.sendNow) {
            this.$refs.form.validateField('scheduleDate', (err) => {
              if (!err) {
                this.$emit('update-campaign-details', obj);
              } else {
                obj.scheduleDate = '';
                self.$emit('update-campaign-details', obj);
              }
            });
          }
        }
      },
    },
  },

  data() {
    const self = this;

    return {
      form: {
        campaignName: '',
        clientMsgId: '',
        batchMsgId: '',
        sendNow: true,
        scheduleDate: '',
        scheduleTimezone: undefined,
      },

      rules: {
        campaignName: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.campaign_name') }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],

        scheduleDate: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.schedule_date') }),
            trigger: 'change',
          },
        ],

        clientMsgId: [
          {
            max: 50,
            message: self.$t('validations.char_limit', { value: '50' }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],

        clientBatchId: [
          {
            max: 50,
            message: self.$t('validations.char_limit', { value: '50' }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },

      pickerOptions: {
        disabledDate(picker) {
          const today = new Date();
          let future = moment().add(30, 'd');

          // 7 days only for voice playfile since uploads last only 7 days
          if (self.formType === 'VOICE' && self.mcsForm.Message && self.mcsForm.Message.uploads) {
            future = moment().add(7, 'd');
          }

          return picker.getTime() < today.getTime() - 86400000 || picker.getTime() > future;
        },
        minTime: '10:00',
      },
    };
  },

  created() {
    this.form.campaignName = `${moment().format('YYYYMMDD-hh:mmA')}-CAMPAIGN`;

    // if (Object.keys(this.data).length) {
    //   this.form.scheduleTimezone = this.data.scheduleTimezone || '';
    //   this.form.clientMsgId = this.data.clientMsgId || '';
    //   this.form.scheduleDate = this.data.scheduleDate || '';
    // }
  },

  methods: {
    setTimeZone() {
      const tzs = this.timezones;

      const userTz = this.user.TimeZoneName || this.$jstz_mc.determine().name();

      const [tzByName] = tzs.filter(tz => tz.TimeZoneName === userTz);

      let tzn = tzByName;

      // if timezone is not in the list, try SG timezone as default
      if (!tzn) {
        const [sgTz] = tzs.filter(tz => tz.TimeZoneName === 'Asia/Singapore');
        tzn = sgTz;
      }

      this.form.scheduleTimezone = tzn.TimeZoneId;
    },
  },
};
</script>
<style lang="scss">
  .sched-mcs-scheduler .el-picker-panel__footer .el-button--text {
    display: none !important;
  }
</style>
