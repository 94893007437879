import Vue from 'vue';
import Vuex from 'vuex';

import socket from './modules/socket';
import websender from './modules/websender';
import recipients from './modules/recipients';
import recipientsContacts from './modules/recipients_contacts';
import user from './modules/user';
import templates from './modules/templates';
import channels from './modules/channels';
import pricing from './modules/pricing';
import common from './modules/common';
import campaigns from './modules/campaigns';
import alerts from './modules/alerts';
import contacts from './modules/contacts';
import sender from './modules/sender';
import voice from './modules/voice';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    errors: [],
    form: {},
    step: '', // CHANNEL, RECIPIENTS, MESSAGE, SEND
  },

  getters: {
    getStep: st => st.step,
    getForm: st => st.form,
    getContactGroupsData:
      st => (st.form && st.form.Recipients && st.form.Recipients.groups
        ? st.form.Recipients.groups
        : []),
    getContactData:
      st => (st.form && st.form.Recipients && st.form.Recipients.contacts
        ? st.form.Recipients.contacts
        : []),
  },

  mutations: {
    // eslint-disable-next-line no-param-reassign
    SET_STEP: (state, name) => { state.step = name; },

    // eslint-disable-next-line no-param-reassign
    RESET_FORM: (state) => { state.form = {}; },

    // eslint-disable-next-line no-param-reassign
    RESET_RECIPIENTS_FORM: (state) => { state.form.Recipients = {}; },

    // eslint-disable-next-line no-param-reassign
    UPDATE_FORM: (state, payload) => { state.form = { ...state.form, ...payload }; },

    API_ERROR(state, payload) {
      // eslint-disable-next-line no-unused-vars
      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(payload);
      // }
      state.errors.push(payload); // eslint-disable-line no-param-reassign
    },
  },

  actions: {},

  modules: {
    socket,
    websender,
    recipients,
    recipientsContacts,
    user,
    templates,
    channels,
    common,
    pricing,
    campaigns,
    alerts,
    contacts,
    sender,
    voice,
  },

  strict: debug,
});

export default store;
