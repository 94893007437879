<template>
  <div class="mb-3">
    <div class="bg-grey-lightest border border-solid border-grey-light rounded-lg">
      <div class="px-5 pt-5">
        <div class="mb-3">
          <p class="text-grey-dark text-sm mb-2">{{ $t('mcs.messaging_channel') }}</p>
          <p class="text-black font-medium">{{  getAppName(messagingType) }}</p>
        </div>
        <div class="mb-3">
          <p class="text-grey-dark text-sm mb-2">{{ $t('fields.subaccount') }}</p>
          <p class="text-black font-medium">{{ subAccount }}</p>
        </div>
      </div>
      <div v-if="channels.length"
        class="bg-white px-5 py-3 rounded-b-lg haschannels overflow-x-auto">
        <ChannelsFallback :channels="channels" :with-label="false"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChannelsFallback from '@/components/ChannelsFallback.vue';


export default {
  name: 'send_details',

  components: {
    ChannelsFallback,
  },

  props: {
    messagingType: {
      type: String,
      default: '',
    },

    subAccount: {
      type: String,
      default: '',
    },

    channels: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getAppName(type = '') {
      let name = 'N/A';

      switch (type) {
        case 'SMS': {
          name = 'SMS';
          break;
        }

        case 'CHAT_APPS': {
          name = 'Chat App';
          break;
        }

        case 'VOICE': {
          name = 'Voice';
          break;
        }

        case 'SMS_ENGAGE': {
          name = 'SMS Engage';
          break;
        }

        default:
      }

      return name;
    },
  },
};
</script>
