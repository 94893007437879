<template>
  <div class="flex flex-col">
    <div class="custom-fields w-full p-6 custom-fields-contacts">
      <div
        v-if="form.Recipients.FileName"
        class="bg-grey-lighter p-3 rounded flex justify-between
        text-xs w-full items-center"
      >
        <div>
          <p class="text-grey-dark">{{ $t('mcs.recip.summary[0]') }}</p>
          <p class="text-black mt-2">
            {{ form.Recipients.FileName }}
            <strong v-if="form.Recipients.FileSize">
              ({{ form.Recipients.FileSize }})
            </strong>
          </p>
        </div>
        <div>
          <button
            class="bg-red-light p-2 px-3 rounded text-white mr-2 flex items-center"
            @click.prevent="discardUpload"
          >
            <i class="icon-novo-trash cursor-pointer mr-2 text-xl" /> Discard
          </button>
        </div>
      </div>

      <div>
        <div class="my-5">
          <h3 class="mb-2 text-sm text-black tracking-normal">{{ $t('mcs.recip.set_fields[0]') }}</h3>
          <p class="text-xs text-grey-dark leading-tight">
            {{  $t('mcs.recip.set_fields[1]') }}
          </p>
        </div>
        <div>
          <CustomFieldsTable
            class="mb-10 border border-grey-light rounded"
            :loading="processLoading"
            :headers="getColumnIndex"
            :header-values="colValues"
            :custom-field-options="customFieldsOptions"
            :options="originalCustomFieldsOptions"
            :data="tableData"
            :custom-field-input="newCustomField"
            @update-column="updateColumnValue"
            @remove-column="removeCustomField"
            @add-custom-field-name="addCustomField"
          />
          <div class="mt-5 flex -mx-2">
            <div
              v-if="form.Type !== 'VOICE'"
              class="px-1 w-1/2">
              <div class="text-xs">
                <h3 class="text-sm text-black tracking-normal">
                  {{  $t('mcs.recip.set_fields[14]') }}
                </h3>
                <p class="text-xs text-grey-dark leading-tight mt-2 pr-2">
                  {{  $t('mcs.recip.set_fields[15]') }}
                  <span class="text-black font-bold">
                    {{  $t('mcs.recip.set_fields[16]') }}
                  </span>
                  {{  $t('mcs.recip.set_fields[17]') }}
                </p>
              </div>
            </div>
            <div
              :key="showNumberFormatOptions"
              class="px-1 w-1/2"
              >
              <div class="text-xs">
                <p>{{ $t('mcs.recip.set_fields[18]') }}
                  <a
                    class="text-blue hover:text-blue-darker text-underline"
                    href
                    @click.prevent="openSettings()"
                  >{{ $t('mcs.recip.set_fields[19]') }}</a>:
                </p>
                <div class="relative country-selection">
                  <el-select
                    v-model="country"
                    :disabled="checkDisableCountryCode() || processLoading"
                    size="small"
                    class="w-full py-3"
                    :placeholder="$t('mcs.recip.set_fields[5]')"
                    filterable
                    @change="v => updateRecipients({ Location: v })"
                    >
                    <template slot="prefix">
                      <div v-if="country" class="flex items-center h-full">
                        <span
                          class="border-grey-light flag-icon"
                          :class="[`flag-icon-${country.toLowerCase()}`]"/>
                      </div>
                    </template>
                    <el-option
                      v-for="item in countriesJson"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                      <span>
                        <span
                          class="mr-2 border-grey-light flag-icon"
                          :class="[`flag-icon-${item.value.toLowerCase()}`]"/>
                          {{ item.name }}
                        </span>
                        <span class="text-grey text-xs">
                          ({{ item.callingCode }})
                        </span>
                    </el-option>
                  </el-select>
                  <span v-if="country" class="absolute py-3 r-5 h-full cursor-pointer clear-btn">
                    <span class="flex items-center">
                      <i @click="clearCountryCode" class="el-icon-circle-close text-base" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="flex-shrink p-6 border-t border-grey-lighter text-right">
      <el-button
        type="text"
        class="mr-3"
        @click="back"
      >
        Back
      </el-button>
      <el-button
        @click="$emit('discard')"
      >
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        :loading="processLoading"
        type="primary"
        :disabled="!isValid()"
        @click="submit()"
        id="mcs-set-custom-fields"
      >
        {{ processLoading ? $t('wait.processing') :  $t('mcs.recip.set_fields[10]') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ContactsSummaryMixin from '@/mixins/contacts-summary';
import countriesJson from '@/json/countries.json';
import CustomFieldsTable from './CustomFieldsTable.vue';

export default {
  name: 'set-custom-fields',

  components: {
    CustomFieldsTable,
  },

  mixins: [ContactsSummaryMixin],

  watch: {
    uploadDataSummary(newVal) {
      this.setSummary({ summary: newVal });
      this.$emit('submit-uploads', newVal);
    },
  },

  data() {
    const originalCustomFieldsOptions = ['MSISDN', 'FirstName', 'LastName', 'ClientMessageId'];

    return {
      countriesJson,
      newCustomField: '',
      colValues: [],
      customFields: {},
      originalCustomFieldsOptions,
      customFieldsOptions: originalCustomFieldsOptions,
      country: '',
      allowDuplicates: false,
      allNumbersLocal: false,
      tableData: [],
    };
  },

  props: {
    form: {
      type: Object,
      default: () => {},
    },

    partialData: {
      type: Object,
      default: () => {},
    },

    processUpload: {
      type: Function,
      default: () => {},
    },

    socketId: {
      type: String,
      default: '',
    },
    queueUrl: {
      type: String,
      default: '',
    },
    uploadContacts: {
      type: Function,
      default: () => {},
    },
    getContactsSummaryStatus: {
      type: Function,
      default: () => {},
    },
  },

  created() {
    // If user edits labels, reset country code flag and the contacts summary
    this.setApplyCountryCodeToAll(false);
    this.setUploadDataCc(null);

    let partialDataList = [];
    // Check if paritalData.List is null
    if (this.partialData && this.partialData.List) {
      partialDataList = [...this.partialData.List];
    }
    const data = partialDataList;

    this.tableData = data;

    this.colValues = this.tableData.length ? new Array(Object.keys(this.tableData[0]).length) : [];

    // Set default values
    this.country = this.uploadDataSummary ? this.form.Recipients.Location : this.userCountry;
    this.allowDuplicates = this.form.Recipients.AllowDuplicates || this.allowDuplicates;
    this.allNumbersLocal = this.form.Recipients.AllNumbersLocal || this.allNumbersLocal;

    if (this.form.Recipients.columnLabels) {
      const colLabels = this.form.Recipients.columnLabels;
      this.customFieldsOptions = Object.keys(colLabels).map(label => ({ label, id: label, isEdit: false }));
      Object.keys(colLabels).forEach((label) => {
        const index = parseInt(colLabels[label], 10);
        if (index >= 0) {
          this.colValues[index] = label;
        }
      });

      this.customFields = this.form.Recipients.columnLabels;
    } else {
      // Set all column to -1
      this.customFields = this.customFieldsOptions.reduce((obj, item) => ({
        ...obj,
        [item]: -1,
      }), {});
    }


    // Update form with default values
    this.$emit('update-form', {
      Recipients: {
        ...this.form.Recipients,
        Location: this.country,
        AllowDuplicates: this.allowDuplicates,
        AllNumbersLocal: this.allNumbersLocal,
        columnLabels: { ...this.customFields },
      },
    });
  },

  computed: {
    ...mapGetters({
      uploadDataSummary: 'websender/getUploadData',
      processLoading: 'websender/getProcessesing',
      hasMcsAccess: 'user/getMcsAccess',
      user: 'user/getUser',
      userCountry: 'websender/getUserCountry',
    }),

    showNumberFormatOptions() {
      const phoneIndex = this.form.Recipients.columnLabels
        && Object.keys(this.form.Recipients.columnLabels).length ? this.form.Recipients.columnLabels.MSISDN : -1;
      let isNum = true;
      const invalidNums = [];
      if (phoneIndex >= 0) {
        this.tableData.forEach((data) => {
          let num = data[phoneIndex];
          num = !num ? '' : num;
          const n = num.toString().replace(/^(\+){1}/g, '').replace(/ /g, '');
          const checkNum = n[0] === '0' ? n : `+${n}`;
          const isValidLength = /[0-9]{7,20}/.test(n);
          isNum = n.match(/^[0-9]+$/) != null;

          try {
            const pn = parsePhoneNumber(checkNum);

            // If INVALID number, check if its valid in length
            // IF VALID, do nothing
            if (!pn.valid) {
              // check if its a number
              // If not a number, do nothing
              if (isNum && isValidLength) {
                invalidNums.push(n);
              }
            }
          } catch (e) {
            // If !isNum or !isValidLegth, do nothing
            if (e && isNum && isValidLength) {
              invalidNums.push(n);
            }
          }
        });
      }
      return invalidNums.length;
    },

    getColumnIndex() {
      return this.tableData.length ? Object.keys(this.tableData[0]) : [];
    },
  },

  methods: {
    ...mapMutations({
      setSummary: 'recipientsContacts/SET_SUMMARY',
      setProcessing: 'websender/SET_PROCESSING',
      setApplyCountryCodeToAll: 'websender/SET_APPLY_CC_TO_ALL',
      setUploadData: 'websender/SET_UPLOAD_DATA',
      setUploadDataCc: 'websender/SET_UPLOAD_DATA_CC',
    }),

    back() {
      this.$emit('change-step', 'ADD_RECIPIENT');
    },

    isValid() {
      return this.colValues.includes('MSISDN');
    },

    async submit() {
      if (!this.isValid()) {
        // this.$message.error(this.$t('mcs.recip.set_fields[11]'));
        this.$notify.error({
          title: this.$t('mcs.recip.set_fields[11]'),
        });
        return;
      }

      let payload = Object
        .assign({}, this.partialData, {
          Column: this.form.Recipients.columnLabels,
          AllowDuplicates: this.form.Recipients.AllowDuplicates || false,
          Country: this.form.Recipients.Location || '',
          AllNumbersLocal: this.form.Recipients.AllNumbersLocal || false,
        });

      payload = {
        ...payload,
        File: this.form.Recipients.File,
        Source: 'UPLOAD',
      };

      this.setProcessing(true);

      try {
        // Upload file using contacts api
        const uploadData = await this.uploadContacts(payload);

        if (uploadData) {
          // Long polling to get the contacts summary
          await this.getContactsSummary({ id: uploadData.id, applyCountryCode: false });
        }
      } catch (err) {
        this.$showError(this, err);
        this.setProcessing(false);
      }
    },

    checkDisableCountryCode() {
      return 0;
      // return !this.hasLocalNumber || (typeof this.uploadData === 'object' && typeof this.file ===
      //   'object') || this.processing;
    },

    getColumnValue(index) {
      const colVal = Object.keys(this.customFields).find(cf => cf === index);
      return colVal || -1;
    },

    updateColumnValue({ val = 0, i = 0 }) {
      this.customFields[val] = i;

      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          columnLabels: { ...this.customFields },
        },
      });
    },


    addCustomField(val) {
      const s = val.replace(/\s+/g, '');
      this.newCustomField = '';
      if (!this.customFieldsOptions.includes(s) && !s.match(/mobile/i)) {
        this.customFieldsOptions = [...this.customFieldsOptions, { id: s, label: s, isEdit: false }];
      } else {
        // this.$message.error(this.$t('mcs.recip.set_fields[12]'));
        this.$notify.error({
          title: this.$t('mcs.recip.set_fields[12]'),
        });
      }
    },

    removeCustomField({ val = 0, i = 0 }) {
      this.colValues.splice(i, 1, null);
      this.customFields = { ...this.customFields, [val]: -1 };

      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          columnLabels: { ...this.customFields },
        },
      });
    },

    discardUpload() {
      this.$confirm(this.$t('mcs.recip.summary[18]'), this.$t('actions.edit'), {
        confirmButtonText: this.$t('actions.discard'),
        cancelButtonText: this.$t('actions.cancel'),
        type: 'warning',
      }).then(() => {
        this.$emit('reset-upload');
        this.$emit('reset-contacts');
        this.$emit('change-step', 'ADD_RECIPIENT');
      });
    },

    updateRecipients(obj) {
      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          ...obj,
        },
      });
    },

    // Open account settings
    openSettings() {
      const event = new Event('preference');
      window.dispatchEvent(event);
    },

    clearCountryCode() {
      this.country = null;
      this.updateRecipients({ Location: null });
    }
  },
};
</script>

<style lang="scss">
.custom-fields {
  .el-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
  }

  .el-select {
    input {
      line-height: 0 !important;
    }
  }

  .fade-up-enter-active {
    transition: all 0.2s ease;
  }

  .fade-up-leave-active {
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
}

.custom-field-table {
  &.el-table--scrollable-x {
    .el-table__body-wrapper {
      overflow-x: hidden !important;
    }
  }
}

.custom-fields-contacts {
  height: calc(100vh - 215px);
  overflow-y: auto;

  .el-tag--success {
    background: #189b55;
  }
}

.country-selection {
  .el-select .el-input .el-select__caret {
    color: black !important;
    font-size: 16px !important;
  }

  .clear-btn {
    right: 28px;
    top: 8px;
  }

  .country-selection:has(.clear-btn:hover) .el-input__inner {
    border: 1px solid black !important;
  }
}
</style>
