import http from '@/utils/http';

export default {
  async getVoiceProfiles({ commit }, payload) {
    try {
      const { data } = await http.voiceApi.get(`/subaccounts/${payload.subAccountId}/speech-profiles`);

      const { speechProfiles } = data;

      commit('SET_VOICE_PROFILES', speechProfiles);

      return speechProfiles;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return Promise.reject(err);
    }
  },

  async getVirtualNumbers({ commit }, payload) {
    try {
      const { data } = await http.voiceApi.get(`/subaccounts/${payload.subAccountId}/numbers`);

      const { numbers } = data;

      commit('SET_VIRTUAL_NUMBERS', numbers);

      return numbers;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return Promise.reject(err);
    }
  },

  async sendVoiceTestMessage({ commit }, payload) {
    try {
      const { data } = await http.campaignApi.post('/campaigns/voice/test', { ...payload });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return Promise.reject(err);
    }
  },

  async sendVoiceMessage({ commit }, payload) {
    try {
      const { data } = await http.campaignApi.post('/campaigns/voice', { ...payload });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return Promise.reject(err);
    }
  },
};
