<template>
  <div class="flex flex-col">
    <el-row v-loading="loadingPricing" class="w-full p-6 compose-message-container" v-if="step === 'MESSAGE'">
      <el-col :span="14" class="msg">
        <div v-if="form.Type.match(/CHAT_APPS|SMS/i)">
          <p class="text-sm mb-5 leading-tight">
            {{ $t("mcs.messages.index[0]") }}
          </p>
          <div class="mb-5">
            <el-radio
              v-model="selected.action"
              label="template"
              class="mr-5"
              size="mini"
              @change="selectAction"
              ><span class="text-sm">
                {{ $t("mcs.messages.index[15]") }}
              </span>
            </el-radio>
            <el-radio
              v-model="selected.action"
              label="message"
              size="mini"
              @change="selectAction"
              ><span class="text-sm">
                {{ $t("mcs.messages.index[2]") }}
              </span>
            </el-radio>
          </div>
        </div>

        <div v-if="form.Type.match(/VOICE/i)">
          <div class="flex flex-col mb-6">
            <h3 class="align-middle">Compose a voice message</h3>
            <div class="mt-6">
              <el-radio-group
                v-model="selected.action"
                size="medium"
                class="button-radio"
                text-color="white"
                fill="#2a2A2A"
                @click.native="(ev) => {
                  selectVoiceTab(ev);
                }"
              >
                <el-radio-button
                  label="message"
                  id="message"
                >
                  Compose a message
                </el-radio-button>
                <el-radio-button
                  label="uploads"
                  id="uploads"
                >
                  Upload an audio file
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div v-if="form.Type === 'CHAT_APPS'">
          <div v-if="selected.action && selected.action === 'template'">
            <div class="mb-5 flex">
              <el-select
                filterable
                @change="handlePreSelectWATemplate"
                :value="selectedTemplateName"
                class="w-1/3 mr-5"
              >
                <el-option
                  v-for="item in getModifiedTemplates(templates)"
                  :key="item.templateName || item.TemplateName"
                  :label="item.templateName || item.TemplateName"
                  :value="item.templateName || item.TemplateName"
                ></el-option>
              </el-select>
              <div class="mb-5 mt-1">
                <el-button size="small" @click="openModal()">
                  {{ $t("mcs.messages.index[14]") }}
                </el-button>
              </div>

            </div>
            <div v-if="selected.template">
              <div>
                <CaTemplate
                  v-if="hasWhatsApp()"
                  @select-template="openModal()"
                  @reset-template="resetTemplate"
                  :selected="selected"
                />
                <SmsTemplate
                  v-else
                  @select-template="openModal()"
                  @reset-template="resetTemplate"
                  :is-chat-apps="Boolean(true)"
                  :selected="selected"
                />
              </div>
              <CaMsgBoxDisabled
                v-if="hasWhatsApp()"
                :body="selected.message || ''"
                :header="selected.header"
                :footer="selected.footer"
              />

              <CaMessageBox
                v-else
                @update-message="updateMessage"
                :message="selected.message || ''"
                :variables="variables"
                :source="form.Recipients ? form.Recipients.source : ''"
                :is-removable="Boolean(false)"
                :text-limit="getMainChannel().match(/VB/i) ? 1000 : 0"
                @modify-custom-fields="modifyCustomFields"
              />

              <CaCustomFields
                v-if="
                  selected.customFields &&
                  selected.customFields.length &&
                  hasWhatsApp()
                "
                :message="message"
                :fields="selected.customFields"
                @manage-custom-fields="manageWACustomFields"
                :source="form.Recipients ? form.Recipients.source : ''"
                @modify-custom-fields="modifyCustomFields"
                :variables="variables"
              />

              <!-- <CaWAQuickReplyInput
              v-if="selected.waButtons && selected.waButtons.length
              && selected.waButtons.some(v => v.type.toLowerCase() === 'quick_reply') && hasWhatsApp()"
              :wa-buttons="selected.waButtons || []"
              @update-buttons="updateWAQRButtons"
            /> -->
            </div>
          </div>

          <div v-if="selected.action && selected.action === 'message'">
            <div v-if="isWhatsAppSetAsMain()">
              <div
                class="
                  text-black text-xs
                  p-5
                  bg-yellow-lightest
                  border border-solid border-yellow
                  rounded
                  mb-5
                  w-5/6
                  leading-normal
                "
              >
                {{ $t("mcs.messages.index[5]") }}
              </div>
              <CaMessageWABox
                @update-message="updateMessage"
                :message="selected.message || ''"
                :variables="variables"
                :source="form.Recipients ? form.Recipients.source : ''"
                @modify-custom-fields="modifyCustomFields"
                :text-limit="1024"
              />
            </div>

            <div v-if="hasMessageTypes()">
              <div
                v-if="hasWhatsApp() && !isWhatsAppSetAsMain()"
                class="
                  text-black text-xs
                  p-5
                  bg-yellow-lightest
                  border border-solid border-yellow
                  rounded
                  mb-5
                  w-5/6
                  leading-normal
                "
              >
                {{ $t("mcs.messages.index[6]") }}
                <br />
                {{ $t("mcs.messages.index[7]") }}
              </div>
              <CaMessageTypes
                :channel="getMainChannel()"
                :messaging-type="selected.messageType || 'text'"
                @update-message-type="updateMessageType"
              />
            </div>

            <div
              v-if="
                (channelDoesSupportText() &&
                  selected.messageType &&
                  selected.messageType.match(/text|rich_media/i)) ||
                (!channelDoesSupportText() && !isWhatsAppSetAsMain())
              "
            >
              <CaMessageBox
                @update-message="updateMessage"
                :channel="getMainChannel()"
                :message="selected.message || ''"
                :variables="variables"
                :source="form.Recipients ? form.Recipients.source : ''"
                @modify-custom-fields="modifyCustomFields"
                :text-limit="getMainChannel().match(/VB/i) ? 1000 : 0"
              />
            </div>

            <div
              v-if="
                channelDoesSupportImage() &&
                selected.messageType &&
                selected.messageType.match(/image|rich_media/i)
              "
            >
              <CaMediaUrlInput
                @update-image-inputs="updateData"
                :data="{
                  title: !Boolean(getMainChannel().match(/VB/i))
                    ? $t('mcs.messages.chat_apps.media_url[4]')
                    : $t('mcs.messages.chat_apps.media_url[3]'),
                  type: 'image',
                }"
                :value="selected.imageInputs || undefined"
                :max-file-size="getFileInfo(getMainChannel()).fileSize"
                :supported-files="getFileInfo(getMainChannel()).fileTypes"
                :only-support-url="Boolean(getMainChannel().match(/VB/i))"
              />
            </div>

            <div
              v-if="
                channelDoesSupportRichMedia() &&
                selected.messageType &&
                selected.messageType.match(/rich_media/i)
              "
            >
              <CaButtonFields
                :buttons="selected.buttonInputs || {}"
                @update-rm-buttons="updateRMButtons"
              />
            </div>
          </div>

          <div v-if="showFallBackMsg()">
            <hr class="mx-0 my-10 h-px bg-grey w-48" />
            <CaFallbackMessage
              :variables="variables"
              :sender-ids="senderIds"
              :original-message="getFallBackMsg()"
              :use-same-text="selected.fallbackOptions.useSameText"
              :senderId="selected.fallbackOptions.senderId"
              :message="selected.fallbackOptions.message"
              :chars-used="selected.fallbackOptions.charsUsed"
              :sms-chars="selected.fallbackOptions.smsChars"
              :sms-parts="selected.fallbackOptions.smsParts"
              @update-fallback-message="updateFallbackMessage"
              @update-fallback-sender-id="updateFallbackSenderId"
              @update-fallback-use-same-text="updateFallbackUseText"
              :source="form.Recipients ? form.Recipients.source : ''"
              @modify-custom-fields="modifyCustomFields"
            />
          </div>
        </div>

        <div v-if="form.Type.match(/SMS/)">
          <div v-if="selected.action && selected.action === 'template'">
            <div class="mb-5 flex">
              <el-select
                  v-if="oxygenMode"
                  filterable
                  @change="handlePreSelectSMSTemplate"
                  :value="selectedTemplateName"
                  class="w-2/3"
                >
                <el-option
                  v-for="item in getModifiedTemplates(templates)"
                  :key="item.TemplateName"
                  :label="item.TemplateName"
                  :value="item.TemplateName"
                ></el-option>
              </el-select>
            </div>
            <div v-if="selected.template">
              <SmsTemplate
                v-if="!oxygenMode"
                @select-template="openModal()"
                @reset-template="resetTemplate"
                :selected="selected"
              />

              <SmsMessageBox
                :is-sms-engage="form.Type === 'SMS_ENGAGE'"
                :selected="selected"
                :variables="variables"
                :senderIds="senderIds"
                :senderVirtualNumbers="senderVirtualNumbers"
                :loading="loadingRemoveSenderId"
                @update-message="updateMessage"
                @on-message-changed="onMessageChanged"
                @update-sender-id="updateData"
                @remove-from-list="removeSenderIdFromList"
                :source="form.Recipients ? form.Recipients.source : ''"
                @modify-custom-fields="modifyCustomFields"
              />
            </div>
          </div>
          <div v-if="selected.action && selected.action === 'message'">
            <SmsMessageBox
              :is-sms-engage="form.Type === 'SMS_ENGAGE'"
              :selected="selected"
              :variables="variables"
              :senderIds="senderIds"
              :senderVirtualNumbers="senderVirtualNumbers"
              :senderId="selected.senderId"
              :loading="loadingRemoveSenderId"
              @update-message="updateMessage"
              @on-message-changed="onMessageChanged"
              @update-sender-id="updateData"
              @remove-from-list="removeSenderIdFromList"
              :source="form.Recipients ? form.Recipients.source : ''"
              @modify-custom-fields="modifyCustomFields"
            />
          </div>
        </div>

        <div
          v-if="
            form.Type === 'SMS_ENGAGE' &&
            ((selected.action === 'template' && selected.senderId) ||
              selected.action === 'message')
          "
        >
          <div v-if="!selected.seForm" class="mb-5">
            <el-button
              :disabled="!selected.senderId"
              @click="openFormModal()"
            >
              {{ $t("mcs.messages.index[8]") }}
            </el-button>
          </div>
          <div v-else>
            <SmsEngageForm
              :title="selected.seForm.SurveyTitle"
              @select-se-form="openFormModal"
              @reset-se-form="resetSeForm"
            />
          </div>
        </div>

        <div v-if="form.Type === 'VOICE'" v-loading="loadingVoiceData">
          <div v-if="selected.action === 'message'">
            <VoiceVirtualNo
              :source="selected.source"
              :virtual-numbers="voiceVirtualNumbers"
              @update-source="updateData"
              :loading="loadingVoiceData"
            />
            <VoiceMessageBox
              :action="selected.action"
              @update-message="updateMessage"
              :message="selected.message || ''"
              :variables="variables"
              :is-removable="Boolean(false)"
              :source="form.Recipients ? form.Recipients.source : ''"
              @modify-custom-fields="modifyCustomFields"
              :text-limit="3000"
            />
            <hr class="mx-0 my-6 h-px bg-grey-lightest w-3/4" />
            <VoiceLanguages
              :language="selected.language"
              :languages="languages"
              :profiles="voiceProfiles"
              @update-profile="updateData"
              @update-language="updateData"
            />

            <VoiceProfiles
              :language="selected.language"
              :profile="selected.profile"
              :profiles="voiceProfiles"
              :loading="loadingVoiceData"
              @update-profile="updateData"
            />
            <VoiceSpeed
              :speed="selected.speed"
              @update-speed="updateData"
            />
            <VoiceRepetition
              :tab-selected="selected.action"
              :repetition="selected.repetition"
              @update-repetition="updateData"
            />
          </div>
          <div v-else>
            <VoiceVirtualNo
              :source="selected.source"
              :virtual-numbers="voiceVirtualNumbers"
              @update-source="updateData"
              :loading="loadingVoiceData"
            />
            <VoiceUploadAudio
              :is-button-type="Boolean(false)"
              :file-data="selected.uploads"
              @update-uploaded-audio="updateData"
            />
            <VoiceRepetition
              :tab-selected="selected.action"
              :repetition="selected.repetition"
              @update-repetition="updateData"
            />
          </div>
          <VoiceActionConfirmation
            :visible="modalVoiceActionVisible"
            :type="selected.action"
            @cancel="modalVoiceActionVisible = false"
            @proceed="(action) => {
              modalVoiceActionVisible = false;
              resetSelection(action)
            }"
          />
        </div>

        <CaTemplates
          v-if="form.Type === 'CHAT_APPS' && hasWhatsApp()"
          :templates="getModifiedTemplates(templates)"
          :categories="getWAFilter('category')"
          :languages="getWAFilter('language')"
          :modal-visible="modalTemplateVisible"
          :hide-types="hasMessageTypes()"
          @close-modal="closeModal"
          @select-template="selectWATemplate"
          :loading="loadingTemplate"
        />

        <SmsTemplates
          v-if="
            form.Type.match(/SMS/) ||
            (form.Type === 'CHAT_APPS' && !hasWhatsApp())
          "
          :templates="templates"
          :sender-ids="getSMSFilter()"
          :modal-visible="modalTemplateVisible"
          @close-modal="closeModal"
          @select-template="selectSmsTemplate"
          :loading="loadingTemplate"
          :is-chat-apps="form.Type === 'CHAT_APPS'"
        />

        <SmsEngageForms
          v-if="form.Type === 'SMS_ENGAGE'"
          :forms="seForms"
          :modal-visible="modalFormVisible"
          @close-modal="closeFormModal"
          @select-se-form="selectSeForm"
          :loading="loadingForms"
        />
      </el-col>
      <el-col :span="10" class="flex-auto pl-5 preview">
        <CaPreviewMessage
          v-if="form.Type === 'CHAT_APPS'"
          :wa-buttons="selected.waButtons"
          :footer="selected.footer"
          :header="selected.header"
          :body="selected.message || ''"
          :imageInputs="selected.imageInputs"
          :buttonInputs="selected.buttonInputs"
          :custom-fields="selected.customFields || []"
          :variables="variables || []"
          :channels="channels"
          :channel="getMainChannel()"
        />

        <SmsPreviewMessage
          v-if="form.Type.match(/SMS/)"
          :se-form="selected.seForm"
          :is-sms-engage="form.Type === 'SMS_ENGAGE'"
          :message="selected.message || ''"
          :sender-id="selected.senderId || ''"
          :variables="variables || []"
          :loading-qr="loadingQRCode"
          @preview-message-sample="previewMessageSample"
          :message-sample-preview="messageSamplePreview"
        />

        <SmsDetails
          v-if="form.Type.match(/SMS/) && selected.message"
          :sms-parts="selected.smsParts"
          :unicode-type="selected.unicodeType"
          :has-custom-fields="hasCustomFields(selected.message)"
          :has-dynamic-url="hasDynamicUrl(selected.message)"
        />

         <VoicePreviewMessage
          v-if="form.Type === 'VOICE'"
          :tab-selected="selected.action"
          :file-data="selected.uploads"
          :message="selected.message || ''"
          :source="selected.source || ''"
          :variables="variables || []"
          @update-uploaded-audio="updateData"
        />

        <VoiceSendTestMessage
          v-if="form.Type === 'VOICE'"
          :disabled="(!selected.message || !selected.source) || (!selected.message || !selected.uploads)"
          :selected="selected"
          :loading="loadingSendVoiceTestMessage"
          :variables="variables"
          :partial-data="partialData"
          :mcs-form="form"
          @send-test-message="sendTestVoiceMessage"
        />
      </el-col>
    </el-row>
    <div class="flex-shrink p-6 border-t border-grey-lighter text-right">
      <div v-if="!canSendCampaigns" class="text-red-light mb-2 text-sm">
        {{ $t('mcs.subscription_warning') }}
      </div>
      <el-button
        type="text"
        class="mr-3"
        @click="back"
      >
        Back
      </el-button>
      <el-button @click="$emit('discard')">
        {{ $t("actions.cancel") }}
      </el-button>
      <el-button
        type="primary"
        :loading="loadingPricing"
        :disabled="nextStepDisabled()"
        @click="nextStep()"
        id="mcs-compose-message"
      >
        {{ $t("mcs.messages.index[9]") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import _ from 'lodash';

// chat apps
import CampaignDetailsMixin from '@/mixins/campaign-details';
import MessageMixin from '@/mixins/message';
import PhoneNumberMixin from '@/mixins/phone-number';
import { hasBrackets, removeBrackets, hasUrlLink } from '@/utils/common';
import languagesJson from '@/json/languages2.json';
import CaTemplates from './partials/ChatApp/Templates.vue';
import CaTemplate from './partials/ChatApp/SelectedTemplate.vue';
import CaMsgBoxDisabled from './partials/ChatApp/MessageBoxDisabled.vue';
import CaCustomFields from './partials/ChatApp/CustomFields.vue';
import CaPreviewMessage from './partials/ChatApp/Preview.vue';
import CaMessageWABox from './partials/ChatApp/MessageWABox.vue';
import CaMessageBox from './partials/ChatApp/MessageBox.vue';
import CaFallbackMessage from './partials/ChatApp/FallbackMessage.vue';
import CaMessageTypes from './partials/ChatApp/MessageTypes.vue';
import CaButtonFields from './partials/ChatApp/ButtonFields.vue';
import CaMediaUrlInput from './partials/ChatApp/MediaUrlInput.vue';
// import CaWAQuickReplyInput from './partials/ChatApp/WAQuickReplyInput.vue';

// sms
import SmsTemplates from './partials/Sms/Templates.vue';
import SmsMessageBox from './partials/Sms/MessageBox.vue';
import SmsPreviewMessage from './partials/Sms/Preview.vue';
import SmsTemplate from './partials/Sms/SelectedTemplate.vue';
import SmsDetails from './partials/Sms/SmsDetails.vue';

// voice
import VoiceLanguages from './partials/Voice/Languages.vue';
import VoiceProfiles from './partials/Voice/Profiles.vue';
import VoiceSpeed from './partials/Voice/Speed.vue';
import VoiceRepetition from './partials/Voice/Repetition.vue';
import VoicePreviewMessage from './partials/Voice/Preview.vue';
import VoiceSendTestMessage from './partials/Voice/SendTestMessage.vue';
import VoiceMessageBox from './partials/Voice/MessageContent.vue';
import VoiceVirtualNo from './partials/Voice/VirtualNo.vue';
import VoiceUploadAudio from './partials/Voice/UploadAudio.vue';
import VoiceActionConfirmation from './partials/Voice/Confirmation.vue';

// sms engage
import SmsEngageForms from './partials/SmsEngage/Forms.vue';
import SmsEngageForm from './partials/SmsEngage/SelectedForm.vue';

// mixin

// utils

export default {
  name: 'messages',

  components: {
    CaTemplates,
    CaTemplate,
    CaMsgBoxDisabled,
    CaCustomFields,
    CaPreviewMessage,
    CaMessageWABox,
    CaMessageBox,
    CaMessageTypes,
    CaButtonFields,
    CaMediaUrlInput,
    CaFallbackMessage,
    // CaWAQuickReplyInput,

    SmsTemplates,
    SmsTemplate,
    SmsMessageBox,
    SmsPreviewMessage,
    SmsDetails,

    // VoiceSource,
    VoiceLanguages,
    VoiceProfiles,
    VoiceSpeed,
    VoiceRepetition,
    VoicePreviewMessage,
    VoiceSendTestMessage,
    VoiceMessageBox,
    VoiceVirtualNo,
    VoiceUploadAudio,
    VoiceActionConfirmation,

    SmsEngageForms,
    SmsEngageForm,
  },

  mixins: [CampaignDetailsMixin, MessageMixin, PhoneNumberMixin],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: {},
      modalTemplateVisible: false,
      modalFormVisible: false,
      modalVoiceActionVisible: false,
      loadingTemplate: false,
      loadingForms: false,
      loadingQRCode: false,
      loadingPricing: false,
      loadingSendVoiceTestMessage: false,
      loadingVoiceData: false,
      loadingRemoveSenderId: false,
      languages: languagesJson,
      oxygenMode: false,
      messageSamplePreview: {},
    };
  },

  computed: {
    ...mapGetters({
      templates: 'templates/getTemplates',
      user: 'user/getUser',
      canSendCampaigns: 'user/getCanSendCampaigns',
      enums: 'templates/getEnums',
      senderIds: 'websender/getSenderIds',
      senderVirtualNumbers: 'websender/getVirtualNumbers',
      pricing: 'pricing/getPricing',
      channelInstances: 'channels/getChannelInstances',
      step: 'getStep',
      form: 'getForm',
      seForms: 'websender/getSeForms',
      seQRCode: 'websender/getSeQRCode',
      partialData: 'websender/getPartialData',
      messageSamples: 'websender/getMessageSamples',
      voiceVirtualNumbers: 'voice/getVirtualNumbers',
      voiceProfiles: 'voice/getVoiceProfiles',
    }),

    selectedTemplateName() {
      const { template } = this.selected;
      return template ? (template.templateName || template.TemplateName) : '';
    },
  },

  created() {
    this.oxygenMode = true;

    const channel = this.getMainChannel();
    let action = 'template';

    if (
      (this.form.Type === 'CHAT_APPS'
        && channel.toUpperCase().match(/LN|VB/i))
      || this.form.Type === 'VOICE'
      || this.form.Type.match(/SMS/)
    ) {
      action = 'message';
    }

    this.resetSelection(action);
    this.updateSelection();

    if (this.form.Type.match(/CHAT_APPS|SMS/i)) {
      this.fetchTemplates();
    }

    if (this.form.Type === 'SMS_ENGAGE') {
      this.fetchSMSEngageForms();
    }

    if (this.form.Type === 'VOICE') {
      this.fetchVoiceData();
    }
  },

  beforeDestroy() {
    const { selected } = this;
    this.updateForm({ Message: selected });
  },

  methods: {
    ...mapActions({
      getWaTemplates: 'templates/getWATemplates',
      getSmsTemplates: 'templates/getSMSTemplates',
      getSenderIds: 'websender/getSenderIds',
      removeSenderId: 'websender/removeSenderId',
      getSmsPricing: 'pricing/getSmsPricing',
      getChatAppsPricing: 'pricing/getChatAppsPricing',
      getVoicePricing: 'pricing/getVoicePricing',
      getSmsEngageFormQRCode: 'websender/getSmsEngageFormQRCode',
      getSmsEngageForms: 'websender/getSmsEngageForms',
      setMessageSamples: 'websender/setMessageSamples',
      sendVoiceTestMessage: 'voice/sendVoiceTestMessage',
      getVoiceVirtualNumbers: 'voice/getVirtualNumbers',
      getVoiceProfiles: 'voice/getVoiceProfiles',
    }),

    ...mapMutations({
      updateForm: 'UPDATE_FORM',
      setStep: 'SET_STEP',
      updateRecipientFormOld: 'recipients/SET_FORM_STEP',
      updateRecipientFormNew: 'recipientsContacts/SET_FORM_STEP',
    }),

    back() {
      this.$emit('change-process-index', 1);
    },

    updateRecipientForm(param) {
      return this.updateRecipientFormNew(param);
    },

    getFileInfo(channel) {
      const f = {};
      // mb
      const fileSize = {
        VB: 1.5,
        LN: 1,
      };

      const fileTypes = {
        VB: ['jpg', 'jpeg'],
        LN: ['jpg', 'jpeg', 'png'],
      };

      if (fileSize[channel]) {
        f.fileSize = fileSize[channel];
      } else {
        f.fileSize = 5;
      }

      if (fileTypes[channel]) {
        f.fileTypes = fileTypes[channel];
      } else {
        f.fileTypes = ['jpg', 'jpeg', 'png'];
      }

      return f;
    },

    resetSelection(action = 'template') {
      let s = {
        action,
        message: '',
        senderId: '',
        template: undefined,
        customFields: [],
      };

      if (this.form.Type === 'CHAT_APPS' && this.hasMessageTypes()) {
        s.messageType = 'text';
      }

      if (this.form.Type.match(/SMS/)) {
        const smsObj = {
          smsParts: 0,
          charsUsed: 0,
          smsSegments: {
            min: 1,
            max: 1,
          },
          unicodeType: '',
        };
        s = { ...s, ...smsObj };
      }

      if (this.hasWhatsApp()) {
        s.header = {};
        s.footer = '';
        s.waButtons = [];
      }

      if (this.hasSmsChannel()) {
        const fallbackOptions = {
          message: '',
          senderId: '',
          smsParts: 0,
          charsUsed: 0,
          unicodeType: '',
          useSameText: true,
          smsSegments: {
            min: 1,
            max: 1,
          },
        };
        s = { ...s, fallbackOptions };
      }

      if (this.form.Type.match(/VOICE/i)) {
        s.language = {
          name: 'English (United States)',
          code: 'en-US',
        };

        s.profile = {
          description: 'English (United States)',
          code: 'en-US',
          profile: 'en-US-AriaRUS',
          gender: 'F',
        };

        s.speed = 1;
        s.repetition = 1;
        s.action = action || 'message';
        s.uploads = undefined;
        s.source = this.selected.source && action === 'uploads' ? this.selected.source : '';
      }

      this.selected = s;
    },

    updateSelection() {
      // update local state if ever global store has Message object
      try {
        if (this.form.Message) {
          const selected = JSON.parse(JSON.stringify(this.selected));
          const form = JSON.parse(JSON.stringify(this.form.Message));
          const s = { ...selected, ...form };

          if (s.customFields && s.customFields.length) {
            const dynamicValues = s.customFields
              .slice(0)
              .filter(v => v.type === 'dynamic');

            if (dynamicValues.length) {
              const dv = dynamicValues.map(v => v.value);
              const withMappedVars = dv.filter(v => this.variables.includes(v));

              const commonVars = _.intersection(dv, withMappedVars);

              // if all custom fields have been not included in variables,
              // then reset custom fields
              if (
                dv.length !== withMappedVars.length
                && commonVars.length !== dv.length
              ) {
                s.customFields = this.getWACustomFields(s.message);

                if (s.template) {
                  const { components } = s.template;

                  const c = this.getComponents(components);

                  // header dynamic field for ex. {{1}}
                  const headerDynamicField = this.getHeaderField(c);

                  // button dynamic suffix for url for ex. https://wavecell.com/{{1}}
                  const buttonUrlDynamicField = this.getButtonsField(c);

                  // should be on top
                  // this header contains only one field
                  if (!Array.isArray(headerDynamicField) && headerDynamicField) {
                    s.customFields.unshift(headerDynamicField);
                  }

                  // this header contains multiple fields (for ex: location)
                  if (Array.isArray(headerDynamicField) && headerDynamicField.length) {
                    s.customFields = [...headerDynamicField, ...s.customFields];
                  }

                  // should be on the last
                  if (buttonUrlDynamicField) {
                    s.customFields.push(buttonUrlDynamicField);
                  }
                }
              }
            }
          }

          this.selected = { ...s };
        }
      } catch (err) {
        this.$showError(this, err);
        // do nothing
      }
    },

    selectAction(v) {
      // reset local state
      this.resetSelection(v);

      // reset Message global state if it has object values
      if (this.form.Message) {
        this.updateForm({ Message: undefined });
      }
    },

    selectVoiceTab(ev) {
      const {
        action, message, source, uploads,
      } = this.selected;

      if (ev && ev.target) {
        const { parentNode } = ev.target;

        if (parentNode.id === action) return;
        if (ev.preventDefault) ev.preventDefault();
      }

      if ((action === 'message' && message) || (action === 'uploads' && uploads) || source) {
        this.modalVoiceActionVisible = true;
      } else {
        this.selected.action = action === 'message' ? 'uploads' : 'message';
      }
    },

    getModifiedTemplates(templates) {
      let t = templates;

      if (this.hasWhatsApp() && !this.isWhatsAppSetAsMain()) {
        t = templates.filter(
          v => v.components
            && v.components.length === 1
            && v.components[0].type.toLowerCase() === 'body',
        );
      }

      return t;
    },

    getWACustomFields(content) {
      const hb = hasBrackets(content);

      if (hb && hb.length) {
        const fields = hb.reduce((a, b) => {
          const obj = {};

          obj.field = b;
          obj.fieldText = b;
          obj.value = '';
          obj.type = 'custom';
          obj.headerType = '';
          obj.buttonType = '';

          // // assign column labels based on order
          // if (this.hasColumnLabels()) {
          //   obj.value = this.variables[k] ? this.variables[k] : '';
          //   obj.type = 'dynamic';
          // }

          a.push(obj);

          return a;
        }, []);

        return fields;
      }

      return [];
    },

    manageWACustomFields(obj) {
      const {
        headerType, action, value, index, type, buttonType,
        fieldType,
      } = obj;
      const s = this.selected;

      switch (action) {
        case 'assign': {
          s.customFields[index].value = value;
          s.customFields[index].type = type;
          s.customFields[index].fieldType = fieldType;
          s.customFields[index].headerType = headerType || '';
          s.customFields[index].buttonType = buttonType || '';
          break;
        }

        case 'unassign': {
          s.customFields[index].value = '';
          s.customFields[index].fieldType = fieldType;
          s.customFields[index].type = type;
          s.customFields[index].headerType = headerType || '';
          s.customFields[index].buttonType = buttonType || '';
          break;
        }

        default:
      }

      this.selected = { ...s };
    },

    modifyCustomFields() {
      // redirect to column label modification
      this.updateRecipientForm('SET_CUSTOM_FIELD');
      this.$emit('change-process-index', 1);
    },

    getWAFilter(type) {
      const t = {
        category: 'all',
        language: 'all',
      };

      const all = t[type];

      return { all, ...this.enums[type] };
    },

    handlePreSelectWATemplate(templateName) {
      const template = this.templates.find(v => (v.templateName || v.TemplateName) === templateName);

      if (this.hasWhatsApp()) {
        this.selectWATemplate(template);
      } else {
        this.selectSmsTemplate(template);
      }
    },


    selectWATemplate(row) {
      const s = this.selected;

      s.template = row;

      const components = this.getComponents(row.components);

      const waCustomFields = this.getWACustomFields(components.body);
      s.customFields = waCustomFields;

      // header dynamic field for ex. {{1}}
      const headerDynamicField = this.getHeaderField(components);

      // button dynamic suffix for url for ex. https://wavecell.com/{{1}}
      const buttonUrlDynamicField = this.getButtonsField(components);

      // should be on top

      // this header contains only one field
      if (!Array.isArray(headerDynamicField) && headerDynamicField) {
        s.customFields.unshift(headerDynamicField);
      }

      // this header contains multiple fields (for ex: location)
      if (Array.isArray(headerDynamicField) && headerDynamicField.length) {
        s.customFields = [...headerDynamicField, ...s.customFields];
      }

      // should be on the last
      if (buttonUrlDynamicField) {
        s.customFields.push(buttonUrlDynamicField);
      }

      s.header = components.header;
      s.message = components.body;
      s.footer = components.footer;

      const { buttons } = components;

      if (
        buttons.length
        && buttons.some(v => v.type.toLowerCase() === 'quick_reply')
      ) {
        s.waButtons = buttons.slice(0).map((v) => {
          // eslint-disable-next-line no-param-reassign
          v.value = v.text;

          return v;
        });
      } else {
        s.waButtons = components.buttons;
      }

      this.selected = { ...s };

      this.closeModal();
    },

    getComponents(components = []) {
      return components.reduce(
        (a, b) => {
          const type = b.type.toLowerCase();

          if (type === 'header') {
            if (b.format.toLowerCase() !== 'text') {
              // eslint-disable-next-line no-param-reassign
              a[type] = {
                type: b.format.toLowerCase(),
                value: '',
              };
            } else {
              // eslint-disable-next-line no-param-reassign
              a[type] = {
                type: b.format.toLowerCase(),
                value: b.text,
              };
            }
          } else if (['body', 'footer'].includes(type)) {
            // eslint-disable-next-line no-param-reassign
            a[type] = b.text;
          } else if (type === 'buttons') {
            // eslint-disable-next-line no-param-reassign
            a[type] = b.buttons;
          }

          return a;
        },
        {
          header: {},
          footer: '',
          body: '',
          buttons: [],
        },
      );
    },

    getHeaderField(components) {
      // Add media fields (Rich media, Image, Video)
      const waHeader = Object.keys(components.header).length && components.header.type
        ? components.header
        : undefined;

      let mediaField;

      if (waHeader) {
        const { value, type } = waHeader;

        if (type === 'text') {
          const dynamicField = hasBrackets(value);

          if (dynamicField && dynamicField.length) {
            mediaField = {
              fieldText: `${value}`,
              field: `h_${dynamicField[0]}`,
              value: '',
              type: 'custom',
              headerType: type,
              fieldType: '',
              buttonType: '',
            };
          }
        } else if (type.toLowerCase().match(/image|video|document/)) {
          mediaField = {
            fieldText: `Media - ${type.replace(/^\w/, c => c.toUpperCase())}`,
            field: '',
            value: '',
            headerType: type,
            fieldType: '',
            type: 'custom',
            buttonType: '',
          };
        } else if (type.toLowerCase().match(/location/)) {
          // new location fields
          const locFields = ['latlong', 'name', 'address'];

          // translations
          const tr = {
            latlong: `${this.$t('fields.latitude')},${this.$t('fields.longitude')}`,
            name: this.$t('column_labels.name'),
            address: this.$t('column_labels.address'),
          };

          // add them as custom fields
          mediaField = locFields.reduce((a, b) => {
            const objLoc = {
              fieldText: `${type.toUpperCase()} - ${tr[b]}`,
              field: b,
              value: '',
              headerType: type,
              fieldType: b,
              type: 'custom',
              buttonType: '',
            };

            a.push(objLoc);
            return a;
          }, []);
        }
      }

      return mediaField;
    },

    getButtonsField(components) {
      // find the slug field
      const waButtons = components.buttons && components.buttons.length
        ? components.buttons
        : undefined;

      let slugField;

      if (waButtons) {
        const urlBtn = waButtons.find(v => v.type.toLowerCase() === 'url');

        if (urlBtn) {
          const urlSuffix = urlBtn.url.match(/({{.*?}}$)/);

          if (urlSuffix) {
            slugField = {
              fieldText: urlBtn.url,
              field: `s_${urlSuffix[0]}`,
              value: '',
              buttonType: urlBtn.type.toLowerCase(),
              headerType: '',
              type: 'custom',
            };
          }
        }
      }

      return slugField;
    },

    hasColumnLabels() {
      const { Recipients } = this.form;

      return (
        Recipients.columnLabels
        // eslint-disable-next-line max-len
        && Object.keys(Recipients.columnLabels).some(
          v => Recipients.columnLabels[v] !== -1,
        )
      );
    },

    showFallBackMsg() {
      const {
        messageType, message, imageInputs, buttonInputs,
      } = this.selected;

      if (this.hasSmsChannel()) {
        if (!messageType && !message) {
          return false;
        }

        if (messageType && messageType.match(/text/) && !message) {
          return false;
        }

        if (messageType && messageType.match(/image/) && !imageInputs) {
          return false;
        }

        if (
          messageType
          && messageType.match(/rich_media/)
          && (!imageInputs
            || !message
            || !buttonInputs
            || (buttonInputs && Object.keys(buttonInputs).length < 2))
        ) {
          return false;
        }

        return true;
      }

      return false;
    },

    getFallBackMsg() {
      const { message, buttonInputs, imageInputs } = this.selected;

      let fbm = '';

      if (message) {
        fbm = message;
      }

      if (imageInputs) {
        const nl = fbm ? '\n' : '';
        fbm = `${fbm}${nl}${imageInputs.url}`;
      }

      if (buttonInputs) {
        const nl = fbm ? '\n' : '';
        fbm = `${fbm}${nl}${buttonInputs.title} ${buttonInputs.url}`;
      }

      return fbm;
    },

    updateFallbackMessage(message) {
      const s = this.selected;

      const smsAttrs = this.getTextAttrs(message);

      s.fallbackOptions.message = message;

      s.fallbackOptions = this.setSmsSegment(
        smsAttrs.smsParts,
        s.fallbackOptions,
      );

      s.fallbackOptions = { ...s.fallbackOptions, ...smsAttrs };

      this.selected = { ...s };
    },

    updateFallbackSenderId(obj) {
      const { senderId, useSameText } = obj;
      const s = this.selected;

      s.fallbackOptions.senderId = senderId;

      if (useSameText) {
        const message = this.getFallBackMsg();
        const smsAttrs = this.getTextAttrs(message);

        s.fallbackOptions.message = message;
        s.fallbackOptions = this.setSmsSegment(
          smsAttrs.smsParts,
          s.fallbackOptions,
        );

        s.fallbackOptions = { ...s.fallbackOptions, ...smsAttrs };
      }

      this.selected = { ...s };
    },

    updateFallbackUseText(useSameText) {
      const s = this.selected;

      s.fallbackOptions.useSameText = useSameText;

      if (useSameText) {
        const message = this.getFallBackMsg();
        const smsAttrs = this.getTextAttrs(message);

        s.fallbackOptions.message = message;

        s.fallbackOptions = this.setSmsSegment(
          smsAttrs.smsParts,
          s.fallbackOptions,
        );

        s.fallbackOptions = { ...s.fallbackOptions, ...smsAttrs };
      }

      this.selected = { ...s };
    },

    hasAssignedCustomFields(message) {
      const b = hasBrackets(message);

      const assigned = this.selected.customFields.filter(v => v.value);

      return b && b.length === assigned.length;
    },

    handlePreSelectSMSTemplate(templateName) {
      const template = this.templates.find(v => v.TemplateName === templateName);
      this.selectSmsTemplate(template);
    },

    selectSmsTemplate(row) {
      let s = this.selected;

      s.message = row.MessageBody;
      s.template = row;

      if (this.form.Type.match(/SMS/)) {
        s.senderId = row.SenderId;

        const smsAttrs = this.getTextAttrs(s.message);

        s = this.setSmsSegment(smsAttrs.smsParts, s);

        this.selected = { ...s, ...smsAttrs };
      } else {
        // if chat apps and no WA

        this.selected = { ...s };
      }

      this.closeModal();
    },

    async selectSeForm(v) {
      const { SubAccountUid: id } = this.form;
      const s = this.selected;
      const url = this.$vars.SMS_ENGAGE_URL;
      const urlRegex = new RegExp(this.$vars.SMS_ENGAGE_URL_REGEX, 'g');

      this.loadingQRCode = true;

      s.seForm = v;

      const urlWithSid = `${url}${v.Sid}`;

      if (s.message) {
        if (urlRegex.test(s.message)) {
          s.message = s.message.replace(urlRegex, urlWithSid);
        } else {
          s.message = `${s.message} \n${urlWithSid}`;
        }
      } else {
        s.message = urlWithSid;
      }

      s.seForm.link = urlWithSid;

      this.selected = { ...s };

      await this.getSmsEngageFormQRCode({ subAccountUid: id, sid: v.Sid });

      this.selected.seForm = {
        ...this.selected.seForm,
        ...{ qrCodeUrl: this.seQRCode.qrUrl },
      };

      this.loadingQRCode = false;

      this.closeFormModal();
    },

    resetSeForm() {
      const s = this.selected;

      const urlRegex = new RegExp(this.$vars.SMS_ENGAGE_URL_REGEX, 'g');
      s.message = s.message.replace(urlRegex, '');
      s.message = s.message.replace(/\n$/, '');
      s.seForm = undefined;

      this.selected = { ...s };
    },

    getSMSFilter() {
      const all = [
        {
          Text: this.$t('mcs.messages.index[11]'),
          SenderId: 'all',
        },
      ];

      const senderIds = this.senderIds.slice(0).reduce((a, b) => {
        const c = {};
        if (b.SenderId) {
          c.Text = b.SenderId;
          c.SenderId = b.SenderId;
        }

        a.push(c);

        return a;
      }, []);

      const filters = all.concat(senderIds);

      return filters;
    },

    setSmsSegment(smsParts, obj) {
      const s = obj;

      s.smsSegments.min = smsParts;

      // Tempory solution
      if (s.smsSegments.max < smsParts) {
        s.smsSegments.max = smsParts;
      }

      return s;
    },

    hasCustomFields(message) {
      const b = hasBrackets(message);

      if (b && b.length) {
        return b.some(v => this.variables.includes(removeBrackets(v)));
      }
      return false;
    },

    // check if user dynamic url enabled
    hasDynamicUrl(message) {
      return hasUrlLink(message) && this.form.UrlShortenerEnabled;
    },

    async removeSenderIdFromList(senderId) {
      this.loadingRemoveSenderId = true;

      await this.removeSenderId({ senderId });

      this.loadingRemoveSenderId = false;
    },

    async onMessageChanged(message = '') {
      const s = this.selected;

      if (this.form.Type === 'SMS_ENGAGE') {
        const urlRegex = new RegExp(this.$vars.SMS_ENGAGE_URL_REGEX, 'g');
        const sidRegex = new RegExp(this.$vars.SMS_ENGAGE_SID_REGEX, 'g');
        const matched = message.match(urlRegex);

        if (matched) {
          const q = matched[0].match(sidRegex);
          if (q) {
            const sid = q[0].split('=')[1];

            if (this.seForms.length && sid) {
              const sidFound = this.seForms.find(
                v => v.Sid.toString() === sid.toString(),
              );

              if (!sidFound) {
                s.seForm = undefined;
              }

              if (sidFound && !s.seForm) {
                s.seForm = sidFound;

                await this.getSmsEngageFormQRCode({
                  subAccountUid: this.form.SubAccountUid,
                });

                s.seForm = {
                  ...s.seForm,
                  ...{ qrCodeUrl: this.seQRCode.qrUrl },
                };
              }
            }
          } else {
            s.seForm = undefined;
          }
        } else {
          s.seForm = undefined;
        }
      }

      this.selected = { ...s };
    },

    updateMessage(message) {
      let s = this.selected;

      s.message = message;

      if (this.form.Type.match(/SMS/)) {
        const smsAttrs = this.getTextAttrs(s.message);

        s = this.setSmsSegment(smsAttrs.smsParts, s);

        this.selected = { ...s, ...smsAttrs };
      } else {
        this.selected = { ...s };
      }

      if (this.form.Type.match(/SMS/)) {
        this.updateMessageSamples();
        this.messageSamplePreview = '';
      }
    },

    updateMessageSamples() {
      if (!this.partialData || !this.partialData.List) {
        return;
      }

      const validRows = this.partialData.List.slice(0, 10);

      const { columnLabels = {} } = this.form.Recipients;
      const arrangedColumnLabels = Object.entries(columnLabels)
        .sort(([, a], [, b]) => columnLabels[a] - columnLabels[b])
        .map(([key]) => {
          if (key.toLowerCase() === 'msisdn') {
            return 'Mobile';
          }

          return key;
        });

      const arrangedColumsByIndex = validRows.map(row => Object.keys(columnLabels).map(key => row[columnLabels[key]]));

      const sampleMessages = arrangedColumsByIndex.map((row) => {
        const message = {
          text: '',
          destination: '',
        };

        message.text = this.selected.message.replace(/{{\s*([a-zA-Z0-9_]+)\s*}}/g, (match, p1) => {
          const variable = row[arrangedColumnLabels.indexOf(p1)];
          if (p1 === 'Mobile' || !this.selected.message.includes('{{Mobile}}')) {
            const mobile = row[0];
            message.destination = mobile;
          }

          return variable;
        });

        return message;
      });

      this.setMessageSamples(sampleMessages);
    },

    previewMessageSample() {
      const randomRow = this.messageSamples[Math.floor(Math.random() * this.messageSamples.length)];

      this.messageSamplePreview = randomRow;
    },

    updateWAQRButtons(value, index) {
      const s = this.selected;
      // wa buttons

      s.waButtons.map((v, i) => {
        if (i === index) {
          // eslint-disable-next-line no-param-reassign
          v.value = value;
        }

        return v;
      });

      this.selected = { ...s };
    },

    updateData(v, key, meta) {
      const s = this.selected;

      s[key] = v;

      // detect country on source and match the language
      if (this.form.Type === 'VOICE' && meta && key === 'source') {
        const matchLang = this.languages.find((l) => {
          const country = l.name.match(/\(([^)]+)\)/);
          let c;

          if (country) {
            // eslint-disable-next-line prefer-destructuring
            c = country[1];
          }

          const re = new RegExp(c, 'g');

          return c && meta.name.match(re);
        });

        if (matchLang) {
          s.language = matchLang;
        } else {
          s.language = {
            name: 'English (United States)',
            code: 'en-US',
          };
        }

        s.profile = this.voiceProfiles.find(p => p.localeCode === s.language.code);
      }

      this.selected = { ...s };
    },

    updateMessageType(messageType = 'text') {
      let s = this.selected;

      s.messageType = messageType;

      s.buttonInputs = undefined;
      s.imageInputs = undefined;
      s.message = '';

      if (this.hasSmsChannel()) {
        const fallbackOptions = {
          message: '',
          senderId: '',
          smsParts: 0,
          charsUsed: 0,
          unicodeType: '',
          useSameText: true,
          smsSegments: {
            min: 1,
            max: 1,
          },
        };
        s = { ...s, fallbackOptions };
      }

      this.selected = { ...s };
    },

    updateRMButtons(btn) {
      const s = this.selected;

      const btns = s.buttonInputs && Object.keys(s.buttonInputs).length
        ? s.buttonInputs
        : {};

      s.buttonInputs = { ...btns, ...btn };

      this.selected = { ...s };
    },

    async sendTestVoiceMessage(params) {
      try {
        this.loadingSendVoiceTestMessage = true;

        const { SubAccountUid: uid } = this.form;

        const payload = params;

        if (payload.meta) {
          payload.meta.subAccountUid = uid;
        }

        await this.sendVoiceTestMessage(payload);

        this.$notify({
          title: 'Test voice has been sent successfully.',
          type: 'success',
        });
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.loadingSendVoiceTestMessage = false;
      }
    },

    async fetchTemplates() {
      let channelId = '';
      let action = 'getSmsTemplates';

      this.loadingTemplate = true;

      await this.getSenderIds();

      if (this.form.Type === 'CHAT_APPS' && this.hasWhatsApp()) {
        action = 'getWaTemplates';

        channelId = this.form.whatsAppChannelId;
      }

      await this[action]({ channelId });

      this.loadingTemplate = false;
    },

    async fetchSMSEngageForms() {
      const { SubAccountUid: id } = this.form;
      this.loadingForms = true;
      await this.getSmsEngageForms({ subAccountUid: id });

      this.loadingForms = false;
    },

    openModal() {
      this.modalTemplateVisible = true;
    },

    openFormModal() {
      this.modalFormVisible = true;
    },

    closeModal() {
      this.modalTemplateVisible = false;
    },

    closeFormModal() {
      this.modalFormVisible = false;
    },

    resetTemplate() {
      this.resetSelection();
    },

    hasNoFieldsWithMessageType() {
      let { messageType, imageInputs } = this.selected;
      const {
        buttonInputs, message, header, customFields,
      } = this.selected;

      if (!messageType && header) {
        messageType = header.type; // eslint-disable-line
        imageInputs = customFields.filter(x => x.headerType === 'image' && x.value !== '').length; // eslint-disable-line
      }

      if (messageType && messageType.match(/text/i) && !message) {
        return true;
      }
      if (messageType && messageType.match(/image/i) && !imageInputs) {
        return true;
      }
      if (
        messageType
        && messageType.match(/rich_media/i)
        && (!imageInputs
          || !buttonInputs
          || !message
          || (buttonInputs && Object.keys(buttonInputs).length < 2))
      ) {
        return true;
      }
      if (!messageType && !message) {
        return true;
      }
      return customFields.filter(x => x.value === '').length;
    },

    nextStepDisabled() {
      const {
        message, fallbackOptions,
        senderId, source, seForm, waButtons,
        action, uploads,
      } = this.selected;


      if (this.form.Type.match(/SMS/) && message && senderId) {
        if (this.form.Type === 'SMS_ENGAGE' && !seForm) {
          return true;
        }

        return false;
      }

      if (this.form.Type === 'CHAT_APPS') {
        if (
          waButtons
          && waButtons.length
          && waButtons.some(
            v => v.type.toLowerCase() === 'quick_reply' && !v.value,
          )
        ) {
          return true;
        }

        if (
          (this.hasSmsChannel()
            && fallbackOptions
            && fallbackOptions.senderId
            && fallbackOptions.message)
          || !this.hasSmsChannel()
        ) {
          return this.hasNoFieldsWithMessageType();
        }
      }

      if (this.form.Type === 'VOICE' && ((action === 'message' && message) || (action === 'uploads' && uploads)) && source) {
        return false;
      }

      return true;
    },

    async fetchVoiceData() {
      try {
        this.loadingVoiceData = true;
        const { SubAccountId: subAccountId } = this.form;
        const payload = { subAccountId };
        await this.getVoiceVirtualNumbers(payload);
        await this.getVoiceProfiles(payload);
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.loadingVoiceData = false;
      }
    },

    fetchPricing() {
      const countries = Object.keys(this.getCountriesWithContactTotals()).filter(c => c.trim() !== '');

      if (this.form.Type === 'CHAT_APPS') {
        return this.getChatAppsPricing({
          subaccountUid: this.form.SubAccountUid,
          countries,
        });
      }

      return this.getSmsPricing({
        subAccountId: this.form.SubAccountId,
        countries,
      });
    },

    calculatePriceRange(pricingPerCountry) {
      const countries = this.getCountriesWithContactTotals();

      let priceRange = {};

      const ch = this.getMainChannel();
      const { messageType } = this.selected;

      if (this.form.Type.match(/CHAT_APPS/i)) {
        priceRange = pricingPerCountry.reduce(
          (acc, val) => {
            if (countries[val.Country]) {
              const msgs = countries[val.Country];

              // todo: price for whatsapp, viber, how about others?
              // todo: for voice
              let price = 0;

              if (this.form.Type === 'CHAT_APPS') {
                // the old pricing
                if (val['WA-HSM'] && ch.match(/WA/i)) {
                  price = parseFloat(val['WA-HSM']);
                }

                // the new pricing (business initiated)
                if (val['WA-CBP-B'] && ch.match(/WA/i)) {
                  price = parseFloat(val['WA-CBP-B']);
                }

                if (ch.match(/VB/i)) {
                  if (
                    messageType
                    && messageType === 'text'
                    && val['VB-OUT-TXT']
                  ) {
                    price = parseFloat(val['VB-OUT-TXT']);
                  }

                  if (
                    messageType
                    && messageType.match(/image|rich_media/i)
                    && val['VB-OUT-MEDIA']
                  ) {
                    price = parseFloat(val['VB-OUT-MEDIA']);
                  }
                }
              }

              const total = msgs * price;
              acc.total += total;
              acc.currency = val.Currency;
            }

            return acc;
          },
          {
            currency: 'EUR',
            total: 0,
          },
        );

        // todo: remove this once viber has calculated price
        if (
          (this.form.Type === 'CHAT_APPS' && ch.match(/VB/i))
          || this.form.Type === 'VOICE'
        ) {
          priceRange.ignore = true;
        }
      }

      if (this.form.Type.match(/SMS/)) {
        const segments = this.form.Message.smsSegments;

        priceRange = pricingPerCountry.reduce(
          (acc, val) => {
            if (countries[val.Country]) {
              const msgs = countries[val.Country];

              acc.currency = val.Currency;
              acc.totalMin += msgs * val.MinPrice * segments.min; // eslint-disable-line
              acc.totalMax += msgs * val.MaxPrice * segments.max; // eslint-disable-line
              acc.totalContractMin
                += msgs * val.MinPriceContract * segments.min; // eslint-disable-line
              acc.totalContractMax
                += msgs * val.MaxPriceContract * segments.max; // eslint-disable-line
              acc.contractCurrency = val.ContractCurrency;
            }

            return acc;
          },
          {
            totalMin: 0,
            totalMax: 0,
            currency: 'EUR',
            totalContractMin: 0,
            totalContractMax: 0,
            contractCurrency: 'EUR',
          },
        );
      }

      this.updateForm({ PriceRange: priceRange });
    },

    async nextStep() {
      const { selected, form } = this;
      const { Type: type } = form;
      this.updateForm({ Message: selected });

      const { action } = selected;

      const templateSelected = action === 'template' ? 'YES' : 'NO';

      const { track } = this.$telemetry;

      track('message_composed', { uiArea: 'multichannel_sender_campaign_page', templateSelected });

      const ch = this.getMainChannel();

      // todo: change this code once they have a calculated price
      if (type === 'CHAT_APPS' && ch.match(/LN/i) || type === 'VOICE') {
        this.updateForm({
          PriceRange: { total: 0, ignore: true, currency: 'EUR' },
        });
        this.$emit('change-process-index', 3);
        return;
      }

      try {
        this.loadingPricing = true;
        await this.fetchPricing();

        this.calculatePriceRange(this.pricing);

        this.$emit('change-process-index', 3);
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error('Unable to get price.');
      } finally {
        this.loadingPricing = false;
      }
    },
  },
};
</script>
<style lang="scss">
.compose-message-container {
  height: calc(100vh - 215px);
  overflow-y: auto;
}

.msg {
  .bg-grey-1 {
    background-color: #f0f1f5;
  }

  .bg-grey-2 {
    background-color: #f1f5f8;
  }
}

.test-voice-notif {
  background-color: #f1f6ff;
  .el-notification__title {
    font-weight: 500;
    font-size: 13px;
  }

  .el-notification__content {
    font-size: 12px;
  }
}
</style>
