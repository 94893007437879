<template>
  <div class="mt-5 w-3/4">
    <!-- <div v-if="isPrepaid"
      class="text-black text-xs p-5 bg-yellow-lightest border border-solid border-yellow rounded leading normal">
      Your balance
      <span class="font-semibold">{{ getBalanceDisplay }} </span>
        is not enough to send all your messages, please
      <a href="/messaging/payment/top-up"
        class="text-blue no-underline hover:underline">
        top up now
      </a>.
    </div> -->
    <i18n
      v-if="isPrepaid"
      path="mcs.send.low_balance[0]"
      tag="div"
      class="text-black text-xs p-5 bg-yellow-lightest border border-solid border-yellow rounded leading normal"
    >
      <template #balance>
        <span class="font-semibold">{{ getBalanceDisplay }} </span>
      </template>
      <template #topup>
        <a href="/messaging/payment/top-up"
          class="text-blue no-underline hover:underline">
          {{ $t('mcs.topup_now') }}
        </a>.
      </template>
    </i18n>
    <!-- <div v-if="isPostpaid"
      class="text-black text-xs p-5 bg-yellow-lightest border border-solid border-yellow rounded leading-normal">
      Your balance and credit limit is not enough to send all your messages, please contact your
      account manager or email
      <a :href="`mailto:${supportEmail}`"
        target="_blank"
        class="text-blue no-underline hover:underline">
        {{ supportEmail }}
      </a>.
    </div> -->

    <i18n
      v-if="isPostpaid"
      path="mcs.send.low_balance[1]"
      tag="div"
      class="text-black text-xs p-5 bg-yellow-lightest border border-solid border-yellow rounded leading-normal"
    >
      <template #supportLink>
        <a :href="`mailto:${supportEmail}`"
        target="_blank"
        class="text-blue no-underline hover:underline">
        {{ supportEmail }}
      </a>.
      </template>
    </i18n>
  </div>
</template>

<script>
export default {
  name: 'send_low_balance_alert',

  props: {
    isPrepaid: {
      type: Boolean,
      default: false,
    },

    isPostpaid: {
      type: Boolean,
      default: false,
    },

    balance: {
      type: Number,
      default: 0,
    },

    currency: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      supportEmail: this.appConfig.supportEmail,
    };
  },

  computed: {
    getBalanceDisplay() {
      const d = this.$vars.PRICE_DECIMAL;
      const l = this.$vars.LOCALE;

      if (this.balance && this.currency) {
        return `${this.currency} ${this.$wcsender.formatPrice(this.balance, d, l)}`;
      }

      return '';
    },
  },
};
</script>
