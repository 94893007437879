<template>
  <div class="flex flex-col">
    <el-row
      v-if="step === 'SEND'"
      class="w-full p-6 send-step-container"
    >
      <el-col :span="12">
        <SendForm
          :user="user"
          :data="sendData"
          :mcs-form="form"
          :timezones="timezones"
          :form-type="form.Type"
          :is-valid-sched="isValidSched()"
          :has-schedule="form.Type.match(/SMS|VOICE/) || isAllowedChatappsToScheduleCampaign()"
          @update-campaign-details="updateCampaignDetails"
        />

        <LowBalanceAlert
          v-if="isPostPaidLowBalance() || isPrepaidLowBalance()"
          :is-prepaid="isPrepaidLowBalance()"
          :is-postpaid="isPostPaidLowBalance()"
          :balance="userBalance.CreditsAmount"
          :currency="userBalance.Currency"
        />

      </el-col>
      <el-col
        :span="12"
        class="send-details">
        <div v-if="form.Type === 'VOICE'">
          <VoiceCampaignPreview
            :variables="variables || []"
            :total-voice-msgs="getTotals().totalVoiceMsgs"
            :valid-total="getTotals().totalValidRecipients"
            :valid-percentage="getTotals().validPercentage"
            :send-now="sendData.sendNow"
            :schedule-date="sendData.scheduleDate"
            :estimated-process-time="getEstimatedTimeDisplay()"
            :schedule-timezone-name="getTimeZone(sendData.scheduleTimezone).Combined"
            :form="form"
          />
        </div>
        <div v-else>
          <Details
            :messaging-type="form.Type"
            :sub-account="form.SubAccountId"
            :channels="channels"
          />
          <Recipients
            :total="getTotals().totalValidRecipients"
            :valid-percentage="getTotals().validPercentage"
          />
          <MessagePreview
            :form="form"
            :has-whats-app="hasWhatsApp()"
            :message-type="form.Type"
            :header="form.Message.header"
            :footer="form.Message.footer"
            :message="form.Message.message"
            :wa-buttons="form.Message.waButtons"
            :image-inputs="form.Message.imageInputs"
            :button-inputs="form.Message.buttonInputs"
            :channel="getMainChannel()"
            :custom-fields="form.Message.customFields"
            :variables="variables"
            :sms-parts="form.Message.smsParts ? form.Message.smsParts : 0"
            :total-sms="getTotals().totalSmsMsgs"
            :total-ca-msgs="getTotals().totalCaMsgs"
          />
          <EstimatedCost v-if="showCost" :estimated-cost="getEstimatedCostDisplay()" />
          <ProcessWhen
            :send-now="sendData.sendNow"
            :schedule-date="sendData.scheduleDate"
            :estimated-process-time="getEstimatedTimeDisplay()"
            :schedule-timezone="sendData.scheduleTimezone"
          />
        </div>
      </el-col>
    </el-row>
    <div class="flex-shrink p-6 border-t border-grey-lighter text-right">
      <div v-if="!canSendCampaigns" class="text-red-light mb-2 text-sm">
        {{ $t('mcs.subscription_warning') }}
      </div>
      <el-button
        type="text"
        class="mr-3"
        @click="back"
      >
        Back
      </el-button>
      <el-button
        @click="$emit('discard')"
      >
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        type="primary"
        class="w-1/6"
        :disabled="disabledSubmit() || !canSendCampaigns"
        @click="submitCampaign()"
        id="mcs-submit-campaign"
        :loading="loading">
        {{ $t('actions.submit') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CampaignDetailsMixin from '@/mixins/campaign-details';
import SendForm from './partials/Form.vue';
import Details from './partials/Details.vue';
import Recipients from './partials/Recipients.vue';
import MessagePreview from './partials/MessagePreview.vue';
import EstimatedCost from './partials/EstimatedCost.vue';
import ProcessWhen from './partials/ProcessWhen.vue';
import LowBalanceAlert from './partials/LowBalanceAlert.vue';
import VoiceCampaignPreview from './partials/VoiceCampaignPreview.vue';


export default {
  name: 'send',

  components: {
    SendForm,
    Details,
    Recipients,
    MessagePreview,
    EstimatedCost,
    ProcessWhen,
    LowBalanceAlert,
    VoiceCampaignPreview,
  },

  mixins: [CampaignDetailsMixin],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sendData: {
        sendNow: true,
        campaignName: '',
        clientMsgId: '',
        scheduleDate: '',
        scheduleTimezone: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
      canSendCampaigns: 'user/getCanSendCampaigns',
      userBalance: 'user/getUserBalance',
      timezones: 'common/getTimeZones',
      step: 'getStep',
      form: 'getForm',
      hasPricingEntitlement: 'user/getPricingEntitlementAccess',
      hasMcsAccess: 'user/getMcsAccess',
      shouldHideCost: 'user/getHideCostFlag',
    }),

    showCost() {
      return this.hasPricingEntitlement && !this.shouldHideCost;
    },
  },

  created() {
    // checking balance is for chat apps
    if (this.form.Type.match(/SMS/)) {
      this.fetchBalance();
    }

    if (this.form.CampaignDetails) {
      this.sendData = this.form.CampaignDetails;
    }
  },

  methods: {
    ...mapActions({
      getBalance: 'user/getBalance',
    }),

    ...mapMutations({
      updateForm: 'UPDATE_FORM',
      setStep: 'SET_STEP',
    }),

    back() {
      this.$emit('change-process-index', 2);
    },

    isAllowedChatappsToScheduleCampaign() {
      return this.form.Type.match(/CHAT_APPS/) && this.hasMcsAccess;
    },

    updateCampaignDetails(sform = {}) {
      this.sendData = { ...this.sendData, ...sform };
    },

    async fetchBalance() {
      await this.getBalance().catch((err) => {
        this.$showError(this, err);
      });
    },

    isPostPaidLowBalance() {
      if (this.form.Type.match(/SMS/)) {
        const { CreditsAmount, OverDraftAuthorized } = this.userBalance;
        const { PriceRange: pr } = this.form;

        const { IsPostPaid } = this.user;
        const RemainingBal = CreditsAmount - pr.totalMin;

        return IsPostPaid
          && (CreditsAmount < OverDraftAuthorized || RemainingBal < OverDraftAuthorized);
      }

      return false;
    },

    isPrepaidLowBalance() {
      if (this.form.Type.match(/SMS/)) {
        const { CreditsAmount } = this.userBalance;
        const { PriceRange: pr } = this.form;

        const { IsPostPaid } = this.user;
        const RemainingBal = CreditsAmount - pr.totalMin;

        return (!IsPostPaid && RemainingBal <= 0);
      }

      return false;
    },

    submitCampaign() {
      const { clientMsgId } = this.sendData;
      if (clientMsgId && clientMsgId.length) {
        this.sendData.clientMsgId = clientMsgId.replace(/=HYPERLINK\(/ig, '');
      }

      this.updateForm({ CampaignDetails: this.sendData });

      this.$emit('submit');
    },

    getTimeZone(tzId) {
      const { TimeZoneId } = this.user;
      const tzn = this.timezones.find(v => v.TimeZoneName === 'Asia/Singapore');

      const tznById = this.timezones.find(v => v.TimeZoneId === Number(tzId));
      const tznByUser = this.timezones.find(v => v.TimeZoneId === Number(TimeZoneId));

      // if timezone is not selected ,use user set timezone
      // if both is empty, just use SG as default
      return tznById || tznByUser || tzn;
    },

    isValidSched() {
      const {
        scheduleDate,
      } = this.sendData;

      const now = moment();

      const diffInMins = moment(scheduleDate).diff(now, 'minutes');

      // eslint-disable-next-line no-restricted-globals
      return !isNaN(diffInMins) && diffInMins > 10;
    },

    disabledSubmit() {
      const {
        campaignName,
        clientMsgId,
        sendNow,
        scheduleDate,
        scheduleTimezone,
      } = this.sendData;

      const {
        Type: type,
        Recipients: rp,
        PriceRange: pr,
        Message: message,
      } = this.form;

      if (
        !campaignName
        || !this.form
        || (clientMsgId && clientMsgId.length > 50)
        || (type.match(/SMS|CHAT_APPS|VOICE/) && !sendNow && (!scheduleDate || !scheduleTimezone || (scheduleDate && !this.isValidSched())))
        || (type.match(/SMS/) && (this.isPostPaidLowBalance() || this.isPrepaidLowBalance()))
        || !rp
        || !message
        || (type.match(/SMS/) && !pr)
        || (type.match(/SMS/) && pr && (!pr.totalMin || !pr.totalContractMin))
        || this.getTotals().totalValidRecipients < 1
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>
<style lang="scss">
  .send-step-container {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }
</style>
