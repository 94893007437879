<template>
  <div class="ma-recipients">
    <div class="flex flex-col">
      <h3 class="align-middle">Add recipients</h3>
      <div class="mt-6">
        <el-radio-group
          v-model="tabSelected"
          size="medium"
          class="recip-radio"
          text-color="white"
          fill="#2a2A2A"
          @click.native="(ev) => { checkChanges(ev) }"
        >
          <el-radio-button
            label="add_contacts"
            id="add_contacts"
          >
            Select existing contacts
          </el-radio-button>
          <el-radio-button
            label="upload_contacts"
            id="upload_contacts"
          >
            Upload file
          </el-radio-button>
        </el-radio-group>
      </div>
      <div
        v-if="tabSelected === 'add_contacts'"
        class="mt-6"
      >
        <div class="flex flex-row justify-between items-center">
          <h3>{{ getAllTotalContacts.toLocaleString() }} total recipients</h3>
          <div class="text-right">
            <el-dropdown
              @command="handleDropdownCommand"
              size="medium"
              trigger="click"
            >
              <el-button type="primary">
                Add recipients<i class="el-icon-arrow-down el-icon--right font-medium"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown" class="recip-dropdown">
                <el-dropdown-item command="contacts">
                  <i class="icon-novo-user align-middle mr-1" />
                  Select contacts
                </el-dropdown-item>
                <el-dropdown-item command="groups">
                  <i class="icon-novo-users align-middle mr-1" />
                  Select groups
                </el-dropdown-item>
                <el-dropdown-item command="number">
                  <i class="icon-novo-dialpad align-middle mr-1" />
                  Type a number
                </el-dropdown-item>
                <el-dropdown-item divided command="directory">
                  <div class="flex items-center" :style="{lineHeight: '20px'}">
                    <i class="icon-novo-directory align-middle mr-1 inline-block flex-shrink" />
                    <div class="flex-auto ml-2">
                      Select contacts <br /> from Company Directory
                    </div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div v-if="(!contactsSelected.contacts.length && !contactsSelected.groups.length
          && !contactsSelected.number.length && !contactsSelected.directory.length)"
          class="bg-white rounded relative h-full w-full border border-grey-lighter mt-6 p-6">
          <div
            class="flex items-center justify-center h-full flex-col adjust-height"
            :style="{ minHeight: 'calc(100vh - 450px)' }"
          >
            <div>
              <Empty />
            </div>
            <p class="mt-6 text-base font-semibold">No recipients added</p>
            <p class="mt-4 leading-normal text-sm mt-4">
              Start by adding an existing contact from your Connect list and groups <br /> or select an existing 8x8
              contact
              from your company directory. <br />
              Up to 10,000 contacts can be selected, if you need more, upload a file.
            </p>
          </div>
        </div>
        <div v-else>
          <div
            v-if="contactsSelected.contacts.length || contactsSelected.groups.length || contactsSelected.number.length">
            <h4 class="mb-6 mt-6"><i class="icon-novo-user-groups-line align-middle text-xl font-semibold mr-1" /> {{
              getConnectTotalContacts.toLocaleString() }} recipients from 8x8 Connect</h4>
            <el-row :gutter="24">
              <el-col
                v-if="contactsSelected.groups.length"
                :span="getColSpan()"
              >
                <AddedContactsList
                  :contacts="contactsSelected.groups"
                  :has-directory="contactsSelected.directory.length"
                  :limit="displayLimit"
                   type="groups"
                  :total="contactsSelected.groups.length"
                  :groups-contacts-total="getGroupsTotalContacts"
                  @view-all-contacts="(type) => {viewAllContacts(type)}"
                  @remove-all-contacts="(type) => {removeAllContacts(type)}"
                  @remove-contact="(type, contact) => { removeContact(type, contact) }" />
              </el-col>
              <el-col
                v-if="contactsSelected.contacts.length"
                :span="getColSpan()"
              >
                <AddedContactsList
                  :contacts="contactsSelected.contacts"
                  :has-directory="contactsSelected.directory.length"
                  :limit="displayLimit"
                   type="contacts"
                  :total="contactsSelected.contacts.length"
                  @view-all-contacts="(type) => {viewAllContacts(type)}"
                  @remove-all-contacts="(type) => {removeAllContacts(type)}"
                  @remove-contact="(type, contact) => { removeContact(type, contact) }" />
              </el-col>
              <el-col
                v-if="contactsSelected.number.length"
                :span="getColSpan()"
              >
                <AddedContactsList
                  :contacts="contactsSelected.number"
                  :has-directory="contactsSelected.directory.length"
                  :limit="displayLimit" type="number"
                  :total="contactsSelected.number.length"
                   @view-all-contacts="(type) => {viewAllContacts(type)}"
                  @remove-all-contacts="(type) => {removeAllContacts(type)}"
                  @remove-contact="(type, contact) => {removeContact(type, contact) }" />
              </el-col>
            </el-row>
          </div>
          <div v-if="contactsSelected.directory.length" class="mt-10 mb-10">
            <h4 class="mb-6">
              <i class="icon-novo-directory align-middle text-xl font-medium mr-1" />
              {{ contactsSelected.directory.length.toLocaleString() }} company contacts
            </h4>
            <AddedContactsList
              :contacts="contactsSelected.directory"
              :limit="displayLimit"
              type="directory"
              :total="contactsSelected.directory.length"
              @view-all-contacts="(type) => {viewAllContacts(type)}"
              @remove-all-contacts="(type) => {removeAllContacts(type)}"
              @remove-contact="(type, contact) => { removeContact(type, contact) }"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <SetCustomFields
          v-if="contactsUploaded"
          :meta="contactsUploaded"
          :form="form"
          @update-file-info="updateUploadFileInfo"
          @discard="()=> { checkChanges(null, true) }"
        />
        <UploadFile
          v-else
          @update-file-info="updateFileInfo"
        />
      </div>
    </div>
    <ContactModal
      v-if="dropdownSelected && ['groups', 'contacts', 'directory'].includes(dropdownSelected)"
      :type="dropdownSelected"
      :has-search="['groups', 'contacts'].includes(dropdownSelected)"
      :has-filter="['directory'].includes(dropdownSelected)"
      :modal-visible="modalVisible"
      :should-not-fetch="shouldNotFetch"
      :added-contacts="contactsSelected[dropdownSelected]"
      @add-to-main-list="(data, toBeRemoved, type, shouldNotFetch) => { addToMainList(data, toBeRemoved, type, shouldNotFetch)}"
      @close-modal="handleCloseModal"
    />

    <PhoneNumberModal
      v-if="dropdownSelected && ['number'].includes(dropdownSelected)"
      :type="dropdownSelected"
      :form="form"
      :modal-visible="modalVisible"
      :should-not-fetch="shouldNotFetch"
      :added-contacts="contactsSelected[dropdownSelected]"
      :country-details="getDefaultCountry()"
      @add-to-main-list="(data, toBeRemoved, type, shouldNotFetch) => { addToMainList(data, toBeRemoved, type, shouldNotFetch)}"
      @close-modal="handleCloseModal"
    />

    <Confirmation
      :visible="confirmationVisible"
      :type="tabSelected"
      @cancel="(type) => {keepRecipients(type)}"
      @discard="(type) => {discardRecipients(type)}"
      @keep="(type) => { keepRecipients(type) }"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import PhoneNumberMixin from '@/mixins/phone-number';

import { mapGetters, mapMutations } from 'vuex';

import ContactModal from './AddContacts/ContactModal.vue';

import PhoneNumberModal from './AddContacts/PhoneNumberModal.vue';

import Empty from '../Empty.vue';
import Confirmation from './Confirmation.vue';
import AddedContactsList from './AddedContactsList.vue';

import UploadFile from './UploadContacts/UploadFile.vue';
import SetCustomFields from './UploadContacts/SetCustomFields.vue';

export default {
  name: 'Recipients',

  components: {
    PhoneNumberModal,
    Empty,
    ContactModal,
    AddedContactsList,
    Confirmation,
    UploadFile,
    SetCustomFields,
  },

  mixins: [PhoneNumberMixin],

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    step: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      tabSelected: 'add_contacts',
      dropdownSelected: '',
      modalVisible: false,
      shouldNotFetch: false,
      confirmationVisible: false,
      contactsSelected: {
        contacts: [],
        groups: [],
        number: [],
        directory: [],
      },
      fromSetFields: false,
      contactsUploaded: undefined,
      displayLimit: 20,
      contactTypes: ['contacts', 'groups', 'number', 'directory'],
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),

    getConnectTotalContacts() {
      const { contacts, number } = this.contactsSelected;
      return [contacts.length, this.getGroupsTotalContacts, number.length].reduce((a, b) => a + Number(b), 0);
    },

    getGroupsTotalContacts() {
      const { groups } = this.contactsSelected;

      return groups.reduce((a, b) => a + Number(b.contacts), 0);
    },

    getAllTotalContacts() {
      const { directory } = this.contactsSelected;
      return this.getConnectTotalContacts + directory.length;
    },
  },

  created() {
    // if main form data exists, update local data
    this.tabSelected = 'upload_contacts';

    const { recipients } = this.form;
    if (recipients && recipients.source && recipients.meta) {
      if (recipients.source.match(/INPUT/i)) {
        this.tabSelected = 'add_contacts';
        this.contactsSelected = { ...recipients.meta };
      }

      if (recipients.source.match(/UPLOAD/i)) {
        this.tabSelected = 'upload_contacts';
        this.contactsUploaded = { ...recipients.meta };
      }
    }
  },

  watch: {
    contactsSelected: {
      handler(contacts) {
        if (!this.contactsSelected.contacts.length
        && !this.contactsSelected.groups.length
        && !this.contactsSelected.number.length
        && !this.contactsSelected.directory.length) {
          this.$emit('update-form', {
            recipients: null,
            summary: null,
            contactsFileId: '',
          });
        } else {
          this.$emit('update-form', {
            recipients: {
              source: 'INPUT',
              meta: {
                ...contacts,
                allowDuplicates: false,
              },
            },
            summary: null,
            contactsFileId: '',
          });
        }
      },
      deep: true,
    },

    contactsUploaded: {
      handler(fileDetails) {
        if (!fileDetails) {
          this.$emit('update-form', {
            recipients: null,
            summary: null,
            contactsFileId: '',
          });
        } else {
          this.$emit('update-form', {
            recipients: {
              source: 'UPLOAD',
              meta: {
                ...fileDetails,
              },
            },
            summary: null,
            contactsFileId: '',
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations({
      setContacts: 'contacts/SET_CONTACTS',
      setContactGroups: 'contacts/SET_CONTACT_GROUPS',
      setContactDirectories: 'contacts/SET_CONTACT_DIRECTORIES',
    }),

    checkChanges(ev, fromSetFields = false) {
      const hasRecipients = this.contactTypes.some(v => this.contactsSelected[v].length);
      this.fromSetFields = fromSetFields;
      // avoid clicking selected radio button
      if (ev && ev.target) {
        const { parentNode } = ev.target;

        if (parentNode.id === this.tabSelected) return;
        if (ev.preventDefault) ev.preventDefault();
      }

      if (hasRecipients || this.contactsUploaded) {
        this.confirmationVisible = true;
      } else {
        this.tabSelected = this.tabSelected === 'add_contacts' ? 'upload_contacts' : 'add_contacts';
      }
    },

    discardRecipients(type) {
      this.tabSelected = type === 'add_contacts' ? 'upload_contacts' : 'add_contacts';

      if (this.fromSetFields) {
        this.tabSelected = 'upload_contacts';
      }

      // reset all
      this.contactTypes.forEach((t) => {
        this.contactsSelected[t] = [];
      });
      this.contactsUploaded = undefined;

      this.confirmationVisible = false;
      this.fromSetFields = '';
    },

    keepRecipients(type) {
      this.tabSelected = type;
      this.confirmationVisible = false;
      this.fromSetFields = '';
    },

    handleDropdownCommand(command) {
      this.dropdownSelected = command;
      this.shouldNotFetch = false;
      this.modalVisible = true;
    },

    handleCloseModal() {
      this.shouldNotFetch = false;
      this.modalVisible = false;
      this.dropdownSelected = '';
      this.setContacts(null);
      this.setContactGroups(null);
      this.setContactDirectories(null);
    },

    addToMainList(contacts = [], toBeRemoved, type = '') {
      if (['contacts', 'groups', 'directory'].includes(type)) {
        this.contactsSelected[type] = _.unionBy(this.contactsSelected[type], contacts, 'id');

        if (toBeRemoved.length) {
          this.contactsSelected[type] = this.contactsSelected[type].filter(v => !toBeRemoved.some(tr => tr.id === v.id));
        }
      }

      if (['number'].includes(type)) {
        this.contactsSelected[type] = _.union(this.contactsSelected[type], contacts);
        if (toBeRemoved.length) {
          this.contactsSelected[type] = this.contactsSelected[type].filter(v => !toBeRemoved.some(tr => tr === v));
        }
      }
    },

    viewAllContacts(type = '') {
      this.shouldNotFetch = true;
      this.modalVisible = true;
      this.dropdownSelected = type;
    },

    removeAllContacts(type = '') {
      let label = '';
      switch (type) {
        case 'contacts': {
          label = 'selected contacts';
          break;
        }
        case 'groups': {
          label = 'selected contact groups';
          break;
        }
        case 'number': {
          label = 'typed phone numbers';
          break;
        }
        case 'directory': {
          label = 'selected company contacts';
          break;
        }
        default:
      }

      this.$confirm(`This will remove all ${label}?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        try {
          this.contactsSelected[type] = [];
        } catch (err) {
          this.$showError(this, err);
        }
      });
    },

    removeContact(type, contact) {
      switch (type) {
        case 'contacts': {
          this.contactsSelected[type] = this.contactsSelected[type].filter(v => v.id !== contact.id);
          break;
        }
        case 'groups': {
          this.contactsSelected[type] = this.contactsSelected[type].filter(v => v.id !== contact.id);
          break;
        }
        case 'number': {
          this.contactsSelected[type] = this.contactsSelected[type].filter(v => v !== contact);
          break;
        }
        case 'directory': {
          this.contactsSelected[type] = this.contactsSelected[type].filter(v => v.id !== contact.id);
          break;
        }
        default:
      }
    },

    getColSpan() {
      let count = 0;

      ['contacts', 'number', 'groups'].forEach((v) => {
        if (this.contactsSelected[v].length) {
          count += 1;
        }
      });

      const colspans = {
        0: 0,
        1: 12,
        2: 12,
        3: 8,
      };

      return colspans[count];
    },

    updateFileInfo(fileInfo = {}) {
      if (!Object.keys(fileInfo).length) {
        this.contactsUploaded = undefined;
        return;
      }

      // prepare columnLabels for setting custom fields
      const columnLabels = {
        MSISDN: -1,
        FirstName: -1,
        LastName: -1,
        ClientMessageId: -1,
      };

      this.contactsUploaded = {
        ...fileInfo,
        columnLabels,
        country: '',
        callingCode: '',
        allNumbersLocal: false,
        allowDuplicates: false,

      };
    },

    updateUploadFileInfo(info) {
      this.contactsUploaded = {
        ...this.contactsUploaded,
        ...info,
      };
    },
  },
};
</script>
<style>
  .recip-radio .el-radio-button__inner {
    background-color: white;
    color: #2a2a2a;
    border: 1px solid #e7e7e7;
  }

  .recip-radio .el-radio-button .el-radio-button--medium .is-active,
  .recip-radio .el-radio-button .el-radio-button--medium .is-active span {
    pointer-events: none;
    cursor: not-allowed;
  }

  .recip-dropdown .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #f1f1f1;
    color: #2a2a2a;
  }

  .adjust-height {
    height: calc(100vh - 450px);
  }
</style>
