<template>
  <div class="mb-5 w-4/5 custom-fields">
    <h5 class="text-black font-medium mb-2">
      {{ $t('mcs.messages.chat_apps.custom_fields[0]') }}
    </h5>
    <p class="leading-tight text-grey-darker mb-3 text-xs">
      {{ $t('mcs.messages.chat_apps.custom_fields[1]', { dynamicFields: getPartialFields(dynamicFields) }) }}
      {{ dynamicFields.length > 2 ? ', etc' : '' }}.
      {{ $t('mcs.messages.chat_apps.custom_fields[2]') }}
    </p>
    <el-table :loading="loading" :data="fields" class="w-full text-xs" row-key="field" border>
      <el-table-column :label="$t('column_labels.dynamic_fields')">
        <template slot-scope="scope">
          <span class="text-xs">
            {{ scope.row.fieldText }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column_labels.variables')">
        <template slot-scope="scope">
          <div v-if="scope.row.value
          && !variables.includes(scope.row.value)
          && scope.row.type !== 'dynamic'" class="w-full flex outline-none">
            <div class="flex-auto text-xs flex">
              <img v-if="scope.row.headerType && scope.row.headerType.match(/video|document/g) && scope.row.value"
                :src="urlResolver(bgUrl, require(`@/assets/media/${getLogo(scope.row.headerType, scope.row.value)}`))"
                :style="{width: '25px', height: '25px'}" class="mr-1 align-middle" />
              <div v-if="scope.row.headerType && scope.row.headerType.match(/image/g) && scope.row.value"
                :style="{width: '25px', height: '25px'}">
                <ImageDisplay :file-url="scope.row.value" />
              </div>
              <a v-if="scope.row.headerType && scope.row.headerType.match(/video|image|document/g)"
                class="ml-1 no-underline hover:underline text-blue block truncate cursor-pointer"
                @click.prevent="download(scope.row)" :style="{ maxWidth:'210px'}">
                {{ shortenFileUrl(scope.row.value) }}
              </a>
              <span v-else class="truncate">{{ scope.row.value }} </span>
            </div>
            <div class="flex-shrink">
              <i class="el-icon-close el-icon--right cursor-pointer"
                @click="remove(scope.$index, scope.row)" />
            </div>
          </div>

          <span v-if="scope.row.value
          && variables.includes(scope.row.value)
          && scope.row.type === 'dynamic'" class="item-tag">
            {{ scope.row.value }}
            <i class="el-icon-close el-icon--right"
              @click="remove(scope.$index, scope.row)" />
          </span>

          <span v-if="!scope.row.value && scope.row.headerType && scope.row.headerType === 'video'"
            class="flex-auto text-grey text-xs cursor-pointer block w-full" @click="openModal(scope.row, scope.$index)">
            {{ $t('mcs.messages.chat_apps.custom_fields[3]') }}
          </span>

          <el-dropdown
            v-if="!scope.row.value  && ((scope.row.headerType && scope.row.headerType !== 'video') || !scope.row.headerType)"
            size="medium" trigger="click" placement="top-start" class="outline-none min-w-full" @command="assign">
            <div class="w-full flex outline-none cursor-pointer">
              <div v-if="scope.row.headerType && scope.row.headerType.match(/image|document/g)"
                class="flex-auto text-grey text-xs">
                <span> {{ $t('mcs.messages.chat_apps.custom_fields[4]') }} </span>
              </div>
              <div v-else-if="scope.row.headerType && scope.row.headerType.match(/location/g)"
                class="flex-auto text-grey text-xs">
                <span v-if="scope.row.fieldType === 'latlong'">{{ $t('mcs.messages.chat_apps.custom_fields[5]') }}</span>
                <span v-else>{{ $t('validations.enter_value') }}</span>
              </div>
              <div v-else class="flex-auto text-grey text-xs">
                {{ $t('mcs.messages.chat_apps.custom_fields[6]') }}
              </div>
              <div class="flex-shrink">
                <i class="el-icon-arrow-down el-icon--right">
                </i>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown" class="cf-dropdown">
              <el-dropdown-item v-for="i in variables" :key="i"
                :command="`${i}|${scope.$index}|${scope.row.headerType || 'none'}|${scope.row.buttonType || 'none'}`"
                :disabled="isAssigned(i, scope.row.headerType)">
                <span class="text-xs">{{ i }}</span>
              </el-dropdown-item>
              <hr v-if="variables.length" class="mx-4 mt-2 mb-5 h-px bg-grey w-48">
              <div class="mx-2 mt-2">
                <el-input v-if="(scope.row.headerType && scope.row.headerType !== 'video') || !scope.row.headerType"
                  :placeholder="getPlaceHolder(scope.row.headerType, scope.row.fieldType || '')" size="small" v-model="inputValue[scope.$index]"
                  @blur="ev => {
                    add(ev, scope.$index, scope.row);
                  }" />
                <div>
                  <el-button v-if="scope.row.headerType && scope.row.headerType.match(/image|document|location/g) && !['name', 'address'].includes(scope.row.fieldType)"
                    size="mini" type="text" class="text-xs mt-2 font-normal"
                    @click="openModal(scope.row, scope.$index)">
                    {{
                    !scope.row.headerType.match(/location/g)
                    ? $t('mcs.messages.chat_apps.custom_fields[7]')
                    : $t('mcs.messages.chat_apps.custom_fields[8]')
                    }}
                  </el-button>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <p v-if="source === 'UPLOAD'" class="mt-3 text-xs">
      {{ $t('mcs.messages.chat_apps.custom_fields[9]') }}
      <a href="/" class="text-blue hover:text-blue-darker no-underline" @click.prevent="$emit('modify-custom-fields')">
        {{ $t('mcs.messages.chat_apps.custom_fields[10]') }}</a>
    </p>
    <UploadMedia v-if="modal.action === 'upload'" :data="modal" :visible="visible" @apply-data="applyMediaData"
      @close-modal="closeModal" />
    <LocationMedia v-if="modal.action === 'location'" :data="modal" :visible="visible" @apply-data="applyLocationData"
      @close-modal="closeModal" />
  </div>
</template>
<script>
import _ from 'lodash';
import { hasBrackets, urlResolver, removeUnicodeChars } from '@/utils/common';
import MediaMixin from '@/mixins/media';
import ImageDisplay from '@/components/ImageDisplay.vue';
import { mapActions } from 'vuex';
import UploadMedia from './UploadMedia.vue';
import LocationMedia from './LocationMedia.vue';

export default {
  name: 'message_ca_custom_fields',

  components: {
    UploadMedia,
    LocationMedia,
    ImageDisplay,
  },

  mixins: [MediaMixin],

  props: {
    fields: {
      type: Array,
      default: () => [],
    },

    variables: {
      type: Array,
      default: () => [],
    },

    source: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: false,
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
      inputValue: {},
      visible: false,
      headers: ['text', 'image', 'location', 'document', 'video'],
      modal: {
        action: 'upload',
        title: '',
        index: -1,
        type: '',
      },
    };
  },

  computed: {
    dynamicFields() {
      return this.fields.slice(0)
        .filter(v => v.field && Boolean(hasBrackets(v.field)));
    },
  },

  methods: {
    ...mapActions({
      saveFile: 'common/saveFile',
      downloadFileUrl: 'common/downloadFileUrl',
    }),

    getPartialFields(b) {
      const f = b.slice(0).reduce((a, k) => {
        a.push(k.field.replace(/h_|s_/, '').trim());
        return a;
      }, []);

      const fields = [...new Set(f)].slice(0, 2);

      return fields.join(' ');
    },

    getPlaceHolder(type, fieldType = '') {
      if (type.match(/location/g)) {
        if (fieldType === 'latlong') {
          return this.$t('mcs.messages.chat_apps.custom_fields[11]');
        }
        // for name and address
        return this.$t('validations.enter_value');
      }

      if (type.match(/video/g)) {
        return this.$t('mcs.messages.chat_apps.custom_fields[13]');
      }

      return this.$t('mcs.messages.chat_apps.custom_fields[12]');
    },

    isAssigned(v, headerType) {
      if (headerType) {
        return this.variables.some(vx => vx === v)
            && this.fields.some(cv => cv.value && cv.value === v && cv.headerType);
      }

      return this.variables.some(vx => vx === v)
          && this.fields.some(cv => cv.value && cv.value === v && !cv.headerType);
    },

    add(ev, index, row) {
      const { value: v } = ev.target;
      const { headerType, buttonType, fieldType } = row;

      let value = v;

      const urlRegex = new RegExp(this.$vars.URL_REGEX, 'g');
      const latlongRegex = new RegExp(this.$vars.LAT_LONG_REGEX, 'g');

      if (!value.trim()) return;

      if (value.trim()) {
        if (headerType && headerType.match(/document|image/g)) {
          // eslint-disable-next-line no-useless-escape
          const ext = value.trim().match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
          const isUrl = value.trim().match(urlRegex);

          if (!isUrl || !ext) {
            this.$message.error(this.$t('mcs.messages.chat_apps.custom_fields[14]'));
            return;
          }

          if (ext && ext.length > 1
            && !this.mediaInfo(headerType).supportedFilesTxt.includes(ext[1].toLowerCase())) {
            if (headerType === 'document') {
              // this.$message.error(this.$t('mcs.messages.chat_apps.custom_fields[15]'));
              this.$notify.error({
                title: this.$t('mcs.messages.chat_apps.custom_fields[15]'),
              });
            } else if (headerType === 'image') {
              // this.$message.error(this.$t('mcs.messages.chat_apps.custom_fields[16]'));
              this.$notify.error({
                title: this.$t('mcs.messages.chat_apps.custom_fields[16]'),
              });
            } else {
              // this.$message.error(this.$t('mcs.messages.chat_apps.custom_fields[17]'));
              this.$notify.error({
                title: this.$t('mcs.messages.chat_apps.custom_fields[17]'),
              });
            }
            return;
          }
        }

        if (headerType && headerType.match(/location/g)) {
          if (fieldType === 'latlong' && !value.trim().match(latlongRegex)) {
            // this.$message.error(this.$t('mcs.messages.chat_apps.custom_fields[18]'));
            this.$notify.error({
              title: this.$t('mcs.messages.chat_apps.custom_fields[18]'),
            });
            return;
          }
          // for name or address
          if (fieldType.match(/address|name/) && value === '') {
            // this.$message.error(this.$t('validations.enter_value'));
            this.$notify.error({
              title: this.$t('validations.enter_value'),
            });
            return;
          }
        }

        if ((headerType && headerType.match(/text/g)) || buttonType) {
          // remove unicode chars like emojis
          value = removeUnicodeChars(value);
        }


        this.$emit('manage-custom-fields', {
          action: 'assign',
          value: _.unescape(this.$sanitize(value, { allowedTags: [] })),
          index,
          type: 'custom',
          fieldType,
          headerType: headerType && this.headers.includes(headerType) ? headerType : '',
          buttonType: buttonType || '',
        });
      }

      this.inputValue[index] = '';
    },

    assign(cv) {
      const c = cv.split('|');
      const [value, index, headerType, buttonType, fieldType] = c;

      this.$emit('manage-custom-fields', {
        action: 'assign',
        value,
        index,
        type: 'dynamic',
        fieldType,
        headerType: headerType && headerType !== 'none' && this.headers.includes(headerType) ? headerType : '',
        buttonType: buttonType && buttonType !== 'none' ? buttonType : '',
      });
    },

    remove(index, row) {
      const value = '';
      const { headerType, buttonType, fieldType } = row;

      this.$emit('manage-custom-fields', {
        action: 'unassign',
        value,
        index,
        type: 'custom',
        fieldType,
        headerType: headerType && this.headers.includes(headerType) ? headerType : '',
        buttonType: buttonType || '',
      });
    },

    openModal(row, index) {
      this.visible = true;

      if (row.headerType.match(/location/g)) {
        this.modal = {
          action: 'location',
          title: row.fieldText,
          index,
          type: row.headerType,
        };
      } else {
        this.modal = {
          action: 'upload',
          title: row.fieldText,
          index,
          type: row.headerType,
        };
      }
    },

    applyMediaData(row, data) {
      const { fileUrl, previewUrl } = data;
      const { index, type, fieldType } = row;

      this.$emit('manage-custom-fields', {
        action: 'assign',
        value: fileUrl,
        previewUrl,
        index,
        type: 'custom',
        fieldType,
        headerType: type,
      });
    },

    applyLocationData(row, data) {
      const { lat, long } = data;
      const { index, type, fieldType } = row;

      //  apply only for lat long
      this.$emit('manage-custom-fields', {
        action: 'assign',
        value: `${lat}, ${long}`,
        index,
        type: 'custom',
        fieldType,
        headerType: type,
      });
    },

    closeModal() {
      this.visible = false;
      this.modal = {
        action: 'upload',
        title: '',
        index: -1,
        type: '',
      };
    },

    async download(row) {
      if (!row.value) return;

      if (row.value && row.value.match(/s3\/uploads/i)) {
        try {
          this.loading = true;
          const response = await this.downloadFileUrl({ fileUrl: row.value });
          if (response && response.data && response.data.url) {
            this.saveFile(response.data.url);
          }
        } catch (err) {
          if (window.Bugsnag) window.Bugsnag.notify(err);
        } finally {
          this.loading = false;
        }
      } else {
        this.saveFile(row.value);
      }
    },

    shortenFileUrl(url) {
      let filename = url;

      if (url.match(/s3\/uploads/i)) {
        try {
          filename = new URL(url).pathname.split('/').pop();
        } catch (e) {
          filename = url;
        }
      }

      return filename;
    },

    getLogo(type) {
      let logo = 'document-green.svg';

      if (type) {
        logo = `${type}-green.svg`;
      }

      return logo;
    },

    urlResolver(bgUrl, path) {
      return urlResolver(bgUrl, path);
    },
  },
};
</script>
<style lang="scss">
  .cf-dropdown {
    width: 20%;
  }

  .custom-fields {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
</style>
