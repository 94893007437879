<template>
  <div>
    <VoicePreviewMessage
      :tab-selected="form.Message.action"
      :file-data="form.Message.uploads"
      :message="form.Message.message || ''"
      :source="form.Message.source || ''"
      :variables="variables || []"
    />
    <div class="flex flex-col mt-6">
      <div class=" border border-solid border-grey-light rounded-lg p-4">
        <div class="flex justify-between mb-6">
          <div class="text-base">Delivery schedule</div>
          <div><i class="icon-novo-clock text-xl font-medium text-yellow-dark"></i></div>
        </div>
        <div class="text-base">{{ sendNow ? 'Now' : getSchedule }}</div>
        <div class="text-base text-grey">Scheduled on</div>
      </div>
      <div class="mt-4 flex">
        <div class="flex-1 mr-2  border border-solid border-grey-light rounded-lg p-4">
          <div class="flex flex-col mb-6">
            <div class="text-base text-grey mb-1">Channel</div>
            <div class="text-base capitalize">{{ form.Type }}</div>
          </div>
          <div class="flex flex-col mb-6">
            <div class="text-base text-grey mb-1">Subaccount</div>
            <div class="text-base ">{{  form.SubAccountId || '-' }}</div>
          </div>
        </div>

        <div class="flex-1 ml-2  border border-solid border-grey-light rounded-lg p-4">
          <div class="flex justify-between mb-6">
            <div class="text-base">Recipients</div>
            <div><i class="icon-novo-check text-green-dark text-xl font-medium"></i></div>
          </div>
          <div class="flex flex-col mb-6">
            <div class="text-xl mb-1">{{ validTotal.toLocaleString() }}</div>
            <div class="text-base text-grey">({{ validPercentage.toFixed(2) }} % valid)</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import VoicePreviewMessage from '../../Messages/partials/Voice/Preview.vue';

export default {
  name: 'VoiceCampaignPreview',

  components: {
    VoicePreviewMessage,
  },

  computed: {
    getSchedule() {
      const date = moment(this.scheduleDate).format('DD MMM YYYY');
      const time = moment(this.scheduleDate).format('h:mm a');


      return `${date} @ ${time} ${this.scheduleTimezoneName}`;
    },
  },

  props: {
    scheduleDate: {
      type: String,
      default: '',
    },

    scheduleTimezoneName: {
      type: String,
      default: '',
    },

    sendNow: {
      type: Boolean,
      default: true,
    },

    form: {
      type: Object,
      default: () => ({}),
    },

    variables: {
      type: Array,
      default: () => ([]),
    },

    totalVoiceMsgs: {
      type: Number,
      default: 0,
    },

    validTotal: {
      type: Number,
      default: 0,
    },

    validPercentage: {
      type: Number,
      default: 0,
    },

    subAccount: {
      type: String,
      default: '',
    },
  },
};
</script>
