<template>
  <div class="fallback-error-codes">
    <div class="fb-error-row">
      <div class="table-th-codes">
        <div class="code-message">
          Error code
        </div>
        <div class="count">
          No. of messages
          <span
            class="sorter"
            @click="() => sortData()"
          >
            <i class="icon-novo-arrow-solid-up" />
            <i class="icon-novo-arrow-solid-down" />
          </span>
        </div>
        <div class="percent">
          % of total
        </div>
      </div>
    </div>
    <div
      v-for="(item, i) in sortedDate"
      :key="i"
      class="fb-error-row"
    >
      <div class="table-tr-codes">
        <div class="code-message">
          Error {{ item.code }}: <span class="message">{{ item.message }}</span>
        </div>
        <div class="count">
          {{ item.count }}
        </div>
        <div class="percent">
          {{ item.percentOfTotal }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UndeliveredErrorCode',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDesc: true,
    };
  },
  computed: {
    sortedDate() {
      const temp = this.data;
      if (this.isDesc) return temp.sort((a, b) => b.count - a.count);
      return temp.sort((a, b) => a.count - b.count);
    },
  },
  methods: {
    sortData() {
      this.isDesc = !this.isDesc;
    },
  },
};
</script>
<style lang="scss">
.fallback-error-codes {
  .fb-error-row {
    padding: 0px 20px !important;
    border-bottom: 1px solid #e0e0e0;
  }
  .table-th-codes,
  .table-tr-codes {
    display: grid;
    grid-template-columns: 1fr 136px 82px;
    grid-gap: 10px;
    height: 36px;
    align-items: center;
    .code-message {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      .message {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }
    }
    .count,
    .percent {
      text-align: right;
    }
    .count {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      display: flex;
      gap: 2px;
      justify-content: flex-end;
      align-items: center;
      .sorter {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i {
            line-height: 0.6 !important;
            color: #C2C2C2;
            &:hover {
                color: #292929;
            }
        }
      }
    }
    .percent {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}
</style>
