export default {
  methods: {
    getTextAttrs(v) {
      const text = v || '';
      const message = {
        unicodeType: 'GSM7',
        smsChars: 0,
        smsParts: 1,
        text: '',
        charsUsed: 0,
      };

      const charset7bit = ['@', '£', '$', '¥', 'è', 'é', 'ù', 'ì', 'ò', 'Ç', '\n', 'Ø', 'ø', '\r', 'Å', 'å', 'Δ', '_', 'Φ', 'Γ', 'Λ', 'Ω', 'Π', 'Ψ', 'Σ', 'Θ', 'Ξ', 'Æ', 'æ', 'ß', 'É', ' ', '!', '"', '#', '¤', '%', '&', '\'', '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§', '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'ä', 'ö', 'ñ', 'ü', 'à'];
      const charset7bitext = ['\f', '^', '{', '}', '\\', '[', '~', ']', '|', '€'];
      const charsArr = text.split('');
      let charsUsed = 0;
      let parts = 1;
      let partCharsUsed = 0;
      let i = 0;

      for (i = 0; i < charsArr.length; i += 1) {
        if (charset7bit.indexOf(charsArr[i]) >= 0) {
          charsUsed += 1;
          message.unicodeType = 'GSM7';
        } else if (charset7bitext.indexOf(charsArr[i]) >= 0) {
          charsUsed += 2;
          message.unicodeType = 'GSM7';
        } else {
          message.unicodeType = 'UCS2';
          charsUsed = charsArr.length;
          break;
        }
      }

      if (message.unicodeType === 'GSM7') {
        if (charsUsed > 160) {
          parts = Math.ceil(charsUsed / 153);
          partCharsUsed = 7;
          message.smsChars = 153;

          for (i = 0; i < charsArr.length; i += 1) {
            if (partCharsUsed + 1 > 160) {
              partCharsUsed = 7;
            }
            if (charset7bitext.indexOf(charsArr[i]) >= 0 && partCharsUsed + 2 > 160) {
              partCharsUsed = 7;
            }
            if (charset7bitext.indexOf(charsArr[i]) === -1) {
              partCharsUsed += 1;
            } else {
              partCharsUsed += 2;
            }
          }
        } else {
          message.smsChars = 160;
        }
      } else {
        /* eslint no-lonely-if: "off" */
        if (charsUsed > 70) {
          parts = Math.ceil(charsUsed / 67);
          partCharsUsed = 3;
          message.smsChars = 67;

          for (i = 0; i < charsArr.length; i += 1) {
            if (partCharsUsed + 1 > 70) {
              partCharsUsed = 3;
            }
            partCharsUsed += 1;
          }
        } else {
          message.smsChars = 70;
        }
      }

      if (charsUsed === 0) {
        message.smsParts = 1;
        message.smsChars = 160;
        message.unicodeType = 'GSM7';
      }

      message.charsUsed = charsUsed;
      message.smsParts = parts;
      message.text = text;

      return message;
    },
  },
};
