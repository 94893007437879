/* eslint-disable no-param-reassign */

import actions from './actions';

export default {
  namespaced: true,

  state: {
    partialData: undefined,
    uploadDetails: null,
    summaryDetails: null,
    contacts: null,
    contactGroups: null,
    contactDirectories: null,
  },

  getters: {
    getPartialData: state => state.partialData,
    getProcessesing: state => state.processing,
    getUploadDetails: state => state.uploadDetails,
    getContacts: state => state.contacts,
    getContactGroups: state => state.contactGroups,
    getContactDirectories: state => state.contactDirectories,
    getSummaryDetails: state => state.summaryDetails,
  },

  mutations: {
    SET_PROCESSING(state, payload) {
      state.processing = payload;
    },

    SET_PARTIAL_DATA(state, payload) {
      state.partialData = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_UPLOAD_DETAILS(state, payload) {
      state.uploadDetails = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_SUMMARY_DETAILS(state, payload) {
      state.summaryDetails = payload;
    },

    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    },

    SET_CONTACT_GROUPS(state, payload) {
      state.contactGroups = payload;
    },

    SET_CONTACT_GROUPS_BY_ID(state, payload) {
      state.contactGroups = payload;
    },

    SET_CONTACT_DIRECTORIES(state, payload) {
      state.contactDirectories = payload;
    },

    RESET_DATA(state) {
      state.partialData = undefined;
      state.uploadDetails = null;
      state.summaryDetails = null;
      state.contacts = null;
      state.contactGroups = null;
      state.contactDirectories = null;
    },
  },

  actions,
};
