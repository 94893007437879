var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mcs-fallback-detail"},[_c('div',{staticClass:"px-5"},[_c('p',{staticClass:"text-grey-dark text-sm mb-2"},[_vm._v("\n      "+_vm._s(_vm.$t("column_labels.total_msgs"))+"\n    ")]),_c('p',{staticClass:"text-black text-base font-bold"},[_vm._v("\n      "+_vm._s(_vm.totalMessages.toLocaleString())+"\n    ")])]),_c('p',{staticClass:"flex items-center mb-4 mx-5 w-1/2"},[_c('i',{staticClass:"el-icon-bottom text-2xl"}),_c('span',{staticClass:"text-grey-dark text-xs ml-2"},[_vm._v("\n      "+_vm._s(_vm.$t("mcs.campaigns.fallback_details[0]"))+"\n    ")])]),(Object.keys(_vm.campaignMeta.channels || []).length)?_c('div',_vm._l((_vm.campaignMeta.channels),function(channel,index){return _c('div',{key:index,staticClass:"mt-4"},[_c('div',{staticClass:"mb-3 border border-grey-light rounded-lg"},[_c('div',{staticClass:"flex p-5"},[_c('div',{staticClass:"details"},[_c('div',{staticClass:"flex items-center"},[_c('img',{attrs:{"src":_vm.getChannelIcon(channel)}}),_c('span',{staticClass:"text-black text-sm ml-2"},[_vm._v("\n                "+_vm._s(_vm.channelByCode(channel).name)+"\n              ")]),_c('span',{staticClass:"text-grey-dark text-xs ml-4"},[_vm._v("\n                "+_vm._s(`${((_vm.channelData[channel] || []).hasOwnProperty("total")
                    ? _vm.channelData[channel].total
                    : 0
                  ).toLocaleString()} ${_vm.$t("mcs.campaigns.fallback_details[2]")}`)+"\n              ")])]),_c('ProgressBar',{staticClass:"mt-4 mr-8",attrs:{"data":(_vm.channelData[channel] || []).hasOwnProperty('chartData')
                  ? _vm.channelData[channel].chartData
                  : [],"total":_vm.totalMessages}})],1),((_vm.channelData[channel] || []).hasOwnProperty('chartData'))?_c('div',{staticClass:"w-1/2 flex items-center"},[_vm._l(((_vm.channelData[channel].chartData || []).sort((a,b) => a.order - b.order)),function(item){return [(item.label)?_c('div',{key:item.value,staticClass:"w-1/2"},[_c('div',{staticClass:"text-grey-dark text-xs mb-2 flex items-center mr-1"},[_c('svg',{staticClass:"mr-1",attrs:{"width":"12","height":"6"}},[_c('rect',{style:({ fill: item.color }),attrs:{"width":"12","height":"6","rx":"3"}})]),(item.label !== 'Undelivered')?_c('div',[_vm._v("\n                    "+_vm._s(item.label)+"\n                  ")]):_c('div',[(!_vm.channelData[channel].errors)?_c('div',[_vm._v("\n                      "+_vm._s(item.label)+"\n                    ")]):_c('div',{staticClass:"text-link flex items-center",on:{"click":() => _vm.handleMenuClick(channel)}},[_vm._v("\n                      "+_vm._s(item.label)+"\n                      "),_c('i',{class:`${_vm.expanded.includes(channel) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}
                        el-icon--right text-base`})])])]),_c('p',{staticClass:"text-black text-base font-bold"},[_vm._v("\n                  "+_vm._s(item.value.toLocaleString())+"\n                ")]),_c('p',{staticClass:"text-sm font-normal mt-2"},[_vm._v("\n                  ("+_vm._s(_vm.computeValuePercentage(item, _vm.channelData[channel].total))+")\n                ")])]):_vm._e()]})],2):_vm._e()]),(_vm.expanded.includes(channel) && _vm.formatUndelivered(channel).length)?_c('div',{staticClass:"expandable-section mt-2"},[_c('UndeliveredErrorCode',{attrs:{"data":_vm.formatUndelivered(channel)}})],1):_vm._e()]),(index + 1 != _vm.campaignMeta.channels.length)?_c('p',{staticClass:"flex items-center mb-4 mx-5 w-1/2"},[_c('i',{staticClass:"el-icon-bottom text-2xl"}),_c('i18n',{staticClass:"text-grey-dark text-xs ml-2",attrs:{"path":"mcs.campaigns.fallback_details[1]","tag":"span"},scopedSlots:_vm._u([{key:"seconds",fn:function(){return [_c('strong',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.getFallbackDuration(channel))+" ")]),_vm._v(", \n          ")]},proxy:true}],null,true)})],1):_vm._e()])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }