<template>
  <div class="mb-4">
    <div v-if="!fileData">
      <el-upload
        v-if="isButtonType"
        id="upload-audio"
        class="w-full mt-5 upload-audio-box"
        ref="uploadAudio"
        accept=".mp3, .wav"
        :show-file-list="false"
        :limit="1"
        action=""
        v-loading="uploading"
        :http-request="(fileData) => { handleRequest(fileData) }"
      >
        <el-button
          plain
          icon="el-icon-upload"
          :loading="uploading"
          :disabled="loading || uploading"
          class="bg-white text-grey-dark"
        >
          Upload an audio file
        </el-button>
      </el-upload>
      <el-upload
        v-else
        id="upload-audio"
        class="mt-5 upload-audio-box w-3/4"
        ref="uploadAudio"
        drag
        accept=".mp3, .wav"
        :show-file-list="false"
        v-loading="uploading"
        :disabled="loading || uploading"
        :limit="1"
        action=""
        :http-request="(fileData) => { handleRequest(fileData) }"
      >
       <div class="flex flex-col items-center justify-center h-full">
          <div class="mb-3">
            <i class="icon-novo-folder text-4xl" />
          </div>
          <div class="text-base text-black font-semibold mb-2">
            Click to upload or drag and drop your files here.
          </div>
          <div class="text-black text-sm">
            Supported formats are .mp3 and .wav,<br/> with a maximum file size of 5MB.
          </div>
       </div>
      </el-upload>
    </div>
    <div v-else>
      <AudioPlayer v-if="isPreview" :file-data="fileData"/>
      <div v-else class="rounded border border-grey flex justify-between p-2 w-2/3 items-center">
        <div class="mr-3 text-xs">
          <i class="icon-novo-check text-blue text-lg font-semibold mr-3 align-middle" />
          <span class="truncate">{{ fileData.fileName  }}</span>
          <span class="text-grey ml-1">({{ fileData.fileSizeInMB.toFixed(2) }} MB)</span>
        </div>
        <div>
          <i role="button" class="icon-novo-close-circle text-grey-dark text-base cursor-pointer align-middle" @click="removeUploadedAudio" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import UploadMixins from '@/mixins/upload';
import AudioPlayer from './AudioPlayer.vue';

export default {
  name: 'voice_upload_audio',

  components: {
    AudioPlayer,
  },

  mixins: [UploadMixins],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isButtonType: {
      type: Boolean,
      default: false,
    },

    isPreview: {
      type: Boolean,
      default: false,
    },

    fileData: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      uploading: false,
    };
  },

  watch: {
    fileData(data) {
      if (!data) {
        this.resetUpload();
      }
    },

    deep: true,
  },

  created() {
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },

  methods: {
    ...mapActions({
      downloadFileUrl: 'common/downloadFileUrl',
      uploadFile: 'common/uploadFile',
    }),
    resetUpload() {
      this.uploading = false;
      if (this.$refs.uploadAudio) {
        this.$refs.uploadAudio.clearFiles();
      }
    },

    handleBeforeUpload() {
      // this.uploading = true;
    },

    async handleRequest(fileData) {
      try {
        this.uploading = true;

        const { file } = fileData;
        const supportedFileTypes = [
          'audio/mpeg',
          'audio/wav',
        ];

        const isAccepted = supportedFileTypes.includes(file.type.toLowerCase());

        const is5MBLimit = file.size / 1024 / 1024 <= 5;

        const fileSizeInMB = file.size / 1024 / 1024;

        //  size in bytes
        const sizeb = file.size;

        const isEmpty = sizeb < 1;
        const isCorrupt = sizeb < 2;

        if (!isAccepted) {
          throw new Error('File not accepted.');
        }
        if (!is5MBLimit) {
          throw new Error('File exceeds file size limit.');
        }

        if (isCorrupt) {
          throw new Error('File is corrupted.');
        }
        if (isEmpty) {
          throw new Error('File is empty.');
        }

        const response = await this.uploadFile({ file });

        const {
          token, baseURL, fileName, fileLocationUrl,
        } = response;

        let downloadURL = window.encodeURI(`${baseURL}/files/${fileName}?token=${token}`);

        if (fileLocationUrl) {
          downloadURL = fileLocationUrl;
        }

        this.$emit('update-uploaded-audio', {
          fileName,
          fileSizeInMB,
          audioUrl: window.encodeURI(downloadURL),
        }, 'uploads');
      } catch (e) {
        this.handleError(e);
        this.resetUpload();
      } finally {
        this.resetUpload();
      }
    },

    handleError(err) {
      this.$showError(this, err);
    },

    removeUploadedAudio() {
      this.resetUpload();
      this.$emit('update-uploaded-audio', undefined, 'uploads');
    },
  },
};
</script>

<style lang="scss">
.upload-audio-box {
  .el-upload { width: 100%; }
  .el-upload-dragger {
    width: 100%;
    border: 2px dashed #0056e0 !important;
    border-radius: 8px;
    height: 200px;
  }
  .el-upload__tip {
    font-size: 16px;
    color: #292929;
    margin-top: 24px;
  }
}
</style>
