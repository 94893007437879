
<template>
  <div class="sms-preview">
    <h2 class="text-base text-black font-normal mb-3">
      {{ $t('actions.preview') }}
    </h2>
    <p class="text-xs leading-tight text-grey-darker mb-2">
      {{ $t('mcs.messages.sms.preview[0]') }}
    </p>

    <a
      v-if="partialData"
      @click="previewCampaignData"
      class="text-blue flex items-center no-underline mb-3 inline-block text-sm mt-5 cursor-pointer"
    >
      <i class="icon-novo-eye mr-1" />
      Preview with campaign data
    </a>
    <div>
      <div class="preview-phone-header">
        <div class="text-sm text-white font-normal p-5 mt-3 flex">
          <div class="flex-shrink text-black">
            <span class="___arw el-icon-arrow-left"> </span>
          </div>
          <div class="flex-auto text-center pr-3 text-black">
            <span class="text-center">{{ senderId || 'SenderID' }}</span>
          </div>
        </div>
      </div>
      <div
        :class="
        ['p-5 prev-content overflow-y-auto',
        { 'flex content-between flex-wrap' :  isSmsEngage && (!seForm || !Object.keys(seForm).length) }]"
      >
        <div v-if="messageSamplePreview.destination" class="text-center text-sm text-gray mb-3">
          Destination: <span>{{ messageSamplePreview.destination ? messageSamplePreview.destination : 'Mobile' }}</span>
        </div>
        <div
          class="leading-normal min-w-full bg-green-lighter rounded-bl-lg rounded-r-lg p-2
              text-xs prev-wrapper whitespace-pre-wrap break-words max-h-full"
          :class="{ 'text-grey': !message.trim() }"
         >{{ messageSamplePreview.text ? messageSamplePreview.text : getMessage() }}</div>
        <div v-if="isSmsEngage">
          <div
            v-if="!seForm || !Object.keys(seForm).length"
            class="bg-red text-white text-sm rounded-lg p-2"
          >
            {{ $t('mcs.messages.sms.preview[1]') }}
          </div>
          <div
            v-if="seForm && Object.keys(seForm).length && seForm.qrCodeUrl"
            v-loading="loadingQr"
            :style="{minHeight: '100px;'}"
          >
            <div class="mt-5 flex justify-center">
              <div
                class="text-center p-1 border border-solid border-blue rounded"
                :style="{width: '150px'}"
              >
                <img :src="seForm.qrCodeUrl"  />
              </div>
            </div>
            <div class="mt-5 bg-white shadow p-2 rounded-lg">
              <i class="text-xs">{{ $t('mcs.messages.sms.preview[2]') }} </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import previewContentMixin from '@/mixins/preview-content';

export default {
  name: 'message_ca_preview',

  mixins: [previewContentMixin],

  props: {
    message: {
      type: String,
      default: '',
    },

    senderId: {
      type: String,
      default: '',
    },

    variables: {
      type: Array,
      default: () => [],
    },

    loadingQr: {
      type: Boolean,
      default: false,
    },

    isSmsEngage: {
      type: Boolean,
      default: false,
    },

    messageSamplePreview: {
      type: Object,
      default: () => ({}),
    },

    seForm: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      partialData: 'websender/getPartialData',
      messageSamples: 'websender/getMessageSamples',
    }),
  },

  methods: {
    getMessage() {
      if (this.message.trim()) {
        return this.message;
      }

      return `${this.$t('mcs.messages.sms.preview[3]')}`;
    },

    previewCampaignData() {
      this.$emit('preview-message-sample');
    },
  },
};
</script>
<style lang="scss">
  .sms-preview {
    width: 450px;
    padding: 20px;
    border-bottom: 1px solid #A3A3A3;
    padding-bottom: 0;

    .prev-content {
      min-height: 330px;
      max-height: 315px;
      padding-bottom: 2rem;
      border: 8px solid #A3A3A3;
      border-top: 0;
      border-bottom: 0;

      p {
        max-width: 15rem;
        word-break: normal;
      }
    }

    .___arw {
      font-weight: 600 !important;
    }
  }

  .preview-phone-header {
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
    border: 8px solid #A3A3A3;
    border-bottom: 0;
  }
</style>
