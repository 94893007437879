<template>
 <el-dialog
    :title="data.title"
    :visible.sync="visible"
    width="40%"
    :before-close="closeModal"
    @open="openModal"
    top="60px"
  >
    <div class="ca-upload-media">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        @submit.prevent.native
      >
        <el-form-item class="action">
          <el-radio
            v-model="form.action"
            label="upload"
            :disabled="uploading"
          >
            {{ $t('mcs.messages.chat_apps.custom_fields[7]') }}
          </el-radio>
          <el-radio
            v-model="form.action"
            label="url"
            :disabled="uploading || data.type === 'video'"
          >
            {{ $t('mcs.messages.chat_apps.custom_fields[4]') }}
          </el-radio>
        </el-form-item>
        <div
          v-if="form.action === 'upload'"
          v-loading="uploading"
          class="min-h-full"
        >
          <el-upload
            v-if="!form.fileName"
            class="w-full upload-media"
            :accept="
              mediaInfo(data.type, data.maxFileSize, data.supportedFiles).supportedFiles.toString()
            "
            :show-file-list="false"
            :http-request="
              f => onRequest(f, mediaInfo(data.type, data.maxFileSize, data.supportedFiles))
            "
            :limit="1"
            ref="upload"
            drag
            action=""
          >
            <div
              class="min-h-full"
              :style="{backgroundColor: '#F1F6FF'}"
            >
              <i class="el-icon-upload text-sm"></i>
              <!-- <div class="text-xs">
                Drag & drop to upload
                <br/>or <em class="text-blue roman">browse</em> to choose a file.
              </div> -->
              <i18n
                path="upload_labels.drag_drop"
                tag="div"
                class="text-xs"
              >
                <template #br>
                  <br>
                </template>
                  <template #browse>
                  <em class="text-blue roman">{{$t('actions.browse')}}</em>
                </template>
              </i18n>
              <div
                class="el-upload__tip text-center mt-6"
                slot="tip"
              >
                {{ $t('upload_labels.supported_types') }}
                {{
                  mediaInfo(
                    data.type,
                    data.maxFileSize,
                    data.supportedFiles).supportedFilesTxt.join(', ')
                }}
                ({{ $t('upload_labels.size_less_than', { size: `${mediaInfo(data.type, data.maxFileSize, data.supportedFiles).maxSize}MB`}) }}
              </div>
            </div>
          </el-upload>
          <div
            v-else
            class="border border-grey solid rounded"
            :style="{backgroundColor: '#F1F6FF', minHeight: '275px'}"
          >
            <div class="bg-white p-3 text-left rounded-t flex">
              <div class="flex-auto pt-1">
                <span class="text-xs text-black truncate">
                  {{ this.form.fileName }}
                </span>
                <span class="text-xs text-grey-dark ml-3">
                  {{ Number(getFileSize(this.form.fileSize).size).toLocaleString() }}
                  {{ getFileSize(this.form.fileSize).label }}
                </span>
              </div>
              <div class="flex-shrink text-right ml-5">
                <el-button
                  size="mini"
                  type="text"
                  class="text-xs font-normal"
                  @click="reUpload"
                >
                   {{ $t('upload_labels.reupload') }}
                </el-button>
              </div>
            </div>
            <div class="p-3">
              <div
                class="flex flex-col justify-center"
                :style="{ minHeight: '220px'}"
              >
                <div
                  v-if="data.type === 'image'"
                  ref="imageUploadRef"
                  class="text-center"
                >
                </div>

                <div
                  v-if="data.type === 'video'"
                  class="text-center"
                >
                  <img
                    :src="urlResolver(
                      bgUrl,
                      require(`@/assets/misc/${getVideoLogo(this.form.fileType)}`)
                    )"
                    alt="video"
                    class="align-middle"
                    :style="{ width: '200px' }"
                  />
                </div>

                <div
                  v-if="data.type === 'document'"
                  class="text-center"
                >
                  <img
                    :src="urlResolver(bgUrl, require('@/assets/misc/pdf.svg'))"
                    :style="{ width: '200px'}"
                    alt="pdf"
                    class="align-middle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <el-form-item
            class="text-xs file-url"
            prop="fileUrl"
            :label="$t('mcs.messages.chat_apps.custom_fields[4]')"
          >
            <el-input
              size="small"
              v-model="form.fileUrl"
              :style="{ width: '80%'}"
              :placeholder="$t('mcs.messages.chat_apps.custom_fields[4]')"
            />
          </el-form-item>
          <div class="mt-5 text-xs">
            {{ $t('upload_labels.supported_types') }}:
            {{
              mediaInfo(
                data.type,
                data.maxFileSize,
                data.supportedFiles).supportedFilesTxt.join(', ')
            }}
            (({{ $t('upload_labels.size_less_than', { size: `${mediaInfo(data.type, data.maxFileSize, data.supportedFiles).maxSize}MB`}) }})
          </div>
        </div>
        <div class="mt-10">
          <el-button
            size="small"
            @click="closeModal"
          >
            {{ $t('actions.discard') }}
          </el-button>
          <el-button
            size="small"
            type="primary"
            :disabled="!form.fileUrl || uploading"
            @click="submit"
          >
            {{ form.action === 'upload' ? $t('upload_labels.attach_file') : $t('mcs.messages.chat_apps.media_url[2]')}}
          </el-button>
        </div>
      </el-form>
    </div>
 </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import { urlResolver, formatBytes } from '@/utils/common';

import MediaMixin from '@/mixins/media';
import UploadMixin from '@/mixins/upload';

export default {
  name: 'message_ca_upload_media',

  mixins: [MediaMixin, UploadMixin],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const self = this;

    const check = (rule, value, callback) => {
      if (self.data.type === 'image') {
        if (self.isImageOnly(value)) {
          const img = new Image();

          img.src = value;

          img.onload = () => callback();

          img.onerror = () => callback(new Error(self.$t('mcs.messages.chat_apps.media_url[6]')));
        } else {
          return callback(new Error(self.$t('validations.image_does_not_exist')));
        }
      } else if (self.data.type === 'document') {
        if (self.isPDFOnly(value)) {
          return callback();
        }
        return callback(new Error(self.$t('validations.valid', { value: self.$t('fields.media_url') })));
      } else {
        return callback();
      }
    };


    return {
      uploading: false,

      form: {
        action: 'upload',
        fileUrl: '',
        fileSize: '',
        fileName: '',
        fileType: '',
        previewUrl: '',
      },

      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),

      rules: {
        fileUrl: [
          {
            required: true,
            trigger: 'blur',
            message: self.$t('validations.required', { value: self.$t('fields.media_url') }),
            transform(value) {
              return value.trim();
            },
          },
          {
            validator: check,
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },

  watch: {
    'form.action': {
      handler(v) {
        this.resetForm(v);
      },
    },
  },

  methods: {
    ...mapActions({
      getUploadUrl: 'common/getUploadUrl',
      downloadFileUrl: 'common/downloadFileUrl',
      request: 'common/request',
    }),

    openModal() {
      this.resetForm();
    },

    closeModal() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.resetForm();
      this.$emit('close-modal');
    },


    reUpload() {
      this.resetForm('upload');
    },

    onRequest(data, mediaInfo) {
      const { file } = data;

      const isLt2M = file.size / 1024 / 1024 < mediaInfo.maxSize;
      // eslint-disable-next-line no-useless-escape
      const ext = file.name.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);

      if (!file.size || !isLt2M) {
        if (!file.size) {
          // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[4]'));
          this.$notify.error({
            title: this.$t('mcs.messages.chat_apps.upload_media[4]'),
          });
        }

        if (!isLt2M) {
          // this.$message.error(`${this.$t('mcs.messages.chat_apps.upload_media[5]')} ${mediaInfo.maxSize}MB!`);

          this.$notify.error({
            title: `${this.$t('mcs.messages.chat_apps.upload_media[5]')} ${mediaInfo.maxSize}MB!`,
          });
        }
        this.resetForm();
        return;
      }

      if (ext && ext.length > 1
        && !this.mediaInfo(this.data.type, data.maxFileSize, data.supportedFiles)
          .supportedFilesTxt.includes(ext[1].toLowerCase())) {
        if (this.data.type === 'document') {
          // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[0]'));
          this.$notify.error({
            title: this.$t('mcs.messages.chat_apps.upload_media[0]'),
          });
        } else if (this.data.type === 'image') {
          this.$notify.error({
            title: this.$t('mcs.messages.chat_apps.upload_media[1]'),
          });
          // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[1]'));
        } else if (this.data.type === 'video') {
          // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[2]'));
          this.$notify.error({
            title: this.$t('mcs.messages.chat_apps.upload_media[2]'),
          });
        } else {
          // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[3]'));
          this.$notify.error({
            title: this.$t('mcs.messages.chat_apps.upload_media[3]'),
          });
        }
        this.resetForm();
        return;
      }

      this.uploadMediaFile(data);
    },

    async uploadMediaFile(fileData) {
      try {
        this.uploading = true;

        const { fileUrl, file, mimeType } = await this.handleUploadRequest(fileData);

        this.form.fileUrl = fileUrl;
        this.form.fileSize = file.size;
        this.form.fileName = file.name;
        this.form.fileType = mimeType;

        setTimeout(() => {
          this.displayUploadedFile();
        }, 0);
      } catch (err) {
        // eslint-disable-next-line no-console
        // console.error('file upload error', e);
        this.resetForm();
        this.$showError(this, err);
        // this.$message.error('Fail to upload. Please try again.');
      } finally {
        this.uploading = false;
        if (this.$refs.upload) {
          this.$refs.upload.clearFiles();
        }
      }
    },

    getFileSize(size) {
      const fileSize = parseInt(size, 10);

      return formatBytes(fileSize);
    },

    // Decode jwt token
    decodeJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

        const decodedData = JSON.parse(jsonPayload);
        return decodedData;
      } catch (err) {
        this.$showError(this, err);
        console.error('Failed to validate region'); // eslint-disable-line
        return 0;
      }
    },

    getVideoLogo(fileType) {
      let logo = 'video.svg';

      if (fileType.match(/mp4/g)) {
        logo = 'mp4.svg';
      }

      if (fileType.match(/3gp/g)) {
        logo = '3gp.svg';
      }

      return logo;
    },

    displayUploadedFile() {
      const { type } = this.data;
      const { fileUrl } = this.form;

      switch (type) {
        case 'image': {
          if (this.$refs.imageUploadRef) {
            const imgBox = this.$refs.imageUploadRef;

            imgBox.innerHTML = '';

            // fetching images with jwt
            this.downloadFileUrl({ fileUrl }).then((response) => {
              const img = new Image();

              img.width = 200;
              img.src = response.data.url;
              img.alt = 'loading...';
              img.style.verticalAlign = 'middle';

              this.form.previewUrl = response.data.url;

              img.onload = () => {
                imgBox.appendChild(img);
              };

              img.onerror = () => {
                this.form.previewUrl = '';
              };
            }).catch((err) => {
              this.$showError(this, err);
            });
          }
          break;
        }

        case 'video': {
          break;
        }

        case 'document': {
          break;
        }

        default:
      }
    },

    resetForm(action) {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
      this.form.action = action || 'upload';
      this.form.fileUrl = '';
      this.form.fileSize = '';
      this.form.fileName = '';
      this.form.fileType = '';
      this.uploading = false;
    },

    submit() {
      try {
        const data = JSON.parse(JSON.stringify(this.form));
        const row = JSON.parse(JSON.stringify(this.data));
        const { type } = row;

        // eslint-disable-next-line no-useless-escape
        const ext = data.fileUrl.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);

        if (ext && ext.length > 1
          && !this.mediaInfo(
            type,
            data.maxFileSize,
            data.supportedFiles,
          ).supportedFilesTxt.includes(ext[1].toLowerCase())
        ) {
          if (type === 'document') {
            // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[0]'));

            this.$notify.error({
              title: this.$t('mcs.messages.chat_apps.upload_media[0]'),
            });
          } else if (type === 'image') {
            // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[1]'));
            this.$notify.error({
              title: this.$t('mcs.messages.chat_apps.upload_media[1]'),
            });
          } else if (type === 'video') {
            // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[2]'));
            this.$notify.error({
              title: this.$t('mcs.messages.chat_apps.upload_media[2]'),
            });
          } else {
            // this.$message.error(this.$t('mcs.messages.chat_apps.upload_media[3]'));
            this.$notify.error({
              title: this.$t('mcs.messages.chat_apps.upload_media[3]'),
            });
          }
          this.resetForm();
          return;
        }

        if (this.$refs.form) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.$emit('apply-data', row, data);

              this.resetForm();
              this.$emit('close-modal');
            }
          });
        }
      } catch (err) {
        // if (window.Bugsnag) {
        //   window.Bugsnag.notify(e);
        // }
        this.$showError(this, err);
        // do nothing
      }
    },

    urlResolver(bgUrl, path) {
      return urlResolver(bgUrl, path);
    },
  },
};
</script>
<style lang="scss">
  .ca-upload-media {
    .upload-media {
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        min-height: 200px !important;
        background-color: #ffffff;
      }
    }

    .action {
      margin-bottom: 15px;
    }
  }

  // .file-url {
  //   label {
  //     line-height: 20px;
  //     font-size: 11px;
  //     float: none;
  //   }
  // }
</style>
