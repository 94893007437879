<template>
  <el-form
    v-if="Object.keys(rules).length"
    :model="form"
    :rules="rules"
    ref="form"
    class="mb-5 w-2/3"
    hide-required-asterisk
    @submit.prevent.native
  >
    <el-form-item
      prop="url"
      class="mb-0"
    >
      <template slot="label">
        <span
          class="text-sm text-black font-medium block leading-none mb-2"
          :class="[{ 'label__asterisk': required }]"
        >
          {{ $t('column_labels.button_url') }}
        </span>
      </template>
      <el-input
        v-model.trim="form.url"
        type="url"
        size="small"
        :placeholder="$t('mcs.messages.chat_apps.button_fields[0]')" />
    </el-form-item>
    <el-form-item prop="title" class="mt-5 mb-0">
      <template slot="label">
        <span
          class="text-sm text-black font-medium block leading-none mb-2"
          :class="[{ 'label__asterisk': required }]"
        >
          {{ $t('column_labels.button_title') }}
        </span>
      </template>
      <el-input
        v-model="form.title"
        :maxlength="buttonMaxLength"
        size="small"
       :placeholder="$t('mcs.messages.chat_apps.button_fields[1]')" />
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: 'message_ca_button_rm_fields',

  props: {
    buttonMaxLength: {
      type: Number,
      default: 100,
    },
    required: {
      type: Boolean,
      default: true,
    },

    buttons: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const self = this;

    return {
      form: {
        url: '',
        title: '',
      },
      rules: {
        url: [
          {
            required: true,
            // eslint-disable-next-line no-useless-escape
            pattern: new RegExp(this.$vars.URL_REGEX, 'g'),
            trigger: 'blur',
            // message: 'Please enter a valid url',
            message: self.$t('validations.valid', { value: self.$t('column_labels.url') }),
          },
        ],

        title: [
          {
            required: true,
            trigger: 'blur',
            message: self.$t('validations.required', { value: self.$t('fields.title') }),
          },

          {
            max: 30,
            trigger: 'blur',
            message: self.$t('validations.char_limit', { value: '30' }),
          },
        ],
      },
    };
  },

  created() {
    if (Object.keys(this.buttons).length) {
      this.form = { ...this.buttons };
    } else {
      this.form = {
        url: '',
        title: '',
      };
    }
  },

  watch: {
    'form.title': {
      handler(n) {
        if (this.$refs.form) {
          const self = this;

          this.$refs.form.validateField('title', (err) => {
            if (!err) {
              self.$emit('update-rm-buttons', { title: n });
            } else {
              self.$emit('update-rm-buttons', { title: '' });
            }
          });
        }
      },
    },

    'form.url': {
      handler(n) {
        if (this.$refs.form) {
          const self = this;

          this.$refs.form.validateField('url', (err) => {
            if (!err) {
              self.$emit('update-rm-buttons', { url: n });
            } else {
              self.$emit('update-rm-buttons', { url: '' });
            }
          });
        }
      },
    },
  },
};
</script>
