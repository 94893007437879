<template>
  <div v-loading="loading" class="cf-table overflow-auto rounded border border-grey-light">
    <table class="cf-table__inner">
      <tr class="cf-table__row border-b border-grey-light">
        <th
          v-for="(header, index) in headers"
          :key="index"
          class="cf-table__header p-2"
        >
          <el-select
            v-if="!headerValues[index]"
            v-model="headerValues[index]"
            size="small"
            class="w-full"
            placeholder="Create a custom field"
            @change="value => $emit('update-column', { val: value, i: index })"
            >
            <div
              class="justify-between items-center"
              :class="[{ 'flex': isCustomColumn(option) }]"
               v-for="(option, optionIndex) in customFieldOptions"
               :key="optionIndex"
            >
              <el-option
                v-if="!option.isEdit"
                :disabled="headerValues.includes(option.label)"
                class="text-black"
                :class="[{ 'ml-3 mr-8 pl-3 pr-3 w-full gap-10': isCustomColumn(option) }]"
                :value="option.label"
              >
                <span
                  class="text-sm"
                  :class="[{ 'text-blue': isCustomColumn(option) }]"
                  >
                  {{ option.label.match(/MSISDN/i) ? 'Mobile' : option.label }}
                </span>
              </el-option>
              <input
                class="border border-grey-light p-2 ml-4 mr-4"
                v-if="option.isEdit" v-model="option.label"
              >
              <div class="flex column-option-action" v-if="isCustomColumn(option)" :class="[{ 'disabled': headerValues.includes(option.label) }]">
                <i
                  @click="option.isEdit = !option.isEdit"
                  class="cursor-pointer"
                  :class="[
                    { 'el-icon-edit ': !option.isEdit },
                    { 'el-icon-check ': option.isEdit }
                  ]"
                ></i>
                <i
                  @click="customFieldOptions.splice(optionIndex,1)"
                  class="el-icon-delete cursor-pointer mr-3"
                ></i>
              </div>
            </div>
            <div class="px-5 mt-2 mb-1">
              <el-input
                :placeholder="$t('mcs.recip.custom_fields[0]')"
                v-model="customFieldInput"
                size="small"
                @change="inputVal => $emit('add-custom-field-name', inputVal)"
                @input="onCustomFieldKeyDown"
                />
            </div>
          </el-select>
          <el-tag
            v-else
            type="success"
            class="w-full el-tag--dark"
            color="#127440"
            closable
            disable-transitions
            hit
            @close="$emit('remove-column', { val: headerValues[index], i: index })"
            >
            <span>{{ headerValues[index].match(/MSISDN/i) ? 'Mobile' : headerValues[index] }}</span>
          </el-tag>
        </th>
      </tr>
      <tr
        v-for="(d, rowKey) in data"
        :key="rowKey"
        class="cf-table__row"
      >
        <td
          v-for="(i, colKey) in d"
          :key="colKey"
          class="cf-table__data"
        >
          <span class="text-xs">
            {{ i || '—' }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    headerValues: {
      type: Array,
      default: () => [],
    },
    customFieldOptions: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    customFieldInput: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isCustomColumn(option) {
      return !this.options.includes(option.label);
    },

    onCustomFieldKeyDown() {
      this.customFieldInput = this.customFieldInput.replace(/[^A-Za-z0-9]/g, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.cf-table {
  width: 100%;
  max-height: 250px;

  &__inner {
    width: 100%;
    border-collapse: collapse;
  }

  &__header {
    position: sticky;
    min-width: 150px;
    top: 0;
    background-color: white;
  }

  &__data {
    padding: .7rem;
    min-width: 150px;
  }

  &__row {
    &:nth-child(even) {
      background-color: whitesmoke;
    }
  }
}
.column-option-action i {
  padding: 8px;
  border-radius: 3px;

  &:hover {
    background: #eee;
  }
}

.disabled {
  pointer-events: none;
}

</style>
