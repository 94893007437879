<template>
  <div class="summary-card bg-white">
    <div class="flex flex-col border border-grey-lighter rounded h-full">
      <div class="summary-card__body p-4 h-full">
        <slot name="body"></slot>
      </div>
      <div
        v-if="$slots.footer"
        class="summary-card__footer p-4 mt-1 py-3 text-xs">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'summary-card',
};
</script>
