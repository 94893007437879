<template>
  <div class="flex pagination">
    <div class="flex-shrink bg-indigo-semi-lightest text-black p-2">
      {{ $t('app_labels.no_of_entries') }}
    </div>
    <div class="flex-shrink bg-transparent p-2">
      <el-dropdown @command="changePageSize">
        <span class="el-dropdown-link text-grey-dark">
          {{ pageSize }}
          <i class="ml-5 el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in pageSizes"
            :key="item"
            :command="item"
          >
            <span class="text-xs">{{ item }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="flex-auto p-2 bg-indigo-semi-lighter">
      <div class="flex">
        <div class="flex-1 text-left">
          1 - {{ pageSize }} of {{ total }}  {{ $t('app_labels.entries') }}
        </div>
        <div class="flex-1 text-right">
          {{ pageNo + 1 }} of {{ pageCount }} {{ $t('app_labels.pages') }}
        </div>
      </div>
    </div>
    <div class="flex-shrink bg-indigo-semi-lightest p-2">
      <el-button
        type="text"
        size="small"
        @click.prevent="$emit('prev')"
        :disabled="Number(pageNo) < 1">
        <i class="el-icon-arrow-left text-lg text-grey-dark" />
      </el-button>
    </div>
    <div class="flex-shrink bg-transparent p-2">
      <el-dropdown @command="changePageNo">
        <span class="el-dropdown-link text-grey-dark">
          {{ pageNo + 1 }} <i class="ml-5 el-icon-arrow-down el-icon--right"></i>
        </span>
         <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in pageCount"
            :key="item"
            :command="item"
          >
            <span class="text-xs">{{ item }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="flex-shrink bg-indigo-semi-lightest p-2">
      <el-button
        type="text"
        size="small"
        @click.prevent="$emit('next')"
        :disabled="Number(pageNo) >= Number(pageCount) - 1"
      >
      <i class="el-icon-arrow-right text-lg text-grey-dark" />
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'custom_pagination',

  props: {
    pageCount: {
      type: Number,
      default: 0,
    },

    pageNo: {
      type: Number,
      default: 0,
    },

    pageSize: {
      type: Number,
      default: 5,
    },

    pageSizes: {
      type: Number,
      default: 5,
    },

    currentPageSize: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    changePageSize(v) {
      this.$emit('change-page-size', Number(v));
    },

    changePageNo(v) {
      this.$emit('change-page-no', Number(v));
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  line-height: 3;

  .bg-indigo-semi-lighter {
    background-color: #E3EDFF;
  }

  .bg-indigo-semi-lightest {
    background-color: #F2F5FF;
  }
}
</style>
