
import { isJSON } from '@/utils/common';

export default {
  connect({ commit, state, dispatch }, payload) {
    let retries = 0;

    (function reconnect() {
      const { Token: token = '', UserId: id = '' } = payload;

      let wsc;
      const url = process.env.VUE_APP_WSS_URL;
      let endpoint = `${url}/${id}?token=${token}`;

      // If we already have socketId from the previous connection, then
      // let's re-use it
      if (state.socketId) {
        endpoint = `${endpoint}&socketId=${state.socketId}`;
      }

      try {
        wsc = new WebSocket(endpoint);
      } finally { // eslint-disable-line
      }

      wsc.addEventListener('error', () => {
        console.log('[ WS ] - Reconnecting...'); // eslint-disable-line no-console
        commit('SOCKET_DISCONNECT');
        setTimeout(() => {
          reconnect();
        }, 1000 * 10);
      });

      wsc.addEventListener('open', (e) => {
        if (e.target.readyState === 1) {
          console.log('[ WS ] - Connection established!'); // eslint-disable-line no-console
          commit('SOCKET_CONNECTED', wsc);

          // Send whitespace every 55 seconds to keep connection alive
          // under load balancer.
          const lp = setInterval(() => {
            if (wsc.readyState !== 1) {
              clearInterval(lp);
              return;
            }
            wsc.send('');
          }, 1000 * 55);

          /**
           * On Message Event
           */
          wsc.addEventListener('message', (message) => {
            try {
              console.log('[ WS ] - Message received'); // eslint-disable-line no-console

              let { data } = message;
              // data undefined
              if (!data) return;

              // token was invalid or ID is taken,
              // so we just return for now
              if (typeof data === 'string' && data.match(/unauthorized|jwt expired|invalid credentials|invalid token/i)) {
                return;
              }

              if (!isJSON(data)) return;

              data = JSON.parse(message.data);

              // If connection established related message, then we store
              // socketId and queueUrl
              if (data.status && data.status === 'connected') {
                commit('SOCKET_REGISTERED', data);
                return;
              }

              if (data.Body && data.Attributes) {
                data = JSON.parse(data.Body);
              }

              /**
               * From the SQS payload the format that we have to follow should
               * be the one below:
               *
               * {
               *   type: 'SOME_EVENT',
               *   data: { ... }
               * }
               *
               * @type => Should be formatted in mutation string format i.e SOME_EVT
               * @data => Any payload object that can be consume by the client
               */
              // COMMIT WITH DEFAULTS
              const payloadType = data.type || 'SOCKET_MESSAGE';
              const payloadData = data.data || {};

              // Notification
              commit(payloadType, payloadData);

              // Check data data if it match WEBSENDER
              // If so, do websender mutations
              if (data.type === 'WEBSENDER_PARTIAL') {
                commit('websender/SET_PARTIAL_DATA', data.data || {}, { root: true });
              }

              if (data.type === 'WEBSENDER_SUMMARY') {
                commit('websender/SET_UPLOAD_DATA', data.data || {}, { root: true });

                // Dispatch action to calculate price range
                data.data.source = 'UPLOAD';
              }

              if (data.type === 'DOWNLOAD_READY') {
                dispatch('common/downloadFile', data.data.DownloadLink, { root: true });
              }
            } catch (err) {
              if (window.Bugsnag) {
                window.Bugsnag.notify(err);
              }
              // console.log('[ WS ] - Failed to received websocket messages');
            }
          });

          wsc.addEventListener('close', () => {
            console.log('[ WS ] - Disconnected!'); // eslint-disable-line no-console

            if (!state.connected) return;

            commit('SOCKET_DISCONNECT');

            setTimeout(() => {
              reconnect();
            }, 1000 * retries);

            retries += 1;
          });
        }

        // Notify on the browser
        window.onfocus = () => { // eslint-disable-line no-undef
          commit('NOTIFICATION_TYPE', 'browser');
        };

        // Notify on desktop
        window.onblur = () => { // eslint-disable-line no-undef
          commit('NOTIFICATION_TYPE', 'desktop');
        };
      });
    }());
  },

  disconnect({ state, commit }) {
    if (state.socket && state.socket.close) state.socket.close();
    commit('SOCKET_DISCONNECT');
    commit('SOCKET_DEREGISTERED');
  },

  resetPartialResult({ commit }) {
    commit('RESET_PARTIAL_RESULT');
  },
};
