<template>
  <div>
    <div class="text-grey-dark text-center text-xs font-medium mt-6">MESSAGE PREVIEW</div>
    <div class="mt-2 px-6 border-b-2 border-grey-lightest relative">
      <span
        class="absolute pin-t pin-l icon-novo-arrow-left text-black text-2xl"
        :style="{left: '50px', top: '25px'}"
      ></span>
      <div class="cp-box border-grey-darker flex relative flex-col p-6">
        <span class="text-black block text-sm font-medium text-center">{{ getSenderId }}</span>
        <div class="mt-6 cp-message p-3 text-sm w-full leading-normal text-black">
          {{  template.text || '' }}
        </div>
        <div class="mt-1 text-grey-dark text-left text-xs">
          {{ getTime() }}
        </div>
      </div>
    </div>
    <div class="flex justify-between text-xs mt-3">
      <div>
        <span class="text-dark">SMS parts:</span>
        <span class="font-semibold ml-1">{{ getSMSDetails(template.text).smsParts}}</span>
      </div>
      <div>
        <span class="text-dark">Encoding:</span>
        <span class="font-semibold mx-1">{{ getSMSDetails(template.text).unicodeType}}</span>
        <span class="text-dark">
          (<a
            href="https://developer.8x8.com/connect/docs/getting-started#b-the-message-encoding-which-character-set-to-use"
            class="text-blue hover:text-blue-darker cursor-pointer"
            target="_blank"
            rel="noopener noreferrer">learn
            </a>
            more
          )
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import PhoneNumberMixin from '@/mixins/phone-number';
import MessageMixin from '@/mixins/message';


export default {
  name: 'MessagePreview',

  mixins: [
    PhoneNumberMixin,
    MessageMixin,
  ],

  props: {
    template: {
      type: Object,
      default: () => ({}),
    },

    senderId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {

    };
  },

  created() {
  },

  computed: {
    getSenderId() {
      const senderID = this.getPhoneNumberInfo(`+${this.senderId}`).number.international;

      return senderID;
    },
  },

  methods: {
    getTime() {
      const date = new Date();
      const hour = date.getHours();
      const min = date.getMinutes();

      return `${hour}:${min}`;
    },

    getSMSDetails(message) {
      // from mixins
      return this.getTextAttrs(message);
    },
  },
};
</script>

<style scoped>
  .cp-box {
    border-radius: 48px 48px 0px 0px;
    border-left: 8px solid #a3a3a3;
    border-right: 8px solid #a3a3a3;
    border-top: 8px solid #a3a3a3;
    height: 315px;
    overflow-y: auto;
  }

  .cp-message {
    background-color: #d2f3e1;
    border-radius: 8px 8px 8px 0px;
  }
</style>
