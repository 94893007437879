<template>
  <div class="flex flex-col justify-center -mt-3 mr-3">
    <el-breadcrumb separator="_________">
      <el-breadcrumb-item
        class="br-item"
        :class="{ 'font-bold': step === 'MESSAGE' }"
      >
        <i
          v-if="messageDone"
          class="mr-1 align-middle icon-novo-wrap-up text-blue-dark font-semibold"
        />
        <i v-else
          class="mr-1 align-middle icon-novo-radio font-semibold"
          :class="{ 'text-blue-dark font-black': step === 'MESSAGE' }"
        />
        Message
      </el-breadcrumb-item>
      <el-breadcrumb-item
        class="br-item"
        :class="{ 'font-bold': step === 'MESSAGE' }"
      >
        <i
          v-if="recipientsDone"
          class="mr-1 align-middle icon-novo-wrap-up text-blue-dark font-semibold"
        />
        <i
          v-else
          class="mr-1 align-middle icon-novo-radio font-semibold"
          :class="{ 'text-blue-dark font-black': step.match(/RECIPIENTS/i) }"
        />
        Recipients
      </el-breadcrumb-item>
      <el-breadcrumb-item
        class="br-item"
        :class="{ 'font-bold': step === 'SUMMARY' }"
      >
        <i
          class="icon-novo-radio mr-1 align-middle font-semibold"
          :class="{ 'text-blue-dark font-black': step === 'SUMMARY' }"
        />
        Confirmation
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>


export default {
  name: 'AlertSenderBreadcrumb',

  props: {
    step: {
      type: String,
      default: '',
    },
    messageDone: {
      type: Boolean,
      default: false,
    },
    recipientsDone: {
      type: Boolean,
      default: false,
    },
  },

  components: {

  },

  mixins: [],

  data() {
    return {
    };
  },
};
</script>
<style>
  .br-item>.el-breadcrumb__inner {
    color: var(--primary) !important
  }

  .br-item i.icon-novo-radio {
    font-size: 22px !important;
  }

   .br-item i.icon-novo-wrap-up {
    font-size: 19px !important;
  }

  .br-item>.el-breadcrumb__separator {
    color: #A3A3A3 !important;
    vertical-align: text-top;
  }
</style>
