<template>
  <el-form
    @submit.prevent.native
    class="inline-block flex flex-col"
  >
    <div
      class="type-contact-container w-1/2 px-6"
      style="width: 300px"
      >
      <div
       v-if="!hasContactAccess()"
      class="mb-3 text-black text-xs p-3 bg-yellow-lightest
      border border-solid border-yellow rounded mb-5 w-3/5 leading-normal">
        <p class="leading-normal">
          {{ $t('mcs.recip.input[0]')}}
        </p>
      </div>
      <p class="mb-2 text-xs text-grey-dark leading-none">
        {{ $t('mcs.recip.search_groups[0]') }}
      </p>
      <el-input
        :placeholder="$t('mcs.recip.search_groups[1]')"
        class="w-full"
        v-model="groupSearch"
        clearable
        @input="s => debounceQuery(s)">
      </el-input>
      <el-table
        v-if="groupResult || loading"
        v-loading="loading"
        ref="groupTable"
        :data="groupResult"
        style="width: 100%"
        class="mt-5"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          :label="$t('column_labels.group_name')">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column
          property="contacts"
          :label="$t('column_labels.no_of_contacts')"
          show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div
        v-if="groupSelection.length > 0"
        class="mt-4">
        <el-button @click="addSelection()">{{ $t('mcs.recip.search_groups[2]') }}</el-button>
      </div>
      <div v-if="contacts.length || groups.length" class="mt-4">
        <div class="flex justify-between items-center py-3">
          <h3 class="text-sm text-black tracking-normal">
            {{ $t('mcs.recip.search_contacts[3]') }}
          </h3>
          <p class="text-xs text-grey-dark leading-none">
            <!-- {{ contacts.length + groups.length }} selected -->
            {{ $t('mcs.recip.input[4]', { length: contacts.length + groups.length }) }}
          </p>
        </div>
        <ul
          v-loading="screenLoading"
          class="list-reset w-full border-t border-grey-light"
        >
          <!-- Contact list -->
          <li
            v-for="(contact, i) in contacts"
            :key="i"
            class="border-b border-grey-light py-3 px-2 text-xs text-black flex justify-between
            hover:bg-blue-lightest hover:text-blue"
          >
            <div class="flex items-center">
                <span
                  v-if="getRegionCode(`+${contact}`)"
                  class="mr-2 border-grey-light flag-icon text-xs"
                  :class="[
                    `flag-icon-${getRegionCode(`+${contact}`)}`
                  ]"/>
                <span>{{ formatPhoneNumber(contact) }}</span>
            </div>
            <el-tooltip
              v-if="!isTrialAccount()"
              effect="dark"
              :content="$t('actions.remove')"
              placement="right"
              :enterable="false"
              :open-delay="1000"
            >
              <button
                @click.prevent="removeContact(contact)"
                class="text-grey hover:text-red"
              >
                <i class="el-icon-delete"></i>
              </button>
            </el-tooltip>
          </li>
          <!-- Group list -->
          <li
            v-for="(group, i) in groups"
            :key="i"
            class="border-b border-grey-light py-3 px-2 text-xs text-black flex justify-between
            hover:bg-blue-lightest hover:text-blue"
          >
            <div class="flex items-center">
              <i class="el-icon-user mr-2 text-sm"></i>
              <span>{{ group.name }}</span>
            </div>
            <el-tooltip
              effect="dark"
              :content="$t('actions.remove')"
              placement="right"
              :enterable="false"
              :open-delay="1000"
            >
              <button
                @click.prevent="removeGroup(group)"
                class="text-grey hover:text-red"
              >
                <i class="el-icon-delete"></i>
              </button>
            </el-tooltip>
          </li>
        </ul>
      </div>
    </div>
    <el-form-item class="flex-shrink p-6 border-t border-grey-lighter text-right">
      <el-button
        type="text"
        class="mr-3"
        @click="back"
      >
        Back
      </el-button>
      <el-button
        :disabled="screenLoading"
        @click="$emit('discard')"
      >
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        ref="submitbutton"
        type="primary"
        :loading="loading || screenLoading"
        @click="submit"
        id="mcs-process-contact-group"
      >
      {{ loading || screenLoading ?
          $t('mcs.recip.search_contacts[4]') :
          $t('mcs.recip.search_contacts[5]')
      }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';
import _ from 'lodash';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ContactsSummaryMixin from '@/mixins/contacts-summary';

export default {
  name: 'input-recipients',

  mixins: [ContactsSummaryMixin],

  data() {
    return {
      screenLoading: false,
      contactSearch: '',
      contactOptions: [],
      mergedSelectOptions: [],
      groupSearch: '',
      groupResult: null,
      groupSelection: [],
    };
  },

  watch: {
    uploadDataSummary(newVal) {
      this.setSummary({ summary: newVal });
      this.$emit('submit-contacts', newVal);
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    contacts: {
      type: Array,
      default: () => {},
    },

    groups: {
      type: Array,
      default: () => {},
    },

    groupOptions: {
      type: Object,
      default: () => ({}),
    },

    searchContactByPhone: {
      type: Function,
      default: () => {},
    },

    searchGroups: {
      type: Function,
      default: () => {},
    },

    userData: {
      type: Object,
      default: () => ({}),
    },

    uploadContacts: {
      type: Function,
      default: () => {},
    },
  },

  created() {
    // this.getGroups();
  },

  computed: {
    ...mapGetters({
      hasMcsAccess: 'user/getMcsAccess',
      uploadDataSummary: 'websender/getUploadData',
    }),
  },

  methods: {
    ...mapActions({
      getSummary: 'recipientsContacts/getRecipientsSummary',
    }),

    back() {
      this.$emit('change-process-index', 0);
    },

    async submit() {
      const { contacts, groups } = this;

      if (contacts.length || groups.length) {
        this.screenLoading = true;
        try {
          if (this.hasMcsAccess) {
            const uploadData = await this.uploadContacts({
              Source: 'INPUT',
              Contacts: contacts,
              Groups: groups,
            });

            if (uploadData) {
              // Long polling to get the contacts summary
              await this.getContactsSummary({ id: uploadData.id });
            }
          } else {
            const data = await this.getSummary({ contacts, groups });
            this.$emit('submit-contacts', data);
          }
        } catch (err) {
          this.$showError(this, err);
          // this.$message.error(err.message || 'Unable to get subaccounts');
        } finally {
          this.screenLoading = false;
        }
      } else {
        // this.$message.error(this.$t('mcs.recip.input[5]'));
        this.$notify.error({
          title: this.$t('mcs.recip.input[5]'),
        });
      }
    },

    removeGroup(group) {
      const groups = this.groups.filter(g => g.id !== group.id);
      this.groups = groups;

      this.$emit('update-form', {
        Recipients: {
          contacts: this.contacts,
          groups,
        },
      });
    },

    removeContact(contact) {
      const contacts = this.contacts.filter(c => c !== contact);
      this.contacts = contacts;

      this.$emit('update-form', {
        Recipients: {
          contacts,
          groups: this.groups,
        },
      });
    },

    debounceQuery: _.debounce(function dbnce(s) { this.getGroups(s); }, 1000),

    async getGroups(query) {
      const q = query ? query.trim() : query;

      try {
        this.loading = true;
        const { items: groups } = await this.searchGroups(q);
        this.groupResult = groups;
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error(err.message);
      } finally {
        this.loading = false;
      }
    },

    handleSelectionChange(selection) {
      this.groupSelection = selection;
    },

    addSelection() {
      const filteredGroups = this.groupSelection.filter(group => this.groups.every(g => g.id !== group.id)); // eslint-disable-line

      this.$emit('update-form', {
        Recipients: {
          contacts: this.contacts,
          groups: [...this.groups, ...filteredGroups],
        },
      });
    },

    formatPhoneNumber(n) {
      const s = n.match(/^[+][0-9]/) ? n : `+${n}`;
      const pn = parsePhoneNumber(s);
      return pn.number.international || n;
    },

    formatName(contact) {
      const { FirstName, LastName } = contact;

      if (!FirstName && !LastName) {
        return 'No name';
      }
      return `${FirstName} ${LastName}`.trim();
    },

    getRegionCode(s) {
      const pn = parsePhoneNumber(s);
      return pn.regionCode ? pn.regionCode.toLowerCase() : null;
    },

    isTrialAccount() {
      return Boolean(this.userData.IsTrialAccount);
    },

    getUserPhoneNo() {
      return this.userData.Phone || '';
    },

    hasContactAccess() {
      return this.userData.Roles.includes('Contacts_v2');
    },
  },
};
</script>
