import actions from './actions';

export default {
  namespaced: true,

  state: {
    channelInstances: [],
  },

  getters: {
    getChannelInstances: state => state.channelInstances,
  },

  mutations: {
    SET_CHANNEL_INSTANCES(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.channelInstances = payload.channelInstances;
    },
  },

  actions,
};
