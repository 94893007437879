<template>
  <div class="flex px-2 py-1 border border-grey-light rounded-lg haslogo bg-white relative z-10">
    <div class="flex-shrink py-3">
      <img :src="getIcon()" alt="icon" />
    </div>
    <div class="flex-auto ml-5 mt-2 py-2">
      <p class="text-grey-dark text-sm mb-1">{{$t('column_labels.status')}}</p>
      <p class="text-black text-lg font-medium">
        {{ campaignStatus(statusCode).name ? $t(`mcs.campaigns.statuses.${getStatusName(statusCode, scheduledAt)}`) : ''
        }}
      </p>
    </div>
    <div class="flex justify-center items-center py-3 flex-col">
      <el-button v-if="isScheduledCampaign" type="danger" size="small" icon="el-icon-close"
        class="-mt-1 w-32 block mb-2" @click="$emit('cancel-scheduled-campaign')">
        {{ $t('actions.cancel') }}
      </el-button>
      <el-tooltip :enterable="false" class="help-cursor" effect="dark" placement="bottom">
        <div slot="content" v-html="getDownloadButtonTooltipContent()" />
        <span>
          <el-button type="button" size="small" icon="el-icon-download" class="-mt-1 w-32 block"
            @click="openExportModal" :disabled="disableDownloadButton">
            {{ $t('actions.download') }}
          </el-button>
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import statusIcon from '@/assets/misc/status.svg';
import CampaignListMixin from '@/mixins/campaign-list';
import { checkIfDayHasPassed } from '@/utils/common';


export default {
  name: 'send_estimated_cost',

  mixins: [CampaignListMixin],

  props: {
    statusCode: {
      type: Number,
      required: true,
    },
    campaignCompletedAt: {
      type: [String, Date],
      required: false,
    },
    scheduledAt: {
      type: [String, Date],
      required: false,
    },
    isScheduledCampaign: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    disableDownloadButton() {
      return this.shouldDisableDownloadButton(this.campaignCompletedAt);
    },
  },


  methods: {
    getIcon() {
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${statusIcon}`;
    },

    getStatusName(statusId, schedule) {
      if (
        ([1, 2, 3, 4].includes(statusId)) && this.$wcsender.dateIsInTheFuture(schedule)) {
        return this.campaignStatus(99).name;
      }
      return this.campaignStatus(statusId).name;
    },

    openExportModal() {
      if (!this.disableDownloadButton) { this.$emit('open'); }
    },

    getDownloadButtonTooltipContent() {
      if (this.campaignCompletedAt && checkIfDayHasPassed(this.campaignCompletedAt)) {
        return this.$t('actions.download');
      }

      return this.$t('mcs.campaigns.tooltip.processing');
    },

    shouldDisableDownloadButton(completedAt) {
      if (!completedAt) return true;
      return !checkIfDayHasPassed(completedAt);
    },
  },
};
</script>
