<template>
  <div>
    <div class="mb-3">
      <div class="p-2 px-3 border rounded-lg z-10 relative bg-white">
        <div class="flex -mx-2">
          <div class="w-1/2 px-2">
            <div class="flex-auto py-3 mt-px mb-px">
              <p class="text-grey-dark text-sm mb-2">{{ $tc("column_labels.channel", 1) }}</p>
              <p class="text-black text-lg font-medium break-words">{{ getAppType.name }}</p>
            </div>
          </div>
          <div class="w-1/2 px-2">
            <div class="flex-auto py-3 mt-px mb-px">
              <p class="text-grey-dark text-sm mb-2">{{ $tc("column_labels.subaccount", 1) }}</p>
              <p class="text-black text-lg font-medium break-words">{{ data.SubAccountId }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="campaignMeta && (campaignMeta.channels || []).length"
        class="-mt-3 border border-t-0 rounded-b-lg bg-grey-lightest"
      >
        <div class="pt-8 pb-5 px-3">
          <div class="py-px my-px flex items-center">
            <p class="text-sm text-grey-dark mr-4">{{ $t("column_labels.sent_via") }}</p>
            <ChannelsFallback :channels="campaignMeta.channels" :showLabel="false" />
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 p-2 px-3 border rounded-lg">
      <div class="flex -mx-2">
        <div class="w-1/2 px-2">
          <div class="flex-auto py-3 mt-px mb-px">
            <p class="text-grey-dark text-sm mb-2">{{ $t("column_labels.created_by") }}</p>
            <p class="text-black text-lg font-medium break-words">{{ data.CreatedBy_Username }}</p>
          </div>
        </div>
        <div class="w-1/2 px-2">
          <div class="flex-auto py-3 mt-px mb-px">
            <p class="text-grey-dark text-sm mb-2">{{ $t("column_labels.created_on") }}</p>
            <p class="text-black text-lg font-medium break-words">
              {{ getLocalTime(data.CreatedAt, currentTzOffset, currentTz) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="data.ReviewedByLogin && [5, 7].includes(data.CampaignStatusId)"
      class="border border-red-light bg-red-lightest text-xs rounded p-5 text-white mb-5 flex items-center"
    >
      <i class="el-icon-error text-xl mr-3"></i>
      <div>
        <div>
          {{ data.CampaignStatusName }} by&nbsp;
          <strong v-if="data.ReviewedByLogin" class="font-semibold">
            {{ data.ReviewedByLogin }}
          </strong>
          &nbsp;
          <span v-if="data.ReviewedAt">
            {{ getLocalTime(data.ReviewedAt, currentTzOffset, currentTz) }}
          </span>
        </div>
        <div v-if="data.CampaignStatusName === 'Rejected'" class="flex mt-2">
          {{ $t("column_labels.reason") }}:
          <p v-if="data.RejectionMsg" class="ml-2">{{ data.RejectionMsg }}</p>
        </div>
      </div>
    </div>
    <div
      v-else-if="data.ReviewedByLogin && ![5, 7].includes(data.CampaignStatusId)"
      class="border border-green-light bg-green-lightest text-xs rounded p-5 text-green-darkest mb-5 flex items-center"
    >
      <i class="el-icon-success text-xl mr-3"></i>
      {{ $t("column_labels.approved_by") }}&nbsp;
      <strong v-if="data.ReviewedByLogin" class="text-black">
        {{ data.ReviewedByLogin }}
      </strong>
      &nbsp;
      <span v-if="data.ReviewedAt">
        {{ getLocalTime(data.ReviewedAt, currentTzOffset, currentTz) }}
      </span>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import ChannelsFallback from '@/components/ChannelsFallback.vue';

// eslint-disable-next-line import/no-unresolved
import appType from '@/json/appType.json';

export default {
  name: 'campaign_info',

  components: {
    ChannelsFallback,
  },

  props: {
    data: {
      type: Object,
      required: true,
      appType,
    },

    campaignMeta: {
      type: Object,
      default: () => {},
    },

    currentTz: {
      type: String,
      default: '(UTC-00)',
    },

    currentTzOffset: {
      type: Number,
      default: 0,
    },

    getLocalTime: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    getAppType() {
      return appType.find(e => e.code === this.data.Product);
    },
  },
};
</script>
