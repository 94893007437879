<template>
  <div class="voice-lang mb-4">
    <div class="w-2/3">
      <div>
        <h5 class="text-black font-medium mb-2 inline-block">
          Language <span class="required" />
        </h5>
        <el-tooltip  placement="top">
          <p slot="content" class="mb-4 text-grey-dark text-xs">
            Select the language you wish to communicate from the list of available languages.
          </p>
          <i class="icon-novo-faq text-base ml-1" />
        </el-tooltip>
      </div>
      <div class="bg-grey-lightest rounded relative">
        <el-dropdown
          size="small"
          class="w-full outline-none px-3 py-2"
          trigger="click"
          placement="top-start"
          @command="handleCommand"
          :style="{ lineHeight: '24px' }"
        >
          <div class="flex">
            <div class="flex-auto">
              <span class="mr-3 text-sm">{{ selected.name }} </span>
              <span class="text-sm font-medium">{{ selected.code }} </span>
            </div>
            <div class="flex-shrink">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </div>
          <el-dropdown-menu
            slot="dropdown"
            class="dp-menu"
          >
            <el-dropdown-item
              v-for="i in languages"
              :key="i.code"
              :command="`${i.name}|${i.code}`"
              :class="[{ 'selected': selected.code === i.code }]"
            >
              <span class="mr-3 text-xs">{{ i.name }} </span>
              <span class="text-xs font-medium">{{ i.code }} </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'message_voice_languages',

  props: {
    language: {
      type: Object,
      default: () => ({
        name: 'English (United States)',
        code: 'en-US',
      }),
    },

    languages: {
      type: Array,
      default: () => [],
    },

    profiles: {
      type: Array,
      default: () => [],
    },

    label: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: {},
    };
  },

  created() {
    this.selected = this.language;
  },

  watch: {
    language(n) {
      if (n) {
        this.selected = n;
      }
    },
  },


  methods: {
    handleCommand(v) {
      const s = v.split('|');

      this.selected = {
        name: s[0],
        code: s[1],
      };

      this.$emit('update-language', { ...this.selected }, 'language');

      const profile = this.profiles.find(p => p.code === this.selected.code);
      this.$emit('update-profile', { ...profile }, 'profile');
    },
  },
};
</script>
<style lang="scss">
  .dp-menu {
    overflow: auto;
    max-height: 300px;
    min-width: 400px;

    .selected {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
  }

</style>
