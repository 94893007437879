export default {
  methods: {
    mediaInfo(mediaType, fileSize, supportedFilesTxt) {
      let info = {
        supportedFilesTxt: [''],
        supportedFiles: [''],
        maxSize: 0,
      };

      let sp = [];
      let spTxt = [];

      if (supportedFilesTxt && supportedFilesTxt.length) {
        spTxt = supportedFilesTxt;
        const spObj = supportedFilesTxt.reduce((a, b) => {
          a.l.push(`.${b.toLowerCase()}`);
          a.u.push(`.${b.toUpperCase()}`);

          return a;
        }, { l: [], u: [] });

        sp = sp.concat(spObj.l).concat(spObj.u);
      }

      switch (mediaType) {
        case 'image': {
          info = {
            supportedFilesTxt: spTxt.length ? spTxt : ['png', 'jpg', 'jpeg'],
            supportedFiles: sp.length ? sp : ['.png', '.jpg', '.jpeg', '.PNG', '.JPG', '.JPEG'],
            maxSize: fileSize || 5, // 5MB
          };
          break;
        }
        case 'document': {
          info = {
            supportedFilesTxt: spTxt.length ? spTxt : ['pdf'],
            supportedFiles: sp.length ? sp : ['.pdf', '.PDF'],
            maxSize: fileSize || 100, // 100MB
          };
          break;
        }
        case 'video': {
          info = {
            supportedFilesTxt: spTxt.length ? spTxt : ['mp4', '3gpp'],
            supportedFiles: sp.length ? sp : ['.mp4', '.3gpp', '.MP4', '.3GPP'],
            maxSize: fileSize || 16, // 16MB
          };
          break;
        }
        default: {
          break;
        }
      }

      return info;
    },

    isImageOnly(fileUrl = '') {
      return fileUrl.toLowerCase().match(/\.([jpeg|jpg|png]+)(?:[\?#]|$)/i);
    },

    isPDFOnly(fileUrl = '') {
      return fileUrl.toLowerCase().match(/\.([pdf]+)(?:[\?#]|$)/i);
    },
  },
};
