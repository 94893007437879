<template>
  <el-dialog
    :title="type === 'add_contacts' ? 'Upload new file or keep selected recipients?' : 'Discard or keep uploaded file?'"
    :visible.sync="visible"
    width="900px"
    :before-close="handleClose"
  >
    <div v-if="type === 'add_contacts'">
      <p class="">Uploading a file with contacts will <b>discard all your selected recipients.</b></p>
      <p class="mt-6"><b>Are you sure you want to continue?</b></p>
    </div>
    <div v-else>
      <p class="">
        You are about to <b>discard the uploaded file.</b>
        <b> Are you sure you want to continue?</b>
      </p>
    </div>
    <span
      slot="footer"
      class="dialog-footer text-right"
    >
      <el-button
        type="text" @click="$emit('cancel' ,type)"
        class="mr-6"
      >
        Cancel
      </el-button>
      <el-button
        @click="$emit('discard', type)"
        class=""
      >
        {{ (type === 'add_contacts') ? 'Yes, discard all contacts and upload a file' : 'Discard uploaded file' }}
        </el-button>
      <el-button
        type="primary"
        @click="$emit('keep', type)"
      >
         {{ (type === 'add_contacts') ? 'No, keep selected recipients' : 'No, keep uploade file' }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'RecipientConfirmation',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleClose() {
      this.$emit('cancel', this.type);
    },
  },
};
</script>
