<template>
  <div class="mb-3 msg-preview">
    <div class="bg-grey-lightest border border-solid border-grey-light rounded-lg">
      <div v-if="messageType.match(/SMS/)">
        <a
          v-if="partialData"
          @click="previewMessageSample"
          class="ml-4 text-blue flex items-center no-underline inline-block text-sm mt-4 cursor-pointer"
        >
          <i class="icon-novo-eye mr-1" />
          Preview with campaign data
        </a>
      </div>
      <div class="px-5" :class="[hideLabel || 'pt-5']">
        <p v-if="!hideLabel" class="text-black font-medium mb-5">{{ $t('mcs.message_preview') }}</p>
        <div
          v-if="messageType.match(/SMS/)"
          class="text-grey-darker mb-5 leading-normal min-w-full text-xs whitespace-pre-wrap break-words max-h-full prev-content"
        >
          <div>{{ messageSamplePreview.text ? messageSamplePreview.text : message }}</div>
        </div>

        <div v-if="messageType === 'VOICE'">
          <div v-if="message.match(/\/files\/(.*)/)">
            <audio :src="message" controls class="voice-audio-player w-full"></audio>
          </div>
          <div v-else class="text-grey-darker mb-5 leading-normal min-w-full text-xs whitespace-pre-wrap break-words max-h-full prev-content">{{ message }} </div>
        </div>
        <div
          :class="[
            { 'shadow border rounded mb-5 border-grey-lighter':  Object.keys(header).length },
          ]"
          v-if="messageType === 'CHAT_APPS'"
        >
          <div v-if="channel.match(/WA/i)">
            <div
              v-if="Object.keys(header).length"
              class="text-grey-dark bg-white rounded-t"
            >
              <div
                class="text-center"
                v-if="header.type !== 'text'"
              >
                <div v-if="header.type === 'image'" class="w-full">
                  <ImageDisplay :file-url="getFileUrl(customFields)" :is-preview="Boolean(true)" />
                </div>
                <div v-else class="py-6 px-2">
                  <img :src="urlResolver(
                        bgUrl,
                        require(`@/assets/media/${getMediaIcons(header.type, customFields)}.svg`)
                      )" :style="{width: '40px', height: '40px'}" class="align-middle" :alt="header.type" />
                </div>
                <div
                  v-if="header.type === 'location'
                    && getLocationFields(customFields).name
                    && getLocationFields(customFields).address
                  "
                  class="text-left bg-grey-shade-custom py-2"
                >
                  <span class="p-1 ml-2 text-xs block">{{ getLocationFields(customFields).name }}</span>
                  <span class="text-grey-dark p-1 ml-2 text-xs block">{{ getLocationFields(customFields).address }}</span>
                </div>
              </div>
              <div
                v-else class="py-4 text-sm text-black font-semibold px-2"
                v-html="replaceHeaderField(header.value, customFields, variables)"
              />
            </div>
            <div
              :class="[
                { 'px-2' : Object.keys(header).length },
                'min-w-full py-4',
                'text-xs',
                'max-h-full overflow-auto',
              ]"
            >
              <div
                class="prev-content whitespace-pre-wrap break-words text-grey-darker leading-tight"
                v-html="getContentMsg(message, variables, customFields)"
              />
              <div
                class="mt-5 text-grey-dark"
                :style="{fontSize: '10px'}"
                v-if="footer"
              >
                {{ footer }}
              </div>
              <div
                class="mt-3 border-t solid border-grey-light p-2 text-grey-dark text-base text-center"
                v-if="hasCTAButtons()"
              >
                <div
                  v-for="(btn,i) in waButtons"
                  :key="i"
                  class="py-2"
                >
                  <div v-if="btn.type.toLowerCase() === 'phone_number'">
                    <a
                      :href="`tel:${btn.phoneNumber}`"
                      target="_blank"
                      class="text-blue hover:text-blue-light no-underline"
                      rel="nofollow noopener"
                    >
                      <img
                        :src="urlResolver(
                          bgUrl,
                          require(`@/assets/misc/phone.svg`)
                        )"
                        class="align-middle"
                        :style="{height: '15px'}"
                      />
                      <span class="align-middle"> {{ btn.text }} </span>
                    </a>
                  </div>

                  <div v-if="btn.type.toLowerCase() === 'url'">
                    <a
                      :href="`${replaceURLSuffix(btn.url, customFields, variables)}`"
                      target="_blank"
                      class="text-blue hover:text-blue-light no-underline"
                      rel="nofollow noopener"
                    >
                      <img
                        :src="urlResolver(
                          bgUrl,
                          require(`@/assets/misc/external-link.svg`)
                        )"
                        class="align-middle"
                        :style="{height: '15px'}"
                      />
                      <span class="align-middle"> {{ btn.text }} </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                v-if="hasQRButtons()"
                class="mt-3 py-2"
              >
                <div class="flex flex-wrap">
                  <div
                    v-for="(btn,i) in waButtons"
                    :key="i"
                    class="quick-btn px-1"
                  >
                    <el-button
                      size="small"
                      class="w-full block bg-white shadow rounded mb-3 text-blue border border-transparent"
                    >
                      {{ btn.text }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              v-if="message"
              class="py-2"
            >
              <div
                class="leading-normal min-w-full
                    text-xs prev-wrapper whitespace-pre-wrap break-words max-h-full"
                v-html="getContentMsg(message, variables, customFields)"
              />
            </div>
            <div
              v-if="imageInputs && Object.keys(imageInputs).length"
              class="image text-center py-2"
            >

              <div v-if="imageInputs.previewUrl.match(/\/s3\/uploads/)" ref="imageInput" />
              <img
                v-else
                :src="imageInputs.previewUrl"
                :alt="imageInputs.previewUrl"
              />
            </div>
            <div
              v-if="buttonInputs && Object.keys(buttonInputs).length"
              :class="[
                'button text-center px-4 py-2',
              ]"
            >
              <a
                :href="buttonInputs.url"
                rel="noopener noreferrer"
                target="_blank"
                class="bg-white rounded-full py-2 px-4 text-sm font-medium no-underline
                shadow border solid border-grey-lighter block"
                :class="[{
                  'bg-purple border-transparent text-white hover:bg-purple-light'
                  : channel.match(/VB/i)
                }]"
              >
                {{buttonInputs.title}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-sm text-grey-darker py-2" v-if="messageSamplePreview.destination">Destination: {{ messageSamplePreview.destination }}</div>
      <div class="bg-white py-3 rounded-b-lg flex"
        v-if="messageType.match(/SMS/)">
        <div class="flex-1">
          <p class="text-sm text-grey-dark mb-2">{{ $t('column_labels.sms_parts') }}</p>
          <span class="font-semibold">{{ Number(smsParts).toLocaleString() }}</span>
        </div>
        <div class="flex-1">
          <p class="text-sm text-grey-dark mb-2">{{ $t('column_labels.total_sms') }}</p>
          <span class="font-semibold">{{ Number(totalSms).toLocaleString() }}</span>
        </div>
      </div>
      <div class="bg-white px-5 py-3 rounded-b-lg"
        v-if="messageType.match(/CHAT_APPS/i) && !hideTotalMessage">
        <p class="text-sm text-grey-dark mb-2">{{ $t('column_labels.total_msgs') }}</p>
        <span class="font-semibold">
          {{
            Number(totalCaMsgs || 0).toLocaleString()
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import previewContentMixin from '@/mixins/preview-content';
import ImageDisplay from '@/components/ImageDisplay.vue';

import { urlResolver } from '@/utils/common';
import { mapGetters } from 'vuex';

export default {
  name: 'send_msg_preview',

  components: { ImageDisplay },

  mixins: [previewContentMixin],

  props: {
    hasWhatsApp: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: '',
    },

    messageType: {
      type: String,
      default: '',
    },

    variables: {
      type: Array,
      default: () => [],
    },

    customFields: {
      type: Array,
      default: () => [],
    },

    smsParts: {
      type: Number,
      default: 0,
    },

    totalSms: {
      type: Number,
      default: 0,
    },

    totalCaMsgs: {
      type: Number,
      default: 0,
    },

    totalVoiceMsgs: {
      type: Number,
      default: 0,
    },

    header: {
      type: Object,
      default: () => ({}),
    },

    footer: {
      type: String,
      default: '',
    },

    channel: {
      type: String,
      default: '',
    },

    buttonInputs: {
      type: Object,
      default: () => ({}),
    },

    imageInputs: {
      type: Object,
      default: () => ({}),
    },

    hideLabel: {
      type: Boolean,
      default: false,
    },

    hideTotalMessage: {
      type: Boolean,
      default: false,
    },

    waButtons: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
      messageSamplePreview: {},
    };
  },

  watch: {
    imageInputs(image) {
      if (image) {
        this.$nextTick(() => {
          this.downloadImage();
        });
      }
    },
  },

  computed: {
    ...mapGetters({
      messageSamples: 'websender/getMessageSamples',
      partialData: 'websender/getPartialData',
    }),
  },

  methods: {
    previewMessageSample() {
      const randomRow = this.messageSamples[Math.floor(Math.random() * this.messageSamples.length)];

      this.messageSamplePreview = randomRow;
    },

    getContentMsg(message, variables, customFields) {
      if (this.messageType === 'CHAT_APPS') {
        if (this.channel.match(/WA/i)) {
          return this.getWAPreviewContent(message, customFields, variables);
        }
        return this.getNonWAPreviewContent(message, customFields, variables);
      }

      return this.getPreviewContent(message, variables);
    },

    urlResolver(bgurl, path) {
      return urlResolver(bgurl, path);
    },

    getMediaIcons(type = 'image', customFields) {
      let iconColor = 'dark';

      const hasValue = customFields.some(v => v.headerType === type && v.value);

      if (hasValue) iconColor = 'green';

      return `${type}-${iconColor}`;
    },

    hasCTAButtons() {
      const { waButtons } = this;
      return waButtons.length
        && waButtons.some(v => ['url', 'phone_number'].includes(v.type.toLowerCase()));
    },

    hasQRButtons() {
      const { waButtons } = this;
      return waButtons.length
        && waButtons.some(v => v.type.toLowerCase() === 'quick_reply');
    },

    getFileUrl(customFields = []) {
      let fileUrl = '';
      const obj = customFields.find(v => v && v.headerType === 'image');
      if (obj && obj.value) {
        fileUrl = obj.value;
      }

      return fileUrl;
    },

    downloadImage() {
      if (this.imageInputs && this.$refs.imageInput && this.imageInputs.previewUrl.match(/\/s3\/uploads/)) {
        const imgBox = this.$refs.imageInput;
        const fileUrl = this.imageInputs.previewUrl;
        imgBox.innerHTML = '';

        // const img = new Image();
        // img.src = 'https://picsum.photos/200/300';
        // img.alt = 'loading...';
        // img.style.verticalAlign = 'middle';
        // img.style.width = '100%';
        // img.style.height = 'auto';

        // img.onload = () => {
        //   imgBox.appendChild(img);
        // };

        // fetching images with jwt
        const jwt = localStorage.getItem('WWW-Authenticate');

        axios.get(fileUrl, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }).then((response) => {
          const img = new Image();

          img.src = response.data.url;
          img.alt = 'loading...';
          img.style.verticalAlign = 'middle';
          img.style.width = '100%';
          img.style.height = 'auto';

          img.onload = () => {
            imgBox.appendChild(img);
          };
        }).catch((err) => {
          this.$showError(this, err);
          console.log(err);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.prev-content {
  p {
    max-width: 15rem;
    word-break: normal;
  }
}

.bg-grey-shade-custom {
  background-color: #f5f5f5;
}
</style>
