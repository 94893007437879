<template>
  <div class="mb-5">
    <p class="text-grey-dark text-xs mb-3">
      {{ $t('mcs.messages.chat_apps.msg_types[0]') }}  <b>{{ getChannelName(channel) }} </b>
    </p>
    <div class="w-2/3 flex">
      <div
        v-for="i in getTypes"
        :key="i"
        :class="[
          'rounded p-2 text-xs flex-1 mr-3 border',
          { 'bg-grey-lighter': i.type !== active },
          { 'bg-white border-green': i.type === active },
        ]"
      >
        <div class="flex">
          <div class="flex-auto">
            <el-radio
              v-model="active"
              :label="i.type"
              class="w-full"
              @change="changeMediaType"
            >

              <span :class="['text-xs mr-3', { 'text-grey-dark' : i.type !== active}]">
                {{ i.name }}
              </span>
            </el-radio>
          </div>
          <div class="flex-shrink">
            <i :class="[
              `align-middle text-lg ${i.icon}`,
              { 'font-bold': i.type === active },
              { 'text-grey': i.type !== active }
              ]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import channelsJson from '@/json/channels.json';

export default {
  name: 'message_ca_types',

  props: {
    channel: {
      type: String,
      default: '',
    },
    messagingType: {
      type: String,
      default: 'text',
    },
  },

  computed: {
    getTypes() {
      let types = [
        { name: 'Text', type: 'text', icon: 'el-icon-document' },
        { name: 'Image', type: 'image', icon: 'el-icon-picture-outline' },
        { name: 'Rich Media', type: 'rich_media', icon: 'el-icon-tickets' },
      ];

      if (this.channel.toUpperCase() === 'LN') {
        types = types.filter(v => v.type !== 'rich_media');
      }

      return types;
    },
  },

  created() {
    this.active = this.messagingType;
  },

  data() {
    return {
      active: 'text',
    };
  },

  methods: {
    getChannelName(ch) {
      const c = channelsJson.find(v => v.code === ch);

      return c.name;
    },

    changeMediaType(v) {
      this.$emit('update-message-type', v);
    },
  },
};
</script>
