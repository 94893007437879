<template>
  <div class="mcs-fallback-detail">
    <div class="px-5">
      <p class="text-grey-dark text-sm mb-2">
        {{ $t("column_labels.total_msgs") }}
      </p>
      <p class="text-black text-base font-bold">
        {{ totalMessages.toLocaleString() }}
      </p>
    </div>
    <p class="flex items-center mb-4 mx-5 w-1/2">
      <i class="el-icon-bottom text-2xl" />
      <span class="text-grey-dark text-xs ml-2">
        {{ $t("mcs.campaigns.fallback_details[0]") }}
      </span>
    </p>
    <div v-if="Object.keys(campaignMeta.channels || []).length">
      <div
        v-for="(channel, index) in campaignMeta.channels"
        :key="index"
        class="mt-4"
      >
        <div class="mb-3 border border-grey-light rounded-lg">
          <div class="flex p-5">
            <div class="details">
              <div class="flex items-center">
                <img :src="getChannelIcon(channel)">
                <span class="text-black text-sm ml-2">
                  {{ channelByCode(channel).name }}
                </span>
                <span class="text-grey-dark text-xs ml-4">
                  {{
                    `${((channelData[channel] || []).hasOwnProperty("total")
                      ? channelData[channel].total
                      : 0
                    ).toLocaleString()} ${$t("mcs.campaigns.fallback_details[2]")}`
                  }}
                </span>
              </div>
              <ProgressBar
                :data="
                  (channelData[channel] || []).hasOwnProperty('chartData')
                    ? channelData[channel].chartData
                    : []
                "
                :total="totalMessages"
                class="mt-4 mr-8"
              />
            </div>
            <div
              v-if="(channelData[channel] || []).hasOwnProperty('chartData')"
              class="w-1/2 flex items-center"
            >
              <template v-for="item in (channelData[channel].chartData || []).sort((a,b) => a.order - b.order)">
                <div
                  v-if="item.label"
                  :key="item.value"
                  class="w-1/2"
                >
                  <div class="text-grey-dark text-xs mb-2 flex items-center mr-1">
                    <svg
                      width="12"
                      height="6"
                      class="mr-1"
                    >
                      <rect
                        width="12"
                        height="6"
                        rx="3"
                        :style="{ fill: item.color }"
                      />
                    </svg>
                    <div v-if="item.label !== 'Undelivered'">
                      {{ item.label }}
                    </div>
                    <div v-else>
                      <div v-if="!channelData[channel].errors">
                        {{ item.label }}
                      </div>
                      <div
                        v-else
                        class="text-link flex items-center"
                        @click="() => handleMenuClick(channel)"
                      >
                        {{ item.label }}
                        <i
                          :class="`${expanded.includes(channel) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}
                          el-icon--right text-base`"
                        />
                      </div>
                    </div>
                  </div>
                  <p class="text-black text-base font-bold">
                    {{ item.value.toLocaleString() }}
                  </p>
                  <p class="text-sm font-normal mt-2">
                    ({{ computeValuePercentage(item, channelData[channel].total) }})
                  </p>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="expanded.includes(channel) && formatUndelivered(channel).length"
            class="expandable-section mt-2"
          >
            <UndeliveredErrorCode :data="formatUndelivered(channel)" />
          </div>
        </div>
        <!-- here -->
        <p
          v-if="index + 1 != campaignMeta.channels.length"
          class="flex items-center mb-4 mx-5 w-1/2"
        >
          <i class="el-icon-bottom text-2xl" />
          <i18n
            path="mcs.campaigns.fallback_details[1]"
            tag="span"
            class="text-grey-dark text-xs ml-2"
          >
            <template #seconds>
              <strong class="text-black">{{ getFallbackDuration(channel) }} </strong>,&nbsp;
            </template>
          </i18n>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProgressBar from '@/components/ProgressBar.vue';
import CampaignListMixin from '@/mixins/campaign-list';
import { getHumanReadable } from '@/utils/common';
import UndeliveredErrorCode from './UndeliveredErrorCode.vue';

export default {
  name: 'FallbackDetails',

  components: {
    ProgressBar,
    UndeliveredErrorCode,
  },

  mixins: [CampaignListMixin],

  props: {
    data: {
      type: Object,
      required: true,
    },

    campaignMeta: {
      type: Object,
      default: () => {},
    },
    subAccount: {
      type: String,
      required: true,
    },
  },

  data() {
    const { totalSent } = this.data.summary;

    return {
      totalMessages: totalSent,
      channelData: {},
      expanded: [],
    };
  },

  computed: {
    ...mapGetters({
      getSubAccounts: 'user/getSubAccounts',
    }),
  },

  created() {
    const self = this;
    // Populate channelData Map
    this.data.channels.forEach((channel) => {
      const {
        ChannelType: channelType,
        MsgDelivered: deliveredValue,
        MsgRead: readValue,
        MsgRejected: rejectedValue,
        MsgUndelivered: undeliveredValue,
        MsgTotal: total,
        UndeliveredErrors: undeliveredErrors,
      } = channel;

      this.channelData[channelType] = {
        total,
        errors: (undeliveredErrors || []).length > 0,
        chartData: [
          {
            order: 1,
            label: self.$t('column_labels.delivered'),
            value: deliveredValue,
            color: '#403a65',
          },
          {
            order: 2,
            label: channelType !== 'SM' ? 'Read' : '',
            value: readValue,
            color: '#10b981',
          },
          {
            order: 3,
            label: self.$t('column_labels.undelivered'),
            value: undeliveredValue,
            color: '#f59e0b',
          },
          {
            order: 4,
            label: self.$t('column_labels.rejected'),
            value: rejectedValue,
            color: '#dc2626',
          },
        ],
      };
    });
  },

  methods: {
    formatUndelivered(type) {
      const channel = this.data.channels.find(c => c.ChannelType === type);
      return channel.UndeliveredErrors.map(error => ({
        code: error.Code,
        message: error.Message,
        count: error.Count,
        percentOfTotal: this.toPercentString((error.Count / (channel.MsgTotal || 1)) * 100),
      }));
    },
    handleMenuClick(channel, expandAll = false) {
      if (this.expanded.includes(channel)) {
        if (!expandAll) this.expanded = this.expanded.filter(c => c !== channel);
      } else {
        this.expanded.push(channel);
      }
    },

    getFallbackDuration(ChannelType) {
      const config = this.getSubAccounts.filter(sa => sa.SubAccountId === this.subAccount)[0]
        .FallbackConfig;
      if (config) {
        const channelConf = config.filter(conf => conf.ChannelType === ChannelType);
        if (channelConf.length) return getHumanReadable(channelConf[0].FallbackDelaySec);
        return '50 seconds';
      }
      return '50 seconds';
    },

    computeValuePercentage(data, total = 0) {
      return this.toPercentString((data.value / (total || 1)) * 100);
    },

    toPercentString(n) {
      return `${n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}%`;
    },
  },
};
</script>
<style lang="scss">
.mcs-fallback-detail {
  .text-link {
    cursor: pointer;
    color: var(--accent-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .expandable-section {
    border-top: 1px solid #e0e0e0;
  }
}
</style>
