import http from '@/utils/http';

export default {
  // Fetch CA templates
  async getChannelInstances({ commit }) {
    const slug = '/chats/channels/all';

    try {
      const { data } = await http.v2.get(`${slug}`);

      commit('SET_CHANNEL_INSTANCES', { channelInstances: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
