<template>
  <div class="ca-upload-file mb-5 w-2/3">
    <el-form
      @submit.prevent.native
      :model="form"
      :rules="rules"
      ref="form"
      hide-required-asterisk
    >
      <el-form-item
        prop="fileUrl"
        class="file-url"
      >
        <template slot="label">
          <span
            v-if="data.title"
            class="text-sm text-black font-medium block leading-none mb-2"
            :class="[{ 'label__asterisk': required }]"
          >
            {{ data.title }}
          </span>
          <div class="text-grey-dark text-xs leading-none mb-2">
            <span class="font-medium">{{ $t('upload_labels.supported_types') }} :</span>
            {{ mediaInfo(data.type, maxFileSize, supportedFiles).supportedFilesTxt.join(', ') }}
            ({{ $t('upload_labels.size_less_than', { size: `${mediaInfo(data.type, maxFileSize, supportedFiles).maxSize}MB`})}})
          </div>
        </template>
        <el-input
          v-model="form.fileUrl"
          @change="handleChange"
          type="url"
          size="small"
          :placeholder="`${$t('mcs.messages.chat_apps.custom_fields[4]')} e.g https://i.ibb.co/5hGh9nM/download-1.jpg`"
        />
      </el-form-item>
    </el-form>
    <p v-if="onlySupportUrl" class="text-xs">
      {{ $t('mcs.messages.chat_apps.media_url[5]') }}
      <a class="text-blue no-underline" href="https://imgbb.com/" target="_blank" rel="noopener noreferrer">imgbb.com</a>, <a class="text-blue no-underline" href="https://beeimg.com/" target="_blank" rel="noopener noreferrer">beeimg.com</a> and
      <a class="text-blue no-underline" href="https://imgbox.com/" target="_blank" rel="noopener noreferrer">imgbox.com</a>
    </p>
    <div v-if="!value.url">
      <div v-if="!onlySupportUrl">
        <span class="text-xs text-grey leading-none mt-3 mb-6 block">
          {{ $t('upload_labels.or_upload') }} {{ data.type || 'file' }}
        </span>
        <div
          @click="openModal()"
          class="ca-upload-file__button text-xs bg-blue-lightest p-3 rounded flex items-center
          hover:bg-blue-lighter"
        >
          <i class="el-icon-upload my-0 mr-2 text-blue-light text-3xl"></i>
          <!-- <span class="block">
            Drag & drop to upload or <span class="text-blue">browse</span> to chooose a file.
          </span> -->
          <i18n
            path="upload_labels.drag_drop"
            tag="span"
            class="block"
          >
            <template #br>
              <br>
            </template>
              <template #browse>
              <em class="text-blue roman">{{$t('actions.browse')}}</em>
            </template>
          </i18n>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-xs bg-blue-lightest p-3 rounded flex">
        <div class="flex-auto pr-3 w-3/5 truncate">
          <img
            :src="value.previewUrl"
            :alt="value.previewUrl"
            class="tiny-image align-middle"
          />
          <a
            download
            :href="value.previewUrl"
            target="_blank"
            rel="noopener noreferrer"
            class="ml-2 no-underline text-blue hover:text-blue-light"
          >
            {{ value.fileName }}
          </a>
        </div>
        <div class="flex-shrink">
          &nbsp;
          <span v-if="value.fileSize">
            {{ Number(getFileSize(value.fileSize).size).toLocaleString() }}
            {{ getFileSize(value.fileSize).label }}
          </span>
        </div>
      </div>
      <div class="mt-2 flex text-xs">
        <span
          v-if="!onlySupportUrl"
          @click="openModal()"
          class="cursor-pointer text-blue hover:text-blue-lighter mr-3"
        >
          {{ $t('mcs.messages.chat_apps.media_url[0]')}}
        </span>
        <span
          @click="discard()"
          class="cursor-pointer text-red hover:text-red-lighter"
        >
          {{ $t('actions.discard') }}
        </span>
      </div>
    </div>
    <UploadMedia
      :data="modal"
      :visible="visible"
      @apply-data="applyMediaData"
      @close-modal="closeModal"
    />
  </div>
</template>
<script>
import MediaMixin from '@/mixins/media';

import { formatBytes } from '@/utils/common';
import UploadMedia from './UploadMedia.vue';

export default {
  name: 'message_ca_upload_file',

  components: {
    UploadMedia,
  },

  mixins: [MediaMixin],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    required: {
      type: Boolean,
      default: true,
    },
    maxFileSize: {
      type: Number,
      default: 0,
    },
    supportedFiles: {
      type: Array,
      default: () => [],
    },
    onlySupportUrl: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const self = this;

    const checkImage = (rule, value, callback) => {
      const img = new Image();

      img.src = value;

      img.onload = () => {
        callback();
      };

      img.onerror = (e) => {
        callback(new Error(self.$t('mcs.messages.chat_apps.media_url[6]')));
      };
    };

    return {
      form: {
        fileUrl: '',
        previewUrl: '',
      },
      visible: false,
      modal: {
        action: 'upload',
        title: '',
        index: -1,
        type: '',
        maxFileSize: 0,
        supportedFiles: [],
      },
      rules: {
        fileUrl: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: self.$t('validations.required', { value: self.$t('fields.media_url') }),
          },
          {
          // eslint-disable-next-line no-useless-escape
            pattern: new RegExp(this.$vars.URL_REGEX, 'g'),
            trigger: ['blur', 'change'],
            message: self.$t('validations.valid', { value: self.$t('fields.media_url') }),
          },
          {
            validator: checkImage,
            trigger: ['blur'],
          },
        ],
      },
    };
  },

  created() {
    if (this.value) {
      this.form.fileUrl = this.value.url;
    } else {
      this.form.fileUrl = '';
    }
  },

  watch: {
    value(v) {
      if (v) {
        this.form.fileUrl = v.url;
      }
    },
  },

  methods: {
    getFileSize(size) {
      const fileSize = parseInt(size, 10);

      return formatBytes(fileSize);
    },

    handleChange(s, meta = {}) {
      this.$refs.form.validateField('fileUrl', (err) => {
        if (!err) {
          const type = meta.fileSize ? 'upload' : 'input';

          if (type === 'input') {
            const splitImageUrl = s.split('/');
            // eslint-disable-next-line no-param-reassign
            meta.fileName = splitImageUrl[splitImageUrl.length - 1] || '';
            // eslint-disable-next-line no-param-reassign
            meta.previewUrl = s;
          }

          this.$emit('update-image-inputs', { url: s, type, ...meta }, 'imageInputs');
        } else {
          this.$emit('update-image-inputs', undefined, 'imageInputs');
        }
      });
    },

    discard() {
      this.reset();
    },

    reset() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.form.fileUrl = '';
      this.$emit('update-image-inputs', undefined, 'imageInputs');
    },

    openModal() {
      this.visible = true;

      this.modal = {
        action: 'upload',
        title: this.data.title,
        index: 0,
        type: this.data.type,
        maxFileSize: this.maxFileSize,
        supportedFiles: this.supportedFiles,
      };
    },

    closeModal() {
      this.visible = false;
      this.modal = {
        action: 'upload',
        title: '',
        index: -1,
        type: '',
      };
    },

    applyMediaData(row, data) {
      const { fileUrl, previewUrl } = data;
      this.form.fileUrl = fileUrl;
      this.form.previewUrl = previewUrl;
      this.handleChange(fileUrl, data);
    },
  },
};
</script>
<style lang="scss">

.ca-upload-file {
  &__button {
    position: relative;
    cursor: pointer;
  }

  .tiny-image {
    width: 20px;
    height: 20px;
  }

  .file-url {
    .el-form-item__label {
      text-align: left;
    }
  }
}
</style>
