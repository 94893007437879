const handlePackageAddon = (addonId) => {
  let user;
  user = localStorage.getItem('CPV3_User');
  user = JSON.parse(user) || {};

  const { Status: hasSubscription } = user.Subscription || false;
  if (!hasSubscription) return false;

  const { Package: subscriptionPackage } = user.Subscription || {};
  let addons;
  if (subscriptionPackage
			&& subscriptionPackage.subscriptions
			&& subscriptionPackage.subscriptions.length
  ) {
    // eslint-disable-next-line prefer-destructuring
    addons = subscriptionPackage.subscriptions[0].addons;
  }

  if (addons && addons.length) {
    if (addons.some(item => item.addon.id.includes(addonId))) {
      return true;
    }
  }

  return false;
};

const getSubscribedCountry = () => {
  let user;
  user = localStorage.getItem('CPV3_User');
  user = JSON.parse(user) || {};

  const { Status: hasSubscription } = user.Subscription || false;
  if (!hasSubscription) return null;

  const { Package: subscriptionPackage } = user.Subscription || {};
  let subscribedCountry;
  if (subscriptionPackage
			&& subscriptionPackage.subscriptions
			&& subscriptionPackage.subscriptions.length
  ) {
    const { prices } = subscriptionPackage.subscriptions[0];

    if (prices.length) subscribedCountry = (prices[0].billingCountryCode || '').toUpperCase();
  }

  return subscribedCountry;
};

export {
  getSubscribedCountry,
  handlePackageAddon,
};
