<template>
  <el-dialog
    :title="data.title"
    :visible.sync="visible"
    width="40%"
    :before-close="closeModal"
    @open="openModal"
    top="60px"
  >
    <div class="ca-location-media">
      <el-form
         ref="form"
        :model="form"
        :rules="rules"
        @submit.prevent.native
      >
        <el-form-item
          :label="$t('mcs.messages.chat_apps.location_media[0]')"
          prop="lat"
        >
          <el-input
            :style="{width: '80%'}"
            size="small"
            :placeholder="$t('mcs.messages.chat_apps.location_media[0]')"
            v-model="form.lat"
          />
        </el-form-item>
        <el-form-item
          :label="$t('mcs.messages.chat_apps.location_media[1]')"
          prop="long"
        >
          <el-input
            :style="{width: '80%'}"
            size="small"
            :placeholder="$t('mcs.messages.chat_apps.location_media[1]')"
            v-model="form.long"
          />
        </el-form-item>
      </el-form>
      <div class="mt-10">
        <el-button
          size="small"
          type="primary"
          @click="submit"
        >
          {{ $t('mcs.messages.chat_apps.location_media[2]') }}
        </el-button>
        <el-button
          size="small"
          class="ml-5"
          @click="closeModal"
        >
          {{ $t('actions.discard') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>

export default {
  name: 'message_ca_location_media',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const self = this;
    return {
      form: {
        lat: '',
        long: '',
      },

      rules: {
        lat: [
          {
            required: true,
            trigger: 'blur',
            message: self.$t('validations.required', { value: self.$t('fields.latitude') }),
          },

          {
            // eslint-disable-next-line no-useless-escape
            pattern: /^[-+]?[0-9]{1,7}(\.[0-9]+)?$/,
            trigger: 'blur',
            message: self.$t('validations.valid', { value: self.$t('fields.latitude') }),
          },
        ],

        long: [
          {
            required: true,
            trigger: 'blur',
            message: self.$t('validations.required', { value: self.$t('fields.longitude') }),
          },

          {
            // eslint-disable-next-line no-useless-escape
            pattern: /^[-+]?[0-9]{1,7}(\.[0-9]+)?$/,
            trigger: 'blur',
            message: self.$t('validations.valid', { value: self.$t('fields.longitude') }),
          },
        ],
      },
    };
  },

  methods: {
    closeModal() {
      this.resetForm();
      this.$emit('close-modal');
    },

    openModal() {
      this.resetForm();
    },

    submit() {
      try {
        const data = JSON.parse(JSON.stringify(this.form));
        const row = JSON.parse(JSON.stringify(this.data));

        this.$refs.form.validate((valid) => {
          if (valid) {
            this.$emit('apply-data', row, data);
            this.resetForm();
            this.$emit('close-modal');
          }
        });
      } catch (e) {
        // do nothing
      }
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.form.lat = '';
      this.form.long = '';
    },
  },
};
</script>
