<template>
  <div class="w-full">
    <img v-if="imgSrc" :src="imgSrc" alt="wa-image" @error="onError" class="w-full" />
    <div v-else :class="{ 'py-6 px-2': isPreview }">
      <img :src="urlResolver(
          bgUrl,
          require(`@/assets/media/image-green.svg`)
        )" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { urlResolver } from '@/utils/common';

export default {
  name: 'imageDisplay',

  props: {
    fileUrl: {
      type: String,
      default: '',
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      imgSrc: '',
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
    };
  },

  watch: {
    fileUrl(n) {
      if (n) {
        this.downloadImage();
      } else {
        this.imgSrc = '';
      }
    },
  },

  created() {
    this.downloadImage();
  },

  methods: {
    ...mapActions({
      downloadFileUrl: 'common/downloadFileUrl',
    }),

    urlResolver(bgurl, path) {
      return urlResolver(bgurl, path);
    },

    onError() {
      this.imgSrc = '';
    },

    downloadImage() {
      if (!this.fileUrl) return;

      if (this.fileUrl.match(/s3\/uploads/i)) {
        this.downloadFileUrl({ fileUrl: this.fileUrl }).then((response) => {
          this.imgSrc = response.data.url;
        }).catch((err) => {
          if (window.Bugsnag) window.Bugsnag.notify(err);
        });
      }

      this.imgSrc = this.fileUrl;
    },
  },
};
</script>
