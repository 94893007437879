const constantData = {
  SMS_TO_SURVEY_DOWNLOAD_MAX_LIMIT: 50000,
  LOCALE: 'en-US',
  DECIMAL_PLACES: 2,
  SCHEDULED_TIME_LIMIT_IN_MINS: 3,
  PRICE_DECIMAL: 6,
  SMS_MSG_PER_SEC: 250,
  CHAT_APP_MSG_PER_SEC: 70,
  VOICE_LETTER_PER_MS: 50,
  SMS_ENGAGE_URL: 'https://smstoweb.net?sid=',
  SMS_ENGAGE_URL_REGEX: /(https:\/\/smstoweb\.net\?sid=[^\s\b\n]*)/,//eslint-disable-line
  SMS_ENGAGE_SID_REGEX: /\A?sid=[^&]+&*/, //eslint-disable-line
  SENDER_ID_RULES: [
    'Alphanumeric allows a maximum of 11 characters and numeric can go up to 15 characters.',
    'Only a single space is allowed for alphanumeric Sender IDs.',
    'For alphanumeric Sender IDs, to ensure delivery, please contact sales',
  ],
  EMAIL_REGEX: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  URL_REGEX: /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/, //eslint-disable-line
  DYNAMIC_FIELD_REGEX: /({{.+}})/g,
  LAT_LONG_REGEX: /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/,
  SENDER_ID_REGEX: /^(?=.*[a-zA-Z -_.])([a-zA-Z0-9 -_.]{1,11})$|^([+?\d]{1,15})$/,
};

const Vars = {
  install(Vue) {
    Vue.mixin({
      created() {
        // Global constantData instance
        this.$vars = constantData;
      },
    });

    Vue.prototype.$vars = constantData; // eslint-disable-line
  },
};

export default Vars;
