<template>
  <ul class="breadcrumbs list-reset" arial-label="Breadcrumb" role="navigation">
    <li
      v-for="(item, i) in items"
      class="breadcrumbs__item text-sm"
      :key="i"
    >
      <a
        class="breadcrumbs__link"
        :class="[
          { 'breadcrumbs__link--active': value === i && !isError(item) },
          { 'breadcrumbs__link--disable': item.disable },
          { 'breadcrumbs__link--error': isError(item) }
        ]"
        @click="changeHandler(item, i)"
      >
        <transition name="slide-up" mode="out-in">
          <span :key="item.label" class="block">
            {{ item.label }}
          </span>
        </transition>
      </a>
      <i
        v-if="i < items.length-1"
        class="el-icon-arrow-right mx-2 color-grey-lightest"
      ></i>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'breadcrumbs',

  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isError(item) {
      return item.error && !item.disable;
    },

    changeHandler(item, index) {
      if (!item.disable) {
        this.$emit('click-link', index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  color: grey;

  &__item {
    display: flex;
    align-items: center;
  }

  &__link {
    cursor: pointer;

    &--disable {
      pointer-events: none;
      color: lightgrey;
    }

    &--error {
      color: var(--red);
    }

    &:hover {
      color: var(--blue);
    }

    &--active {
      color: var(--blue);
    }
  }

  .fade-up-enter-active {
    transition: all 0.2s ease;
  }

  .fade-up-leave-active {
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
}
</style>
