const setCaretPosition = (textField, caretPos) => {
  const el = textField;

  el.value = el.value;
  // ^ this is used to not only get "focus", but
  // to make sure we don't have it everything -selected-
  // (it causes an issue in chrome, and having it doesn't hurt any other browser)

  if (el !== null) {
    if (el.createTextRange) {
      const range = el.createTextRange();

      range.move('character', caretPos);
      range.select();
    } else if (el.selectionStart || el.selectionStart === 0) {
      el.focus();
      el.setSelectionRange(caretPos, caretPos);
    } else {
      el.focus();
    }
  }
};

const insertAtCursor = (textField, newText) => {
  let sel = null;
  let startPos = 0;
  let endPos = 0;
  let extractStart = '';
  let extractEnd = '';

  // IE support
  if (document.selection) {
    textField.focus();
    sel = document.selection.createRange();
    sel.text = newText;
  } else { // other browsers
    // get start and end positions
    startPos = textField.selectionStart;
    endPos = textField.selectionEnd;

    // extract start and end string
    extractStart = textField.value.substring(0, startPos);
    extractEnd = textField.value.substring(endPos, textField.value.length);

    // insert new text in between start and end depending on the cursor
    textField.value = `${extractStart}${newText}${extractEnd}`; // eslint-disable-line

    const pos = startPos + newText.length;

    // add focus and set cursor position
    setCaretPosition(textField, pos);
  }

  return textField;
};

export { insertAtCursor as default };
