import http from '@/utils/http';

export default {
  // Fetch CA templates
  async getWATemplates({ commit }, payload = {}) {
    const { channelId } = payload;
    const slug1 = `/chats/templates?channelId=${channelId}&status=APPROVED`;
    const slug2 = `/chats/templates/enums?channelId=${channelId}`;

    try {
      const { data } = await http.v2.get(`${slug1}`);
      const { data: resp } = await http.v2.get(`${slug2}`);

      resp.category = Object.keys(resp.category).reduce((a, b) => {
        const newCategories = ['AUTHENTICATION', 'UTILITY', 'MARKETING'];

        if (newCategories.includes(b)) {
          // eslint-disable-next-line no-param-reassign
          a[b] = resp.category[b];
        }

        return a;
      }, { });

      commit('SET_TEMPLATES', { templates: data });
      commit('SET_ENUMS', { enums: resp });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Fetch SMS templates
  async getSMSTemplates({ commit }) {
    try {
      const { data } = await http.v1.get('/templates');
      commit('SET_TEMPLATES', { templates: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
