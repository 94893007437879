<template>
  <div class="border-grey-lighter border solid flex flex-col w-full relative rounded bg-white">
    <div class="flex-shrink border-b border-grey-lighter px-3 py-3 flex justify-between bg-grey-lightest rounded-t items-center">
      <div class="w-4/5 text-sm">
        <span
          v-if="type === 'contacts' || type === 'directory'"
          class="font-semibold"
        >
          {{ `${total.toLocaleString()} contacts`}}
        </span>
        <span
          v-if="type === 'number'"
          class="font-semibold"
        >
          {{ `${total.toLocaleString()} typed numbers`}}
        </span>
        <span
          v-if="type === 'groups'"
          class="font-semibold"
        >
          {{ `${total.toLocaleString()} Groups`}} ({{groupsContactsTotal.toLocaleString()}} contacts)
        </span>
        <span
          v-if="contacts.length > limit"
          class="ml-3"
        >
          <span class="align-middle text-2xl text-grey">
            &bull;
          </span>
          <a
            href="#" @click.prevent="$emit('view-all-contacts',type)"
            class="anchor-link align-middle no-underline"
          >
            See all
          </a>
        </span>
      </div>
      <div class="w-1/5 text-right">
        <i
          class="icon-novo-close-circle text-2xl cursor-pointer text-grey-darker hover:text-black"
          @click="$emit('remove-all-contacts', type)"
        />
      </div>
    </div>
    <div class="list-wrapper" :style="{ maxHeight: hasDirectory ? 'calc(100vh - 600px)' : 'calc(100vh - 450px)'}">
      <div
        v-for="(v,i) in contacts.slice(0, limit)"
        :key="i"
        class="flex items-center border-t border-grey-lighter text-sm px-3 py-3 hover:bg-grey-lightest"
      >
        <div
          v-if="type === 'contacts'"
          class="w-2/5 truncate mr-2"
        >
          <span v-if="v.firstName && v.lastName">
            {{ v.firstName }} {{ v.lastName }}
          </span>
          <span v-else>
            -
          </span>
        </div>
        <div
          v-if="type === 'contacts'"
          class="w-2/5 truncate"
        >
          <span :class="`mr-1 flag-icon flag-icon-squared flag-icon-${getPhoneNumberInfo(`+${v.addresses.msisdn}`).regionCode.toLowerCase()}`" />
          {{ v.addresses && v.addresses.msisdn &&  getPhoneNumberInfo(v.addresses.msisdn).valid
            ? getPhoneNumberInfo(`+${v.addresses.msisdn}`).number.international
            : v.addresses && v.addresses.msisdn ? v.addresses.msisdn : '-'}}
        </div>

        <div
          v-if="type === 'groups'"
          class="w-2/5 truncate mr-2"
        >
          <span v-if="v.name">
            {{ v.name }}
          </span>
          <span v-else>
            -
          </span>
        </div>
        <div
          v-if="type === 'groups'"
          class="w-2/5 truncate"
        >
          {{ v.contacts.toLocaleString() }} contacts
        </div>

        <div
          v-if="type === 'number'"
          class="w-4/5 truncate"
        >
          <span :class="`mr-2 flag-icon flag-icon-squared flag-icon-${getPhoneNumberInfo(v).regionCode.toLowerCase()}`" />
          {{ getPhoneNumberInfo(v).valid ? getPhoneNumberInfo(v).number.international : v || '-'}}
        </div>

        <div v-if="type === 'directory'"
          class="w-1/5 truncate"
        >
          <el-button
            icon="icon-novo-user"
            circle
            disabled
            size="small"
          ></el-button>
          <span  class="ml-2 truncate">
            {{ v.firstName }}  {{ v.lastName }}
          </span>
        </div>

        <div v-if="type === 'directory'"
          class="w-1/5 truncate"
        >
          <span :class="`mr-1 flag-icon flag-icon-squared flag-icon-${getPhoneNumberInfo(`+${v.phoneNumber}`).regionCode.toLowerCase()}`" />
          {{ getPhoneNumberInfo(`+${v.phoneNumber}`).valid ? getPhoneNumberInfo(`+${v.phoneNumber}`).number.international : v.phoneNumber || '-'}}
        </div>

        <div v-if="type === 'directory'"
          class="w-1/5 truncate"
        >
          <span  class="ml-1 truncate">
            {{ v.department }}
          </span>
        </div>


        <div v-if="type === 'directory'"
          class="w-1/5 truncate"
        >
          <span  class="ml-1 truncate">
            {{ v.location }}
          </span>
        </div>

        <div class="w-1/5 text-right">
          <i
            class="icon-novo-close-circle text-2xl cursor-pointer text-grey-darker hover:text-black"
            @click="$emit('remove-contact', type, v)"
          />
        </div>

      </div>
    </div>
  </div>
</template>
<script>
// import {
//   mapGetters,
//   mapActions,
// } from 'vuex';
import PhoneNumberMixin from '@/mixins/phone-number';

export default {
  name: 'ContactsList',

  mixins: [PhoneNumberMixin],

  props: {
    contacts: {
      type: Object,
      default: () => ([]),
    },
    limit: {
      type: Number,
      default: 20,
    },

    hasDirectory: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: '',
    },

    total: {
      type: Number,
      default: 0,
    },

    groupsContactsTotal: {
      type: Number,
      default: 0,
    },
  },

  computed: {

  },

  created() {

  },

  methods: {

  },
};
</script>
<style scoped>
  .list-wrapper {
    overflow-y: auto;
  }
</style>
