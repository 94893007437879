const handlePackageLimit = (moduleId) => {
  let user;
  user = localStorage.getItem('CPV3_User');
  user = JSON.parse(user) || {};

  const { Status: hasSubscription } = user.Subscription || false;
  if (!hasSubscription) return true;

  const { Package: subscriptionPackage } = user.Subscription || {};
  let stiggEntitlements;
  if (subscriptionPackage
      && subscriptionPackage.subscriptions
      && subscriptionPackage.subscriptions.length
  ) {
    const { entitlements, inheritedEntitlements } = subscriptionPackage.subscriptions[0].plan;
    stiggEntitlements = [...entitlements, ...inheritedEntitlements];
  } else {
    stiggEntitlements = subscriptionPackage;
  }

  if (stiggEntitlements.some(item => item.feature.id.includes(moduleId))) {
    return true;
  }

  return false;
};

export default handlePackageLimit;
