<template>
  <div class="flex mb-3 p-5 bg-grey-lightest border border-grey-light rounded-lg haslogo">
    <div class="flex-shrink py-2">
      <!-- <div class="rounded-full h-12 w-12 flex items-center justify-center bg-green-lightest">
        <i class="font-medium el-icon-check text-green" />
      </div> -->
      <img :src="getIcon()" alt="icon"/>
    </div>
    <div class="flex-auto ml-5 py-2">
      <p class="text-grey-dark text-sm mb-2">{{ $t('mcs.recipients') }}</p>
      <p class="text-black text-lg font-medium mb-2">{{Number(total).toLocaleString()}}</p>
      <p class="text-grey-dark text-sm">({{parseFloat(validPercentage).toFixed(1)}}% {{ $t('mcs.valid') }})</p>
    </div>
  </div>
</template>

<script>

import recepientIcon from '@/assets/misc/recepients.svg';

export default {
  name: 'send_recepients',

  props: {
    total: {
      type: Number,
      default: 0,
    },

    validPercentage: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    getIcon() {
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${recepientIcon}`;
    },
  },
};
</script>
