<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col">
    <div class="summary p-6">
      <div
        v-if="form.Recipients.source.match(/UPLOAD/i)"
        class="rounded bg-grey-lighter p-3 flex justify-between
        text-xs w-full items-center"
      >
        <div>
          <p class="text-grey-dark">{{ $t('mcs.recip.summary[0]') }}</p>
          <p class="text-black mt-2">
            {{ form.Recipients.FileName }}
            <strong v-if="form.Recipients.FileSize">
              ({{ form.Recipients.FileSize }})
            </strong>
          </p>
        </div>
        <div>
          <button
            class="bg-red-light p-2 px-3 rounded text-white mr-2 flex items-center"
            size="small"
            @click.prevent="discardUpload"
          >
            <i class="icon-novo-trash cursor-pointer mr-2 text-lg" /> Discard
          </button>
        </div>
      </div>
      <div
        v-else
        class="border border-grey-light bg-grey-lighter p-3 rounded-sm flex justify-between
        text-xs w-full items-center"
      >
        <div>
          <p class="text-grey-dark">{{ $t('mcs.recip.summary[2]') }}</p>
          <p class="text-black mt-2 ellipsis">
            {{ getContactAndGroups().toString().replace(/,/g, ', ') }}
          </p>
        </div>
        <div>
          <el-button
            size="small"
            class="mr-2"
            @click.prevent="discardUpload"
          >
            {{ $t('mcs.recip.summary[1]') }}
          </el-button>
        </div>
      </div>

      <div>
        <div class="my-5">
          <div v-if="form.Recipients.source.match(/UPLOAD/i)">
            <h3 class="mb-2 text-sm text-black tracking-normal">{{ $t('mcs.recip.summary[3]') }}</h3>
            <p class="text-xs text-grey-dark leading-tight">
              {{ $t('mcs.recip.summary[4]') }}
            </p>
            <div v-if="customFields.length" class="flex items-center my-2">
              <TagList
                v-model="customFields"
                :min="1"
                :disableDelete="['MSISDN']"
                @change="updateCustomFields"
                class="mr-3"
              />
              <p class="text-xs text-grey-dark mt-1">
              <a
                @click.prevent="$emit('change-step', 'SET_CUSTOM_FIELD')"
                class="text-blue hover:text-blue-light cursor-pointer">
                {{ $t('mcs.recip.summary[5]') }}
              </a>
              </p>
            </div>
          </div>
          <h3 class="mt-5 mb-2 text-sm text-black tracking-normal"> {{ $t('mcs.recip.summary[6]') }}</h3>
          <div v-loading="loadDestinations" class="flex relative items-stretch -mx-1">
            <div class="w-full flex flex-wrap px-1 -m-1">
              <SummaryCard class="w-1/2 p-1">
                <template slot="body">
                  <div class="flex justify-between">
                    <div>
                      <h4 class="text-sm text-black font-normal">Total contacts</h4>
                      <span
                        v-if="form.Recipients.source.match(/UPLOAD/i)"
                        class="text-3xl text-black my-3 mt-6 block">
                        {{ formatNumber(summaryData.Total) }}
                      </span>
                      <span v-else class="text-3xl text-black my-3 mt-6 block">
                        {{ formatNumber(getTotalNumbers()) }}
                      </span>
                      <p class="text-xs text-grey-dark leading-tight">
                        recipients in the list
                      </p>
                    </div>
                    <i class="text-xl font-medium icon-novo-user-groups-line text-black"></i>
                  </div>
                </template>
              </SummaryCard>

              <SummaryCard class="w-1/2 p-1">
                <template slot="body">
                  <div class="flex justify-between">
                    <div>
                      <h4
                        class="text-sm text-black font-normal"
                        :class="[{ 'text-red': !isValid() }]"
                      >
                        {{ $t('column_labels.valid') }}
                      </h4>
                      <span
                        v-if="form.Recipients.source.match(/UPLOAD/i)"
                        class="text-3xl text-black my-3 mt-6 block"
                        :class="[{ 'text-red': !isValid() }]"
                      >
                        {{ formatNumber(summaryData.ValidEntries) }}
                      </span>
                      <span v-else class="text-3xl text-black my-3 mt-6 block">
                        {{ formatNumber(summaryData.ContactsUniqueCount || 0) }}
                      </span>
                      <p class="text-xs text-grey-dark leading-tight">
                        recipients who will receive the message
                      </p>
                    </div>
                    <i class="el-icon-check text-xl text-teal-light font-medium"></i>
                  </div>
                </template>
              </SummaryCard>

              <SummaryCard class="w-1/2 p-1">
                <template slot="body">
                  <div class="flex justify-between">
                    <div>
                      <h4 class="text-sm text-black font-normal"> {{ $t('mcs.recip.summary[9]') }}</h4>
                      <span
                        v-if="form.Recipients.source.match(/UPLOAD/i)"
                        class="text-3xl text-black my-3 mt-6 block">
                        {{ formatNumber(summaryData.InvalidEntries) }}
                      </span>
                      <span v-else class="text-3xl text-black my-3 mt-6 block">
                        {{
                          formatNumber(
                            (summaryData.ContactsUniqueCount || 0) - getTotalNumbers()
                          )
                        }}
                      </span>
                      <p class="text-xs text-grey-dark leading-tight">
                        numbers are malformed or have errors
                      </p>
                    </div>
                    <i class="el-icon-circle-close text-xl text-red-light font-medium"></i>
                  </div>
                </template>
                <template slot="footer">
                  <el-button
                    :disabled="Boolean((summaryData.ContactsUniqueCount || 0) - getTotalNumbers() === 0)"
                    size="small"
                    class="mr-2"
                    @click="downloadInvalidContacts()"
                  >
                    {{ $t('mcs.recip.summary[11]') }}
                  </el-button>
                </template>
              </SummaryCard>

              <SummaryCard class="w-1/2 p-1">
                <template slot="body">
                  <div class="flex justify-between">
                    <div>
                      <h4 class="text-sm text-black font-normal">{{ this.$t('column_labels.duplicates') }}</h4>
                      <span
                        v-if="form.Recipients.source.match(/UPLOAD/i)"
                        class="text-3xl text-black my-3 mt-6 block">
                        {{ formatNumber(summaryData.DuplicateEntries) }}
                      </span>
                      <span v-else class="text-3xl text-black my-3 mt-6 block">
                        {{ formatNumber(summaryData.ContactsDuplicatesCount || 0) }}
                      </span>
                      <transition name="fade-up" mode="out-in">
                        <div :key="form.Recipients.AllowDuplicates">
                          <p v-if="form.Type === 'VOICE'" class="text-xs text-grey-dark leading-tight">
                            {{ $t('mcs.recip.summary[12]') }}
                          </p>
                          <p v-else class="text-xs text-grey-dark leading-tight">
                            {{ $t('mcs.recip.summary[13]') }}
                          </p>
                        </div>
                      </transition>
                    </div>
                    <i class="icon-novo-error text-xl text-orange-light font-medium"></i>
                  </div>
                </template>
                <template slot="footer">
                  <el-switch
                    :disabled="!hasDuplicates() || form.Type === 'VOICE'"
                    :value="form.Recipients.AllowDuplicates"
                    @change="v => updateRecipients({ AllowDuplicates: v })"
                    active-color="#14CD65"
                    :active-text="$t('mcs.recip.summary[19]')">
                  </el-switch>
                </template>
              </SummaryCard>
            </div>
            <div class="w-full px-1">
              <SummaryCard class="w-full">
                <template slot="body">
                  <h4 class="text-lg text-black font-normal">{{ $tc('column_labels.destinations', 2) }}</h4>
                  <div class="h-full flex justify-center items-center">
                    <Doughnut
                      :key="chartKey"
                      :chart-options="chartOptions"
                      :chart-data="chartData"
                      chart-id="destination-dougnut-chart"
                      dataset-id-key=""
                      :plugins="plugins"
                      css-classes=""
                      :styles="{}"
                      :width="600"
                      :height="300"
                    />
                  </div>
                </template>
                <template slot="footer">
                  <p v-if="!destinationsData.length" class="text-grey-dark my-1 inline-block">
                    {{ $t('mcs.recip.summary[14]') }}
                  </p>
                </template>
              </SummaryCard>
              <div v-if="this.form.Recipients.source.match(/UPLOAD/i) && form.Recipients.Location" class="mt-2 text-sm">
                <div
                  v-if="showCountryCodeNotif" 
                  class="flex relative p-3 border border-grey-lighter border-l border-gray-500 rounded" 
                  style="column-gap: 10px;"
                >
                  <div class="left-border" />
                  <span class="text-base icon-novo-info-circle" />
                  <p>
                    {{ $t('mcs.recip.summary[20]') }} 
                    <span class="text-black font-bold">
                      {{ $t('mcs.recip.summary[21]', { no: destinationCountPlaceholder, country: countryPlaceholder }) }}.
                    </span>
                  </p>
                  <span @click="() => showCountryCodeNotif = false" class="text-base el-icon-close cursor-pointer" />
                </div>
                <div v-if="showCountryCodeApply" class="flex items-center mt-2">
                  <el-switch
                    :value="applyCountryCodeToAll"
                    @change="v => setApplyCountryCodeToAll(v)"
                    class="apply-toggle"
                    inline-prompt>
                  </el-switch>
                  <p class="ml-2">{{ $t('mcs.recip.summary[22]') }} <span class="font-bold">{{ countryPlaceholder }}</span> {{ $t('mcs.recip.summary[23]') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="flex-shrink p-6 border-t border-grey-lighter text-right">
      <div v-if="!canSendCampaigns" class="text-red-light mb-2 text-sm">
          {{ $t('mcs.subscription_warning') }}
        </div>
      <el-button
        type="text"
        class="mr-3"
        @click="back"
      >
        Back
      </el-button>
      <el-button
        @click="$emit('discard')"
      >
        {{ this.$t('actions.cancel') }}
      </el-button>
      <el-button
        :disabled="!isValid()"
        :type="isValid() ? 'primary' : 'info'"
        @click="next"
        id="mcs-add-recipients"
      >
        {{ isValid() ? $t('mcs.recip.summary[16]') : $t('mcs.recip.summary[17]') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import countriesJson from '@/json/countries.json';
import TagList from '@/components/TagList.vue';
import SummaryCard from '@/components/SummaryCard.vue';
import DonutChart from '@/components/DonutChart.vue';
import { Doughnut } from 'vue-chartjs/legacy';
import ContactsSummaryMixin from '@/mixins/contacts-summary';

export default {
  name: 'add-recipients',

  components: {
    TagList,
    SummaryCard,
    DonutChart,
    Doughnut,
  },

  mixins: [ContactsSummaryMixin],

  watch: {
    uploadDataSummaryCc(newVal) {
      if (newVal) {
        this.summaryData = this.formatContactsSummary(newVal);
        this.destinationsData = this.getDestinations(this.summaryData);
        this.loadDestinations = false;
      }
    },

    applyCountryCodeToAll(newVal) {
      this.applyCountryCode(newVal);
    },
  },

  props: {
    form: {
      type: Object,
      default: () => {},
    },
    changeProcessIndex: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters({
      inputSummary: 'recipientsContacts/getSummary',
      user: 'user/getUser',
      canSendCampaigns: 'user/getCanSendCampaigns',
      uploadDataSummary: 'websender/getUploadData',
      uploadDataSummaryCc: 'websender/getUploadDataWithCc',
      applyCountryCodeToAll: 'websender/getApplyCcToAll',
    }),

    countriesChartData() {
      const countries = this.destinationsData;
      const countryMatching = countries.reduce((a, b) => {
        const country = countriesJson.find(c => c.name.toUpperCase() === b.country.toUpperCase());

        if (country) {
          const { name } = country;
          a.push({ country: name, total: b.total });
        } else {
          a.push({ country: 'unknown', total: b.total });
        }

        return a;
      }, []);

      const countriesWithTotals = countryMatching.reduce((a, b) => {
        if (b.country !== 'unknown') {
          a.labels.push(`${b.country} (${b.total.toLocaleString()})`);
          a.data.push(b.total);
        }
        return a;
      }, { labels: [], data: [] });


      const unknownTotal = countryMatching.reduce((a, b) => {
        if (b.country === 'unknown') {
          // eslint-disable-next-line no-param-reassign
          a += b.total;
        }

        return a;
      }, 0);


      const chartLabels = unknownTotal < 1 ? [...countriesWithTotals.labels]
        : [...countriesWithTotals.labels].concat([`Unknown (${unknownTotal.toLocaleString()})`]);

      const chartData = unknownTotal < 1 ? [...countriesWithTotals.data]
        : [...countriesWithTotals.data].concat([unknownTotal]);

      return {
        chartData,
        chartLabels,
      };
    },

    chartData() {
      const { chartData, chartLabels } = this.countriesChartData;
      return {
        labels: chartLabels,
        datasets: [
          {
            backgroundColor: this.getColors(10),
            data: chartData,
          },
        ],
      };
    },

    plugins() {
      const { chartData } = this.countriesChartData;

      return [{
        id: 'text',
        beforeDraw(chart) {
          const { width } = chart;
          const { height } = chart;
          const { ctx } = chart;

          ctx.restore();
          const fontSize = (height / 150).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.fillStyle = '#292929';
          ctx.textBaseline = 'middle';

          const text = (chartData.length).toLocaleString();
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 3;

          ctx.fillText(text, textX, textY);
          ctx.save();

          const fontSize2 = (height / 350).toFixed(2);
          ctx.font = `${fontSize2}em sans-serif`;
          ctx.fillStyle = '#858585';
          ctx.textBaseline = 'middle';

          const text1 = chartData.length > 1 ? 'Destinations' : 'Destination';
          const textX1 = Math.round((width - ctx.measureText(text).width) / 2.2) - 5;
          const textY1 = height / 2.2;

          ctx.fillText(text1, textX1, textY1);
          ctx.save();
        },
      }];
    },
  },

  data() {
    return {
      summaryData: {},
      countriesJson,
      customFields: [],
      includeDuplicates: false,
      chartColor: ['#9F87FF', '#515E79', '#4BCFC5', '#FF6C6B', '#3F9EFF'],
      destinationsData: [],
      chartKey: 0,
      chartOptions: {
        cutout: '80%',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            maxWidth: 800,
            labels: {
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
            },
          },
        },
        events: ['mousemove', 'mouseout'],
      },
      destinationCountPlaceholder: 0,
      countryPlaceholder: '',
      showCountryCodeNotif: true,
      showCountryCodeApply: true,
      loadDestinations: false,
    };
  },

  created() {
    this.populateCustomFieldsTag();

    if (this.applyCountryCodeToAll) {
      this.showCountryCodeNotif = false;
      this.summaryData = this.formatContactsSummary(this.uploadDataSummaryCc);
    } else if (this.form.Recipients.source.match(/UPLOAD/i)) {
      this.summaryData = this.formatContactsSummary(this.uploadDataSummary);
    } else {
      this.summaryData = this.formatContactsSummary(this.inputSummary);
    }

    if (!this.form.Recipients.Location) {
      this.showCountryCodeNotif = false;
      this.showCountryCodeApply = false;
    }

    const country = this.countriesJson.find(d => d.value === this.form.Recipients.Location);
    this.countryPlaceholder = country ? `(${country.callingCode}) ${country.name}` : '';
    this.destinationsData = this.getDestinations(this.summaryData);
  },

  methods: {
    ...mapActions({
      downloadFile: 'common/downloadFile',
      uploadContacts: 'contacts/uploadContacts',
    }),

    ...mapMutations({
      setApplyCcToAll: 'websender/SET_APPLY_CC_TO_ALL',
    }),

    next() {
      this.updateRecipients({ Summary: this.summaryData, contactsFileId: this.summaryData.contactsFileId });
      this.$emit('done-recipients');
    },

    back() {
      this.$emit('change-process-index', 0);
    },

    formatContactsSummary(recipientsSummary) {
      const countries = Object.keys(recipientsSummary.Countries).map(
        c => ({
          Country: c,
          ContactsCount: recipientsSummary.Countries[c],
        }),
      );

      return {
        ...recipientsSummary,
        ContactsUniqueCount: recipientsSummary.Total - recipientsSummary.DuplicateEntries,
        ContactsDuplicatesCount: recipientsSummary.DuplicateEntries,
        countries,
      };
    },

    getDestinations(summary) {
      this.destinationCountPlaceholder = 0;
      if (summary.countries) {
        // Force chart to re-render
        this.chartKey++;
      }

      return summary.countries.map((c) => {
        let countryName = 'Unknown';
        if (c.Country !== null && c.Country !== 'null' && c.Country.trim() !== '') {
          const country = this.countriesJson.find(d => d.value === c.Country);
          countryName = country.name || c.countryName;

          this.destinationCountPlaceholder++;
        }

        return {
          country: countryName,
          total: c.ContactsCount,
        };
      });
    },

    getColors(length = 0) {
      const pallet = ['#B23683', '#0B49AA', '#E0E0E0', '#009B89', '#D83848', '#FFC693', '#D9991C', '#D2F3E1', '#98CDC2', '#99BBF3', '#246FE5', '#D1B9D8', '#BC3F5F'];
      const colors = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        colors.push(pallet[i % (pallet.length - 1)]);
      }

      return colors;
    },

    hasDuplicates() {
      return this.form.Recipients.source.match(/UPLOAD/i)
        ? (this.summaryData.DuplicateEntries > 0)
        : (this.summaryData.ContactsDuplicatesCount > 0);
    },

    hasMalformed() {
      return this.form.Recipients.source.match(/UPLOAD/i)
        ? (this.summaryData.InvalidEntries > 0)
        : ((this.summaryData.ContactsUniqueCount || 0) - this.getTotalNumbers() > 0);
    },

    async downloadInvalidContacts() {
      if (!this.summaryData.InvalidEntries) { return 0; }

      const { InvalidFileLocation: downloadURL } = this.summaryData;

      return this.downloadFile(downloadURL);
    },

    isValid() {
      const validEntries = this.summaryData.ValidEntries;

      return validEntries >= 1;
    },

    getTotalNumbers() {
      return this.summaryData.countries.reduce((acc, curr) => acc + curr.ContactsCount, 0);
    },

    // Switch allow duplicates in state
    updateRecipients(obj) {
      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          ...obj,
        },
      });
    },

    formatPhoneNumber(n, type = 'international') {
      const s = n.match(/^[+][0-9]/) ? n : `+${n}`;
      const pn = parsePhoneNumber(s);
      return pn.number[type] || n;
    },

    getContactAndGroups() {
      const { contacts, groups } = this.form.Recipients;
      const formattedContacts = contacts.map(c => this.formatPhoneNumber(c));
      const groupNames = groups.map(g => g.name);
      return [...formattedContacts, ...groupNames];
    },

    // Populate column label tags
    populateCustomFieldsTag() {
      if (this.form.Recipients.source.match(/UPLOAD/i)
          && Object.keys(this.form.Recipients.columnLabels).length) {
        const { columnLabels } = this.form.Recipients;
        this.customFields = Object.keys(columnLabels).filter(col => columnLabels[col] >= 0);
      }
    },

    updateCustomFields(v) {
      const { columnLabels } = this.form.Recipients;
      const newColumnLabels = { ...columnLabels, [v]: -1 };

      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          columnLabels: { ...newColumnLabels },
        },
      });
    },

    formatNumber(n) {
      return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },

    getExcessDestinationData() {
      return this.destinationsData.slice(3);
    },

    getChartColorByIndex(index) {
      return this.chartColor[index % this.chartColor.length];
    },

    discardUpload() {
      this.$confirm(this.$t('mcs.recip.summary[18]'), this.$t('actions.edit'), {
        confirmButtonText: this.$t('actions.discard'),
        cancelButtonText: this.$t('actions.cancel'),
        type: 'warning',
      }).then(() => {
        this.$emit('reset-upload');
        this.$emit('reset-contacts');
        this.$emit('change-step', 'ADD_RECIPIENT');
      });
    },

    setApplyCountryCodeToAll(apply) {
      this.setApplyCcToAll(apply);
    },

    async applyCountryCode(apply) {
      this.loadDestinations = true;

      if (apply) {
        this.showCountryCodeNotif = false;
        if (this.uploadDataSummaryCc) {
          this.summaryData = this.formatContactsSummary(this.uploadDataSummaryCc);
          this.destinationsData = this.getDestinations(this.summaryData);
          this.loadDestinations = false;
        } else {
          // Upload file using contacts api
          const payload = {
            ...this.form.Recipients,
            Country: this.form.Recipients.Location || null,
            AllNumbersLocal: true,
            Column: this.form.Recipients.columnLabels,
            Source: 'UPLOAD',
          };

          const uploadData = await this.uploadContacts(payload);

          if (uploadData) {
            // Long polling to get the contacts summary
            await this.getContactsSummary({ id: uploadData.id, applyCountryCode: true });
          }
        }
      } else {
        this.summaryData = this.formatContactsSummary(this.uploadDataSummary);
        this.destinationsData = this.getDestinations(this.summaryData);
        this.loadDestinations = false;
        this.showCountryCodeNotif = true;
      }
    },
  },
};
</script>

<style lang="scss">
  .summary {
    height: calc(100vh - 215px);
    overflow-y: auto;
  }

  .left-border {
    position: absolute;
    height: 70%;
    left: 0;
    border: 2px solid black;
    border-radius: 2px;
  }

  .apply-toggle {
    .el-switch__label.is-active {
      color: #292929;
    }
  }
</style>
