<template>
  <div
    class="cf-table overflow-auto"
    :style="{ width: headers.length < 2 ? '50%' : '100%' }"
  >
    <table class="cf-table__inner">
      <tr class="cf-table__row">
        <th
          v-for="(header, index) in headers"
          :key="index"
          class="cf-table__header"
        >
          <el-select
            v-if="!headerValues[index]"
            v-model="headerValues[index]"
            size="small"
            class="w-full"
            placeholder="Choose or type a label"
            @change="value => $emit('update-column', { val: value, i: index })"
            >
            <div
              class="justify-between items-center"
              :class="[{ 'flex': isCustomColumn(option) }]"
               v-for="(option, optionIndex) in customFieldOptions"
               :key="optionIndex"
            >
              <el-option
                v-if="!option.isEdit"
                :disabled="headerValues.includes(option.label)"
                class="text-black"
                :class="[{ 'ml-3 mr-8 pl-3 pr-3 w-full gap-10': isCustomColumn(option) }]"
                :value="option.label"
              >
                <span
                  class="text-sm"
                  :class="[{ 'text-blue': isCustomColumn(option) }]"
                  >
                  {{ option.label.match(/MSISDN/i) ? 'Mobile' : option.label }}
                </span>
              </el-option>
              <input
                class="border border-grey-light p-2 ml-4 mr-4"
                v-if="option.isEdit" v-model="option.label"
              >
              <div
                class="flex column-option-action"
                v-if="isCustomColumn(option)"
                :class="[{ 'col-disabled': headerValues.includes(option.label) }]"
              >
                <i
                  @click="option.isEdit = !option.isEdit"
                  class="cursor-pointer"
                  :class="[
                    { 'icon-novo-edit ': !option.isEdit },
                    { 'icon-novo-check': option.isEdit }
                  ]"
                ></i>
                <i
                  @click="customFieldOptions.splice(optionIndex,1)"
                  class="icon-novo-trash cursor-pointer mr-3"
                ></i>
              </div>
            </div>
            <div class="px-5 mt-2 mb-1">
              <el-input
                placeholder="Add custom name"
                v-model="customFieldInput"
                size="small"
                @change="inputVal => $emit('add-custom-field-name', inputVal)"
                />
            </div>
          </el-select>
          <el-tag
            v-else
            type="success"
            class="w-full el-tag--dark"
            color="#127440"
            closable
            disable-transitions
            hit
            @close="$emit('remove-column', { val: headerValues[index], i: index })"
            >
            <span class="text-sm font-medium">
              {{ headerValues[index].match(/MSISDN/i) ? 'Mobile' : headerValues[index] }}
            </span>
          </el-tag>
        </th>
      </tr>
      <tr
        v-for="(d, rowKey) in data"
        :key="rowKey"
        class="cf-table__row"
      >
        <td
          v-for="(i, colKey) in d"
          :key="colKey"
          class="cf-table__data"
        >
          <span class="text-xs">
            {{ i || '—' }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    headerValues: {
      type: Array,
      default: () => [],
    },
    customFieldOptions: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    customFieldInput: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isCustomColumn(option) {
      return !this.options.includes(option.label);
    },
  },
};
</script>

<style lang="scss" scoped>
.cf-table {
  width: 100%;
  height: calc(100vh - 600px);
  border: 1px solid #f1f1f1;
  border-collapse: collapse;
  border-radius: 8px;


  &__inner {
    width: 100%;
    border-collapse: collapse;
  }

  &__header {
    position: sticky;
    min-width: 150px;
    top: 0;
    background-color: white;
  }

  &__data {
    padding: .7rem;
    min-width: 150px;
  }

  &__row {
    &:nth-child(even) {
      background-color: #f1f1f1;
    }
  }
}
.column-option-action i {
  padding: 8px;
  border-radius: 3px;


  &:hover {
    background: #f1f1f1;
  }
}

.col-disabled {
  pointer-events: none;
}

.el-tag--dark {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-tag.el-tag--success .el-tag__close {
    font-size: 16px;
    font-weight: 500;
  }
}

</style>
