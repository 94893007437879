<template>
  <div class="voice-preview">
    <div class="text-grey-dark text-center text-xs font-medium mt-6">MESSAGE PREVIEW</div>
    <div class="mt-2 px-6 border-b-2 border-grey-lightest relative">
      <span
        class="absolute pin-t pin-l icon-novo-arrow-left text-black text-2xl"
        :style="{left: '50px', top: '25px'}"
      ></span>
      <div class="cp-box border-grey-darker flex relative flex-col p-6">
        <span class="block text-center text-grey-dark text-xs mb-2">Call from</span>
        <span class="text-black block text-lg font-medium text-center">{{ source ? this.getPhoneNumberInfo(source).number.international : '-' }}</span>
        <div class="flex flex-col justify-center items-center h-full relative" :style="{minHeight: '315px'}">
          <div class="">
            <Visualizer />
          </div>
          <div v-if="tabSelected === 'message'"
            v-html="getPreviewContent(message, variables) || 'Preview of your message goes here...'"
            class="mt-4 bg-green-lighter p-3 text-xs w-full leading-normal text-grey-dark rounded" />
          <div v-else>
            <UploadAudio
              :loading="loading"
              :is-button-type="Boolean(true)"
              :file-data="fileData"
              :is-preview="Boolean(true)"
              @update-uploaded-audio="(fileDetails) => {
                $emit('update-uploaded-audio', fileDetails, 'uploads');
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import previewContentMixin from '@/mixins/preview-content';
import PhoneNumberMixin from '@/mixins/phone-number';
import Visualizer from './Visualizer.vue';
import UploadAudio from './UploadAudio.vue';

export default {
  name: 'message_voice_preview',

  mixins: [previewContentMixin, PhoneNumberMixin],

  components: {
    Visualizer,
    UploadAudio,
  },

  props: {
    source: {
      type: String,
      default: '-',
    },

    message: {
      type: String,
      default: '',
    },

    fileData: {
      type: Object,
      default: undefined,
    },

    variables: {
      type: Array,
      default: () => [],
    },

    tabSelected: {
      type: String,
      default: 'message',
    },
  },

  data() {
    return {
    };
  },

  methods: {
  },

};
</script>
<style lang="scss">
  .voice-preview {
    .prev-content {
      min-height: 330px;
      max-height: 900px;
      padding-bottom: 2rem;
    }
  }
</style>
