<template>
  <div class="upload-recipients inline-block flex flex-col">
    <div class="upload-recipients-form px-6">
      <h3 class="mb-2 text-sm text-black tracking-normal">
        {{ $t('actions.upload_file') }}
      </h3>
      <i18n
        path="mcs.recip.upload[0]"
        tag="p"
        class="text-xs text-grey-dark leading-tight"
      />
      <div class="w-full my-3">
        <div
          v-if="showUploadInfo"
          class="flex relative p-3 border border-grey-lighter border-l border-gray-500 rounded"
          style="column-gap: 10px;"
        >
          <div class="left-border" />
          <span class="align-start text-base icon-novo-info-circle" />
          <p class="text-sm leading-tight">
            {{ $t('mcs.recip.upload[2]') }} 
            <a class="text-blue hover:text-blue-darker text-underline"
              href="https://en.wikipedia.org/wiki/E.164"
              target="_blank"
              rel="noopener noreferrer"
            >{{ $t('mcs.recip.upload[3]') }}</a> 
            {{ $t('mcs.recip.upload[4]') }} 
          </p>
          <span @click="() => showUploadInfo = false" class="align-start ml-auto text-base el-icon-close cursor-pointer" />
        </div>
      </div>
      <div v-loading="processing || uploading">
        <el-upload
          v-if="hasMcsAccess"
          id="upload-file-input"
          class="w-full mt-5 upload-file-input"
          ref="upload"
          :auto-upload="false"
          drag
          accept=" .csv, .txt, .xlsx"
          :show-file-list="false"
          :disabled="processing || uploading"
          :httpRequest="(hooks) => { handleRequest(hooks) }"
          :limit="1"
          :multiple="false"
          :before-upload="handleBeforeUpload"
          :on-success="handleSuccess"
          :on-change="handlePreview"
          :on-error="handleError">
          <div class="upload-icon-container flex flex-col justify-center">
            <i class="el-icon-folder text-3xl mb-5"></i>
            <h5>Click to upload or drag and drop your file here.</h5>
            <p class="mt-2 mb-2 text-xs text-grey-dark leading-tight">Supported formats are .csv, .txt, and .xlsx, with a maximum file size of 30MB.</p>
          </div>

          <div class="el-upload__tip flex justify-between" slot="tip">
            <div>
              <a
                id="mcs-download-sample-file"
                class="text-blue hover:text-blue-dark cursor-pointer"
                @click.prevent="downloadSampleFile('mcs-download-sample-file')"
              >
                {{$t('actions.download')}}
              </a>
              {{$t('mcs.recip.upload[1]')}}
            </div>
          </div>

        </el-upload>
        <el-upload
          v-else
          id="upload-file-input"
          class="w-full mt-5 upload-file-input"
          ref="upload"
          drag
          accept=" .csv, .txt, .xlsx"
          :show-file-list="false"
          :disabled="processing ||uploading "
          :action="
            `${apiUrl}/websender/upload?socketId=${socketId || ''}&queueUrl=${queueUrl || ''}`
          "
          :headers="getHeaders"
          :limit="1"
          :before-upload="handleBeforeUpload"
          :on-progress="handleProgress"
          :on-success="handleSuccess"
          :on-error="handleError">
          <div><i class="el-icon-upload text-2xl"></i></div>
          <i18n
            path="upload_labels.drag_drop"
            tag="div"
            class="text-xs"
          >
            <template #br>
              <br>
            </template>
             <template #browse>
              <em class="text-blue roman">{{$t('actions.browse')}}</em>
            </template>
          </i18n>
          <div class="el-upload__tip flex justify-between" slot="tip">
            <div>
              <a
                id="old-mcs-download-sample-file"
                class="text-blue hover:text-blue-dark cursor-pointer"
                @click.prevent="downloadSampleFile('old-mcs-download-sample-file')"
              >
                {{$t('actions.download')}}
              </a>
              {{$t('mcs.recip.upload[1]')}}
            </div>
            <div>
              <strong>{{$t('upload_labels.supported_types')}}</strong>
              .csv/.txt/.xlsx ({{$t('upload_labels.size_less_than', { size: "30MB" })}})
            </div>
          </div>

        </el-upload>
      </div>
    </div>
    <div class="flex-shrink p-6 border-t border-grey-lighter text-right">
      <el-button
        type="text"
        class="mr-3"
        @click="back"
      >
        Back
      </el-button>
      <el-button
        id="mcs-processing-uploaded-contacts"
        :disabled="processing || uploading "
        @click="$emit('discard')"
      >
       {{ processing || uploading ? $t('wait.uploading') : $t('actions.cancel') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import * as XLSX from 'xlsx';
import UploadMixins from '@/mixins/upload';

export default {
  name: 'upload-recipients',

  mixins: [UploadMixins],

  props: {
    form: {
      type: Object,
      default: () => {},
    },
    partialData: {
      type: Array,
      default: () => [],
    },
    processing: {
      type: Boolean,
      default: false,
    },
    socketId: {
      type: String,
      default: '',
    },
    queueUrl: {
      type: String,
      default: '',
    },
    changeProcessIndex: {
      type: Function,
      default: () => {},
    },
  },

  watch: {
    partialData(pData) {
      if (pData) {
        this.$emit('change-step', 'SET_CUSTOM_FIELD');
      }
    },
  },

  data() {
    return {
      uploading: false,
      apiUrl: `${process.env.VUE_APP_API_URL}/api/v1`,
      supportedFileTypes: [
        'text/csv',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
      showUploadInfo: true,
    };
  },

  created() {
    this.$emit('set-processing', false);

    // Change apiUrl value based on User's RegionId
    const token = localStorage.getItem('WWW-Authenticate');
    const decodedData = this.decodeJwt(token);

    if (decodedData && Object.keys(decodedData).includes('RegionId')) {
      const { RegionId: region } = decodedData;
      const regionId = region;
      const { apiConfig } = this.http;

      this.apiUrl = apiConfig.v1[regionId];
    }

    this.setUploadData(null);
    this.setUploadDataCc(null);
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
      hasMcsAccess: 'user/getMcsAccess',
    }),

    getHeaders() {
      const token = localStorage.getItem('WWW-Authenticate');
      return {
        Authorization: `Bearer ${token}`,
      };
    },
  },

  methods: {
    ...mapMutations({
      updateForm: 'UPDATE_FORM',
      setProcessing: 'websender/SET_PROCESSING',
    }),

    ...mapActions({
      setPartialData: 'websender/setPartialData',
      setUploadData: 'websender/setUploadData',
      setUploadDataCc: 'websender/setUploadDataCc',
    }),

    back() {
      this.$emit('change-process-index', 0);
    },

    decodeJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

        const decodedData = JSON.parse(jsonPayload);
        return decodedData;
      } catch (err) {
        this.$showError(this, err);
        console.error('Failed to validate region'); // eslint-disable-line
        return 0;
      }
    },

    handleRequest(hooks) {
      // no custom request at this time
      this.uploading = true;
      Promise.resolve().then(() => {
        hooks.onSuccess();
        this.uploading = false;
      });
    },

    resetUpload() {
      this.setPartialData(undefined);
      this.setProcessing(false);
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
    },

    async handlePreview(file) {
      try {
        const { raw: fileDetails } = file;

        const { supportedFileTypes } = this;

        const isAccepted = supportedFileTypes.includes(fileDetails.type.toLowerCase());

        const isFileSizeWithinLimit = fileDetails.size / 1024 / 1024 <= 30;

        //  size in bytes
        const sizeb = fileDetails.size;

        const isEmpty = sizeb < 1;

        if (!isAccepted) {
          throw new Error('File type is not accepted.');
        }

        if (!isFileSizeWithinLimit) {
          throw new Error('File exceeds file size limit(30mb)');
        }

        if (isEmpty) {
          throw new Error('File has been detected empty.');
        }

        let data;
        if (fileDetails.type === supportedFileTypes[2]) {
          data = await fileDetails.arrayBuffer();
        } else {
          // Set BOM in the file
          const blob = new Blob(['\ufeff', fileDetails], { type: `${fileDetails.type};charset=utf-8` });
          data = await blob.arrayBuffer();
        }

        // Set raw to true to read each column as a string
        const workbook = XLSX.read(data, { sheetRows: 20, raw: true, sheetStubs: true });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' }); // defval - to include all the empty cells

        // Get the length of the row with the biggest length
        const columnCount = rows.reduce((max, currentArray) => {
          if (Array.isArray(currentArray)) {
            const { length } = currentArray;
            return length > max ? length : max;
          }
          return max;
        }, 0);

        // Adjust the items in the first row so headers can be matched accordingly
        const firstRow = rows[0];
        if (firstRow.length < columnCount || firstRow.includes(undefined)) {
          const newFirstRow = [];
          const columns = [...Array(columnCount).keys()];
          for (const i in columns) {
            if (i > firstRow.length - 1) {
              newFirstRow[i] = '';
            } else {
              newFirstRow[i] = !firstRow[i] ? '' : firstRow[i];
            }
          }
          rows[0] = newFirstRow;
        }

        // Remove header validation
        // if (!/[0-9]{1,20}/.test(rows.slice(0)[0])) {
        //   const incorrectHeaders = this.checkHeaders(rows.slice(0));
        //   if (incorrectHeaders > 0) {
        //     // this.resetUpload();
        //     this.handleError(new Error('Invalid Headers'));
        //     return;
        //   }
        // }

        // Get only first 20
        const n = 20;
        const partialData = {
          List: rows.slice(0, n),
        };

        this.setPartialData(partialData);
        this.uploading = false;
        this.updateForm({
          Recipients: {
            ...this.form.Recipients,
            source: 'UPLOAD',
            File: fileDetails,
            FileName: fileDetails.name,
            FileType: fileDetails.type,
            FileUrl: '',
            columnLabels: {
              MSISDN: -1,
              FirstName: -1,
              LastName: -1,
              ClientMessageId: -1,
            },
          },
        });

        if (this.$refs.upload) {
          this.$refs.upload.clearFiles();
        }
      } catch (err) {
        this.resetUpload();
        this.handleError(err);
      }
    },

    checkHeaders(arr) {
      const headers = arr[0];
      const validHeaders = [
        'Msisdn',
        'FirstName',
        'LastName',
        'Country',
        'ExternalId',
        'WeChatUserId',
        'FacebookUserId',
        'Email',
        'ZaloId',
        'LineId',
        'KakaoTalkId',
      ];

      return headers.filter((h) => {
        const i = validHeaders.find(v => v.toUpperCase() === h.toUpperCase());
        return i === undefined;
      }).length;
    },

    handleBeforeUpload() {
      this.uploading = true;
    },

    handleProgress(p) {
      if (Number(p.percent) === 100) {
        this.$emit('set-processing', true);
      }
    },

    handleSuccess(resp) {
      const {
        fileName,
        fileType,
        location,
      } = resp;

      this.uploading = false;
      this.updateForm({
        Recipients: {
          ...this.form.Recipients,
          source: 'UPLOAD',
          FileName: fileName,
          FileType: fileType,
          FileUrl: location,
          columnLabels: {
            MSISDN: -1,
            FirstName: -1,
            LastName: -1,
            ClientMessageId: -1,
          },
        },
      });
    },

    handleError(err) {
      this.$showError(this, err);

      this.resetUpload();

      this.uploading = false;

      this.$emit('set-processing', false);
    },
  },
};
</script>

<style lang="scss">
.upload-recipients {
  .el-upload { width: 100%; }
  .el-upload-dragger { width: 100%; height: 200px; }

  .upload-recipients-form {
    height: calc(100vh - 270px);
    overflow-y: auto;
  }

  .upload-file-input {
    .el-upload-dragger {
      border: 1px dashed #0056e0;
    }
  }

  .upload-icon-container {
    height: 100%;
  }
}

.left-border {
  position: absolute;
  height: 70%;
  left: 0;
  border: 2px solid black;
  border-radius: 2px;
}
</style>
