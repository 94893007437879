<template>
  <div
    class="border-grey-lightest border mt-4 p-3 rounded"
    :style="{width: '96.9999%'}"
  >
    <div class="text-xl font-semibold mb-6">Destinations</div>
    <div class="flex justify-center">
      <Doughnut
        :chart-options="chartOptions"
        :chart-data="chartData"
        chart-id="destination-dougnut-chart"
        dataset-id-key=""
        :plugins="plugins"
        css-classes=""
        :styles="{}"
        :width="600"
        :height="300"
      />
    </div>
  </div>
</template>
<script>

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js';
import { Doughnut } from 'vue-chartjs/legacy';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: 'DestinationChart',

  components: {
    Doughnut,
  },

  props: {
    countriesChartData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      chartOptions: {
        cutout: '80%',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            maxWidth: 800,
            labels: {
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
            },
          },
        },
      },
    };
  },

  computed: {
    chartData() {
      const { chartData, chartLabels } = this.countriesChartData;
      return {
        labels: chartLabels,
        datasets: [
          {
            backgroundColor: this.getColors(10),
            data: chartData,
          },
        ],
      };
    },
    plugins() {
      const { chartData } = this.countriesChartData;

      return [{
        id: 'text',
        beforeDraw(chart) {
          const { width } = chart;
          const { height } = chart;
          const { ctx } = chart;

          ctx.restore();
          const fontSize = (height / 150).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.fillStyle = '#292929';
          ctx.textBaseline = 'middle';

          const text = (chartData.length).toLocaleString();
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 3;

          ctx.fillText(text, textX, textY);
          ctx.save();

          const fontSize2 = (height / 350).toFixed(2);
          ctx.font = `${fontSize2}em sans-serif`;
          ctx.fillStyle = '#858585';
          ctx.textBaseline = 'middle';

          const text1 = chartData.length > 1 ? 'Destinations' : 'Destination';
          const textX1 = Math.round((width - ctx.measureText(text).width) / 2.2) - 5;
          const textY1 = height / 2.2;

          ctx.fillText(text1, textX1, textY1);
          ctx.save();
        },
      }];
    },
  },

  methods: {
    getColors(length = 0) {
      const pallet = ['#B23683', '#0B49AA', '#E0E0E0', '#009B89', '#D83848', '#FFC693', '#D9991C', '#D2F3E1', '#98CDC2', '#99BBF3', '#246FE5', '#D1B9D8', '#BC3F5F'];
      const colors = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        colors.push(pallet[i % (pallet.length - 1)]);
      }

      return colors;
    },
  },
};
</script>
