<template>
  <div class="mb-5">
    <h4 class="text-black font-medium mb-2">
      {{ $t('mcs.messages.chat_apps.fallback_msg[0]') }}
    </h4>
    <p class="leading-tight text-grey-darker mb-4 text-xs w-4/5">
        {{ $t('mcs.messages.chat_apps.fallback_msg[1]') }}
    </p>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.prevent.native>
      <h5 class="text-black font-medium mb-2 label__asterisk">
        {{ $t('fields.sender_id') }}
      </h5>
      <p class="leading-tight text-grey-darker mb-2 text-xs w-3/5">
        {{ $t('mcs.messages.sms.msg_box[0]') }}
        <a href="/support/articles/360039522251?search_term=SMS-senderID"
          class="text-blue hover:text-blue-darker cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
          >
          {{ $t('mcs.messages.sms.msg_box[1]') }}
        </a>
      </p>
      <el-form-item prop="from">
        <el-select
          v-model="form.from"
          filterable
          :default-first-option="true"
          clearable
          allow-create
          size="small"
          class="w-1/2"
          :placeholder="$t('mcs.messages.sms.msg_box[2]')">
          <div v-loading="loading">
            <el-option
              v-for="item in senderIds"
              :key="item.SenderId"
              :label="item.SenderId"
              :value="item.SenderId">
              {{ item.SenderId }}
            </el-option>
          </div>
        </el-select>
      </el-form-item>
      <div class="mb-2">
        <h5 class="text-black font-medium mb-2">
           {{ $t('mcs.messages.chat_apps.fallback_msg[2]') }}
        </h5>
        <el-switch
          v-model="sameText"
        />
        <span class="text-xs ml-2 text-grey-darker">
           {{ $t('mcs.messages.chat_apps.fallback_msg[3]') }}
        </span>
      </div>
      <div v-if="!sameText"
        class="w-2/3">
        <el-form-item prop="message">
          <div class="flex">
            <div class="flex-auto">
              <h5 class="text-black font-medium flex-auto label__asterisk">
                {{ $t('column_labels.message') }}
              </h5>
            </div>
            <div class="flex-shrink text-grey-darker text-xs">
              {{ charsUsed }} / {{ totalPartsChars }} {{$t('column_labels.characters')}}
            </div>
          </div>
          <el-input
            ref="msgBox"
            v-model="form.message"
            type="textarea"
            class="text-xs"
            :placeholder="$t('mcs.messages.sms.msg_box[3]')"
            :rows="4"
            :disabled="!form.from"
          />
        </el-form-item>
      </div>
    </el-form>
    <div v-if="variables.length && !sameText">
      <p class="mb-2 text-xs"> {{ $t('mcs.messages.sms.msg_box[4]')}} </p>
      <div class="mb-2">
        <span
          v-for="i in variables"
          :key="i"
          class="mr-3 item-tag"
        >
          {{ i }}
          <button
            type="link"
            :disabled="!form.from"
            class="el-icon-plus el-icon--right"
            @click="addCustomField(i)"
          />
        </span>
      </div>
      <p class="text-xs">
        <span v-if="source === 'UPLOAD'">
          <a
            href="#"
            class="text-blue hover:text-blue-darker no-underline"
            @click.prevent="$emit('modify-custom-fields')">
            {{ $t('actions.modify') }}
          </a> {{ $t('mcs.messages.sms.msg_box[5]') }}
        </span>
        <a
          href="https://support.wavecell.com/hc/en-us/articles/115005656203-How-can-I-add-custom-fields-on-my-messages-"
          class="text-blue hover:text-blue-darker no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >{{ $t('mcs.messages.sms.msg_box[6]') }}</a>
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import insertAtCursor from '@/utils/insertAtCursor';

export default {
  name: 'fallback_message_sms_sender_id',

  props: {
    senderIds: {
      type: Array,
      default: () => [],
    },

    variables: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: '',
    },

    useSameText: {
      type: Boolean,
      default: true,
    },

    originalMessage: {
      type: String,
      default: '',
    },

    senderId: {
      type: String,
      default: '',
    },

    smsChars: {
      type: Number,
      default: 160,
    },

    smsParts: {
      type: Number,
      default: 1,
    },

    charsUsed: {
      type: Number,
      default: 0,
    },

    source: {
      type: String,
      default: '',
    },
  },

  data() {
    const checkIfBlacklisted = async (rule, value, cb) => {
      // check if blacklisted
      const res = await this.validateSenderId({ senderId: value });

      if (!res.blacklisted) {
        cb();
      } else {
        cb(new Error(this.$t('mcs.messages.sms.msg_box[7]')));
      }
    };

    const self = this;

    return {
      form: {
        from: '',
        message: '',
      },

      sameText: true,

      rules: {
        from: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.sender_id') }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
          {
            // pattern: /^((?=.*[a-zA-Z]{1,})(?=.*[0-9]?)(?=^[a-zA-Z0-9\_\-\.\!\"\#\$\%\&\'\(\)\*\+\,\/\:\;\<\=\>\?\@\[\\\]\^\{\|\}\~]+\s?[a-zA-Z0-9\_\-\.\!\"\#\$\%\&\'\(\)\*\+\,\/\:\;\<\=\>\?\@\[\\\]\^\{\|\}\~]+$).{1,11}|[0-9]{1,15})$/, // eslint-disable-line
            // eslint-disable-next-line no-useless-escape
            pattern: new RegExp(self.$vars.SENDER_ID_REGEX, 'g'),
            message: `${self.$t('validations.valid', { value: self.$t('fields.sender_id') })}. ${this.$t('mcs.messages.sms.msg_box[8]')}`,
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
          {
            validator: checkIfBlacklisted,
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
        ],

        message: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.message') }),
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  computed: {
    totalPartsChars() {
      return this.smsChars * this.smsParts;
    },
  },

  watch: {
    'form.from': {
      handler(n) {
        if (this.$refs.form) {
          const self = this;

          this.$refs.form.validateField('from', (err) => {
            if (!err) {
              self.updateSenderId(n);
            } else {
              self.updateSenderId('');
            }
          });
        }
      },
    },

    'form.message': {
      handler(n) {
        if (this.$refs.form) {
          const self = this;

          this.$refs.form.validateField('message', (err) => {
            if (!err) {
              self.$emit('update-fallback-message', n);
            } else {
              self.$emit('update-fallback-message', '');
            }
          });
        }
      },
    },

    sameText: {
      handler(n, o) {
        if (n !== o) {
          this.$emit('update-fallback-use-same-text', n);

          if ((!n && !this.form.message) || n) {
            this.form.message = this.originalMessage.trim();
          }
        }
      },
    },
  },

  created() {
    this.form.from = this.senderId;
    this.form.message = this.message;
    this.sameText = this.useSameText;
  },

  methods: {
    ...mapActions({
      validateSenderId: 'websender/validateSenderId',
    }),

    addCustomField(field) {
      if (this.$refs.msgBox) {
        const cfText = `{{${field}}}`;
        const textArea = this.$refs.msgBox.$refs.textarea;

        const textfield = insertAtCursor(textArea, cfText);

        this.form.message = textfield.value;
      }
    },

    updateSenderId(senderId) {
      const { sameText } = this;

      this.$emit('update-fallback-sender-id', { senderId, useSameText: sameText });
    },
  },
};
</script>
