<template>
  <div :loading="loading">
    <div class="border-2 border-dashed border-blue p-4 flex justify-between items-center mt-6 rounded">
      <div>
        <span class="text-grey-dark text-sm">uploaded file</span>
        <span class="block font-semibold mt-1">{{ meta.fileName }}</span>
      </div>
      <div>
        <el-button
          type="danger"
          icon="icon-novo-trash"
          @click="$emit('discard')"
        >
          Discard
        </el-button>
      </div>
    </div>
    <div
      v-if="hasDetectedLocalNumbers && showNumberFormatOptions()"
      class="mt-6 py-2 rounded flex border-grey-lightest border w-full"
    >
      <div class="px-4 py-2 border-l-4 border-yellow flex w-full">
        <div class="flex-shrink align-top">
          <i class="icon-novo-exclamation text-yellow text-2xl mr-4" />
        </div>
        <div class="leading-normal flex-auto text-sm" :style="{wordBreak: 'break-word'}">
          We've detected that this list contains phone numbers in the local format, without country prefix, so we've applied your account's billing country code.
          If this is usually different than your messages' destination, set up your default country prefix in your profile preferences
          <span class="text-blue cursor-pointer hover:text-blue-lighter underline" @click="openProfilePreference()">here</span>.
        </div>
        <div class="flex-shrink">
          <i
            class="icon-novo-close-large text-black hover:text-grey-dark text-grey-darkest font-medium cursor-pointer ml-4 text-xl"
            @click="hasDetectedLocalNumbers = false"
          />
        </div>
      </div>
    </div>

    <div v-if="showNumberFormatOptions()" class="mt-6 flex mb-6">
      <div class="mr-6">
        <label class="block mb-1 text-sm">Country code prefix</label>
         <el-select
            v-model="country"
            filterable
            :clearable="Boolean(true)"
            @change="handleCountryCodeChange"
            :style="{width: '150px'}"
          >
            <template #prefix v-if="country">
              <span :class="`block mt-3 mr-3 flag-icon flag-icon-squared flag-icon-${(country || '').toLowerCase()}`" />
            </template>
            <el-option
              v-for="(item,i) in countriesJson"
              :key="i"
              :label="item.callingCode"
              :value="item.value">
              <span :class="`mr-1 flag-icon flag-icon-squared flag-icon-${item.value.toLowerCase()}`" />
              <span>{{  item.callingCode }} {{  item.name }}</span>
            </el-option>
          </el-select>
      </div>
      <div class="flex mt-6">
        <el-switch
          v-model="allNumbersLocal"
          @change="handleAllLocalNumbersChange"
          class="mt-1"
        />
        <div class="ml-2">
          <p class="font-semibold text-lg">Automatically add country code to local phone numbers
            <el-tooltip
              placement="right-start"
              effect="light"
            >
              <div
                :style="{width: '400px'}"
                class="leading-normal text-xs"
                slot="content"
              >
                When you include the default country code,
                all phone numbers in their local format will automatically switch to the international format.
                Note that numbers already in the international format will remain unchanged.
                Please double-check that the phone numbers are accurately filled in; otherwise, they may not be delivered.
              </div>
              <i class="icon-novo-faq" />
            </el-tooltip>
          </p>
          <p class="text-grey-dark text-sm mt-1">The country code will be added regardless of the phone number's format</p>
        </div>
      </div>
    </div>
    <div class="mt-6">
      <p class="text-lg font-semibold">Match Columns </p>
      <p class="text-grey-dark text-sm mt-1 mb-3">
        Match the columns in your file to their respective labels. You can also create custom labels.
      </p>
      <CustomFieldsTable
        :headers="getColumnIndex"
        :header-values="colValues"
        :custom-field-options="customFieldsOptions"
        :options="defaultCustomFieldsOptions"
        :data="tableData"
        :custom-field-input="newCustomField"
        @update-column="updateColumnValue"
        @remove-column="removeCustomField"
        @add-custom-field-name="addCustomField"
      />
    </div>
    <div class="flex mt-6">
      <el-switch
        v-model="allowDuplicates"
        @change="handleAllowDuplicatesChange"
        class="mt-1"
      />
      <div class="ml-2">
        <p class="text-lg font-semibold">Include duplicate numbers
        </p>
        <p class="text-grey-dark text-sm mt-1">
          By including duplicate numbers, messages will be sent to all the contacts in the list.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import countriesJson from '@/json/countries.json';
import { parsePhoneNumber } from 'awesome-phonenumber';
import CustomFieldsTable from './CustomFieldsTable.vue';

export default {
  name: 'SetCustomFields',

  components: {
    CustomFieldsTable,
  },

  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const defaultCustomFieldsOptions = ['MSISDN', 'FirstName', 'LastName', 'ClientMessageId'];

    return {
      hasDetectedLocalNumbers: false,
      countriesJson,
      allNumbersLocal: false,
      country: '',
      allowDuplicates: false,
      newCustomField: '',
      colValues: [],
      customFields: {},
      defaultCustomFieldsOptions,
      customFieldsOptions: defaultCustomFieldsOptions,
      tableData: [],
      loading: false,
    };
  },

  created() {
    // Set default values
    this.country = this.meta.country || this.getDefaultCountry() || this.country;
    this.allowDuplicates = this.meta.allowDuplicates || this.allowDuplicates;
    this.allNumbersLocal = this.meta.allNumbersLocal || this.allNumbersLocal;

    let partialDataList = [];
    // Check if paritalData.List is null
    if (this.meta.partialData && this.meta.partialData.List) {
      partialDataList = [...this.meta.partialData.List];
    }
    const data = partialDataList;

    this.tableData = data;

    this.colValues = this.tableData.length ? new Array(Object.keys(this.tableData[0]).length) : [];

    if (this.meta.columnLabels) {
      const colLabels = this.meta.columnLabels;
      this.customFieldsOptions = Object.keys(colLabels).map(label => ({ label, id: label, isEdit: false }));
      Object.keys(colLabels).forEach((label) => {
        const index = parseInt(colLabels[label], 10);
        if (index >= 0) {
          this.colValues[index] = label;
        }
      });

      this.customFields = this.meta.columnLabels;
    } else {
      // Set all column to -1
      this.customFields = this.customFieldsOptions.reduce((obj, item) => ({
        ...obj,
        [item]: -1,
      }), {});
    }

    this.hasDetectedLocalNumbers = Boolean(this.showNumberFormatOptions());
    this.allNumbersLocal = Boolean(this.showNumberFormatOptions());
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),

    getColumnIndex() {
      return this.tableData.length ? Object.keys(this.tableData[0]) : [];
    },
  },

  methods: {
    openProfilePreference() {
      const ev = new CustomEvent('open:settings', {
        detail: {
          tab: 'preference',
        },
      });

      window.dispatchEvent(ev);
    },

    handleCountryCodeChange(country = '') {
      const countryInfo = this.getCountryInfo(country);

      if (countryInfo) {
        const { callingCode, value } = countryInfo;
        this.$emit('update-file-info', { country: value, callingCode, allNumbersLocal: this.allNumbersLocal });
      }
    },

    getDefaultCountry() {
      const { country } = this.form;

      return country;
    },

    getCountryInfo(country = '') {
      const countryInfo = countriesJson.find(v => v.value === country);

      return countryInfo;
    },

    handleAllLocalNumbersChange(isAllLocal = false) {
      const countryInfo = this.getCountryInfo(this.country);
      const { callingCode, value } = countryInfo;

      if (isAllLocal) {
        this.$emit('update-file-info', { country: value, callingCode, allNumbersLocal: true });
      } else {
        this.$emit('update-file-info', { country: '', callingCode: '', allNumbersLocal: false });
      }
    },

    handleAllowDuplicatesChange(allowDuplicates = false) {
      this.$emit('update-file-info', { allowDuplicates });
    },

    getColumnValue(index) {
      const colVal = Object.keys(this.customFields).find(cf => cf === index);
      return colVal || -1;
    },

    updateColumnValue({ val = 0, i = 0 }) {
      this.customFields[val] = i;

      this.hasDetectedLocalNumbers = Boolean(this.showNumberFormatOptions());
      this.allNumbersLocal = Boolean(this.showNumberFormatOptions());

      const countryInfo = this.getCountryInfo(this.country);
      const { callingCode, value } = countryInfo;

      this.$emit('update-file-info', {
        columnLabels: { ...this.customFields },
        allNumbersLocal: this.allNumbersLocal,
        country: value,
        callingCode,
      });
    },

    addCustomField(val) {
      const s = val.replace(/\s+/g, '');
      this.newCustomField = '';
      if (!this.customFieldsOptions.includes(s) && !s.match(/mobile/i)) {
        this.customFieldsOptions = [...this.customFieldsOptions, { id: s, label: s, isEdit: false }];
      } else {
        this.$notify.error({
          title: 'Custom field missing',
          message: 'You must have at least one custom field set.',
        });
      }
    },

    removeCustomField({ val = 0, i = 0 }) {
      this.colValues.splice(i, 1, null);
      this.customFields = { ...this.customFields, [val]: -1 };

      this.$emit('update-file-info', { columnLabels: { ...this.customFields } });
    },

    showNumberFormatOptions() {
      const { columnLabels } = this.form.recipients.meta;
      const phoneIndex = columnLabels && Object.keys(columnLabels).length ? columnLabels.MSISDN : -1;
      let isNum = true;
      const invalidNums = [];
      if (phoneIndex >= 0) {
        this.tableData.forEach((data) => {
          let num = data[phoneIndex];
          num = !num ? '' : num;
          const n = num.toString().replace(/^(\+){1}/g, '').replace(/ /g, '');
          const checkNum = n[0] === '0' ? n : `+${n}`;
          const isValidLength = /[0-9]{7,20}/.test(n);
          isNum = n.match(/^[0-9]+$/) != null;

          try {
            const pn = parsePhoneNumber(checkNum);

            // If INVALID number, check if its valid in length
            // IF VALID, do nothing
            if (!pn.valid) {
              // check if its a number
              // If not a number, do nothing
              if (isNum && isValidLength) {
                invalidNums.push(n);
              }
            }
          } catch (e) {
            // If !isNum or !isValidLegth, do nothing
            if (e && isNum && isValidLength) {
              invalidNums.push(n);
            }
          }
        });
      }
      return invalidNums.length;
    },

  },
};
</script>

<style lang="scss">

</style>
