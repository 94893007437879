<template>
  <el-dialog
    :title="$t('mcs.campaigns.export_modal.title')"
    :visible.sync="visible"
    class="export-modal"
    width="40%"
    @close="closeDialog">
    <div>
      <i18n
        class="text-sm mt-2 mb-3"
        path="messaging.logs_full_download[2]"
        tag="p">
        <template #email>
          <b class="text-grey-darkest">{{ userEmail }}</b>
        </template>
      </i18n>
      <el-form
        @submit.native.prevent
        ref="form"
        name="email"
        :model="form"
        :rules="formRules">
        <a
          v-if="!addEmail"
          class="text-sm text-blue cursor-pointer"
          @click="showEmailInput"
        >
          {{ $t('messaging.logs_full_download[3]') }}
        </a>
        <el-form-item
          v-else
          prop="email">
          <el-input
            v-model="form.email"
            size="small"
            :placeholder="$t('messaging.reports.export_modal[5]')"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button
        type="primary"
        size="small"
        :loading="loading"
        @click="submitForm">
        {{ $t('messaging.logs_full_download[5]') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'ExportModal',

  props: {
    visible: {
      type: Boolean,
      default() {
        return true;
      },
    },
    campaignId: {
      type: Number,
    },
  },

  created() {
    this.userEmail = this.getLoginUser().Email;
  },

  data() {
    const checkEmail = (rule, value, callback) => {
      const input = value.trim();

      if (input !== '' && !input.match(this.$constants.EMAIL_REGEX)) {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.email') })));
        return;
      }

      if (input !== '' && input === this.userEmail) {
        callback(new Error(this.$t('messaging.reports.export_modal[6]')));
        return;
      }

      callback();
    };

    return {
      addEmail: false,
      form: {
        email: '',
      },
      userEmail: '',
      formRules: {
        email: [
          {
            trigger: 'blur',
            validator: checkEmail,
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },


  methods: {

    ...mapActions({
      downloadChatappsCampaign: 'campaigns/downloadChatappsCampaign',
    }),

    getLoginUser() {
      const cpv3User = localStorage.getItem('CPV3_User');
      return JSON.parse(cpv3User || {});
    },

    closeDialog() {
      this.$emit('close');
    },

    showEmailInput() {
      this.addEmail = true;
    },

    submitForm() {
      const validateCallback = async (valid) => {
        if (!valid) {
          return;
        }

        try {
          await this.downloadChatappsCampaign({ campaign_id: this.campaignId, additionalEmail: this.form.email });
          this.$notify({
            title: this.$t('mcs.campaigns.toast.success.title'),
            message: this.$t('mcs.campaigns.toast.success.message'),
            type: 'success',
            duration: 8000,
          });
        } catch (err) {
          this.$showError(this, err);
        } finally {
          this.$emit('close');
        }
      };

      this.$refs.form.validate(validateCallback);
    },
  },

};
</script>
<style lang="scss">
.export-modal .el-dialog__body {
  padding: 10px 20px;
}
</style>
