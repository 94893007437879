<template>
  <div class="mb-3 haslogo">
    <div class="bg-grey-lightest border border-solid border-grey-light rounded-lg">
      <div class="px-5 p-5 flex">
        <div class="flex-shrink py-3">
          <img :src="getIcon()" alt="icon"/>
        </div>
        <div class='flex-auto ml-5 mt-1 py-3'>
          <p class="text-grey-dark text-sm mb-2">{{ $t('mcs.process_on') }}</p>
          <div v-if="!sendNow">
            <p class="text-black text-lg font-medium mb-1">{{ getScheduleDate }}</p>
            <p class="text-black text-sm font-medium mb-2">{{ getScheduleTime }}</p>
          </div>
          <div v-else class="text-black text-lg">
            {{ $t('mcs.now') }}
          </div>
        </div>
      </div>
      <div class="bg-white px-5 py-3 rounded-b-lg">
        <p class="text-sm text-center">
          <span class="text-grey-dark">
            Est. {{ $t('mcs.process_time') }}
          </span>
          <span class="text-grey-darker font-semibold ml-1">
            {{ estimatedProcessTime }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import processDateIcon from '@/assets/misc/process-date.svg';

export default {
  name: 'send_process_when',

  props: {
    scheduleDate: {
      type: String,
      default: '',
    },

    scheduleTimeZone: {
      type: String,
      default: '',
    },

    sendNow: {
      type: Boolean,
      default: true,
    },

    estimatedProcessTime: {
      type: String,
      default: '',
    },
  },

  computed: {
    getScheduleTime() {
      return moment(this.scheduleDate).format('h:mm a');
    },

    getScheduleDate() {
      return moment(this.scheduleDate).format('DD MMM YYYY');
    },
  },

  methods: {
    getIcon() {
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${processDateIcon}`;
    },
  },
};
</script>
