import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      hasMcsAccess: 'user/getMcsAccess',
      uploadDataSummary: 'websender/getUploadData',
    }),
  },

  methods: {
    ...mapActions({
      getContactsSummaryStatus: 'contacts/getContactsSummaryStatus',
    }),

    ...mapMutations({
      setSummary: 'recipientsContacts/SET_SUMMARY',
      setProcessing: 'websender/SET_PROCESSING',
      setUploadData: 'websender/SET_UPLOAD_DATA',
      setUploadDataCc: 'websender/SET_UPLOAD_DATA_CC',
    }),

    async getContactsSummary(uploadData) {
      const { applyCountryCode, id } = uploadData;
      const summaryStatus = await this.getContactsSummaryStatus({ id });
      const { data, status } = summaryStatus;

      if (status !== 200) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        await this.getContactsSummary(uploadData);
      } else if (data.status === 'completed') {
        const summary = {
          Total: data.total,
          ValidEntries: data.inserted,
          InvalidEntries: data.errors,
          DuplicateEntries: data.duplicates,
          Countries: Object.keys(data.countries).reduce((acc, k) => {
            acc[k.toUpperCase()] = data.countries[k];
            return acc;
          }, {}),
          InvalidFileLocation: data.invalidFileLocation,
          contactsFileId: id,
        };

        if (applyCountryCode) {
          this.setUploadDataCc(summary);
        } else {
          this.setUploadData(summary);
        }
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000));
        await this.getContactsSummary(uploadData);
      }
    },
  },
};
