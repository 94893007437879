/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    pricing: [],
  },

  getters: {
    getPricing: st => st.pricing,
  },

  mutations: {
    SET_PRICING(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.pricing = payload.pricing;
    },
  },

  actions,
};
