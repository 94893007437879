<template>
  <div class="flex mb-3 p-5 bg-grey-lightest border border-grey-light rounded-lg haslogo">
    <div class="flex-shrink py-3">
      <img :src="getIcon()" alt="icon"/>
    </div>
    <div class="flex-auto ml-5 mt-2 py-2">
      <p class="text-grey-dark text-sm mb-1">{{ label || $t('column_labels.estimated_cost') }}</p>
      <p class="text-black text-lg font-medium">{{ estimatedCost }}</p>
    </div>
  </div>
</template>

<script>
import estimatedCostIcon from '@/assets/misc/estimated-cost.svg';

export default {
  name: 'send_estimated_cost',

  props: {
    label: {
      type: String,
      default: 'Estimated cost',
    },

    estimatedCost: {
      type: String,
      default: '',
    },
  },

  methods: {
    getIcon() {
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${estimatedCostIcon}`;
    },
  },
};
</script>
