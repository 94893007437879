var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"voice-speed mb-4"},[_c('div',{staticClass:"w-2/3"},[_c('div',[_vm._m(0),_c('el-tooltip',{attrs:{"placement":"top"}},[_c('p',{staticClass:"mb-4 text-grey text-xs",attrs:{"slot":"content"},slot:"content"},[_vm._v("\n           "+_vm._s(_vm.$t('mcs.messages.voice.speed[0]'))+".\n        ")]),_c('i',{staticClass:"icon-novo-faq text-base ml-1"})])],1),_c('div',{staticClass:"flex w-2/3 slider ml-2"},[_c('div',{staticClass:"flex-auto"},[_c('el-slider',{attrs:{"step":.01,"max":2,"min":0.5,"show-input-controls":false},on:{"change":_vm.handleSliderChange},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}}),_c('div',{staticClass:"flex"},_vm._l((this.valid),function(i){return _c('div',{key:i,class:[
              'flex-1 text-grey-dark text-xs',
              { 'text-left' : Number(i) === 0.5},
              { 'text-left -ml-1' : Number(i) === 1},
              { 'text-right' : Number(i) === 2},
            ]},[_vm._v("\n            "+_vm._s(i)+"\n          ")])}),0)],1),_c('div',{staticClass:"flex-shrinkm ml-5"},[_c('el-input-number',{style:({ width: '100px'}),attrs:{"controls-position":"right","max":2,"step":.01,"min":0.5,"size":"mini"},on:{"change":_vm.handleInputChange},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-black font-medium mb-2 inline-block"},[_vm._v("\n        Playback speed "),_c('span',{staticClass:"required"})])
}]

export { render, staticRenderFns }