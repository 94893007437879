<template>
  <div class="mb-5 ca-msg-box w-4/5">
    <div class="flex justify-end items-center mb-2">
      <div class="flex-auto">
        <label for="message-content" class="text-sm text-black font-medium label__asterisk">
          {{ $t('column_labels.message') }}
          <el-tooltip
            class="item"
            effect="dark"
            content="Should be a string of no more than 1024 characters."
            placement="top"
          >
            <i
              class="create-template__info-icon el-icon-info text-grey hover:text-blue"
            ></i>
          </el-tooltip>
        </label>
      </div>
      <div class="flex-shrink">
        <div class="bg-blue-lightest rounded overflow-hidden">
          <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="Bold"
                placement="top"
                :enterable="false"
                open-delay="500"
              >
                <span
                  class="inline-block cursor-pointer py-1 px-2"
                  :class="{ 'bg-blue-lighter shadow-inner': isActive.bold() }"
                  @click="commands.bold">
                <strong>B</strong>
                </span>
              </el-tooltip>

              <el-tooltip
                class="item mx-px"
                effect="dark"
                content="Italic"
                placement="top"
                :enterable="false"
                open-delay="500"
              >
                <span
                  class="inline-block cursor-pointer py-1 px-2"
                  :class="{ 'bg-blue-lighter shadow-inner': isActive.italic() }"
                  @click="commands.italic">
                  <i>I</i>
                </span>
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                content="Strikethrough"
                placement="top"
                :enterable="false"
                open-delay="500"
              >
                <span
                  class="inline-block cursor-pointer py-1 px-2"
                  :class="{ 'bg-blue-lighter shadow-inner': isActive.strike() }"
                  @click="commands.strike">
                  <strike>S</strike>
                </span>
              </el-tooltip>
            </div>
          </editor-menu-bar>
        </div>
      </div>
    </div>
    <div class="editor mb-3">
      <editor-content
        :class="
          `editor__content p-2 border-grey-light border rounded ${isEmpty
            ? 'border-red-light'
            : ''}`
        "
        :editor="editor"
        ref="editor"
      />
      <div class="flex mt-1">
        <div class="flex-auto">
          <p v-if="isEmpty" class="text-red text-xs">
            {{ $t('validations.required', { value: $t('column_labels.message')})}}
          </p>
          <p v-if="isMaxLimitReached" class="text-red text-xs">
            {{ $t('validations.char_limit', { value: "1024" })}}
          </p>
        </div>
        <div
          v-if="textLimit"
          class="flex-shrink ml-3 text-grey text-xs text-right"
        >
          {{ charCount(content) }} / {{ textLimit }}
        </div>
      </div>
    </div>

    <div v-if="variables && variables.length">
      <p class="mb-2 text-xs">  {{ $t('mcs.messages.sms.msg_box[4]') }} </p>
      <div class="mb-2">
        <span
          v-for="i in variables"
          :key="i"
          class="mr-3 item-tag"
        >
          {{ i }}
          <i
            v-if="hasAdded(i)"
            class="el-icon-close el-icon--right"
            @click="removeVariable(i)"
          />
          <i
            v-else
            class="el-icon-plus el-icon--right"
              @click="addVariable(i)"
          />
        </span>
      </div>
      <p v-if="source === 'UPLOAD'"
        class="text-xs">
        <a
          href="#"
          class="text-blue hover:text-blue-darker no-underline"
          @click.prevent="$emit('modify-custom-fields')"
        > {{ $t('actions.modify') }}</a> {{ $t('mcs.messages.sms.msg_box[5]') }}.
        <a
          href="https://support.wavecell.com/hc/en-us/articles/115005656203-How-can-I-add-custom-fields-on-my-messages-"
          class="text-blue hover:text-blue-darker no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >{{ $t('mcs.messages.sms.msg_box[6]') }}</a>
      </p>
    </div>
  </div>
</template>
<script>
import {
  Editor,
  EditorContent,
  EditorMenuBar,
} from 'tiptap';

import _ from 'lodash';

import { hasBrackets, transformToWAValidTemplate, transformToHTML } from '@/utils/common';

import {
  Bold,
  Italic,
  Strike,
  Placeholder,
  HardBreak,
} from 'tiptap-extensions';

export default {
  name: 'message_ca_msg_wa_box',

  components: {
    EditorContent,
    EditorMenuBar,
  },

  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },

    variables: {
      type: Array,
    },

    source: {
      type: String,
      default: '',
    },

    textLimit: {
      type: Number,
      default: 0,
    },

    message: {
      type: String,
      default: '',
    },
  },

  data() {
    const self = this;

    return {
      isEmpty: false,
      isMaxLimitReached: false,
      content: '',
      editor: new Editor({
        extensions: [
          new HardBreak(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: self.$t('mcs.messages.sms.msg_box[3]'),
            showOnlyWhenEditable: true,
          }),
        ],
        content: '',
        onUpdate() {
          self.previewContent();
        },
      }),
    };
  },

  created() {
    if (this.message) {
      this.editor.setContent(this.setDisplayContent(this.message));
      this.previewContent();
    }
  },

  methods: {
    setDisplayContent(c) {
      let t = _.unescape(this.$sanitize(c.trim().replace(/\n/g, '<p></p>')));

      t = transformToHTML(t);

      return t;
    },

    previewContent() {
      let html = this.editor.getHTML()
        .replace(/<br\s*(\s+[^>]+)*[/]?>/gi, '<p></p>')
        .replace(/<\/p>/g, '\n')
        .replace(/<p>/g, '');

      if (html === '<p></p>' || !html.trim()) {
        html = '';
      }

      this.content = html;

      this.isEmpty = Boolean(!html);

      this.isMaxLimitReached = this.isCharMaxLength(html, 1024);

      if (this.isMaxLimitReached) {
        html = '';
      }

      this.$emit('update-message', html);
    },

    charCount(content) {
      if (!content || (content && !content.trim().length)) {
        return 0;
      }

      let c = _.unescape(this.$sanitize(
        transformToWAValidTemplate(content.trim()),
        { allowedTags: [] },
      ));

      c = c.replace(/\*(\S[^*]+\S)\*/g, '$1');
      c = c.replace(/_(\S[^_]+\S)_/g, '$1');
      c = c.replace(/~(\S[^~]+\S)~/g, '$1');

      return c.length;
    },

    isCharMaxLength(content, maxNo) {
      if (!content.length) return false;

      const c = this.charCount(content);

      return c > maxNo;
    },

    addVariable(variable) {
      const transaction = this.editor.state.tr.insertText(`{{${variable}}}`);
      this.editor.view.dispatch(transaction);
    },

    removeVariable(v) {
      const html = this.editor.getHTML();

      const content = html.replace(`{{${v}}}`, '');

      this.editor.setContent(content);

      this.previewContent();
    },

    hasAdded(v) {
      const html = this.editor.getHTML();

      const b = hasBrackets(html);

      if (b && b.length) {
        return b.includes(`{{${v}}}`);
      }

      return false;
    },
  },
};
</script>
<style lang="scss">
.ca-msg-box {
  &__info-icon {
    margin-left: 3px;
    font-size: 10px;
    cursor: pointer;
  }

  .editor__content {
    [contenteditable='true'] {
      height: 108px;
      outline: none;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 0.75rem;
      user-select: text;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-wrap: break-word;
      white-space: pre-wrap;
      -webkit-user-modify: read-write-plaintext-only;
    }
  }

  .editor p.is-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    color: #DCDFE6;
    pointer-events: none;
    height: 0;
    font-size: 0.75rem;
  }
}
</style>
