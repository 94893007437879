<template>
  <div class="flex items-center mcs-delivery-report">
    <div class="w-1/2">
      <div class="p-2">
        <DonutChart
          :title="`${$t('column_labels.total')} ${$t('column_labels.sent')}`"
          :data="chartData"
          :options="chartOptions"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div
        v-for="(data, i) in chartData"
        :key="i"
        :class="[
          { 'rounded-t-lg border-b-0 bg-grey-lighter': i === 0 },
          { 'bg-grey-lightest': i > 0 && i < chartData.length - 1 },
          { 'rounded-b-lg border-t-0 bg-white': i === chartData.length - 1 },
        ]"
        class="border border-solid border-grey-light p-3 flex items-center"
      >
        <svg
          width="20"
          height="20"
          class="mr-3"
        >
          <rect
            width="20"
            height="20"
            rx="3"
            :style="{ fill: data.color }"
          />
        </svg>
        <div>
          <p class="text-black text-base font-medium">
            {{ data.value.toLocaleString() }}
          </p>
          <p
            v-if="(data.label || '').toLowerCase() !== 'undelivered'"
            class="text-grey-dark text-xs mb-1"
          >
            {{ data.label }}
          </p>
          <div
            v-else
            class="undelivered-details"
          >
            <p
              v-if="!hasUndeliveredErrors"
              class="text-grey-dark text-xs mb-1"
            >
              {{ data.label }}
            </p>
            <el-dropdown
              v-else
              trigger="click"
              @visible-change="handleMenuClick"
            >
              <span class="el-dropdown-link">
                <div class="text-link flex items-center">
                  {{ data.label }}<i :class="`${undeliverMenuIcon} el-icon--right text-base`" />
                </div>
              </span>
              <el-dropdown-menu
                slot="dropdown"
                class="mcs-delivery-report"
              >
                <el-dropdown-item
                  v-for="(channel, index) in undeliveredErrors"
                  :key="index"
                >
                  <div class="mcs-undelivered-items flex items-center gap-1">
                    <div class="ms-channel-icon-container">
                      <img
                        :src="getChannelIcon(channel.ChannelType)"
                        class="block mx-auto ms-channel-icon"
                      >
                    </div>
                    <div class="details-container flex flex-col">
                      <div class="label">
                        {{ getChannelName(channel.ChannelType) }}
                      </div>
                      <div class="details">
                        {{ toNumberString(channel.MsgUndelivered) }} messages •
                        {{ getUndeliveredPercentage(channel) }}
                      </div>
                    </div>
                  </div>
                </el-dropdown-item>
                <div
                  class="action flex justify-between items-center"
                  @click="$emit('show-breakdown', channelTypes)"
                >
                  <div role="button" class="info-section cursor-pointer">
                    <i class="icon-novo-info-circle novo-icon" />
                    <span>See error codes breakdown</span>
                  </div>
                  <i class="icon-novo-arrow-right-long novo-icon" />
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <span class="text-black text-sm font-medium ml-auto">{{ getPercentage(data.value) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import DonutChart from '@/components/DonutChart.vue';
import CampaignListMixin from '@/mixins/campaign-list';
import channelsJson from '@/json/channels.json';

export default {
  name: 'CaDeliveryReport',

  components: {
    DonutChart,
  },

  mixins: [CampaignListMixin],

  props: {
    data: {
      type: Object,
      required: true,
    },

    fallbackDetail: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      channelsJson,
      undeliverMenuIcon: 'el-icon-arrow-down',
      chartOptions: {
        width: '100%',
        strokeWidth: 11,
        strokeLineCap: 'butt',
        labelColor: '#22292F',
        labelTop: false,
      },
      chartData: [
        {
          label: 'Delivered',
          value: this.data.delivered,
          color: '#4180ff',
        },
        {
          label: 'Undelivered',
          value: this.data.undelivered,
          color: '#2e384d',
        },
        {
          label: 'Rejected',
          value: this.data.rejected,
          color: '#e3ecff',
        },
      ],
    };
  },

  computed: {
    channelTypes() {
      return this.undeliveredErrors.map(channel => channel.ChannelType) || [];
    },
    undeliveredErrors() {
      return (this.fallbackDetail.channels || [])
        .filter(channel => (channel.MsgUndelivered || 0) > 0);
    },

    hasUndeliveredErrors() {
      try {
        const { summary: { totalUndelivered } } = this.fallbackDetail;
        return totalUndelivered > 0;
      } catch (error) {
        return false;
      }
    },
  },

  methods: {
    getPercentage(v) {
      return this.toPercentString((v / (this.data.total || 1)) * 100);
    },
    getUndeliveredPercentage(channel) {
      const percent = (channel.MsgUndelivered || 0) / (channel.MsgTotal || 1);
      return this.toPercentString(percent * 100);
    },

    toPercentString(n) {
      return `${n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}%`;
    },

    handleMenuClick(visible) {
      this.undeliverMenuIcon = visible ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },

    toNumberString(n) {
      return new Intl.NumberFormat('en-US').format(n);
    },
  },
};
</script>

<style lang="scss">
.el-dropdown-menu {
  &.mcs-delivery-report {
    width: 318px !important;
    padding-top: 8px !important;
    .el-dropdown-menu__item {
      padding: 12px !important;
      height: 56px !important;
      .mcs-undelivered-items {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        .ms-channel-icon-container {
          line-height: 20px !important;
        }
        .details-container {
          display: flex;
          flex-direction: column;

          .label {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }

          .details {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }

    .action {
      padding: 8px 12px !important;
      color: var(--accent-color);
      height: 41px;

      .info-section {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .novo-icon {
        font-size: 24px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.legend {
  display: inline-block;
}

.mcs-delivery-report {
  .text-link {
    color: var(--accent-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
</style>
