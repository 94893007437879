<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    @submit.prevent.native
    class="mb-4"
  >
    <div class="w-2/3">
      <div>
        <h5 class="text-black font-medium mb-2 text-sm">
          <!-- {{ $t('column_labels.source') }} -->
          Originating phone number (Caller ID) <span class="required" />
        </h5>
      </div>
      <el-form-item prop="virtualNo">
        <div class="relative">
           <el-select
            v-model="form.virtualNo"
            placeholder="Select an originating phone number"
            clearable
            @change="handleChange"
            class="w-full"
          >
            <template #prefix v-if="form.virtualNo">
              <span :class="`align-middle block mt-3 mr-3 flag-icon flag-icon-squared flag-icon-${(getPhoneNumberInfo(form.virtualNo).regionCode || '').toLowerCase()}`" />
            </template>
            <el-option
              v-for="(item,i) in virtualNumbers"
              :key="i"
              :label="getPhoneNumberInfo(item.msisdn).number.international || ''"
              :value="item.msisdn">
              <span :class="`align-middle mr-1 flag-icon flag-icon-squared flag-icon-${(getPhoneNumberInfo(item.msisdn).regionCode || '').toLowerCase()}`" />
              <span class="text-xs">{{ getPhoneNumberInfo(item.msisdn).number.international || '' }}</span>
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </div>
  </el-form>
</template>
<script>

import PhoneNumberMixin from '@/mixins/phone-number';

export default {
  name: 'message_voice_virtual_no',

  mixins: [PhoneNumberMixin],

  components: {
  },

  props: {
    source: {
      type: String,
      default: '',
    },

    label: {
      type: Boolean,
      default: false,
    },

    virtualNumbers: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      form: {
        virtualNo: '',
      },

      rules: {
        virtualNo: [
          {
            required: true,
            message: 'Virtual number is required.',
            trigger: 'change',
          },
        ],
      },
    };
  },

  watch: {
    source(newVal) {
      this.form.virtualNo = newVal;
    },

    virtualNumbers(newVal) {
      if (!this.source && newVal.length) {
        const value = newVal[0].msisdn ? newVal[0].msisdn : '';
        this.$emit('update-source', value, 'source');
      }
    },

    deep: true,
  },

  created() {
    this.form.virtualNo = this.source;
  },

  methods: {
    handleChange(value) {
      this.$refs.form.validateField('virtualNo', (err) => {
        if (!err) {
          this.$emit('update-source', value, 'source');
        } else {
          this.$emit('update-source', '', 'source');
        }
      });
    },
  },
};
</script>
