<template>
  <div class="mb-10">
    <div class="flex justify-between">
      <h1 class="mb-5 text-xl text-color font-semibold page-title">Send new alert</h1>
      <Breadcrumb
        :step="step"
        :message-done="isTemplateAndSenderIdSelected()"
        :recipients-done="(hasRecipientsSelected() && hasContacts()) || (hasRecipientsSelected() && isColumnLabelsSet())"
      />
    </div>

    <div class="bg-white rounded relative h-full w-full border border-grey-lighter">
      <div class="flex flex-col rounded relative">
        <div
          class="p-6 rounded component-wrapper"
          v-loading="loading"
        >
          <div
            v-if="hasNoSenderId || !form.subAccountId"
            class="mb-6 py-2 rounded flex border-grey-lightest border w-full"
          >
            <div class="px-4 py-2 border-l-4 border-red flex">
              <div class="flex-shrink align-top">
                <i class="icon-novo-error text-red text-2xl mr-4" />
              </div>
              <div v-if="hasNoSenderId" class="leading-normal flex-auto text-sm" :style="{wordBreak: 'break-word'}">
                It looks like your account does not have a virtual number assigned yet. Reach out to your admin
                to set it up, or contact
                <a
                  href="mailto:cpaas-support@8x8.com"
                  class="anchor-link underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cpaas-support@8x8.com
                </a> for assistance.
              </div>
              <div v-if="!form.subAccountId" class="leading-normal flex-auto text-sm">
                It looks like you do not have subaccount based on your subscription.
              </div>
            </div>
          </div>
          <component
            :is="currentViewComponent()"
            :form="form"
            :step="step"
            :has-no-sender-id="hasNoSenderId"
            @update-step="(name) => { updateStep(name) }"
            @loading="setMainLoading"
            @update-form="(data) => { updateForm(data)}"
          />
        </div>
        <div class="flex-shrink p-6 border-t border-grey-lighter text-right">
          <el-button
            v-if="isBackVisible()"
            @click="back()"
            class="mr-6"
            type="text"
            :disabled="loading"
          >
            Back
          </el-button>
          <el-button
            @click.prevent="checkChanges()"
            class="mr-1"
            :disabled="loading"
          >
            Cancel
          </el-button>
          <el-button
            type="primary"
            @click="next()"
            :loading="loading"
            :disabled="disableContinue()"
          >
            {{ step === 'SUMMARY' ? 'Send alert message' : 'Continue' }}
          </el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="Cancel Alert?"
      width="700px"
      top="320px"
      :visible.sync="cancelModalVisible"
    >
      <p class="text-sm leading-normal">
        Are you sure you want to cancel this alert?
        Cancelling will stop the alert from being sent, and any information you've entered will be discarded.
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">Yes, cancel this alert</el-button>
        <el-button type="primary" @click="cancelModalVisible = false">No, continue the alert</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Sending in..."
      width="450px"
      top="320px"
      :visible.sync="countdownModalVisible"
      @open="handleCountDownModalOpen"
      @close="handleCountDownModalClose"
    >
      <p class="text-center text-xl font-semibold text-center mb-4 text-black">
          {{ countdown }} s
      </p>
      <p class="text-sm leading-normal">This message will be sent in 5 seconds. Review quickly or take action before the countdown ends.</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="countdownModalVisible = false;" :disabled="loading">Yes, cancel this alert</el-button>
        <el-button type="primary" @click="sendAlert" :loading="loading">Send now</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';

import moment from 'moment';

import MessageMixin from '@/mixins/message';
import { getSubscribedCountry } from '@/utils/subscription';
import MessageList from './partials/Message/Templates.vue';
import RecipientsContacts from './partials/Recipients/Recipients.vue';
import SummaryPanel from './partials/Summary/Summary.vue';

import Breadcrumb from './partials/Breadcrumb.vue';


export default {
  name: 'AlertSender',

  components: {
    Breadcrumb,
  },

  mixins: [MessageMixin],

  data() {
    return {
      stepSeq: ['MESSAGE', 'RECIPIENTS', 'SUMMARY'],
      step: 'MESSAGE',
      form: {
        template: null,
        recipients: null,
        summary: null,
        country: '',
        subAccountId: '',
        senderId: '',
        contactsFileId: '',
      },
      hasNoSenderId: false,
      loading: false,
      retry: 1,
      cancelModalVisible: false,
      countdownModalVisible: false,
      countdown: 0,
      defaultCountdown: 1000 * 1,
      interval: null,
    };
  },

  beforeDestroy() {
    this.resetForm();
  },

  beforeRouteLeave(to, from, next) {
    this.resetForm();

    next();
  },

  created() {
    this.resetForm();
    this.fetchUserDetails();
    const { smsSubaccountId } = this.getSubscriptionMeta();
    this.form.subAccountId = smsSubaccountId || '';
    this.fetchVirtualNumberBySubAccountUid();
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
      uploadDetails: 'contacts/getUploadDetails',
      summaryDetails: 'contacts/getSummaryDetails',
    }),
  },

  watch: {
    form: {
      handler() {
        // console.log('newFormValues', newFormValues);
      },
      deep: true,
    },

    countdown(value) {
      if (value === 0 && this.countdownModalVisible) {
        this.sendAlert();
      }
    },
  },

  methods: {
    ...mapActions({
      getUserDetails: 'user/getUserDetails',
      getVirtualNumberBySubAccountUid: 'user/getVirtualNumberBySubAccountUid',
      uploadContacts: 'contacts/uploadContacts',
      getContactsSummary: 'contacts/getContactsSummaryStatus', // long polling
      sendSmsMessage: 'websender/sendSmsMessage',
    }),
    ...mapMutations({
      resetAlertsData: 'alerts/RESET_DATA',
      resetContactsData: 'contacts/RESET_DATA',
      setCampaignSent: 'websender/SET_CAMPAIGN_SENT_DATA',
    }),

    async fetchUserDetails() {
      try {
        await this.getUserDetails();

        const { Country: country, CountryCode: code, TimeZoneCountry: timeZoneCountry } = this.user;

        const uc = localStorage.getItem('user_country');

        const userIPCountry = uc ? uc.split(';')[1] : '';

        const subscriptionCountry = getSubscribedCountry();

        this.form.country = subscriptionCountry || country || code || userIPCountry || timeZoneCountry || '';
      } catch (e) {
        if (window.Bugsnag) {
          window.Bugsnag.notify(e);
        }
      }
    },

    async fetchVirtualNumberBySubAccountUid() {
      try {
        const { smsSubaccountUid } = this.getSubscriptionMeta();
        const data = await this.getVirtualNumberBySubAccountUid({ subAccountUid: smsSubaccountUid });

        if (Object.keys(data).length && data.virtualNumbers && data.virtualNumbers.length) {
          this.form.senderId = data.virtualNumbers[0].VN;
        } else {
          this.form.senderId = '';
          this.hasNoSenderId = true;
        }
      } catch (e) {
        this.form.senderId = '';
        this.$showError(this, e);
      } finally {
        // this.loading = false;
      }
    },

    currentViewComponent() {
      let view = null;

      switch (this.step) {
        case 'MESSAGE':
          view = MessageList;
          break;
        case 'RECIPIENTS':
          view = RecipientsContacts;
          break;
        case 'SUMMARY':
          view = SummaryPanel;
          break;
        default:
          view = MessageList;
          break;
      }
      return view;
    },

    handleCountDownModalOpen() {
      this.interval = setInterval(() => {
        this.countdown = this.countdown - 1;
      }, this.defaultCountdown);
    },

    handleCountDownModalClose() {
      this.interval = null;
      this.countdown = 0;
    },

    checkChanges() {
      if (this.isTemplateAndSenderIdSelected() || this.hasRecipientsSelected() || this.hasSummaryDefined()) {
        this.cancelModalVisible = true;
      } else {
        this.cancel();
      }
    },

    isTemplateAndSenderIdSelected() {
      return this.form.template && this.form.senderId && this.form.subAccountId;
    },

    isColumnLabelsSet() {
      const { meta, source } = this.form.recipients;

      if (source && source === 'UPLOAD'
        && meta && meta.columnLabels
        && Object.keys(meta.columnLabels).some(v => meta.columnLabels[v] !== -1)
        && meta.columnLabels.MSISDN !== -1) {
        return true;
      }

      return false;
    },

    hasContacts() {
      const { meta, source } = this.form.recipients;
      return source && source === 'INPUT' && meta;
    },

    hasRecipientsSelected() {
      return this.form.recipients
        && this.form.recipients.source
        && this.form.recipients.meta;
    },

    hasSummaryDefined() {
      return this.hasRecipientsSelected() && this.isTemplateAndSenderIdSelected() && this.form.summary;
    },

    disableContinue() {
      if (this.step === 'MESSAGE') {
        return !this.isTemplateAndSenderIdSelected();
      }

      if (this.step === 'RECIPIENTS') {
        return !(
          (this.hasRecipientsSelected() && this.hasContacts())
          || (this.hasRecipientsSelected() && this.isColumnLabelsSet())
        );
      }

      if (this.step === 'SUMMARY') {
        return !this.hasSummaryDefined();
      }

      return true;
    },

    checkIfColumnLabelsSet(columnLabels) {
      const keys = Object.keys(columnLabels);
      return (keys.length && keys.some(v => columnLabels[v] !== -1));
    },

    updateForm(formData) {
      this.form = { ...this.form, ...formData };
    },

    updateStep(stepName = '') {
      this.step = stepName;
    },

    setMainLoading(loading) {
      this.loading = loading;
    },

    isBackVisible() {
      const index = this.stepSeq.indexOf(this.step);

      return index > 0;
    },

    back() {
      const index = this.stepSeq.indexOf(this.step);

      if (index !== 0) {
        this.step = this.stepSeq[index - 1];
      }
    },

    cancel() {
      this.resetForm();
      this.$router.push('/');
    },

    resetForm() {
      this.step = 'MESSAGE';
      this.form = {
        template: null,
        recipients: null,
        summary: null,
        country: '',
        subAccountId: '',
        senderId: '',
        contactsFileId: '',
      };

      this.loading = false;

      this.interval = null;
      this.countdown = 0;

      this.resetStoreData();
    },

    resetStoreData() {
      this.resetAlertsData();
      this.resetContactsData();
      this.setCampaignSent(undefined);
    },

    next() {
      const index = this.stepSeq.indexOf(this.step);
      const { recipients, summary, contactsFileId } = this.form;

      if (index !== -1 && index < this.stepSeq.length - 1) {
        switch (this.step) {
          case 'MESSAGE': {
            this.step = this.stepSeq[index + 1];
            break;
          }

          case 'RECIPIENTS': {
            if (recipients && contactsFileId && summary) {
              this.step = this.stepSeq[index + 1];
            } else {
              this.processContacts();
            }

            break;
          }
          default:
        }
      } else if (this.step === 'SUMMARY') {
        this.countdown = 5;
        this.interval = null;
        this.countdownModalVisible = true;
      }
    },

    async processContacts() {
      try {
        this.loading = true;
        const { recipients, country } = this.form;
        const { source, meta } = recipients;

        let payload = { Source: source };

        if (source && meta) {
          switch (source) {
            case 'INPUT': {
              const {
                contacts = [], number = [], groups = [], directory = [],
              } = meta;

              const allContacts = [];

              // phone numbers and contacts should be merged
              if (contacts && contacts.length) {
                contacts.forEach((c) => {
                  if (c.addresses && c.addresses.msisdn) {
                    allContacts.push(c.addresses.msisdn);
                  }
                });
              }

              // phone numbers and contacts should be merged
              if (directory && directory.length) {
                directory.forEach((c) => {
                  if (c.phoneNumber) {
                    allContacts.push(c.phoneNumber);
                  }
                });
              }

              if (number && number.length) {
                number.forEach((c) => {
                  if (c) {
                    allContacts.push(c.replace('+', ''));
                  }
                });
              }

              // payload for contact, contact groups and phone numbers
              payload = {
                ...payload,
                Contacts: allContacts,
                Groups: groups,
              };

              break;
            }

            case 'UPLOAD': {
              const {
                allNumbersLocal,
                allowDuplicates,
                file,
                columnLabels,
                partialData,
              } = meta;

              const { List: list } = partialData;

              // payload for upload
              payload = {
                ...payload,
                AllNumbersLocal: allNumbersLocal,
                AllowDuplicates: allowDuplicates,
                Column: columnLabels,
                Country: allNumbersLocal ? country : '',
                File: file,
                List: list,
              };
              break;
            }

            default:
          }

          await this.uploadContacts(payload);

          const { id } = this.uploadDetails;

          // set contacts file id
          this.form.contactsFileId = id;

          this.getSummaryDetails();
        }
      } catch (err) {
        this.loading = false;
        this.$showError(this, err);
      }
    },

    async sendAlert() {
      this.interval = 0;
      this.countdown = 0;
      this.countdownModalVisible = false;

      try {
        const {
          template,
          recipients,
          contactsFileId,
          senderId,
          subAccountId,
        } = this.form;
        // const { text, templateId, name: templateName } = template;
        const { text } = template;

        const { meta, source } = recipients;

        const { allowDuplicates, allNumbersLocal, country } = meta;

        const payload = {
          meta: {
            source,
            productType: 'SMS',
            contactsFileId,
          },
          message: {
            from: senderId,
            to: source === 'INPUT' ? 'Groups' : 'Upload',
            allowDuplicates: Boolean(allowDuplicates),
            text: text.replace(/=HYPERLINK\(/ig, ''),
            parts: this.getTextAttrs(text).smsParts,
            clientMessageId: '',
            encoding: 'AUTO',
            saveTemplate: false,
            schedule: 'Send message now',
            campaignName: `${moment().format('YYYYMMDD-hh:mmA')}-PROACTIVE-ALERT`,
            subAccountId,
            country: allNumbersLocal ? country : '',
            allNumbersLocal: Boolean(allNumbersLocal),
            templateId: '',
            templateName: '',
          },
          type: 'PROACTIVE-ALERT',
        };

        this.loading = true;

        await this.sendSmsMessage(payload);

        const { valid } = this.form.summary;

        this.$notify.success({ title: `Alert message successfully sent to ${valid.toLocaleString()} recepients` });
        this.resetForm();
        this.$router.push('/');
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    async getSummaryDetails() {
      try {
        const { id } = this.uploadDetails;
        await this.getContactsSummary({ id });

        const { status } = this.summaryDetails;

        if (status !== 'completed') {
          await new Promise(resolve => setTimeout(resolve, 1000));
          await this.getSummaryDetails();
        } else {
          const {
            total, inserted, errors, duplicates, countries, invalidFileLocation,
          } = this.summaryDetails;

          this.form.summary = {
            total,
            valid: inserted,
            invalid: errors,
            duplicates,
            countries: Object.keys(countries).reduce((acc, k) => {
              if (k) {
                acc[k.toUpperCase()] = countries[k];
              }
              return acc;
            }, {}),
            invalidFileLocation,
          };
          this.loading = false;
          const index = this.stepSeq.indexOf(this.step);
          this.step = this.stepSeq[index + 1];
        }
      } catch (err) {
        // try retrying if not show error
        if (this.retry !== 0) {
          this.retry = this.retry - 1;
          this.getSummaryDetails();
        } else {
          this.$showError(this, err);
          this.loading = false;
        }
      }
    },

    getSubscriptionMeta() {
      let metaData = {};
      if (Object.keys(this.user).length) {
        const { Subscription: subscription } = this.user;
        if (Object.keys(subscription).length && subscription.Package) {
          const { metadata } = subscription.Package;
          if (Object.keys(metadata).length) {
            metaData = { ...metaData, ...metadata };
          }
        }
      }

      return metaData;
    },
  },
};
</script>
<style>
  .component-wrapper {
    height: calc(100vh - 210px);
    overflow-y:auto;
  }
</style>
