import Layout from './views/Layout.vue';
import Sender from './views/sender/Sender.vue';
import AlertSender from './views/alert-sender/AlertSender.vue';
import Campaigns from './views/campaigns/Campaigns.vue';
import Unauthorized from './views/Unauthorized.vue';

export default [
  {
    path: '/',
    name: 'sender',
    component: Layout,
    children: [
      {
        path: '',
        name: 'campaigns',
        component: Campaigns,
      },
      {
        path: 'send',
        name: 'sender',
        component: Sender,
      },
      {
        path: 'send-alerts',
        name: 'alert-sender',
        component: AlertSender,
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: Unauthorized,
  },
];
