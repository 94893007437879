/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    group: [],
    contact: [],
    inputContactsType: 'upload', // upload, input, contact, groups
    summary: {},
    formStep: null,
  },

  getters: {
    getGroupData: st => st.group,
    getGroupTotalActive: st => st.group.totalActive,
    getContactData: st => st.contact,
    getContactTotalActive: st => st.contact.totalActive,
    getSummary: st => st.summary,
    getFormStep: st => st.formStep,
  },

  mutations: {
    SET_UPLOAD_TYPE: (st, type) => { st.inputContactsType = type; },
    SET_GROUP: (st, payload) => { st.group = payload.group; },
    SET_CONTACT: (st, payload) => { st.contact = payload.contact; },
    SET_SUMMARY: (st, payload) => { st.summary = payload.summary; },
    SET_FORM_STEP: (st, payload) => { st.formStep = payload; },
  },

  actions,
};
