<template>
  <div
    class="leading-none"
  >
    <div v-if="formStep.match(/ADD_RECIPIENT/)" class="px-6">
      <!-- <p class="mb-2 text-sm text-grey-darkest leading-tight">
        Add recipients by uploading a file, typing the mobile number or user id on
        <br/>the field provided.
      </p> -->
      <el-radio-group
        :disabled="processLoading || loading || isTrialAccount()"
        :value="inputContactsType"
        class="my-5"
        @input="value => setUploadType(value)"
      >
        <el-radio
          label="upload"
          :disabled="isTrialAccount()"
          id="upload-file-radio-btn">
          {{ $t('actions.upload_file')}}
        </el-radio>
        <el-radio
          label="input"
          id="add-new-number-radio-btn">
          {{ $t('mcs.recip.index[1]')}}
        </el-radio>
        <el-radio
          label="contact"
          id="add-contact-radio-btn">
          {{ $t('mcs.recip.index[2]')}}
        </el-radio>
        <el-radio
          label="groups"
          id="add-contact-group-radio-btn">
          {{ $t('mcs.recip.index[3]')}}
        </el-radio>
      </el-radio-group>
      <div>
      </div>
    </div>

    <component
      :is="formComponent"
      :loading="loading"
      :processing="processLoading"
      :groupOptions="groupData"
      :contactOptions="contactData"
      :contacts="selectedContact"
      :groups="selectedGroups"
      :searchContactByPhone="getContactByMSISDN"
      :searchGroups="getGroups"
      :socket-id="socketId"
      :queue-url="queueUrl"
      :form="form"
      :partial-data="partialData"
      :process-upload="processUpload"
      :upload-contacts="uploadContacts"
      :user-data="user"
      @reset-upload="setPartialData(undefined); resetUpload"
      @reset-contacts="resetContacts"
      @discard="$emit('discard')"
      @change-step="updateRecipientForm"
      @done-recipients="nextStep"
      @submit-contacts="submitContacts"
      @submit-uploads="submitUploads"
      @set-processing="setProcessing"
      @update-form="updateForm"
      @change-process-index="changeProcessIndex"
    />
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';

import InputRecipients from './partials/InputRecipients.vue';
import UploadRecipients from './partials/UploadRecipients.vue';
import SetCustomFields from './partials/SetCustomFields.vue';
import SearchContacts from './partials/SearchContacts.vue';
import SearchGroups from './partials/SearchGroups.vue';
import Summary from './partials/Summary.vue';

export default {
  name: 'add-recipients',

  props: {
    form: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  computed: {
    ...mapState(
      'recipientsContacts', ['inputContactsType'],
      'socket', ['connected', 'socketId', 'queueUrl'],
    ),

    ...mapState('websender', {
      activeStep: 'activeStep',
      smsSegments: 'smsSegments',
      uploadData: 'uploadData',
      groupSenderData: 'groupData',
      contactEstimation: 'contactEstimation',
      groupEstimation: 'groupEstimation',
      uploadEstimation: 'uploadEstimation',
    }),

    ...mapState(['form']),

    ...mapGetters({
      partialData: 'websender/getPartialData',
      socketId: 'socket/getSocketId',
      queueUrl: 'socket/getQueueUrl',
      groupData: 'recipientsContacts/getGroupData',
      selectedContact: 'getContactData',
      selectedGroups: 'getContactGroupsData',
      contactData: 'recipientsContacts/getContactData',
      formStep: 'recipientsContacts/getFormStep',
      processLoading: 'websender/getProcessesing',
      uploadDetails: 'contacts/getUploadDetails',
      user: 'user/getUser',
      hasMcsAccess: 'user/getMcsAccess',
    }),

    formComponent() {
      let form = null;
      if (this.formStep.match(/ADD_RECIPIENT/)) {
        switch (this.inputContactsType) {
          case 'upload':
            form = UploadRecipients;
            break;
          case 'input':
            form = InputRecipients;
            break;
          case 'contact':
            form = SearchContacts;
            break;
          case 'groups':
            form = SearchGroups;
            break;
          default:
            form = UploadRecipients;
            break;
        }

        /*  form = this.isUploadContacts ? UploadRecipients : InputRecipients;  */
      } else if (this.formStep.match(/SET_CUSTOM_FIELD/)) {
        form = SetCustomFields;
      } else if (this.formStep.match(/SUMMARY/)) {
        form = Summary;
      } else {
        form = InputRecipients;
      }
      return form;
    },
  },

  data() {
    return {
      loading: false,
      // currentStep: 'ADD_RECIPIENT', // Current step for Recipient
      // currentStep: 'SET_CUSTOM_FIELD', // Curernt step for Recipient
      // currentStep: 'SUMMARY', // Curernt step for Recipient
    };
  },

  created() {
    this.fetchUserDetails();
    this.updateRecipientForm(this.formStep || 'ADD_RECIPIENT');

    if (this.isTrialAccount()) {
      this.setUploadType('input');
    }

    if (this.formStep === 'ADD_RECIPIENT' || this.formStep === 'SET_CUSTOM_FIELD') {
      this.resetUpload();
    }

    this.fetchContactGroups();
  },

  beforeDestroy() {
    this.updateRecipientForm(this.formStep);
  },

  methods: {
    ...mapMutations({
      resetRecipientstForm: 'RESET_RECIPIENTS_FORM',
      updateForm: 'UPDATE_FORM',
      setUploadType: 'recipientsContacts/SET_UPLOAD_TYPE',
      updateRecipientForm: 'recipientsContacts/SET_FORM_STEP',
      setApplyCcToAll: 'websender/SET_APPLY_CC_TO_ALL',
      setUserCountry: 'websender/SET_USER_COUNTRY',
    }),

    ...mapActions({
      getGroups: 'recipientsContacts/getGroups',
      getContactByMSISDN: 'recipientsContacts/getContactByMSISDN',
      setPartialData: 'websender/setPartialData',
      setUploadData: 'websender/setUploadData',
      setUploadDataCc: 'websender/setUploadDataCc',
      setProcessing: 'websender/setProcessing',
      processUpload: 'websender/processUpload',
      uploadContacts: 'contacts/uploadContacts',
      getUserDetails: 'user/getUserDetails',
    }),

    changeProcessIndex(index) {
      this.$emit('change-process-index', index);
    },

    async fetchContactGroups() {
      try {
        this.loading = true;
        await this.getGroups();
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error(err.message || 'Failed to fetch groups');
      } finally {
        this.loading = false;
      }
    },

    async fetchUserDetails() {
      try {
        await this.getUserDetails();

        const { CountryCode: countryCode } = this.user;

        const userCountryFromCache = localStorage.getItem('user_country');
        const userIPCountry = userCountryFromCache ? userCountryFromCache.split(';')[1] : '';
        const userCountry = countryCode || userIPCountry || '';
        this.setUserCountry(userCountry);
      } catch (e) {
        if (window.Bugsnag) {
          window.Bugsnag.notify(e);
        }
      }
    },

    resetUpload() {
      this.setUploadData(null);
      this.setUploadDataCc(null);
      this.setApplyCcToAll(false);
      this.setProcessing(false);
    },

    // Reset recipients values to empty
    resetContacts() {
      this.updateForm({
        Recipients: {
          contacts: [],
          groups: [],
        },
      });
    },

    nextStep() {
      this.$emit('change-process-index', 2);
    },

    submitContacts(summary) {
      this.updateRecipientForm('SUMMARY');
      this.updateForm({
        Recipients: {
          ...this.form.Recipients,
          source: 'INPUT',
          contactsFileId: this.uploadDetails.id,
          Location: '',
          AllowDuplicates: false,
          AllNumbersLocal: false,
          Summary: summary,
          FileUrl: summary.InvalidFileLocation,
          PartialData: this.partialData,
        },
      });

      if (this.form.Recipients) {
        const { contacts } = this.form.Recipients;

        let methodUsed = 'Upload';

        const recipientsNumber = contacts.slice(0).reduce((a, b) => {
          if (b) {
            const toShow = b.substring(0, b.length - 4);

            const masked = `${toShow}${b.slice(-4).replace(/./g, '*')}`;
            a.push(masked);
          }

          return a;
        }, []);

        switch (this.inputContactsType) {
          case 'upload':
            methodUsed = 'Upload';
            break;
          case 'input':
            methodUsed = 'Type a mobile number';
            break;
          case 'contact':
            methodUsed = 'Select a contact';
            break;
          case 'groups':
            methodUsed = 'Select a group';
            break;
          default:
            methodUsed = 'Upload';
            break;
        }

        const { track } = this.$telemetry;

        track('recepients_added', { uiArea: 'multichannel_sender_campaign_page', methodUsed, recipientsNumber });
      }
    },

    submitUploads(summary) {
      this.updateRecipientForm('SUMMARY');
      this.updateForm({
        Recipients: {
          ...this.form.Recipients,
          source: 'UPLOAD',
          PartialData: this.partialData,
        },
      });
    },

    isTrialAccount() {
      return Boolean(this.user.IsTrialAccount);
    },
  },
};
</script>
