import { parsePhoneNumber, getExample, getCountryCodeForRegionCode } from 'awesome-phonenumber';

import countriesJson from '@/json/countries.json';

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
    }),
  },

  methods: {
    getDefaultCountry() {
      const countries = countriesJson;
      const fromLC = localStorage.getItem('user_country') ? localStorage.getItem('user_country').split(';')[1] : this.getUser.TimeZoneCountry || 'SG';
      const country = this.getUser.CountryCode || fromLC;
      const callingCode = countries.find(c => c.value.toLowerCase() === country.toLowerCase());
      const placeholder = getExample(callingCode.value, 'mobile').number.international;

      return {
        country,
        callingCode: getCountryCodeForRegionCode(country),
        placeholder,
      };
    },

    getPhoneNumberInfo(phoneNumber = '') {
      const pn = {
        ...parsePhoneNumber(phoneNumber),
      };

      if (!pn.valid && pn.possibility !== 'is-possible') {
        return {
          valid: false,
          number: {
            international: phoneNumber,
            e164: phoneNumber,
          },

          regionCode: '',
          countryCode: null,
        };
      }

      if (pn.possibility === 'is-possible') {
        pn.valid = true;
      }

      return pn;
    },
  },
};
