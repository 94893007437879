<template>
  <div class="voice-repetition">
    <div class="w-2/3">
      <div>
        <h5 class="text-black font-medium mb-2 inline-block">
           Replay Frequency <span class="required"/>
        </h5>
        <el-tooltip  placement="top">
          <p slot="content" class="mb-4 text-grey text-xs">
            {{ $t('mcs.messages.voice.rep[0]') }}.
          </p>
          <i class="icon-novo-faq text-base ml-1" />
        </el-tooltip>
      </div>
      <div>
       <el-radio-group
        @change="handleChange"
        v-model="selected"
       >
        <el-radio
          v-for="i in choices"
          :key="i.label"
          :label="i.label"
        >
          {{ i.title }}
        </el-radio>
      </el-radio-group>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'message_voice_repetition',

  props: {
    repetition: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      selected: 1,

      choices: [
        { label: 1, title: this.$t('mcs.messages.voice.rep[1]') },
        { label: 2, title: this.$t('mcs.messages.voice.rep[2]', { no: '2' }) },
        { label: 3, title: this.$t('mcs.messages.voice.rep[2]', { no: '3' }) },
      ],
    };
  },

  watch: {
    repetition(v) {
      if (v) {
        this.selected = v;
      }
    },
  },

  created() {
    this.selected = this.repetition;
  },


  methods: {
    handleChange(v) {
      this.$emit('update-repetition', Number(v), 'repetition');
    },
  },
};
</script>
