<template>
  <div class="mt-1 p-1">
    <div class="flex mb-2">
      <p class="flex-1 text-xs">
        <span class="text-grey-darker">{{ $t('column_labels.sms_parts') }}:</span>
        <span class="text-black font-normal"> {{ smsParts }} </span>
      </p>
      <p class="flex-1 text-xs text-right">
        <span class="text-grey-darker">{{ $t('column_labels.encoding_type') }} </span>
        <a
          href="https://developer.8x8.com/connect/docs/getting-started#b-the-message-encoding-which-character-set-to-use"
          class="text-blue hover:text-blue-darker cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
          >
          {{ unicodeType }}
        </a>
      </p>
    </div>
    <div v-if="hasCustomFields && openCustomFields"
      class="border border-solid border-blue-lighter p-2 mb-2 rounded bg-blue-lightest flex">
      <p class="leading-tight text-xs text-blue flex-auto">
        {{ $t('mcs.messages.sms.details[0]') }}
      </p>
      <p class="flex-shrink">
        <i class="el-icon-close text-xs cursor-pointer align-top text-black"
          @click="hideCustomFields()"/>
      </p>
    </div>
    <div v-if="hasDynamicUrl && openDynamicUrl"
      class="border border-solid border-purple-lighter p-2 bg-purple-lightest rounded flex">
      <p class="leading-tight text-xs text-purple flex-auto">
       {{ $t('mcs.messages.sms.details[1]') }}
      </p>
      <p class="flex-shrink">
        <i class="el-icon-close text-xs cursor-pointer align-top text-black"
          @click="hideDynamicUrl()" />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'message_ca_selected_template',

  props: {
    smsParts: {
      type: Number,
      default: 1,
    },

    unicodeType: {
      type: String,
      default: 'GSM7',
    },

    hasDynamicUrl: {
      type: Boolean,
      default: false,
    },

    hasCustomFields: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      openCustomFields: true,
      openDynamicUrl: true,
    };
  },

  methods: {
    hideDynamicUrl() {
      this.openDynamicUrl = false;
    },

    hideCustomFields() {
      this.openCustomFields = false;
    },
  },
};
</script>
