<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    @submit.prevent.native
    class="voice-dest mb-6"
  >
    <div class="w-2/3">
      <div>
        <h5 class="text-black font-medium mb-2 inline-block">
          Recipient phone number <span class="required" />
        </h5>
        <el-tooltip  placement="top">
          <p slot="content" class="mb-4 text-grey-dark text-xs">
            <i18n
              path="mcs.messages.voice.source[0]"
              tag="p"
              class="mb-3 text-grey-dark text-xs"
            >
              <template #br>
                <br>
              </template>
            </i18n>
          </p>
          <i class="icon-novo-faq text-base ml-1" />
        </el-tooltip>
      </div>
      <el-form-item prop="destination">
        <div class="relative w-3/4 bg-grey-lightest voice-rep">
          <!-- <vue-tel-input
            v-model="form.source"
            required
            :input-options="{showDialCode: true}"
            class="voice-source__tel-input mt-2 text-sm text-grey-dark"
            @input="handlePhoneValidate"
          /> -->
          <VueTelInput
            v-model="form.destination"
            mode="international"
            :default-country="country"
            :auto-default-country="false"
            :input-options="{ showDialCode: false, placeholder: 'Enter a valid phone number'}"
            :dropdownOptions="{
              showDialCodeInList: true,
              showDialCodeInSelection: true,
              showSearchBox: true,
              showFlags: true
            }"
            :disabled="loading"
            required
            @input="handlePhoneValidate"
          >
            <template v-slot:arrow-icon>
              <i class="icon-novo-arrow-down ml-1" />
            </template>
          </VueTelInput>
        </div>
         <p
            v-if="!isValid && form.destination !==''"
            class="text-xs text-red"
          >
            Enter a valid phone number
          </p>
      </el-form-item>
    </div>
  </el-form>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import PhoneNumberMixin from '@/mixins/phone-number';

export default {
  name: 'message_voice_destination',

  mixins: [PhoneNumberMixin],

  components: {
    VueTelInput,
  },

  props: {
    destination: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      form: {
        destination: '',
      },


      isValid: false,
    };
  },

  created() {
    this.form.destination = this.destination;

    const { country } = this.getDefaultCountry();

    this.country = country;
  },

  methods: {
    handlePhoneValidate() {
      this.isValid = this.getPhoneNumberInfo(this.form.destination).valid;

      if (this.isValid) {
        const dest = this.getPhoneNumberInfo(this.form.destination).number.e164;
        this.$emit('update-destination', dest);
      } else {
        this.$emit('update-destination', '');
      }
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
.voice-rep {
  .vue-tel-input {
    border: none;
    border-radius: .5rem;
    height: 40px;
    width: 440px;
    background-color: #f1f1f1;

    .vti__input {
      width: 355px;
      border: none;
      border-radius: .5rem;
      background-color: #f1f1f1;
    }

    .vti__dropdown {
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 7px;
      padding-right: 7px;
      outline: 0;
      width: 90px;

      input {
        font-size: 0.75rem;
        background-color: #f1f1f1;
      }

      .vti__dropdown-list {
        border: 1px solid #DCDFE6;
      }
    }
  }

  .vue-tel-input:focus-within {
    box-shadow: none;
    border: none;
  }
}

</style>
