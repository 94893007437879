<template>
  <div class="mb-5">
    <h5 class="text-black font-medium mb-2">
      {{ $t('column_labels.message') }}
    </h5>
    <div class="rounded bg-grey-lighter
      border border-solid border-grey-light text-xs w-4/5">
      <div
        v-if="Object.keys(header).length"
        class="px-3 py-4 text-grey-dark bg-white rounded-t"
      >
        <div
          class="text-center"
          v-if="header.type !== 'text'"
        >
          <img
            :src="urlResolver(
              bgUrl,
              require(`@/assets/media/${getMediaIcons(header.type)}.svg`)
            )"
            class="align-middle"
            :alt="header.type"
          />
        </div>
        <div v-else>
          {{ header.value }}
        </div>
      </div>
      <div
        v-html="formatMessage"
        contenteditable="false"
        class="p-3 whitespace-pre-wrap break-words text-grey-black leading-tight"
      />
      <div
        v-if="footer"
        class="text-grey-dark px-3 pb-3"
        :style="{fontSize: '11px'}"
      >
        {{ footer }}
      </div>
    </div>
  </div>
</template>
<script>
import { urlResolver } from '@/utils/common';

export default {
  name: 'message_ca_msg_box_disabled',

  props: {
    header: {
      type: Object,
      default: () => ({}),
    },

    body: {
      type: String,
      default: '',
    },

    footer: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
    };
  },

  computed: {
    formatMessage() {
      return this.$sanitize(this.body);
    },
  },

  methods: {
    urlResolver(bgurl, path) {
      return urlResolver(bgurl, path);
    },

    getMediaIcons(type = 'image') {
      const iconColor = 'dark';

      return `${type}-${iconColor}`;
    },
  },
};
</script>
