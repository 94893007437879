<template>
  <div>
    <svg width="100%" height="9">
      <rect width="100%" height="100%" rx="5" fill="#e3ecff" />
      <rect
        v-for="item in data"
        :key="item.label"
        :fill="getItemByLabel(item.label).color || '#e3ecff'"
        :width="`${getItemByLabel(item.label).value || 0}%`"
        :visibility="getItemByLabel(item.label).value <= 0"
        fill-opacity="1"
        height="100%"
        rx="5"
        class="progress-bar__bar"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'progress-bar',

  props: {
    total: {
      type: Number,
      default: 0,
    },

    data: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dataset: new Map(),
    };
  },

  created() {
    // Sort to decending to paint svg in proper layer order
    const total = this.total || this.data.reduce((acc, curr) => acc + curr.value, 0);
    const MIN_WIDTH_BAR = 2;

    // Convert data to HashMap
    this.data
      .sort((a, b) => (a.value > b.value ? -1 : 1))
      .forEach((d) => {
        const percentValue = (d.value / (total || 1)) * 100;
        this.dataset.set(
          d.label,
          {
            ...d,
            value: percentValue <= MIN_WIDTH_BAR && percentValue > 0 ? MIN_WIDTH_BAR : percentValue,
          },
        );
      });
  },

  methods: {
    getItemByLabel(key) {
      return this.dataset.has(key) ? this.dataset.get(key) : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  &__bar {
    transition: width 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
</style>
