import http from '@/utils/http';

export default {
  async getChatAppsPricing({ commit }, payload) {
    const slug = '/mcs/chatapps/pricing';

    try {
      const { data } = await http.v2.post(`${slug}`, payload);

      commit('SET_PRICING', { pricing: data });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getVoicePricing({ commit }, payload) {
    const slug = '/mcs/voice/pricing';

    try {
      const { data } = await http.v2.post(`${slug}`, payload);

      commit('SET_PRICING', { pricing: data });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },


  async getSmsPricing({ commit }, payload) {
    const slug = '/websender/price-range';

    try {
      const { data } = await http.v1.post(`${slug}`, payload);

      commit('SET_PRICING', { pricing: data });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
