/* eslint-disable no-param-reassign */

import actions from './actions';

export default {
  namespaced: true,

  state: {
    activeStep: 0,
    processing: false,
    partialData: undefined,
    groupData: undefined,
    uploadEstimation: undefined,
    groupEstimation: undefined,
    contactEstimation: undefined,
    senderIds: [],
    campaignSent: undefined,

    seQRCode: {},
    seForms: [],
    messageSamples: [],
    virtualNumbers: [],

    uploadData: null, // Contacts summary
    uploadDataCc: null, // Contacts summary with applied country code to all numbers
    applyCcToAll: false, // Force apply country code to all numbers flag
    userCountry: null, // Basis for the default country code
  },

  getters: {
    getVirtualNumbers: state => state.virtualNumbers,
    getSenderIds: state => state.senderIds,
    getPartialData: state => state.partialData,
    getProcessesing: state => state.processing,
    getCampaignSent: state => state.campaignSent,
    getSeQRCode: state => state.seQRCode,
    getSeForms: state => state.seForms,
    getMessageSamples: state => state.messageSamples,
    getUploadData: state => state.uploadData,
    getUploadDataWithCc: state => state.uploadDataCc,
    getApplyCcToAll: state => state.applyCcToAll,
    getUserCountry: state => state.userCountry,
  },

  mutations: {
    SET_VIRTUAL_NUMBERS(state, payload) {
      state.virtualNumbers = payload;
    },

    SET_ACTIVE_STEP(state, payload) {
      state.activeStep = payload;
    },

    SET_PROCESSING(state, payload) {
      state.processing = payload;
    },

    SET_PARTIAL_DATA(state, payload) {
      state.partialData = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_UPLOAD_DATA(state, payload) {
      state.uploadData = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_UPLOAD_DATA_CC(state, payload) {
      state.uploadDataCc = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_APPLY_CC_TO_ALL(state, payload) {
      state.applyCcToAll = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_USER_COUNTRY(state, payload) {
      state.userCountry = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_GROUP_DATA(state, payload) {
      state.groupData = payload;

      // Stop processing
      if (payload) {
        state.processing = false;
      }
    },

    SET_UPLOAD_ESTIMATION(state, payload) {
      state.uploadEstimation = payload;
    },

    SET_GROUP_ESTIMATION(state, payload) {
      state.groupEstimation = payload;
    },

    SET_CONTACT_ESTIMATION(state, payload) {
      state.contactEstimation = payload;
    },

    SET_SENDER_IDS(state, payload) {
      state.senderIds = payload.senderIds.filter(v => v.SenderId);
    },

    REMOVE_SENDER_ID(state, payload) {
      state.senderIds = state.senderIds.filter(item => payload.senderId !== item.SenderId);
    },

    SET_CAMPAIGN_SENT_DATA(state, payload) {
      state.campaignSent = payload;
    },

    SET_SE_QR_CODE(state, payload) {
      state.seQRCode = payload;
    },

    SET_SE_FORMS(state, payload) {
      state.seForms = payload;
    },

    SET_MESSAGE_SAMPLES(state, payload) {
      state.messageSamples = payload;
    },
  },

  actions,
};
