import http from '../../../utils/http';

export default {
  // Fetch groups data
  getGroups: async ({ commit }, query = null) => {
    try {
      let url = '/groups?include=totals&include=countries&offset=0&limit=500';
      if (query && query !== '') {
        url = url.concat(`&search=${query}`);
      }
      const { data } = await http.v1.get(url);
      commit({ type: 'SET_GROUP', group: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getContactByMSISDN: async ({ commit }, query) => {
    try {
      let url = '/contacts?include=fields&include=groups&include=totals&offset=0&limit=10';
      if (query && query !== '') {
        url = url.concat(`&qs=${query}`);
      }
      const { data } = await http.v1.get(url);
      commit({ type: 'SET_CONTACT', contact: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getRecipientsSummary: async ({ commit }, payload) => {
    try {
      const { data } = await http.v1.post(
        '/groups/get-totals-mcs',
        payload,
      );
      commit({ type: 'SET_SUMMARY', summary: data || {} });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
