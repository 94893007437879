/* eslint-disable import/no-unresolved */
<template>
  <div>
    <div class="flex -mx-1">
      <div class="w-2/3 px-1">
        <CampaignInfo
          :current-tz="tz.timeZone"
          :current-tz-offset="tz.timeZoneOffset"
          :get-local-time="getLocalTime"
          :data="campaign"
          :campaign-meta="campaignMeta"
        />
        <div class="m-10">
          <h4 class="text-black font-medium mb-3">
            {{ $t("column_labels.sent") }} vs {{ $t("column_labels.delivered") }} vs
            {{ $t("column_labels.rejected") }}
          </h4>
          <DeliveryReport
            :data="deliveryReportData()"
            :fallback-detail="fallbackDetail"
            @show-breakdown="hanldeShowBreakdown"
          />
        </div>
      </div>
      <div class="w-1/3 px-1">
        <Status
          :statusCode="campaign.CampaignStatusId"
          @open="() => updateExportModalVisibility(true)"
          :campaignCompletedAt="campaign.CompletedAt"
          :scheduled-at="campaign.ScheduledAt"
          :is-scheduled-campaign="
            isScheduled(campaign.ScheduledAt) &&
            (campaign.CampaignStatusId === 1 || campaign.CampaignStatusId === 4) &&
            (userData.AccessLevel === 'A' || campaign.CreatedBy.toUpperCase() === userData.UserId)
          "
          @cancel-scheduled-campaign="
            () => {
              $emit('cancel-campaign', campaign);
            }
          "
        />
        <ProcessWhen
          :scheduleDate="getLocalTime(campaign.ScheduledAt, tz.timeZoneOffset, tz.timeZone)"
          :campaignCompletedAt="campaign.CompletedAt"
        />
        <TotalMessages :total="campaign.MsgTotal" />
        <Cost
          label="Cost"
          v-if="showCost"
          :estimatedCost="![5, 7].includes(campaign.CampaignStatusId) ? getCost(campaign) : '-'"
          class="px-2 pb-1 pt-4 -mt-3 rounded-t-none"
        />
        <MessagePreview
          v-if="showMessage"
          class="pt-20 -mt-20 sticky pin-t"
          :has-whats-app="metaAvailable && campaignMeta.channels && campaignMeta.channels.length ? campaignMeta.channels.includes('WA') : false"
          :message-type="productTypeByCode(campaign.Product).value"
          :header="metaAvailable ? getTemplateHeader(campaignMeta) : {}"
          :footer="metaAvailable ? getFooterText(campaignMeta) : ''"
          :message="campaign.TemplateBody"
          :imageInputs="metaAvailable ? getImageUrl(campaignMeta) : {}"
          :buttonInputs="metaAvailable ? getButtonFields(campaignMeta) : {}"
          :wa-buttons="metaAvailable ? getWAButtons(campaignMeta) : []"
          :channel="campaign.ChannelType"
          :custom-fields="metaAvailable ? getCustomFields(campaignMeta) : []"
          :variables="metaAvailable ? Object.keys(campaignMeta.columnLabels || []) : []"
          :sms-parts="getTextAttrs(campaign.TemplateBody).smsParts || 0"
          :total-sms="campaign.SmsTotal || 0"
          :hide-total-message="true"
        />
      </div>
    </div>
    <div class="fallback-section">
      <h4 class="text-black font-medium mt-5 mb-8">
        {{ $tc("column_labels.channel", 2) }}
      </h4>
      <FallbackDetail
        v-if="fallbackDetail"
        ref="fallbackDetail"
        :data="fallbackDetail"
        :campaign-meta="campaignMeta"
        :sub-account="campaign.SubAccountId"
      />
    </div>
    <ExportModal
      :visible="exportModalVisible"
      :campaignId="campaign.CampaignId"
      @close="() => updateExportModalVisibility(false)"
    />
  </div>
</template>

<script>
import Moment from 'moment';
import { mapGetters } from 'vuex';
import CampaignListMixin from '@/mixins/campaign-list';
import msgMixin from '@/mixins/message';
import Cost from '@/views/sender/partials/Send/partials/EstimatedCost.vue';
import MessagePreview from '@/views/sender/partials/Send/partials/MessagePreview.vue';
import CampaignInfo from './CampaignInfo.vue';
import Status from './CampaignStatus.vue';
import DeliveryReport from './DeliveryReport.vue';
import ExportModal from './ExportModal.vue';
import FallbackDetail from './FallbackDetail.vue';
import ProcessWhen from './ProcessWhen.vue';
import TotalMessages from './TotalMessages.vue';

export default {
  name: 'CaCampaignDetail',

  components: {
    CampaignInfo,
    DeliveryReport,
    FallbackDetail,
    Status,
    ProcessWhen,
    TotalMessages,
    Cost,
    MessagePreview,
    ExportModal,
  },

  mixins: [msgMixin, CampaignListMixin],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    campaign: {
      type: Object,
      required: true,
    },

    fallbackDetail: {
      type: Object,
      default: () => {},
    },

    showMessage: {
      type: Boolean,
      default: false,
    },

    getLocalTime: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      campaignMeta: {},
      metaAvailable: false,
      exportModalVisible: false,
      expandFallback: '',
    };
  },

  computed: {
    ...mapGetters({
      tz: 'user/getTz',
      userData: 'user/getUser',
      hasPricingEntitlement: 'user/getPricingEntitlementAccess',
      shouldHideCost: 'user/getHideCostFlag',
    }),

    showCost() {
      return this.hasPricingEntitlement && !this.shouldHideCost;
    },
  },

  created() {
    try {
      this.campaignMeta = JSON.parse(this.campaign.CampaignMeta) || {};
    } catch (err) {
      this.$showError(this, err);
      // this.$message.error(`Failed to parse campaign meta: ${err || ''}`);
    } finally {
      this.metaAvailable = !!Object.keys(this.campaignMeta).length;
    }
  },

  methods: {
    hanldeShowBreakdown(channels) {
      channels.forEach((channel) => {
        this.$refs.fallbackDetail.handleMenuClick(channel, true);
      });
    },
    isScheduled(scheduledAt) {
      if (!scheduledAt) return false;

      const timeLimit = this.$vars && this.$vars.SCHEDULED_TIME_LIMIT_IN_MINS
        ? this.$vars.SCHEDULED_TIME_LIMIT_IN_MINS
        : 3;

      const current = Moment();
      const st = Moment.utc(scheduledAt).subtract(timeLimit, 'minutes');
      return !current.isSameOrAfter(st);
    },

    getFooterText(meta = {}) {
      if (
        !(
          Object.keys(meta).length
          && meta.template
          && meta.template.footer
          && meta.template.footer.text
        )
      ) return '';
      return meta.template.footer.text;
    },

    getImageUrl(meta = {}) {
      if (!(Object.keys(meta).length && meta.message && meta.message.imageURL)) return {};
      return { url: meta.message.imageURL, previewUrl: meta.message.imageURL };
    },

    getButtonFields(meta = {}) {
      if (!(Object.keys(meta).length && meta.message && meta.message.buttonFields)) return {};

      return meta.message.buttonFields;
    },

    getWAButtons(meta = {}) {
      // eslint-disable-next-line max-len
      if (
        !(
          Object.keys(meta).length
          && meta.message
          && meta.message.buttons
          && meta.message.buttons.length
        )
      ) return [];

      const btns = meta.message.buttons;
      const urlBtn = meta.message.buttons.find(
        v => v.type.toLowerCase() === 'url' && v.value && v.dynamic,
      );

      if (urlBtn) {
        btns.map((v) => {
          // eslint-disable-next-line no-useless-escape
          if (v.value && v.dynamic && v.type.toLowerCase() === 'url') {
            // eslint-disable-next-line no-param-reassign
            v.url = v.value.replace(/\/([^/]+)\/?$/, '/{{1}}');
          }
          return v;
        });
      }

      return btns;
    },

    getTemplateHeader(meta = {}) {
      if (!(Object.keys(meta).length && meta.header)) return '';

      const { header } = meta;

      return {
        type: Object.keys(header).length && header.type ? header.type.toLowerCase() : '',
        value: Object.keys(header).length && header.text ? header.text : '',
      };
    },

    getTemplateMeta(meta = {}) {
      if (!(Object.keys(meta).length && meta.template)) return {};

      return meta.template;
    },

    getCustomFields(meta = {}) {
      if (!Object.keys(meta).length) return {};

      // Populate custom fields
      let customFields = [];

      if (meta.customFields && Object.keys(meta.customFields).length) {
        customFields = Object.keys(meta.customFields).map(key => ({
          field: `{{${key}}}`,
          ...meta.customFields[key],
        }));
      }

      // Populate header custom fields
      const headerCustomFields = [];
      const waButtonFields = [];
      const templateMeta = this.getTemplateMeta(meta);

      if (Object.keys(templateMeta).length && templateMeta.header && templateMeta.header.format) {
        const headerType = templateMeta.header.format.toLowerCase();

        if (headerType !== 'location') {
          headerCustomFields.push({
            field: templateMeta.header.format.toLowerCase() === 'text' ? 'h_{{1}}' : '',
            headerType,
            fieldType: '',
            type: 'custom',
            value: meta.message && meta.message.header ? meta.message.header : '',
            buttonType: '',
          });
        }
      }

      if (
        meta.message
        && meta.message.buttons
        && meta.message.buttons
        && meta.message.buttons.some(v => v.type.toLowerCase() === 'url')
      ) {
        const urlBtn = meta.message.buttons.find(
          v => v && v.type.toLowerCase() === 'url' && v.value && v.dynamic,
        );

        let value = '';

        if (urlBtn && urlBtn.value) {
          const { value: v } = urlBtn;
          value = v;
        }

        waButtonFields.push({
          field: urlBtn ? 's_{{1}}' : '',
          headerType: '',
          type: 'custom',
          value,
          buttonType: 'url',
          fieldType: '',
        });
      }

      return [...headerCustomFields, ...customFields, ...waButtonFields];
    },

    getCost(campaignDetail) {
      const d = this.$vars.PRICE_DECIMAL;
      const l = this.$vars.LOCALE;
      const { Price, PriceCurrency } = campaignDetail;
      // Numbers above 0 will only show 2 decimal places
      const decimal = Price < 1 ? d : 2;
      return `${PriceCurrency || ''} ${this.$wcsender.formatPrice(Price, decimal, l)}`;
    },

    // Format Delivered, Undelivered and Rejected into an object
    deliveryReportData() {
      const {
        MsgTotal: total,
        MsgDelivered: delivered,
        MsgUndelivered: undelivered,
        MsgError: rejected,
      } = this.campaign;

      return {
        total,
        delivered,
        undelivered,
        rejected,
      };
    },

    updateExportModalVisibility(value) {
      this.exportModalVisible = value;
    },
  },
};
</script>
