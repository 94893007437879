<template>
  <div>
    <div v-if="templates.length">
      <h3 class="">Outbound only</h3>
      <el-row class="t-row" :gutter="24">
        <el-col
          v-for="item in getOutboundTemplates(templates)"
          :key="item.templateId"
          :span="8"
          class="mt-6"
          >
          <Template
            :template="item"
            :selected="selected"
            type="O"
             @select-template="(t, type) => { selectTemplate(t, type) }"
          />
        </el-col>
        <!-- <el-col :span="8" class="mt-6">
          <a
            class="create-template flex flex-col justify-center items-center block no-underline text-blue text-large border border-grey-lighter template-card relative border-dashed">
            <i class="block icon-novo-plus text-2xl font-bold mb-3" />
            <h4>Create Template</h4>
          </a>
        </el-col> -->
      </el-row>
      <h3 class="mt-6">Outbound & inbound</h3>
      <el-row
        class="t-row"
        :gutter="24"
      >
        <el-col
          v-for="item in getOutboundInboundTemplates(templates)"
          :key="item.templateId"
          :span="8"
          class="mt-6"
        >
          <Template
            :template="item"
            :selected="selected"
            type="IO"
            @select-template="(t, type) => { selectTemplate(t, type) }" />
        </el-col>
      </el-row>
    </div>
    <div
      v-else
      class="flex flex-col justify-center items-center"
      :style="{minHeight: '500px'}"
    >
      <Empty/>
      <p class="leading-normal mt-4">No templates</p>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapActions,
} from 'vuex';

import Template from './Template.vue';
import Empty from '../Empty.vue';

export default {
  name: 'Templates',

  components: {
    Template,
    Empty,
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selected: {},
    };
  },

  computed: {
    ...mapGetters({
      templates: 'alerts/getAlertTemplates',
    }),
  },

  created() {
    this.fetchTemplates();
  },

  methods: {
    ...mapActions({
      getTemplates: 'alerts/getAlertTemplates',
    }),

    getOutboundTemplates(templates) {
      return templates.filter(v => v.outbound && !v.inbound);
    },

    getOutboundInboundTemplates(templates) {
      return templates.filter(v => v.outbound && v.inbound);
    },

    selectTemplate(template, type) {
      this.selected = { ...template, type };
      this.$emit('update-form', { template: this.selected });
      // this.$notify({
      //   type: 'success',
      //   title: `Template '${template.name}' has been selected.`,
      // });
    },

    async fetchTemplates() {
      try {
        this.$emit('loading', true);
        await this.getTemplates();

        const { template } = this.form;

        // if main form data exists, update local data
        if (template
          && this.templates.length
          && this.templates.some(v => v.templateId === template.templateId)) {
          this.selected = { ...this.form.template };
        }
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.$emit('loading', false);
      }
    },
  },
};
</script>
<style lang="scss">
.template-card {
  height: 204px;
  border-radius: 8px;

  &:focus, &:hover, &:active {
    border: 1px solid #0256E0;
  }

  &:hover {
    cursor: pointer
  }

  .create-template {
    border: 1px dashed #0256E0;
  }
}

.template-card.selected {
  outline: 2px solid #0256E0 !important;
  cursor: not-allowed;
  pointer-events: none;

}
</style>
