/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    loading: false,
    selectedCampaignId: '',
    campaigns: [],
    statuses: [],
    searchResults: [],
    filters: {},
    fallbackDetail: '',
  },

  getters: {
    getLoading: state => state.loading,
    getCampaigns: state => state.campaigns,
    getStatuses: state => state.statuses,
    getFilters: state => state.filters,
    getSelectedCampaign: state => (state.campaigns || [])
      .find(c => c.CampaignId === state.selectedCampaignId) || {},
    getCampaignFallbackDetail: state => state.fallbackDetail,
  },

  mutations: {
    SET_LOADING: (st, payload) => { st.loading = payload.bool; },
    SET_SELECTED_CAMPAIGNS: (st, id) => { st.selectedCampaignId = id; },
    SET_CAMPAIGNS: (st, payload) => { st.campaigns = payload.campaigns; },
    SET_STATUSES: (st, payload) => {
      st.statuses = payload.statuses.map(
        s => ({ value: s.CampaignStatusId, name: s.CampaignStatusName }),
      );
    },
    SET_RESULTS: (st, payload) => { st.searchResults = payload.campaigns; },
    SET_FILTERS: (st, payload) => { st.filters = payload; },
    SET_FALLBACK_DETAIL: (st, payload) => { st.fallbackDetail = payload.data; },
  },

  actions,
};
