<template>
  <div>
    <div v-loading="uploading || loading">
      <el-upload
        id="upload-file-input-for-alert"
        class="w-full mt-5 upload-alert-box"
        ref="uploadAlert"
        drag
        accept=".csv, .txt, .xlsx"
        :show-file-list="false"
        :disabled="loading ||uploading"
        :limit="1"
        :auto-upload="false"
        :before-upload="handleBeforeUpload"
        :http-request="(hooks) => { handleRequest(hooks) }"
        :on-change="handleChange"
        :on-success="handleSuccess"
        :on-error="handleError">
       <div class="flex flex-col items-center justify-center h-full">
          <div class="mb-3"><i class="icon-novo-folder text-4xl" /></div>
          <div class="text-base text-black font-semibold mb-2">Click to upload or drag and drop your files here.</div>
          <div class="text-black text-sm">Supported formats include .CSV, .TXT and .XLSX <br/> with a maxium file size of 30MB.</div>
       </div>
        <div
          class="el-upload__tip"
          slot="tip"
        >
          <p class="font-semibold mb-2">Sample file</p>
          <p class="text-sm">You can download a sample file and check the format of your contacts below:</p>
          <div class="mt-4">
            <a
              id="pma-download-sample-file"
              class="text-blue hover:text-blue-dark cursor-pointer font-semibold text-sm"
              @click.prevent="downloadSampleFile('pma-download-sample-file')"
            >
              <i class="icon-novo-download" /> Download a sample .csv file
            </a>
          </div>
        </div>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import UploadMixins from '@/mixins/upload';

export default {
  name: 'UploadFile',

  mixins: [UploadMixins],

  props: {
    loading: {
      type: Boolean,
      loading: false,
    },
  },

  data() {
    return {
      uploading: false,
      supportedFileTypes: [
        'text/csv',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
    };
  },

  created() {
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },

  methods: {
    resetUpload() {
      this.uploading = false;
      if (this.$refs.uploadAlert) {
        this.$refs.uploadAlert.clearFiles();
      }
    },

    handleBeforeUpload() {
      // this.uploading = true;
    },

    handleRequest(hooks) {
      // no custom request at this time
      this.uploading = true;
      Promise.resolve().then(() => {
        hooks.onSuccess();
        this.uploading = false;
      });
    },

    async handleChange(file) {
      try {
        const fileDetails = await this.handleUploadedContactsFile(file);
        this.$emit('update-file-info', fileDetails);
        this.resetUpload();
      } catch (e) {
        this.handleError(e);
        this.$emit('update-file-info', {});
        this.resetUpload();
      }
    },

    handleSuccess() {
      this.uploading = false;
      this.resetUpload();
    },

    handleError(err) {
      if (window.Bugsnag) {
        window.Bugsnag.notify(err);
      }

      this.resetUpload();

      let msg = 'Please try upload again later.';

      if (err.message) {
        msg = err.message;
      }

      // this.$message.error(emsg);
      this.$notify.error({
        title: 'File upload has been failed',
        message: msg || '',
      });
    },
  },
};
</script>

<style lang="scss">
.upload-alert-box {
  .el-upload { width: 100%; }
  .el-upload-dragger {
    width: 100%;
    min-height: calc(100vh - 460px);
    border: 2px dashed #0056e0 !important;
    border-radius: 8px;
    height: calc(100vh - 460px);
  }
  .el-upload__tip {
    font-size: 16px;
    color: #292929;
    margin-top: 24px;
  }
}
</style>
