/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    templates: [],
    total: 0,
  },

  getters: {
    getAlertTemplates: state => state.templates,
    getTotal: state => state.total,
  },

  mutations: {
    SET_ALERT_TEMPLATES: (st, payload) => {
      st.templates = payload.templates;
      st.total = payload.total;
    },

    RESET_DATA(state) {
      state.templates = [];
      state.total = 0;
    },
  },

  actions,
};
