<template>
<el-dialog
  title="Sms Templates"
  :visible="modalVisible"
  width="85%"
  top="60px"
  destroy-on-close
  @close="closeModal"
>
  <div class="flex flex-col sms-templates h-full"
    v-loading="loading">
    <div class="flex-auto">
      <div class="mb-5 flex">
        <div
          v-if="!isChatApps"
          class="flex-initial mr-5">
          <h5 class="mb-2">{{ $t('fields.sender_id') }}</h5>
          <el-select
            v-model="senderId"
            filterable
            size="small"
            style="width: 200px;">
            <el-option
              v-for="k in senderIds"
              :key="k.SenderId"
              :label="k.Text"
              :value="k.SenderId">
            </el-option>
          </el-select>
        </div>
        <div class="flex-initial">
          <h5 class="mb-2"> &nbsp; </h5>
          <el-input
            size="small"
            style="width: 300px;"
            :placeholder="$t('mcs.messages.sms.templates[0]')"
            suffix-icon="el-icon-search"
            v-model="search" />
        </div>
      </div>
    </div>

    <div class="flex-auto min-h-custom">
      <el-table
        :data="tableData.paginated"
        class="w-full">
        <el-table-column
          :width="250"
          :label="$t('column_labels.template_name')">
          <template slot-scope="scope">
            <span class="text-xs truncate">
              <text-highlight
                :queries="search"
                :highlightStyle="{ background: 'none'}"
              >
                {{ scope.row.TemplateName }}
              </text-highlight>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!isChatApps"
          :width="150"
          :label="$t('fields.sender_id')">
          <template slot-scope="scope">
            <span class="text-xs">
              {{ scope.row.SenderId }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('column_labels.message')">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover"
              width="400">
              <div class="bg-grey-lighter p-1">
                <div class="p-2 text-xs text-center font-semibold">
                  ({{ scope.row.SenderId }})
                </div>
                <div class="bg-white p-2 text-xs">
                  {{ scope.row.MessageBody }}
                </div>
              </div>
              <span
                class="text-xs truncate"
                slot="reference"
              >
                <text-highlight
                  :queries="search"
                  :highlightStyle="{ background: 'none'}"
                >
                  {{ scope.row.MessageBody }}
                </text-highlight>
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="selectTemplate(scope.row)">
            {{ $t('mcs.messages.index[15]') }}
          </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="flex-shrink mt-10">
      <Pagination
        v-if="total > 0"
        @next="nextPage"
        @prev="prevPage"
        @change-page-no="changePageNo"
        @change-page-size="changePageSize"
        :page-count="pageCount"
        :page-no="pageNo"
        :page-size="pageSize"
        :current-page-size="currentPageSize"
        :page-sizes="[5, 10, 15, 20, 50]"
        :total="total"
      />
    </div>
  </div>
</el-dialog>
</template>
<script>

import TextHighlight from 'vue-text-highlight';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'messages_wa_templates',

  components: {
    Pagination,
    TextHighlight,
  },

  props: {
    selected: {
      type: Object,
      default: () => ({}),
    },

    templates: {
      type: Array,
      default: () => [],
    },

    senderIds: {
      type: Object,
      default: () => ({}),
    },

    modalVisible: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    isChatApps: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      senderId: 'all',

      pageSize: 5,
      pageNo: 0,
    };
  },

  computed: {
    pageCount() {
      if (this.tableData
        && this.tableData.original
        && Array.isArray(this.tableData.original)) {
        return Math.ceil(this.tableData.original.length / this.pageSize);
      }

      return 0;
    },

    currentPageSize() {
      if (this.tableData
        && this.tableData.paginated
        && Array.isArray(this.tableData.paginated)) {
        return this.tableData.paginated.length;
      }

      return 0;
    },

    total() {
      if (this.tableData
        && this.tableData.original
        && Array.isArray(this.tableData.original)) {
        return this.tableData.original.length;
      }

      return 0;
    },

    tableData() {
      let templateData = this.templates.slice(0);
      const start = this.pageNo * this.pageSize;
      const end = start + this.pageSize;

      if (this.senderId !== 'all') {
        templateData = templateData.slice(0).filter(v => v.SenderId === this.senderId);
      }

      if (this.search.trim().length) {
        const searchString = this.$sanitize(this.search);
        const filters = ['TemplateName', 'MessageBody'];
        const result = templateData
          .filter(row => filters.some((k) => {
            if (row[k]) {
              return row[k]
                .replace(/\s+(?=\d)|[+]/g, '')
                .toLowerCase()
                .includes(searchString.replace(/\s+(?=\d)|[+]/g, '').toLowerCase());
            }
            return 0;
          }));

        return {
          paginated: result.slice(start, end) || [],
          original: result || [],
        };
      }

      return {
        paginated: templateData.slice(start, end) || [],
        original: templateData || [],
      };
    },
  },

  watch: {
    'tableData.original': {
      handler(n, o) {
        if (n && Array.isArray(n)
          && o && Array.isArray(o)
          && n.length !== o.length) {
          this.changePageNo(1);
        }
      },
    },
  },

  methods: {
    nextPage() {
      this.pageNo = this.pageNo + 1;
    },

    prevPage() {
      this.pageNo = this.pageNo - 1;
    },

    changePageNo(pageNo) {
      this.pageNo = pageNo - 1;
    },

    changePageSize(size) {
      this.pageSize = size;
      this.pageNo = 0;
    },

    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },

    reset() {
      this.search = '';
      this.senderId = 'all';
      this.pageSize = 5;
      this.pageNo = 0;
    },

    selectTemplate(row) {
      this.reset();
      const r = row;

      r.MessageBody = r.MessageBody;
      this.$emit('select-template', r);
    },
  },
};
</script>
<style lang="scss">
  .sms-templates {
    .min-h-custom {
      min-height: calc(100vh - 400px);
    }
  }
</style>
