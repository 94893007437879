<template>
  <el-dialog
    class="ma-recipient-dialog"
    :title="getTitle()"
    :visible.sync="modalVisible"
    width="909px"
    :before-close="onModalBeforeClose"
    @open="onModalOpen"
  >
    <div>
      <div v-if="warningVisible && type === 'directory'" class="mb-6 py-2 rounded flex border-grey-lightest border w-full">
        <div class="px-4 py-2 border-l-4 border-grey-darker flex w-full">
          <div class="flex-shrink align-top">
            <i class="icon-novo-exclamation text-grey-darker text-2xl mr-4" />
          </div>
          <div class="leading-normal flex-auto text-sm" :style="{wordBreak: 'break-word'}">
            You're seeing only the 8x8 contacts that your user has access to, based on role and permissions.
            Reach out to your administrator for more details or
            <a
              class="text-blue cursor-pointer hover:text-blue-lighter underline"
              rel="noopener nofollow"
              target="_blank"
              href="https://docs.8x8.com/8x8WebHelp/admin-console/Content/role-based-access-control.htm#:~:text=8x8%20Role%2Dbased%20access%20control,their%20role%20within%20an%20organization">
              learn more about role-based access control in 8x8
            </a>.
          </div>
          <div class="flex-shrink">
            <i
              class="icon-novo-close-large text-black hover:text-grey-dark text-grey-darkest font-medium cursor-pointer ml-4 text-xl"
              @click="warningVisible = false"
            />
          </div>
        </div>
      </div>
      <div class="border-grey-lighter border solid flex flex-col w-full relative rounded bg-grey-inputs bg-white">
        <div class="flex-shrink border-b border-grey-lighter px-3 py-3 flex justify-between items-center header-h">
          <div
            v-if="type === 'contacts' || type === 'directory'"
            class="flex items-center font-medium"
          >
            {{ getSelectedTotal ? getSelectedTotal.toLocaleString() : 'No contacts' }} selected
          </div>
          <div
            v-if="type === 'groups'"
            class="flex items-center font-medium"
          >
             {{ getSelectedTotal ? getSelectedTotal.toLocaleString() : 'No groups' }} selected
          </div>
          <div class="flex items-center">
            <div
              v-if="hasSearch"
              class="mr-6"
            >
              <el-input
                v-model="filters.search"
                class=""
                placeholder="Search..."
                :disabled="loading"
                prefix-icon="icon-novo-search"
                :style="{width: '270px'}" @change="handleSearch"
              />
            </div>
            <div v-if="hasFilter" class="mr-6">
              <el-badge class="filter" :value="filterCount" type="warning" :hidden="!filterCount">
                <span class="cursor-pointer icon-novo-filter text-2xl p-1 hover-icon" @click="isMoreFilterVisible = !isMoreFilterVisible" />
              </el-badge>
            </div>
            <span
              class="text-grey-darker flex-shrink block"
            >
              {{ paginationDef.total ? `${(paginationDef.total ).toLocaleString()} results` : 'No results' }}
            </span>
          </div>
        </div>
         <div
            v-if="hasFilter && isMoreFilterVisible"
            class="flex border-b border-grey-lighter px-3 py-3 flex items-center justify-between"
          >
            <div class="flex">
              <div
                v-for="(v, i) in ['departments', 'locations']"
                :key="i"
              >
                <div>
                  <el-dropdown trigger="click"  @command="handleDirectoryFilterCommand">
                    <div
                      role="button"
                      class="cursor-pointer bg-grey-lightest px-2 py-2 mr-3 rounded "
                      :class="{ 'bg-blue-lightest': Boolean(filters[v]) && filters[v] !== 'all' }"
                    >
                      <span class="capitalize">{{ v.replace('s', '') }}</span>
                      <span
                        v-if="filters[v] && filters[v] !== 'all'"
                        class="font-medium text-black"
                      >
                        is <span class="capitalize">{{filters[v]}}</span>
                      </span>
                      <i class="el-icon-arrow-down el-icon--right font-medium"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown" :style="{ height: '300px', overflowY: 'auto', width: '200px'}">
                      <el-dropdown-item
                        v-for="(fv, k) in getFilters(directory, v)"
                        :key="k"
                        :command="`${v}:${fv}`"
                        :class="{'filter-selected' : filters[v] === fv}"
                      >
                        <span class="capitalize">{{ fv }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>

            <div class="text-right">
              <a class="anchor-link no-underline" href="#" @click.prevent="clearFilters">Clear all</a>
            </div>
         </div>
        <div>
          <el-table
            v-loading="loading"
            :data="tableData"
            ref="ctable"
            height="350"
            fit size="small"
            :row-key="(row) => row.id"
            :row-class-name="selectedRowColor"
            @select="handleSelectionChange"
            @select-all="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              fixed reserve-selection
            >
            </el-table-column>

            <el-table-column
              v-if="type == 'contacts'"
              label="Name"
              v-slot="scope"
              prop="firstName"
              sortable
              fixed
            >
              <div class="truncate">
                <span  class="mr-1">
                  {{ scope.row.firstName ? scope.row.firstName : '' }}
                </span>
                <span v-if="scope.row.lastName">
                  {{ scope.row.lastName }}
                </span>
                <span v-else>
                  -
                </span>
              </div>
            </el-table-column>

             <el-table-column
              v-if="type == 'directory'"
              label="Name"
              v-slot="scope"
              prop="firstName"
              sortable
              fixed
              width="300"
            >
              <div class="flex">
                <div class="flex-shrink mr-3">
                  <el-button
                    icon="icon-novo-user"
                    circle
                    disabled
                    size="small"
                  />
                </div>
                <div class="flex flex-col flex-auto">
                  <span  class="block truncate">
                    {{ scope.row.firstName }}  {{ scope.row.lastName }}
                  </span>
                  <span class="block text-grey text-xs">
                    {{ scope.row.jobTitle}}
                  </span>
                </div>
              </div>
            </el-table-column>
            <el-table-column
              v-if="type == 'contacts'"
              label="Phone number"
              sortable v-slot="scope"
              prop="addresses.msisdn"
              fixed
            >
              <span :class="`mr-1 flag-icon flag-icon-squared flag-icon-${getPhoneNumberInfo(`+${scope.row.addresses.msisdn}`).regionCode.toLowerCase()}`" />
              <span
                v-if="scope.row.addresses && scope.row.addresses.msisdn"
                class="truncate"
              >
                 {{ getPhoneNumberInfo(`+${scope.row.addresses.msisdn}`).valid  ? getPhoneNumberInfo(`+${scope.row.addresses.msisdn}`).number.international :  scope.row.addresses.msisdn }}
              </span>
            </el-table-column>

            <el-table-column
              v-if="type == 'directory'"
              label="Phone number"
              sortable
              v-slot="scope"
              prop="phoneNumber"
              fixed
            >
              <span :class="`mr-1 flag-icon flag-icon-squared flag-icon-${getPhoneNumberInfo(`+${scope.row.phoneNumber}`).regionCode.toLowerCase()}`" />
              <span
                v-if="scope.row.phoneNumber"
                class="truncate"
              >
                {{ getPhoneNumberInfo(`+${scope.row.phoneNumber}`).valid  ? getPhoneNumberInfo(`+${scope.row.phoneNumber}`).number.international :  scope.row.phoneNumber }}
              </span>
            </el-table-column>

            <el-table-column
              v-if="type == 'directory'"
              label="Department"
              sortable
              v-slot="scope"
              prop="department"
              fixed
            >
              <span
                class="truncate"
              >
                {{ scope.row.department || '-'}}
              </span>
            </el-table-column>

            <el-table-column
              v-if="type == 'directory'"
              label="Location"
              sortable
              v-slot="scope"
              prop="location"
              fixed
            >
              <span
                class="truncate"
              >
                {{ scope.row.location || '-'}}
              </span>
            </el-table-column>

            <el-table-column
              v-if="type == 'groups'"
              label="Name"
              sortable
              v-slot="scope"
              prop="name"
            >
              <span
                v-if="scope.row.name"
                class="truncate"
              >
                {{ scope.row.name}}
              </span>
              <span>

              </span>
            </el-table-column>

            <el-table-column
              v-if="type == 'groups'"
              label="Description"
              sortable
              v-slot="scope"
              prop="description"
            >
              <span
                class="truncate"
              >
                {{ scope.row.description || '-'}}
              </span>
            </el-table-column>
            <el-table-column
              v-if="type == 'groups'"
              label="Members"
              sortable
              v-slot="scope"
              prop="contacts"
            >
              <span class="truncate">
                {{ scope.row.contacts ? scope.row.contacts.toLocaleString() : 0}}
              </span>
            </el-table-column>


            <div slot="empty">
              <div class="flex flex-col justify-center items-center">
                <Empty />
                <p v-if="type == 'directory' && directory && directory.message" class="text-grey-darker mt-2 el-table__empty-text">
                  {{ directory.message || 'No data' }}
                </p>
                <p v-else class="text-grey-darker mt-2">No data</p>
              </div>
            </div>
          </el-table>
          <div class="flex-shrink px-3 py-2 border-grey-lighter">
            <Pagination
              :filters="filters"
              :loading="loading"
              :data="getData()"
              :pagination-def="paginationDef"
              @handle-page-change="handlePageChange"
            />
          </div>
        </div>
      </div>
      <div class="dialog-footer flex justify-end mt-6">
        <el-button
          type="text"
          class="mr-2"
          @click="handleClose()"
        >
          Close
        </el-button>
        <el-button
          type="primary"
          @click="handleDone()"
          :disabled="disabled()">
          Done
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  mapGetters,
  mapActions,
} from 'vuex';
import _ from 'lodash';

import PhoneNumberMixin from '@/mixins/phone-number';
import Empty from '../../Empty.vue';
import Pagination from './Pagination.vue';

export default {
  name: 'Contacts',

  components: {
    Pagination,
    Empty,
  },

  mixins: [PhoneNumberMixin],

  props: {
    addedContacts: {
      type: Array,
      default: () => [],
    },
    modalVisible: {
      type: Boolean,
      default: false,
    },
    hasFilter: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'contacts',
    },
    shouldNotFetch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      selected: [],
      localData: [],
      warningVisible: true,
      isMoreFilterVisible: true,
      paginationDef: {
        pages: 0,
        total: 0,
        limit: 20,
        currentPage: 1,
        hasPreviousPage: false,
        hasNextPage: false,
        pageLimits: [10, 20, 30, 40, 50],
      },
      filters: {
        search: '',
        departments: 'all',
        locations: 'all',
      },
    };
  },

  computed: {
    ...mapGetters({
      contacts: 'contacts/getContacts',
      groups: 'contacts/getContactGroups',
      directory: 'contacts/getContactDirectories',
      user: 'user/getUser',
    }),

    filterCount() {
      const count = Object.keys(this.filters).reduce((a, b) => {
        if (b === 'departments' && this.filters[b] && this.filters[b] !== 'all') {
          // eslint-disable-next-line no-param-reassign
          a += 1;
        }

        if (b === 'locations' && this.filters[b] && this.filters[b] !== 'all') {
          // eslint-disable-next-line no-param-reassign
          a += 1;
        }

        return a;
      }, 0);

      return count;
    },

    getSelectedTotal() {
      // eslint-disable-next-line no-nested-ternar
      const { selected } = this;

      return selected.length;
    },

    tableData() {
      if (!this.shouldNotFetch && this.type !== 'directory') {
        return this.localData;
      }

      const selected = this.localData;
      const { limit, currentPage } = this.paginationDef;
      const start = (currentPage - 1) * limit;
      const end = start + limit;
      const paginated = selected.slice(start, end);
      return paginated;
    },
  },

  mounted() {
    this.reset();

    this.fetchData(true);
  },

  watch: {
    tableData(v) {
      if (v.length) {
        this.$nextTick(() => {
          this.toggleSelection(v);
        });
      }
    },
  },

  methods: {
    ...mapActions({
      getContacts: 'contacts/getContacts',
      getContactGroups: 'contacts/getContactGroups',
      getContactsFromDirectories: 'contacts/getContactsFromDirectories',
    }),

    getTitle() {
      const title = {
        contacts: 'Select 8x8 Connect contacts',
        groups: 'Select 8x8 Connect contact groups',
        directory: 'Select company contacts',
      };

      return title[this.type];
    },

    getFilters(directory, value) {
      if (directory && directory.filters && directory.filters[value]) {
        return ['all'].concat(directory.filters[value]);
      }

      return [];
    },

    clearFilters() {
      this.filters = {
        search: '',
        departments: 'all',
        locations: 'all',
      };

      this.fetchViaApi();
    },

    handleDirectoryFilterCommand(command) {
      const splitAction = command.split(':');
      const filter = splitAction[0];
      const value = splitAction[1];

      this.filters[filter] = value;
      this.paginationDef = {
        ...this.paginationDef,
        currentPage: 1,
      };
      this.fetchViaApi();
    },

    getData() {
      return this[this.type];
    },

    onModalOpen() {
      this.reset();

      this.fetchData(true);
    },

    disabled() {
      const { selected, addedContacts } = this;
      const [long, short] = addedContacts.length > selected.length ? [addedContacts, selected] : [selected, addedContacts];
      const hasDiff = _.differenceBy(long, short, 'id');

      if (!selected.length || (addedContacts.length && !hasDiff.length)) {
        return true;
      }

      return false;
    },

    onModalBeforeClose(done) {
      this.reset();
      this.$emit('close-modal');
      done();
    },

    fetchData(firstLoad) {
      if (this.shouldNotFetch) {
        this.fetchViaLocal(firstLoad);
      } else {
        this.fetchViaApi(firstLoad);
      }
    },

    fetchViaLocal(firstLoad = false) {
      const { limit } = this.paginationDef;
      const self = this;

      this.localData = this.addedContacts.slice(0);

      if (this.addedContacts.length && firstLoad) {
        this.selected = [].concat([...this.localData]);
      }

      this.paginationDef = {
        ...this.paginationDef,
        ...{
          pages: Math.ceil(self.localData.length / limit),
          total: self.localData.length,
        },
      };

      const { currentPage, pages } = this.paginationDef;

      this.paginationDef = {
        ...this.paginationDef,
        ...{
          hasPreviousPage: currentPage !== 1 && pages > 1,
          hasNextPage: currentPage < pages && pages > 1,
        },
      };
    },

    async fetchViaApi(firstLoad = false) {
      try {
        this.loading = true;
        const { search } = this.filters;
        const { limit, currentPage } = this.paginationDef;
        const { AccountId: accountId } = this.user;

        const searchStr = this.$sanitize(search.trim());
        const offset = (Number(currentPage) - 1) * limit;

        if (this.type === 'contacts') {
          await this.getContacts({
            accountId, query: searchStr, limit, offset,
          });

          this.localData = this.contacts.items ? this.contacts.items : [];
        }

        if (this.type === 'groups') {
          await this.getContactGroups({
            accountId, name: searchStr, limit, offset,
          });

          this.localData = this.groups.items ? this.groups.items : [];
        }

        if (this.type === 'directory') {
          await this.getContactsFromDirectories({
            accountId, filters: this.filters, limit, currentPage,
          });

          this.localData = this.directory.items.length ? this.directory.items : [];
        }

        if (this.addedContacts.length && firstLoad) {
          this.selected = [].concat([...this.addedContacts]);
        }

        const {
          totalFiltered, totalPages, page, hasPreviousPage, hasNextPage,
        } = this[this.type];

        this.paginationDef = {
          ...this.paginationDef,
          ...{
            pages: totalPages, total: totalFiltered, currentPage: page, hasPreviousPage, hasNextPage,
          },
        };
      } catch (error) {
        this.$showError(this, error);
      } finally {
        this.loading = false;
      }
    },

    handleSelectionChange(selection = []) {
      const uncheckedRows = this.tableData.filter(v => !selection.some(s => s.id === v.id));

      this.selected = _.unionBy([...this.selected], selection, 'id');

      if (uncheckedRows.length) {
        this.selected = this.selected.filter(v => !uncheckedRows.some(s => s.id === v.id));
      }
    },

    toggleSelection(items = []) {
      // eslint-disable-next-line no-nested-ternary
      const { selected } = this;
      if (selected.length) {
        items.forEach((row) => {
          if (this.$refs.ctable && selected.find(v => v.id === row.id)) {
            this.$refs.ctable.toggleRowSelection(row, true);
          }
        });
      }
    },

    handleDone() {
      let toBeRemoved = [];

      if (this.addedContacts.length) {
        toBeRemoved = this.addedContacts.filter(v => !this.selected.some(s => s.id === v.id));
      }

      this.$emit('add-to-main-list', this.selected, toBeRemoved, this.type, this.shouldNotFetch);
      this.reset();
      this.$emit('close-modal');
    },

    handleClose() {
      this.reset();
      this.$emit('close-modal');
    },

    handlePageChange(def = {}) {
      this.paginationDef = { ...this.paginationDef, ...def };

      this.fetchData();
    },

    handleSearch() {
      this.paginationDef.currentPage = 1;
      this.fetchData();
    },

    // eslint-disable-next-line no-unused-vars
    selectedRowColor({ row, rowIndex }) {
      if (this.$refs.ctable && this.$refs.ctable.selection.find(element => element.id === row.id)) {
        return 'row-tb-cls ';
      }
      return '';
    },

    reset() {
      this.selected = [];
      this.localData = [];
      this.filterCount = 0;
      this.warningVisible = true;
      this.isMoreFilterVisible = true;
      this.paginationDef = {
        pages: 0,
        total: 0,
        limit: 20,
        currentPage: 1,
        hasPreviousPage: false,
        hasNextPage: false,
        pageLimits: [10, 20, 30, 40, 50, 100],
      };
      this.filters = {
        search: '',
        departments: 'all',
        locations: 'all',
      };

      if (this.$refs.ctable) {
        this.$refs.ctable.clearSelection();
      }
    },

  },
};
</script>
<style>
  .ma-recipient-dialog .el-dialog .el-table tr.el-table__row.row-tb-cls>td {
    background-color: #CCDDF9 !important;
  }

  .ma-recipient-dialog .el-dialog .el-table tr.el-table__row.hover-row>td {
    background-color: #CCDDF9 !important;
  }

  .filter-selected {
    background-color: #CCDDF9 ;
  }

  .ma-recipient-dialog .el-dialog .el-table thead tr th .el-checkbox__inner{
    margin-left: 4px !important;
  }

  .header-h {
    min-height: 56px;
  }

  .el-table__empty-text {
    width: 100% !important;
  }
</style>
